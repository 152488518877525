import React from 'react'

export default class IconWarning extends React.PureComponent {

    render() {
        return (
            <svg className="attention__icon" width="30px" height="25px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs />
                <g id="master" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="invitro_analiz_in-notice" transform="translate(-103.000000, -287.000000)">
                        <g id="notice-copy-2" transform="translate(0.000000, 269.000000)">
                            <g id="etan" transform="translate(104.000000, 19.000000)">
                                <g id="Group">
                                    <path id="Triangle"
                                          d="M15.6959966,1.71359457 L27.0875013,19.9400021 C27.672922,20.8766752 27.3881757,22.1105759 26.4515026,
                                          22.6959966 C26.1336394,22.8946611 25.7663442,23 25.3915047,23 L2.60849528,23 C1.50392578,23 0.608495283,
                                          22.1045695 0.608495283,21 C0.608495283,20.6251606 0.713834173,20.2578653 0.912498675,19.9400021 L12.3040034,
                                          1.71359457 C12.8894241,0.77692151 14.1233248,0.492175181 15.0599979,1.07759584 C15.3175361,
                                          1.23855726 15.5350352,1.45605631 15.6959966,1.71359457 Z"
                                          stroke="#FF6A13" strokeWidth="2" />
                                    <rect id="Rectangle-12" fill="#FF6A13" x="13" y="8" width="2" height="8" />
                                    <rect id="Rectangle-12-Copy" fill="#FF6A13" x="13" y="18" width="2" height="2" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

}
