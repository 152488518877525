import { LKE_KK } from 'themes/invitro/IsInvitro';

export enum CATEGORIES {
    INVITRO_NEWS = 'INVITRO_NEWS',
    NEW_RESEARCH = 'NEW_RESEARCH',
    TECHNOLOGICAL_INFORMATION = 'TECHNOLOGICAL_INFORMATION',
    BLANKS = 'BLANKS',
    INSTRUCTIONS_DOCTOR = 'INSTRUCTIONS_DOCTOR',
    INSTRUCTIONS_NURSE = 'INSTRUCTIONS_NURSE',
    TRAINING_COURSE = 'TRAINING_COURSE'
}

export const NEWS_CATEGORIES: Record<CATEGORIES, { name: string; path: CATEGORIES; localeKey?: string; siteStyle?: string; static?: boolean }> = {
    INVITRO_NEWS: {
        name: 'Новости {{nameCompany}}',
        path: CATEGORIES.INVITRO_NEWS,
        localeKey: 'Common.news'
    },
    NEW_RESEARCH: {
        name: 'Новые исследования',
        path: CATEGORIES.NEW_RESEARCH
    },
    TECHNOLOGICAL_INFORMATION: {
        name: 'Технологическая информация',
        path: CATEGORIES.TECHNOLOGICAL_INFORMATION
    },
    BLANKS: {
        name: 'Бланки',
        path: CATEGORIES.BLANKS
    },
    INSTRUCTIONS_DOCTOR: {
        name: 'Инструкции врача',
        path: CATEGORIES.INSTRUCTIONS_DOCTOR
    },
    INSTRUCTIONS_NURSE: {
        name: 'Инструкции медсестры',
        path: CATEGORIES.INSTRUCTIONS_NURSE,
        siteStyle: LKE_KK
    },
    TRAINING_COURSE: {
        name: 'Обучающий курс по преаналитическим требованиям',
        path: CATEGORIES.TRAINING_COURSE,
        siteStyle: LKE_KK,
        static: true
    }
};
