import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';

export const LKE_KK = 'LKE_KK';

const IsInvitro = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
    const { siteSetting } = useSelector((state: AppTypes.State) => state.startup.settings);
    return siteSetting.siteStyle === LKE_KK ? <Fragment>{children}</Fragment> : null;
};

export default React.memo(IsInvitro);
