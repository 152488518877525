import React, { useEffect, useState } from 'react';
import styles from './UserProfile.module.css';
import invitroUserPhoto from 'page/layout/header/profile/invitroUserPhoto.png';
import vetunionUserPhoto from 'page/layout/header/profile/vetunionUserPhoto.png';
import logoutIcon from 'themes/icons/logout.svg';
import mailIcon from 'themes/icons/mail.svg';
import { LOGOUT } from 'redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import selectedOfficeIcon from 'themes/icons/arrow.svg';
import { SELECT_OFFICE_OR_GROUP, SET_FAVORITE_OFFICE } from 'redux/office/actions';
import { AppTypes } from 'types/AppTypes';
import { GET_CURRENT_USER } from 'redux/user/actions';
import { OfficesTypes } from 'types/OfficesTypes';
import ReactTooltip from 'react-tooltip';
import { SELECT_MENU_TYPE } from 'constants/SelectMenuType';
import { useTranslation } from 'react-i18next';
import IsInvitro from 'themes/invitro/IsInvitro';
import IsVetunion from 'themes/vetunion/IsVetunion';
import closePopupIcon from 'themes/icons/close.svg';
import Icon from 'components/icon/Icon';
import searchOfficesIcon from 'themes/icons/search.svg';
import officeClapsIcon from 'themes/icons/clasp.svg';
import { actionsSelector } from 'redux/app/selectors';

const UserProfile = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const [showCompanyInfo, setShowCompanyInfo] = useState(false);
    const [showOfficeListMenu, setShowOfficeListMenu] = useState(false);
    const { offices, selectedOfficeOrGroup, allOfficeGroups } = useSelector((state: AppTypes.State) => state.offices);
    const [searchString, setSearchString] = useState<string>('');
    const [foundOffices, setFoundOffices] = useState<OfficesTypes.OfficeType[]>([]);
    const [foundOfficeGroups, setFoundOfficeGroups] = useState<OfficesTypes.OfficeGroupType[]>([]);
    const [showOffices, setShowOffices] = useState<boolean>(false);
    const [showOfficeGroups, setShowOfficeGroups] = useState<boolean>(false);
    const { currentUser } = useSelector((state: AppTypes.State) => state.user);
    const actions = useSelector(actionsSelector);

    useEffect(() => ReactTooltip.rebuild() as () => void);

    useEffect(() => {
        if (!currentUser) {
            dispatch(GET_CURRENT_USER.base());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener('click', () => setShowOfficeListMenu(false));
        return document.removeEventListener('click', () => {
            return;
        });
    }, []);

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    useEffect(() => {
        search(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[SET_FAVORITE_OFFICE.BASE]?.loading]);

    const search = (refresh?: boolean) => {
        if (searchString.length > 0 || refresh) {
            const filteredOffices = offices?.filter(
                (office) =>
                    office?.name.toUpperCase()?.indexOf(searchString) >= 0 ||
                    office?.clientCode.toUpperCase()?.indexOf(searchString) >= 0 ||
                    office?.fullName.toUpperCase()?.indexOf(searchString) >= 0
            );

            const filteredOfficeGroups = allOfficeGroups?.filter((group) => group?.name.toUpperCase()?.indexOf(searchString) >= 0);

            setFoundOffices([...filteredOffices]);
            setFoundOfficeGroups([...filteredOfficeGroups]);
            // eslint-disable-next-line react-hooks/exhaustive-deps

            if (filteredOffices.length < 1 || filteredOfficeGroups.length < 1) {
                setShowOffices(false);
                setShowOfficeGroups(false);
            }

            if (filteredOffices.length > 0 && filteredOfficeGroups.length < 1) {
                setShowOffices(true);
                setShowOfficeGroups(false);
            }

            if (filteredOffices.length < 1 && filteredOfficeGroups.length > 0) {
                setShowOffices(false);
                setShowOfficeGroups(true);
            }
        } else {
            setShowOffices(false);
            setShowOfficeGroups(false);
        }
    };

    const renderOfficeAndGroupList = (offices: OfficesTypes.OfficeType[], officeGroups: OfficesTypes.OfficeGroupType[]) => {
        if (offices.length < 1 && officeGroups.length < 1) {
            return null;
        }
        return (
            <div className={styles.officeListMenu}>
                <div className={actions[SET_FAVORITE_OFFICE.BASE]?.loading ? styles.officeListOverlay : ''}>
                    {offices.length > 0 && (
                        <div>
                            <span
                                onClick={() => {
                                    setShowOffices((prevState) => !prevState);
                                }}
                                className={styles.linkShow}
                            >
                                {t(`Common.offices`)}
                                <Icon
                                    style={{ right: 0, marginLeft: '5px' }}
                                    icon={`${selectedOfficeIcon}#arrow`}
                                    className={classNames([styles.selectMenu, showOffices ? styles.selectedOfficeIconRotate : '', styles.selectedOfficeIcon])}
                                />
                            </span>
                        </div>
                    )}
                    {offices?.map((office) => (
                        <div key={office.id}>
                            {showOffices && (
                                <div className={styles.officeListLinkWrapper}>
                                    <div
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setShowOfficeListMenu(false);
                                            dispatch(SELECT_OFFICE_OR_GROUP.base({ id: office.id, type: SELECT_MENU_TYPE.OFFICE }));
                                            setSearchString('');
                                        }}
                                        className={styles.officeListLink}
                                    >
                                        {office.clientCode} / {office.fullName}
                                    </div>
                                    <Icon
                                        onClick={() => dispatch(SET_FAVORITE_OFFICE.base({ officeId: office.id, flag: !office.favorite }))}
                                        setDefaultStyles={false}
                                        width={'20px'}
                                        height={'20px'}
                                        icon={`${officeClapsIcon}#claps`}
                                        className={classNames([styles.officeClaps, office.favorite && styles.officeClapsActive])}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {officeGroups.length > 0 && (
                    <div className={styles.officeListItemGroups}>
                        <div>
                            <span
                                onClick={() => {
                                    setShowOfficeGroups((prevState) => !prevState);
                                }}
                                className={styles.linkShow}
                            >
                                {t(`Common.officeGroups`)}
                                <Icon
                                    style={{ right: 0, marginLeft: '5px' }}
                                    icon={`${selectedOfficeIcon}#arrow`}
                                    className={classNames([
                                        styles.selectMenu,
                                        showOfficeGroups ? styles.selectedOfficeIconRotate : '',
                                        styles.selectedOfficeIcon
                                    ])}
                                />
                            </span>
                        </div>
                        {officeGroups?.map((officeGroup) => {
                            const clientCodes: string[] = [];
                            officeGroup?.offices?.forEach((office) => clientCodes.push(office.clientCode));
                            return (
                                showOfficeGroups && (
                                    <div key={officeGroup.id} data-for={'userProfile'} data-tip={`<div>${clientCodes.join('<br/>')}</div>`}>
                                        <div
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setShowOfficeListMenu(false);
                                                dispatch(SELECT_OFFICE_OR_GROUP.base({ id: officeGroup.id, type: SELECT_MENU_TYPE.OFFICE_GROUP }));
                                                setSearchString('');
                                            }}
                                            className={styles.officeListLink}
                                        >
                                            {officeGroup.name}
                                        </div>
                                    </div>
                                )
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <IsInvitro>
                <img className={styles.photo} src={invitroUserPhoto} alt={t(`UserProfile.avatar`)} />
            </IsInvitro>
            <IsVetunion>
                <img className={styles.photo} src={vetunionUserPhoto} alt={t(`UserProfile.avatar`)} />
            </IsVetunion>
            <div className={styles.content}>
                <div className={styles.name}>{currentUser?.nickname}</div>
                <Link className={styles.profileLink} to="/settings/cabinet">
                    {t(`UserProfile.myProfile`)}
                </Link>
                <div className={styles.companyInfo}>
                    <div onClick={() => setShowCompanyInfo(!showCompanyInfo)} className={styles.companyInfoTitle}>
                        {t(`UserProfile.informationOrganization`)}
                    </div>
                    {showCompanyInfo && (
                        <div className={styles.popupCompanyInfo}>
                            {Array.isArray(selectedOfficeOrGroup?.offices) ? (
                                <h6>{t(`UserProfile.groupOffices`)}</h6>
                            ) : (
                                <h6>{t(`UserProfile.informationOrganization`)}</h6>
                            )}
                            <Icon onClick={() => setShowCompanyInfo(!showCompanyInfo)} icon={`${closePopupIcon}#close`} className={styles.closePopupIcon} />
                            <div className={styles.name}>
                                {Array.isArray(selectedOfficeOrGroup?.offices) ? (
                                    <span>{selectedOfficeOrGroup?.name}</span>
                                ) : (
                                    <span>
                                        {selectedOfficeOrGroup?.clientCode} / {selectedOfficeOrGroup?.organizationFullName}
                                    </span>
                                )}
                            </div>
                            <div className={styles.popupCompanyContacts}>
                                {selectedOfficeOrGroup?.admins?.map((admin, index) => (
                                    <div key={index}>
                                        <Icon icon={`${mailIcon}#mail`} className={styles.mailIcon} />
                                        {admin.nickname && <span className={styles.popupCompanyContactLink}>{admin.nickname}</span>}
                                        {admin.email && (
                                            <span className={styles.popupCompanyEmail}>
                                                , <a href={`mailto:${admin.email}`}>{admin.email}</a>
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={styles.group}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <div
                        className={styles.selectedOfficeBlock}
                        onClick={() => {
                            (offices?.length > 0 || allOfficeGroups?.length > 0) && setShowOfficeListMenu(!showOfficeListMenu);
                        }}
                    >
                        <div className={styles.profileLabel}>{selectedOfficeOrGroup?.clientCode || selectedOfficeOrGroup?.name}</div>
                        <Icon
                            icon={`${selectedOfficeIcon}#arrow`}
                            className={classNames([showOfficeListMenu ? styles.selectedOfficeIconRotate : '', styles.selectedOfficeIcon])}
                        />
                        <a
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                dispatch(LOGOUT.base());
                            }}
                            className={styles.logout}
                            href="/logout"
                        >
                            <Icon icon={`${logoutIcon}#logout`} className={styles.logoutIcon} />
                        </a>
                    </div>
                    <div className={classNames([showOfficeListMenu ? styles.menuShow : styles.menuHidden])}>
                        {(offices.length > 10 || allOfficeGroups.length > 10) && (
                            <div className={styles.searchOffices}>
                                <Icon icon={`${searchOfficesIcon}#search`} className={styles.searchOfficesIcon} />
                                <input
                                    value={searchString}
                                    type="search"
                                    onChange={({ target }) => setSearchString(target.value?.toUpperCase())}
                                    placeholder={t(`Common.search`)}
                                />
                            </div>
                        )}
                        {renderOfficeAndGroupList(
                            searchString.length > 0 ? foundOffices : offices,
                            searchString.length > 0 ? foundOfficeGroups : allOfficeGroups
                        )}
                    </div>
                </div>
            </div>
            {
                // @ts-ignore
                <ReactTooltip
                    id={'userProfile'}
                    textColor={'#ffffff'}
                    backgroundColor={'#8a8a8a'}
                    resizeHide={true}
                    html={true}
                    type={'light'}
                    effect={'solid'}
                    place={'right'}
                />
            }
        </div>
    );
};

export default React.memo(UserProfile);
