import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styles from './FormOfficeGroups.module.css';
import classNames from 'classnames';
import isEmpty from 'validator/lib/isEmpty';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import { OfficesTypes } from 'types/OfficesTypes';
import { PermissionType } from 'constants/PermissionType';
import { AppTypes } from 'types/AppTypes';
import { CREATE_OFFICE_GROUP, UPDATE_OFFICE_GROUP, FETCH_OFFICE_GROUP, FETCH_OFFICES_FOR_GROUP } from 'redux/office/actions';
import { useTranslation } from 'react-i18next';

type TProps = {
    edit?: boolean;
    officeGroup?: OfficesTypes.OfficeGroupType | null;
};

const FormOfficeGroup: FC<TProps> = ({ edit, officeGroup }) => {
    const { officesForGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [groupName, setGroupName] = useState('');
    const [searchString, setSearchString] = useState<string>('');
    const [selectedOffices, setSelectedOffices] = useState<string[]>([]);
    const [errorGroupName, setErrorGroupName] = useState<boolean>(false);
    const [errorOffices, setErrorOffices] = useState<boolean>(false);
    const { t } = useTranslation('common');
    const actions = useSelector(actionsSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (officeGroup && edit) {
            officeGroup?.offices?.forEach((office) => setSelectedOffices((prevState) => [...prevState, office.id]));
            setGroupName(officeGroup.name);
        }
    }, [officeGroup, edit]);

    // @ts-ignore
    useEffect(() => {
        dispatch(FETCH_OFFICES_FOR_GROUP.base({ permissions: [PermissionType.RESULTS] }));
        return () => dispatch(FETCH_OFFICE_GROUP.success());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOfficeClick = (officeId: string) =>
        setSelectedOffices((prevState) => {
            if (!selectedOffices.includes(officeId)) {
                return [...prevState, officeId];
            } else {
                return [...selectedOffices.filter((office: string) => office !== officeId)];
            }
        });

    return (
        <div className={styles.container}>
            <div className={styles.backLink}>
                ← <Link to={'/settings/officeGroups'}>{t(`Common.back`)}</Link>
            </div>
            {(actions[FETCH_OFFICE_GROUP.BASE]?.loading && edit) || actions[FETCH_OFFICES_FOR_GROUP.BASE]?.loading ? (
                <Preloader />
            ) : (
                <>
                    <div className={styles.settingRow}>
                        <label>{t(`FormOfficeGroup.groupName`)}</label>
                        <input
                            className={classNames([errorGroupName ? styles.error : null, styles.groupName])}
                            onChange={(e) => setGroupName(e.target.value)}
                            type="text"
                            value={groupName}
                        />
                        <br />
                    </div>
                    <div className={styles.offices}>
                        <div className={styles.selectedOfficeList}>
                            <div>{t(`FormOfficeGroup.officesAddedGroup`)}</div>
                            <div style={{ height: '530px' }} className={classNames([errorOffices ? styles.error : null, styles.officeList])}>
                                {officesForGroup?.map((office: OfficesTypes.OfficesForGroupType) =>
                                    selectedOffices.includes(office.id) ? (
                                        <div key={office.id}>
                                            <input
                                                onChange={() => handleOfficeClick(office.id)}
                                                type="checkbox"
                                                checked={selectedOffices.includes(office.id)}
                                            />
                                            <span onClick={() => handleOfficeClick(office.id)}>{office.clientCode}</span>
                                        </div>
                                    ) : null
                                )}
                            </div>
                        </div>
                        <div className={styles.availableOfficeList}>
                            <div>{t(`FormOfficeGroup.availableOffices`)}</div>
                            {officesForGroup && officesForGroup.length > 15 && (
                                <Fragment>
                                    <input
                                        style={{ width: '100%', marginTop: '5px' }}
                                        placeholder={t(`FormOfficeGroup.findOffice`)}
                                        onChange={(e) => setSearchString(e.target.value)}
                                        type="search"
                                        value={searchString}
                                    />
                                    <br />
                                </Fragment>
                            )}
                            <div className={styles.officeList}>
                                {officesForGroup &&
                                    [
                                        ...officesForGroup?.filter((office: OfficesTypes.OfficesForGroupType) =>
                                            !isEmpty(searchString, { ignore_whitespace: true })
                                                ? office?.clientCode?.toUpperCase()?.indexOf(searchString.trim().toUpperCase()) >= 0
                                                : office
                                        )
                                    ]?.map((office) => (
                                        <div key={office.id} style={{ background: selectedOffices.includes(office.id) ? '#e5f4f6' : 'inherit' }}>
                                            <input
                                                onChange={() => handleOfficeClick(office.id)}
                                                type="checkbox"
                                                checked={selectedOffices.includes(office.id)}
                                            />
                                            <span onClick={() => handleOfficeClick(office.id)}>{office.clientCode}</span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <button
                            onClick={() => {
                                if (!isEmpty(groupName, { ignore_whitespace: true }) && selectedOffices.length > 0) {
                                    edit && officeGroup
                                        ? dispatch(
                                              UPDATE_OFFICE_GROUP.base({
                                                  id: officeGroup.id,
                                                  name: groupName,
                                                  offices: [...selectedOffices]
                                              })
                                          )
                                        : dispatch(
                                              CREATE_OFFICE_GROUP.base({
                                                  name: groupName,
                                                  offices: [...selectedOffices]
                                              })
                                          );
                                    navigate('/settings/officeGroups', { state: { isForm: true } });
                                } else {
                                    setErrorGroupName(() => isEmpty(groupName, { ignore_whitespace: true }));
                                    setErrorOffices(selectedOffices.length < 1);
                                }
                            }}
                        >
                            {t(`Common.create`)}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default React.memo(FormOfficeGroup);
