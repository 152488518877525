import { decode } from 'js-base64';
import isBase64 from 'validator/lib/isBase64';

export function loadFile(response: any, type = 'application/pdf') {
    const blob = new Blob([response.data], { type });
    const link = document.createElement('a');
    let filename = '';
    const disposition = response.headers['content-disposition'];
    if ((disposition && disposition.indexOf('attachment') !== -1) || disposition.indexOf('inline') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }
    link.href = window.URL.createObjectURL(blob);
    if (isBase64(filename)) {
        link.download = `${decode(filename)}`;
    } else {
        link.download = `${filename}`;
    }
    link.click();
    return true;
}

export function printFile(response: any) {
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus();
            iframe.contentWindow?.print();
        }, 1);
    };
    return true;
}
