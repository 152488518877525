import React from 'react';
import classNames from 'classnames/bind';

import Button from 'components/button/Button';

import styles from './ButtonSecondary.module.css';

export default class ButtonSecondary extends React.PureComponent {
    render() {
        const { className, ...rest } = this.props;
        return <Button className={classNames(styles.button, className)} {...rest} />;
    }
}
