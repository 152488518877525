import React from 'react';
import styles from './TestValue.module.css';
import classNames from 'classnames/bind';

const TestValue = (props) => {
    const { test, className, mark, inProgressText } = props;
    if (typeof test.value === 'undefined') {
        return null;
    }
    const { unit, value, cultures, laboratoryComment } = test;
    const outbound = test.outbound;
    const showUnit = !isNaN(parseFloat(value)) && unit;
    const inProgress = value === null && laboratoryComment === null && (!cultures || cultures.length <= 0);

    if (inProgress) {
        return inProgressText;
    }

    if (value) {
        return (
            <div className={classNames(mark ? styles.outboundMark : null)}>
                {mark && <span className={classNames(outbound ? styles.outbound : null, styles.mark)} />}
                <span className={classNames(styles.value, outbound ? styles.outbound : null, className)}>
                    {`${value}${showUnit ? ' ' + unit : ''}${outbound ? ' *' : ''}`}
                </span>
            </div>
        );
    }

    if (cultures) {
        return (
            <div>
                {cultures.map((culture, index) => {
                    const hasResistances = culture.resistances && culture.resistances.length > 0;
                    const cultureResistanceClassName = hasResistances ? styles.cultureResistance : null;
                    const secondCultureClassName = index > 0 ? styles.secondCulture : null;
                    const cultureClassName = classNames(styles.culture, cultureResistanceClassName, secondCultureClassName);

                    return (
                        <div key={culture.finding}>
                            <div className={cultureClassName}>
                                <div>{culture.finding}</div>
                                <div>{culture.growth}</div>
                            </div>
                            {hasResistances && (
                                <div className={styles.resistanceBlock}>
                                    {culture.resistances.map((resistance) => (
                                        <div key={resistance.antibiotic} className={styles.resistance}>
                                            <span className={styles.antibiotic}>{resistance.antibiotic}</span>
                                            <span>{resistance.sir}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    return '';
};

export default React.memo(TestValue);
