import React, { Fragment, useEffect } from 'react';
import styles from 'page/auth/confirm/ChangePassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_EMAIL } from 'redux/user/actions';
import { actionsSelector } from 'redux/app/selectors';
import Notification from 'components/notification/Notification';
import { SET_ACTIONS } from 'redux/app/actions';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');

const UpdateEmailPage = () => {
    const dispatch = useDispatch();
    const actions = useSelector(actionsSelector);
    const location: any = useLocation();
    const parsedLocationString = queryString.parse(location.search);
    const { t } = useTranslation('common');

    useEffect(() => {
        dispatch(
            UPDATE_EMAIL.base({
                email: parsedLocationString.email,
                code: parsedLocationString.code
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [UPDATE_EMAIL.BASE]: null
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`UpdateEmailPage.title`)}</h1>
            <div className={styles.content}>
                {actions[UPDATE_EMAIL.BASE]?.success && (
                    <div className={styles.noticeBlock}>
                        <Notification type={'success'}>{t(`UpdateEmailPage.noticeInfo`)}</Notification>
                        <br />
                        <a href="/login">{t(`Common.linkLogin`)}</a>
                    </div>
                )}
                {actions[UPDATE_EMAIL.BASE]?.error && (
                    <div className={styles.noticeBlock}>
                        {actions[UPDATE_EMAIL.BASE]?.error?.errors?.map((error) => (
                            <Fragment>
                                <Notification type={'error'}>
                                    <p className={styles.notice}>{error.message}</p>
                                </Notification>
                                <br />
                            </Fragment>
                        ))}
                        <a href="/login">{t(`Common.linkLogin`)}</a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(UpdateEmailPage);
