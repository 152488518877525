import React, { Fragment, useEffect } from 'react';
import styles from './NewsDetailsPage.module.css';
import HeaderSubMenu from 'page/layout/menu/HeaderSubMenu';
import { DOWNLOAD_FILE, GET_NEWS_BY_ID } from 'redux/news/actions';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import { formatDate, parseISO } from 'utils/timeUtils';
import { AppTypes } from 'types/AppTypes';
import { Link, NavLink, useParams } from 'react-router-dom';
import { NewsTypes } from 'types/NewsTypes';
import fileIcon from './fileIcon.jpg';
import { useTranslation } from 'react-i18next';
import IsAvailable from 'components/access/IsAvailable';

const NewsDetailsPage = () => {
    const { newsById } = useSelector((state: AppTypes.State) => state.news);
    const { t } = useTranslation('common');
    const actions = useSelector(actionsSelector);
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(GET_NEWS_BY_ID.base({ newsId: params.newsId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.container}>
            <IsAvailable options={[{ news: true }]}>
                <HeaderSubMenu />
                <div className={styles.content}>
                    {actions[GET_NEWS_BY_ID.BASE]?.loading ? (
                        <Preloader />
                    ) : (
                        newsById?.id && (
                            <Fragment>
                                <article className={styles.article}>
                                    <h1 className={styles.title}>{newsById.title}</h1>
                                    <div className={styles.date}>{newsById.startDate && formatDate(parseISO(newsById.startDate), 'ru', 'dd MMMM yyyy')}</div>
                                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: newsById.content }} />
                                </article>
                                {newsById.files && newsById?.files?.length > 0 && (
                                    <div className={styles.files}>
                                        {newsById?.files?.map((file: NewsTypes.File, i) => (
                                            <div className={styles.file} key={i}>
                                                <NavLink
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        dispatch(DOWNLOAD_FILE.base({ id: file.id }));
                                                    }}
                                                    to={`/download/${file.id}`}
                                                >
                                                    <img className={styles.fileIcon} alt="" src={fileIcon} />
                                                    <span>{file.name}</span>
                                                </NavLink>
                                                <span className={styles.fileExtension}>{file?.contentType?.split('/')[1]?.toUpperCase()}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Fragment>
                        )
                    )}
                    {newsById?.category && (
                        <div className={styles.newsBack}>
                            <Link to={`/news?category=${newsById?.category}`}>{t(`NewsDetailsPage.backToNews`)}</Link>
                        </div>
                    )}
                </div>
            </IsAvailable>
        </div>
    );
};

export default React.memo(NewsDetailsPage);
