import React, { useEffect, useRef } from 'react';
import classnames from 'classnames/bind';
import Preloader from 'components/preloader/Preloader';
import Modal from 'components/modal/Modal';
import OfficeList from 'page/preorders/create/components/list/OfficeList';
import OfficeMap from 'page/preorders/create/components/map/OfficeMap';
import styles from './OfficeSelectionModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CHOOSE_OFFICE, FETCH_OFFICES, SELECT_OFFICE, SET_FILTER, SHOW_OFFICE_SELECTION_MODAL } from 'redux/preorders/actions';
import { AppTypes } from 'types/AppTypes';
import { cartDataProductsArticlesSelector, cartDataProductsIdsSelector, filteredOfficesSelector } from 'redux/preorders/selectors';
import { actionsSelector } from 'redux/app/selectors';
const classNames = classnames.bind(styles);

const OfficeSelectionModal = ({ preorder, setPreorder, selectedOfficeId, loading }: any) => {
    const productsIds = useSelector((state: AppTypes.State) => cartDataProductsIdsSelector(state));
    const productsArticles = useSelector((state: AppTypes.State) => cartDataProductsArticlesSelector(state));
    const { position, filter, showOfficeSelectionModal } = useSelector((state: AppTypes.State) => state.preorders.preorder);
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const filteredOffices = useSelector((state: AppTypes.State) => filteredOfficesSelector(state));
    const dispatch = useDispatch();
    const searchInputRef = useRef<any>();

    useEffect(() => {
        if (showOfficeSelectionModal) {
            dispatch(FETCH_OFFICES.base({ articles: [...productsArticles] }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOfficeSelectionModal]);

    const renderContent = () => {
        const classNameAdaptiveMode = classNames({
            officesContainer: true,
            officesNotFound: !filteredOffices || filteredOffices.length === 0
        });

        const selectOffice = (itemOffice: any) => {
            if (itemOffice.office.id === selectedOfficeId) {
                return false;
            }

            dispatch(
                SELECT_OFFICE.base({
                    officeId: itemOffice.office.id,
                    productsIds: productsIds
                })
            );
        };

        const chooseOffice = (itemOffice: any) => {
            dispatch(CHOOSE_OFFICE.base(itemOffice));
            dispatch(SHOW_OFFICE_SELECTION_MODAL.base({ show: !showOfficeSelectionModal }));
        };

        // @ts-ignore
        return (
            <div className={classNameAdaptiveMode}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <h1>{t(`OfficeSelectionModal.headerTitle`)}</h1>
                    </div>
                    <div className={styles.searchBox}>
                        <form name="search_office">
                            <input
                                ref={searchInputRef}
                                className={styles.searchString}
                                type="search"
                                placeholder={t(`OfficeSelectionModal.search`)}
                                onChange={(event) => dispatch(SET_FILTER.base({ query: event.target.value }))}
                                value={filter.query || ''}
                                onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                        searchInputRef.current.blur();
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </form>
                    </div>
                </div>
                <div className={styles.contentBlock}>
                    <div className={styles.officePanel}>
                        {(!filteredOffices || filteredOffices.length === 0) && !actions[FETCH_OFFICES.BASE]?.loading ? (
                            <div className={classNameAdaptiveMode}>
                                <div className={styles.contentBlock}>
                                    <p className={styles.emptyText}>{t(`OfficeSelectionModal.noOffices`)}</p>
                                </div>
                            </div>
                        ) : (
                            <OfficeList selectOffice={selectOffice} offices={filteredOffices} selectedOfficeId={preorder?.officeId} setPreorder={setPreorder} />
                        )}
                    </div>
                    <OfficeMap
                        offices={filteredOffices}
                        selectedOfficeId={preorder?.officeId}
                        selectOffice={selectOffice}
                        chooseOffice={chooseOffice}
                        setPreorder={setPreorder}
                        position={position}
                    />
                </div>
            </div>
        );
    };

    return (
        <div>
            <Modal
                onClose={() => dispatch(SHOW_OFFICE_SELECTION_MODAL.base({ show: !showOfficeSelectionModal }))}
                show={showOfficeSelectionModal}
                classNameWrapper={styles.modalWrapper}
                classCloseIcon={styles.classCloseIcon}
                className={classNames({
                    modal: true,
                    officesNotFound: !filteredOffices || filteredOffices.length === 0
                })}
            >
                {loading && <Preloader />}
                {renderContent()}
            </Modal>
        </div>
    );
};

export default React.memo(OfficeSelectionModal);
