import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import arrowRight from 'themes/icons/arrow.svg';
import styles from './Breadcrumbs.module.css';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';

type TLink = {
    link: string;
    name?: string;
    active?: boolean;
};

type TProps = {
    links: TLink[];
};

const Breadcrumbs: FC<TProps> = ({ links }) => {
    return (
        <div className={styles.container}>
            {links.map((item: TLink, i) => {
                return !item.active ? (
                    <Link key={i} to={item.link} className={classNames(styles.button)}>
                        {item.name}
                        {!item.active && <Icon width={'11px'} height={'9px'} icon={`${arrowRight}#arrow`} className={styles.arrowRight} />}
                    </Link>
                ) : (
                    <span key={i}>{item.name}</span>
                );
            })}
        </div>
    );
};

export default React.memo(Breadcrumbs);
