import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import { Tabs as ReactTabs, Tab, TabList, TabPanel } from 'react-tabs';
import classNames from 'classnames';
import styles from './Tabs.module.css';

const Tabs = ({
    animated,
    titles,
    pages,
    selected,
    rootClass,
    tabDisabledClass,
    tabSelectedClass,
    panelSelectedClass,
    tabsClass,
    tabClass,
    panelClass
}: any) => {
    const [positionActiveTab, setPositionActiveTab] = useState<string>(animated ? 'activeTabRight' : '');
    const [indexActiveTab, setIndexActiveTab] = useState<number>(0);
    const tabListRef = useRef();

    useEffect(() => {
        if (animated) {
            // @ts-ignore
            const tabSelected = tabListRef && tabListRef.current && tabListRef.current.querySelector("li[aria-selected='true']");
            document?.querySelector(`.${positionActiveTab}`)?.classList.add('customTabsContainer');
            // @ts-ignore
            setTimeout(() => tabSelected?.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' }), 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animated, tabListRef]);

    const handleClickTab = (index: number, countTabs: number) => {
        if (!animated) {
            return false;
        }
        const positionActiveTab = index < indexActiveTab ? 'activeTabRight' : 'activeTabLeft';

        setPositionActiveTab(positionActiveTab);
        setIndexActiveTab(index);
    };

    return (
        <ReactTabs
            defaultIndex={selected}
            className={classNames(rootClass, styles.reactTabs, positionActiveTab)}
            disabledTabClassName={classNames(tabDisabledClass, styles.reactTabsTabDisabled)}
            selectedTabClassName={classNames(tabSelectedClass, styles.reactTabsTabSelected)}
            selectedTabPanelClassName={classNames(panelSelectedClass, styles.reactTabsPanelSelected)}
        >
            <div ref={() => tabListRef}>
                <TabList className={classNames(tabsClass, styles.reactTabsList)}>
                    {titles.map((title: any, i: number) => {
                        let tabTitle, onClick: any;
                        if (typeof title === 'object') {
                            tabTitle = title.tabTitle;
                            onClick = () => {
                                if (typeof title.onClick === 'function') {
                                    title.onClick();
                                }
                                handleClickTab(i, titles.length);
                            };
                        } else {
                            tabTitle = title;
                            onClick = () => {
                                handleClickTab(i, titles.length);
                            };
                        }

                        return (
                            <Tab onClick={() => onClick()} className={classNames(tabClass, styles.reactTabsTab)} key={tabTitle}>
                                {tabTitle}
                            </Tab>
                        );
                    })}
                </TabList>
            </div>

            {pages.map((page: any, i: number) => (
                <TabPanel className={classNames(panelClass, styles.reactTabsPanel)} key={i}>
                    {page}
                </TabPanel>
            ))}
        </ReactTabs>
    );
};

export default React.memo(Tabs);
