import React from 'react';
import styles from './Footer.module.css';
import { formatDate } from 'utils/timeUtils';
import { useTranslation } from 'react-i18next';
import IsInvitro from 'themes/invitro/IsInvitro';
import IsVetunion from 'themes/vetunion/IsVetunion';

const Footer = () => {
    const { t } = useTranslation('common');
    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                <div className={styles.copy}>
                    {/* <a href="#">{t(`Common.termsService`)}</a> */}
                    <span className={styles.copyText}>
                        &copy; 2011-{formatDate(new Date(), 'ru', 'yyyy')}, <IsInvitro>{t(`Common.INVITRO`)}</IsInvitro>
                        <IsVetunion>{t(`Common.VetUnion`)}</IsVetunion>
                    </span>
                </div>
                {/*<LanguageSelector />*/}
            </div>
        </footer>
    );
};

export default React.memo(Footer);
