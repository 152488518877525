import React, { useEffect, useState, FC } from 'react';
import DatePicker from 'react-datepicker';
import searchStringIcon from 'themes/icons/search.svg';
import styles from 'components/filter/FilterPeriodDate.module.css';
import classNames from 'classnames';
import { sub, startOfYesterday, startOfTomorrow, isDate } from 'date-fns';
import { formatDate } from 'utils/timeUtils';
import { ResultsTypes } from 'types/ResultsTypes';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';
import CustomDatePickerInput from 'components/input/datepicker/DatePickerInput';

type TProps = {
    searchString?: string;
    setSearchString?: (param: string) => void;
    setServerSideFilter: (param: ResultsTypes.ServerSideFilter) => void;
    serverSideFilter: ResultsTypes.ServerSideFilter;
    showOrder?: boolean;
    limitItemsPage?: string | number | (string | null)[];
    selectedOfficeOrGroupId?: string;
    classNamePopup?: string;
};

const FilterPeriodDate: FC<TProps> = ({
    searchString,
    setSearchString,
    setServerSideFilter,
    serverSideFilter,
    limitItemsPage,
    selectedOfficeOrGroupId,
    classNamePopup
}) => {
    const [showPopupPeriod, setShowPopupPeriod] = useState(false);
    const [periodMonthApplied, setPeriodMonthApplied] = useState(false);
    const { t } = useTranslation('common');

    const [filterDate, setFilterDate] = useState<{
        fromDate?: string | null;
        fromDateRaw?: Date | null;
        periodFromDate?: string | null;
        periodFromDateRaw?: Date | null;
        toDate?: string | null;
        toDateRaw?: Date | null;
        periodToDate?: string | null;
        periodToDateRaw?: Date | null;
        title?: string | null;
        monthFromTitle?: string | null;
        monthToTitle?: string | null;
    }>();

    const [period, setPeriod] = useState<{
        periodFromDate?: string | null;
        periodFromDateRaw?: Date | null;
        periodToDate?: string | null;
        periodToDateRaw?: Date | null;
        isDatePicker?: boolean;
    }>();

    useEffect(() => {
        initFilter();
        document.addEventListener('click', () => setShowPopupPeriod(false));
        return document.removeEventListener('click', () => {
            return;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPeriodMonthApplied(false);
        setShowPopupPeriod(false);
        if (filterDate?.fromDate || filterDate?.toDate) {
            setPeriod({});
            setServerSideFilter({ fromDate: filterDate?.fromDate, toDate: filterDate?.toDate, start: 0, limit: limitItemsPage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDate]);

    useEffect(() => {
        setPeriod((prevState) => {
            if (prevState?.isDatePicker) {
                setShowPopupPeriod(true);
            }
            return prevState;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    useEffect(() => {
        initFilter();
        setFilterDate({ fromDate: null, toDate: null, title: t(`FilterPeriodDate.last1.5years`) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroupId]);

    const initFilter = () => {
        setPeriod({
            ...period,
            periodFromDate: formatDate(sub(new Date(), { days: 30 as number }), 'ru', 'yyyy-MM-dd'),
            periodFromDateRaw: sub(new Date(), { days: 30 as number }),
            periodToDate: formatDate(new Date(), 'ru', 'yyyy-MM-dd'),
            periodToDateRaw: new Date()
        });
    };

    const resetFilter = () => {
        setFilterDate({ fromDate: null, toDate: null, title: t(`FilterPeriodDate.last1.5years`) });
        setServerSideFilter({ fromDate: null, toDate: null, start: 0, limit: limitItemsPage });
    };

    return (
        <div className={styles.filter}>
            {setSearchString && (
                <div className={styles.searchString}>
                    <Icon icon={`${searchStringIcon}#search`} className={styles.searchStringIcon} />
                    <input
                        value={searchString}
                        type="search"
                        placeholder={t(`FilterPeriodDate.placeholder`)}
                        onChange={(event) => {
                            if (setServerSideFilter) {
                                setServerSideFilter({ filter: event.target.value, start: 0, limit: limitItemsPage });
                            }
                            if (setSearchString) {
                                setSearchString(event.target.value);
                            }
                        }}
                    />
                </div>
            )}
            <div className={styles.periodsWrapper}>
                <div>
                    <div
                        className={styles.periodButton}
                        onClick={(event) => {
                            event.stopPropagation();
                            !showPopupPeriod && setShowPopupPeriod(!showPopupPeriod);
                        }}
                    >
                        {!periodMonthApplied && (filterDate?.title ? filterDate.title : t(`FilterPeriodDate.period`))}
                        {periodMonthApplied &&
                            period?.periodFromDateRaw &&
                            `${t(`FilterPeriodDate.periodFromShort`)} ${formatDate(period?.periodFromDateRaw, 'ru', 'PP')}`}
                        {periodMonthApplied &&
                            period?.periodToDate &&
                            ` ${t(`FilterPeriodDate.periodToShort`)} ${formatDate(period?.periodToDateRaw, 'ru', 'PP')}`}
                        <span className={styles.iconPeriodArrow}>▾</span>
                    </div>
                </div>
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    className={classNames([styles.periodPopup, showPopupPeriod ? styles.showPeriodPopup : null, classNamePopup])}
                >
                    <div className={styles.periodDayValues}>
                        <div
                            onClick={() => {
                                setPeriod({});
                                setFilterDate({ fromDate: null, toDate: null, title: t(`FilterPeriodDate.last1.5years`) });
                                setServerSideFilter({ fromDate: null, toDate: null, start: 0, limit: limitItemsPage });
                            }}
                        >
                            {t(`FilterPeriodDate.last1.5years`)}
                        </div>
                        <div
                            onClick={() =>
                                setFilterDate({
                                    fromDate: formatDate(new Date(), 'ru', 'yyyy-MM-dd'),
                                    toDate: formatDate(new Date(), 'ru', 'yyyy-MM-dd'),
                                    title: t(`FilterPeriodDate.today`)
                                })
                            }
                        >
                            {t(`FilterPeriodDate.today`)}
                        </div>
                        <div
                            onClick={() =>
                                setFilterDate({
                                    fromDate: formatDate(startOfYesterday(), 'ru', 'yyyy-MM-dd'),
                                    toDate: formatDate(startOfYesterday(), 'ru', 'yyyy-MM-dd'),
                                    title: t(`FilterPeriodDate.yesterday`)
                                })
                            }
                        >
                            {t(`FilterPeriodDate.yesterday`)}
                        </div>
                        <div
                            onClick={() =>
                                setFilterDate({
                                    fromDate: formatDate(sub(new Date(), { days: 7 as number }), 'ru', 'yyyy-MM-dd'),
                                    toDate: formatDate(startOfTomorrow(), 'ru', 'yyyy-MM-dd'),
                                    title: t(`FilterPeriodDate.last7days`)
                                })
                            }
                        >
                            {t(`FilterPeriodDate.last7days`)}
                        </div>
                        <div
                            onClick={() =>
                                setFilterDate({
                                    fromDate: formatDate(sub(new Date(), { days: 30 as number }), 'ru', 'yyyy-MM-dd'),
                                    toDate: formatDate(startOfTomorrow(), 'ru', 'yyyy-MM-dd'),
                                    title: t(`FilterPeriodDate.last30days`)
                                })
                            }
                        >
                            {t(`FilterPeriodDate.last30days`)}
                        </div>
                    </div>
                    <div className={styles.periodFromTo}>
                        <div className={styles.periodFrom}>
                            <span className={styles.periodTitle}>{t(`FilterPeriodDate.periodFrom`)}</span>
                            <DatePicker
                                customInput={<CustomDatePickerInput />}
                                isClearable
                                dateFormat={'dd.MM.yyyy'}
                                locale={'ru'}
                                selected={period?.periodFromDateRaw as Date}
                                maxDate={new Date()}
                                className={!isDate(period?.periodFromDateRaw) ? styles.notValidDate : ''}
                                onChange={(date: Date) => {
                                    if (isEmpty(date) && isEmpty(period?.periodToDate)) {
                                        resetFilter();
                                    }
                                    setPeriod({
                                        ...period,
                                        periodFromDate: formatDate(date, 'ru', 'yyyy-MM-dd'),
                                        periodFromDateRaw: date,
                                        isDatePicker: true
                                    });
                                }}
                                showYearDropdown={true}
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                            />
                        </div>
                        <div className={styles.periodTo}>
                            <span className={styles.periodTitle}>{t(`FilterPeriodDate.periodTo`)}</span>
                            <DatePicker
                                customInput={<CustomDatePickerInput />}
                                isClearable
                                dateFormat={'dd.MM.yyyy'}
                                locale={'ru'}
                                selected={period?.periodToDateRaw as Date}
                                maxDate={new Date()}
                                className={!isDate(period?.periodToDateRaw) ? styles.notValidDate : ''}
                                onChange={(date: Date) => {
                                    if (isEmpty(date) && isEmpty(period?.periodFromDate)) {
                                        resetFilter();
                                    }
                                    setPeriod({
                                        ...period,
                                        periodToDate: formatDate(date, 'ru', 'yyyy-MM-dd'),
                                        periodToDateRaw: date,
                                        isDatePicker: true
                                    });
                                }}
                                showYearDropdown={true}
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                            />
                        </div>
                    </div>
                    <button
                        disabled={
                            (isEmpty(period?.periodFromDate) && isEmpty(period?.periodToDate)) ||
                            !isDate(period?.periodFromDateRaw) ||
                            !isDate(period?.periodToDateRaw)
                        }
                        className={styles.applyPeriod}
                        onClick={() => {
                            setShowPopupPeriod(false);
                            setPeriodMonthApplied(true);
                            setServerSideFilter({ fromDate: period?.periodFromDate, toDate: period?.periodToDate, start: 0, limit: limitItemsPage });
                        }}
                    >
                        {t(`FilterPeriodDate.applyPeriod`)}
                    </button>
                </div>
                {setSearchString && (
                    <div className={styles.checkboxes}>
                        <input
                            checked={serverSideFilter.notPrinted || false}
                            type="checkbox"
                            onChange={(event) =>
                                setServerSideFilter
                                    ? setServerSideFilter({
                                          limit: limitItemsPage,
                                          start: 0,
                                          notPrinted: event.target.checked || null
                                      })
                                    : true
                            }
                        />
                        <label>{t(`FilterPeriodDate.unprinted`)}</label>
                        <input
                            checked={serverSideFilter.outOfReferenceValues || false}
                            type="checkbox"
                            onChange={(event) =>
                                setServerSideFilter
                                    ? setServerSideFilter({
                                          limit: limitItemsPage,
                                          start: 0,
                                          outOfReferenceValues: event.target.checked || null
                                      })
                                    : true
                            }
                        />
                        <label>{t(`FilterPeriodDate.outOfReferenceValues`)}</label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(FilterPeriodDate);
