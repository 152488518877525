import React from 'react';
import IconWarning from './IconWarning';
import styles from './AlertWarning.module.css';
import classNames from 'classnames/bind';

export default class AlertWarning extends React.PureComponent {
    render() {
        const { content, className } = this.props;
        return (
            <div className={classNames(styles.error, className)}>
                <IconWarning />
                <div>{content}</div>
            </div>
        );
    }
}
