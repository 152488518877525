import React from 'react';
import styles from './Phone.module.css';
import phoneIcon from 'themes/icons/phone.svg';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';

const Phone = () => {
    const { t } = useTranslation('common');
    return (
        <div className={styles.container}>
            <div>
                {t(`Phone.hotlineFor`)}
                <span className={styles.text}> {t(`Phone.doctors`)}</span>
            </div>
            <a className={styles.phoneLink} href="tel:+79645599702">
                <Icon icon={`${phoneIcon}#phone`} className={styles.phoneIcon} />
                <span className={styles.phoneNumber}>8-(964)-559-97-02</span>
            </a>
        </div>
    );
};

export default React.memo(Phone);
