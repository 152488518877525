import React, { Fragment, FC } from 'react';
import styles from './Card.module.css';

type TProps = {
    office?: any;
};

const OfficeCard: FC<TProps> = ({ office }) => {
    return (
        <Fragment>
            {office?.id && (
                <div className={styles.container}>
                    <div className={styles.patientInfoItems}>
                        <div className={styles.patientInfoRow}>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>Название:</div>
                                <div className={styles.patientInfoDesc}>{office.name}</div>
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>Клиент. код:</div>
                                <div className={styles.patientInfoDesc}>{office.clientCode}</div>
                            </div>
                        </div>
                        <div className={styles.patientInfoRow}>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>Адрес:</div>
                                <div className={styles.patientInfoDesc}>{office.address}</div>
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>Телефон:</div>
                                <div className={styles.patientInfoDesc}>{office.externalPhone}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(OfficeCard);
