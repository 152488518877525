import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';

const RequestsPage = () => {
    const navigate = useNavigate();
    const { siteSetting } = useSelector((state: AppTypes.State) => state.startup.settings);
    useEffect(() => {
        if (siteSetting?.orders?.materials) {
            return navigate('/requests/materials');
        }
        if (!siteSetting?.orders?.materials && siteSetting?.orders?.couriers) {
            return navigate('/requests/courier');
        }
    });
    return null;
};

export default React.memo(RequestsPage);
