import React, { FC, Fragment, useEffect } from 'react';
import styles from './OrdersPanel.module.css';
import clearInputIcon from 'themes/icons/close.svg';
import { CLEAR_MATERIAL_ORDER, SET_ADDRESS_ORDER } from 'redux/requests/actions';
import { useDispatch } from 'react-redux';
import { RequestsTypes } from 'types/RequestsTypes';
import classNames from 'classnames';
import { OfficesTypes } from 'types/OfficesTypes';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';

type TProps = {
    order: RequestsTypes.Order;
    showOrder?: boolean;
    address: string;
    totalQuantity: number;
    setOrder?: () => void;
    setShowModalConfirmOrder?: (param: boolean) => void;
    setChangeAddress?: (param: boolean) => void;
    setActiveFolderList?: (param: [] | [string]) => void;
    setResetForm?: (param: boolean) => void;
    dispatch?: any;
    selectedOfficeOrGroup?: OfficesTypes.OfficeType | null;
};

const OrdersPanel: FC<TProps> = ({
    order,
    address,
    totalQuantity,
    setShowModalConfirmOrder,
    showOrder,
    setOrder,
    setActiveFolderList,
    setChangeAddress,
    setResetForm
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    useEffect(() => {
        dispatch(
            SET_ADDRESS_ORDER.base({
                address
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    return (
        <div className={styles.materialsToolbarWrapper}>
            <div className={styles.materialsToolbar}>
                <div className={styles.materialsToolbarItem}>
                    <input
                        className={classNames([address?.length < 1 ? styles.error : null])}
                        disabled={showOrder}
                        onChange={(event) => {
                            if (setChangeAddress) {
                                setChangeAddress(true);
                            }
                            dispatch(
                                SET_ADDRESS_ORDER.base({
                                    address: event.target.value
                                })
                            );
                        }}
                        value={address}
                        type="text"
                        placeholder={t(`Common.address`)}
                    />
                    {address && address?.length > 0 && (
                        <Icon
                            icon={`${clearInputIcon}#close`}
                            className={styles.clearInputIcon}
                            onClick={() => {
                                if (setResetForm) {
                                    setResetForm(true);
                                }
                                dispatch(
                                    SET_ADDRESS_ORDER.base({
                                        address: ''
                                    })
                                );
                            }}
                        />
                    )}
                </div>
                <div className={styles.materialsToolbarItem}>
                    <div className={styles.materialsToolbarTotal}>
                        {t(`OrdersPanel.totalQuantity`)}: <span>{totalQuantity}</span> {t(`OrdersPanel.quantityShort`)}
                    </div>
                    <div className={styles.materialsToolbarButtons}>
                        {showOrder ? (
                            <button
                                className={styles.repeatButton}
                                disabled={!(order.materials.length > 0)}
                                onClick={() => {
                                    if (setOrder) {
                                        setOrder();
                                    }
                                }}
                            >
                                {t(`Common.repeat`)}
                            </button>
                        ) : (
                            <Fragment>
                                <button
                                    onClick={() => {
                                        dispatch(CLEAR_MATERIAL_ORDER.base());
                                        if (setActiveFolderList) {
                                            setActiveFolderList([]);
                                        }
                                        if (setResetForm) {
                                            setResetForm(true);
                                        }
                                    }}
                                >
                                    {t(`Common.reset`)}
                                </button>
                                <button
                                    disabled={!(order?.materials?.length > 0) || !(address?.length > 0)}
                                    onClick={() => (setShowModalConfirmOrder ? setShowModalConfirmOrder(true) : null)}
                                >
                                    {t(`Common.send`)}
                                </button>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(OrdersPanel, (prevProps: any, nextProps: any) => {
    if (prevProps?.selectedOfficeOrGroup?.id !== nextProps?.selectedOfficeOrGroup?.id) {
        nextProps?.dispatch(
            SET_ADDRESS_ORDER.base({
                address: nextProps.selectedOfficeOrGroup?.address
            })
        );
    }
    return false;
});
