import React, { Fragment, useEffect, useState } from 'react';
import styles from 'page/requests/materials/RequestsMaterialsPage.module.css';
import HeaderSubMenu from 'page/layout/menu/HeaderSubMenu';
import { useSelector, useDispatch } from 'react-redux';
import OrdersPanel from 'page/requests/components/panel/OrdersPanel';
import MaterialList from 'page/requests/components/materials/MaterialList';
import { SET_ORDER, FETCH_ORDER_HISTORY, FETCH_MATERIALS_ALL } from 'redux/requests/actions';
import { orderHistoryByFolderSelector, orderHistorySelector } from 'redux/requests/selectors';
import { RequestsTypes } from 'types/RequestsTypes';
import { formatDate, parseISO } from 'utils/timeUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { actionsSelector } from 'redux/app/selectors';
import { AppTypes } from 'types/AppTypes';
import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

const RequestsHistoryDetailsPage = () => {
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const { currentUser } = useSelector((state: AppTypes.State) => state.user);
    const { t } = useTranslation('common');
    const orderHistory = useSelector(orderHistorySelector);
    const orderHistoryByFolder = useSelector(orderHistoryByFolderSelector);
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const actions = useSelector(actionsSelector);
    const [order, setOrder] = useState<RequestsTypes.Order>();
    const query = queryString.parse(window.location?.search as string);

    useEffect(() => {
        if (orderHistory.length < 1) {
            dispatch(FETCH_ORDER_HISTORY.base({ all: query.allOfficeHistory }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(
                FETCH_MATERIALS_ALL.base({
                    officeId: selectedOfficeOrGroup?.id,
                    userId: currentUser?.id,
                    allOffices: true
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id]);

    useEffect(() => {
        if (orderHistory.length > 0) {
            const orderById = orderHistory?.filter((item: RequestsTypes.Order) => item.id === params.orderId)[0];
            if (orderById) {
                setOrder(orderById);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderHistory]);

    const handleSetOrder = () => {
        dispatch(
            SET_ORDER.base({
                order,
                repeat: true
            })
        );
        navigate('/requests/materials', { state: { repeatOrder: true } });
    };

    return (
        <div className={styles.container}>
            <HeaderSubMenu />
            <IsAvailable options={[{ orders: { enable: true, materials: true } }]}>
                <Access
                    officeGroup={{
                        selected: Array.isArray(selectedOfficeOrGroup?.offices),
                        message: t(`Common.requestOfficeNotice`)
                    }}
                    permissions={[PermissionType.ORDERS_MATERIALS]}
                    message={t(`Common.noAccess`)}
                    className={styles.messagePermissions}
                    errors={[actions[FETCH_ORDER_HISTORY.BASE]?.error]}
                >
                    {order ? (
                        <Fragment>
                            <div className={styles.content}>
                                <h1 className={styles.title}>
                                    {t(`RequestsHistoryDetailsPage.orderFrom`)} {order.createdTime ? formatDate(parseISO(order.createdTime), 'ru') : null}
                                </h1>
                                <MaterialList
                                    materials={orderHistoryByFolder?.filter((item: RequestsTypes.Order) => item.id === params.orderId)}
                                    order={order}
                                    showOrder={true}
                                    orderId={params.orderId}
                                />
                                <div className={styles.materialsComment}>
                                    <div className={styles.materialsCommentTitle}>{t(`Common.comment`)}</div>
                                    <textarea disabled={true} value={order.comment} className={styles.materialsCommentField} />
                                </div>
                            </div>
                            <OrdersPanel
                                order={order}
                                showOrder={true}
                                setOrder={handleSetOrder}
                                address={order.address}
                                totalQuantity={sumBy(order.materials, 'quantity')}
                            />
                        </Fragment>
                    ) : null}
                </Access>
            </IsAvailable>
        </div>
    );
};

export default React.memo(RequestsHistoryDetailsPage);
