import React, { Fragment, useEffect } from 'react';
import styles from 'components/table/TableResults.module.css';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { ResultsTypes } from 'types/ResultsTypes';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Preloader from 'components/preloader/Preloader';

const TableResults = ({ results, loading }: any) => {
    const { t } = useTranslation('common');

    useEffect(() => ReactTooltip.rebuild() as () => void);

    const renderResult = () => {
        return (
            <Fragment>
                {results?.testResults?.length > 0
                    ? Object?.entries(groupBy(results?.testResults, 'groupId')).map((testResult, i) => (
                          <div className={styles.tableResultsBlock} key={results.inz}>
                              <table className={styles.tableResults}>
                                  <thead>
                                      <tr className={styles.tableResultsTitles}>
                                          <th className={styles.tableResultsAnalyses}>{t(`Common.study`)}</th>
                                          <th className={styles.tableResultsResult}>{t(`Common.result`)}</th>
                                          <th className={styles.tableResultsReference}>{t(`Common.referenceValue`)}</th>
                                          <th className={styles.tableResultsComment}>{t(`Common.comment`)}</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {testResult[1]?.map((result) => {
                                          if (result.cultures.length > 0) {
                                              return result?.cultures?.map((culture: ResultsTypes.TypeCultures) => renderResultRow(result, culture));
                                          } else {
                                              return renderResultRow(result, null);
                                          }
                                      })}
                                  </tbody>
                              </table>
                          </div>
                      ))
                    : null}
            </Fragment>
        );
    };

    const renderResultRow = (result: ResultsTypes.TypeTestResult, culture: ResultsTypes.TypeCultures | null) => {
        return (
            <tr key={result.code}>
                <td className={styles.tableResultsAnalyses}>{result.shortName}</td>
                <td className={classNames([styles.tableResultsResult, result.outbound && styles.referenceValue])}>
                    {!result.value && t(`Common.processed`)}
                    {result.outbound && (
                        <span className={styles.referenceMarker} data-for={'tableResults'} data-tip={t(`Common.resultsBeyondRefValues`)}>
                            !
                        </span>
                    )}
                    {result.value} {result.unit} {culture?.finding} {culture?.growth}
                </td>
                <td className={styles.tableResultsReference}>
                    {result.refText ? (
                        result.refText
                    ) : (
                        <Fragment>
                            {result.refMin} {result.refMin && result.refMax && ' - '} {result.refMax} {result.unit}
                        </Fragment>
                    )}
                </td>
                <td className={styles.tableResultsComment}>{result.laboratoryComment}</td>
            </tr>
        );
    };

    return (
        <Fragment>
            {loading ? <Preloader /> : renderResult()}
            {
                // @ts-ignore
                <ReactTooltip
                    border={true}
                    borderColor={'#939393'}
                    id={'tableResults'}
                    resizeHide={true}
                    html={true}
                    type={'light'}
                    effect={'solid'}
                    place={'top'}
                />
            }
        </Fragment>
    );
};

export default React.memo(TableResults);
