import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CabinetPage.module.css';
import { UPDATE_EMAIL, CHANGE_PASSWORD, GET_CURRENT_USER } from 'redux/user/actions';
import Notification from 'components/notification/Notification';
import { actionsSelector } from 'redux/app/selectors';
import { AppTypes } from 'types/AppTypes';
import { SET_ACTIONS } from 'redux/app/actions';
import isEmail from 'validator/lib/isEmail';
import { isEmpty } from 'lodash';
import InputPassword from 'components/input/password/InputPassword';
import { useTranslation } from 'react-i18next';

const CabinetPage = () => {
    const dispatch = useDispatch();
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const { currentUser } = useSelector((state: AppTypes.State) => state.user);
    const [fields, setFields] = useState({
        oldPassword: '',
        password: '',
        passwordRepeat: '',
        newEmail: '',
        confirmCode: ''
    });
    const { oldPassword, password, passwordRepeat, newEmail } = fields;
    const [notValidPassword, setNotValidPassword] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [emailUpdated, setEmailUpdated] = useState(false);

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [CHANGE_PASSWORD.BASE]: null,
                    [UPDATE_EMAIL.BASE]: null
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (actions[UPDATE_EMAIL.BASE]?.payload?.data?.email) {
            setCodeSent(true);
        }
        if (actions[UPDATE_EMAIL.BASE]?.payload?.data?.email === newEmail) {
            setFields(() => {
                setCodeSent(false);
                setEmailUpdated(true);
                dispatch(GET_CURRENT_USER.base());
                return { ...fields, newEmail: '', confirmCode: '' };
            });
            setTimeout(() => setEmailUpdated(false), 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[UPDATE_EMAIL.BASE]]);

    const handleSetFields = (field: { name: string; value: string }) => {
        setFields((prevState: any) => {
            prevState[field.name] = field.value;
            return {
                ...prevState
            };
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.settingBlock}>
                <h2>{t(`CabinetPage.changePassword`)}</h2>
                {actions[CHANGE_PASSWORD.BASE]?.error && (
                    <div className={styles.noticeBlock}>
                        {actions[CHANGE_PASSWORD.BASE]?.error?.errors?.map((error) => (
                            <Fragment>
                                <Notification type={'error'}>
                                    <p className={styles.notice}>{error.message}</p>
                                </Notification>
                                <br />
                            </Fragment>
                        ))}
                    </div>
                )}
                {actions[CHANGE_PASSWORD.BASE]?.success && (
                    <div className={styles.noticeBlock}>
                        <Notification type={'success'}>
                            <p className={styles.notice}>{t(`CabinetPage.notice`)}</p>
                        </Notification>
                        <br />
                    </div>
                )}
                <form className={styles.passwordForm}>
                    <div className={styles.settingRow}>
                        <label>{t(`CabinetPage.oldPassword`)}</label>
                        <InputPassword value={fields.oldPassword} onChange={(e: any) => handleSetFields(e.target)} showVisibilityBtn name="oldPassword" />
                    </div>
                    <div className={styles.settingRow}>
                        <label>{t(`CabinetPage.newPassword`)}</label>
                        <InputPassword
                            setNotValidPassword={setNotValidPassword}
                            name="password"
                            showVisibilityBtn
                            showStrength
                            value={password}
                            error={notValidPassword}
                            onChange={(e: any) => handleSetFields(e.target)}
                            max={64}
                        />
                    </div>
                    <div className={styles.settingRow}>
                        <label>{t(`CabinetPage.newPasswordRepeat`)}</label>
                        <InputPassword
                            name="passwordRepeat"
                            showVisibilityBtn
                            value={fields.passwordRepeat}
                            notMatch={
                                fields.password.length > 0 &&
                                fields.passwordRepeat.length > 0 &&
                                fields.password !== fields.passwordRepeat
                            }
                            error={false}
                            onChange={(e: any) => handleSetFields(e.target)}
                            max={64}
                        />
                    </div>
                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            return dispatch(
                                CHANGE_PASSWORD.base({
                                    oldPassword,
                                    password,
                                    passwordRepeat
                                })
                            );
                        }}
                        disabled={password.length < 1 || password !== passwordRepeat || notValidPassword}
                        type={'submit'}
                    >
                        {t(`Common.save`)}
                    </button>
                </form>
            </div>
            <div className={styles.settingBlock}>
                <h2>{t(`CabinetPage.changeEmail`)}</h2>
                <form className={styles.emailForm}>
                    <div className={styles.settingRow}>
                        <label className="">{t(`CabinetPage.currentEmail`)}</label>
                        <div>{currentUser?.email}</div>
                    </div>
                    <div className={styles.settingRow}>
                        <label>{t(`CabinetPage.newEmail`)} </label>
                        <input
                            disabled={codeSent && !emailUpdated}
                            value={fields.newEmail}
                            onChange={(e) => handleSetFields(e.target)}
                            type="email"
                            name="newEmail"
                        />
                    </div>
                    <div className={styles.noticeBlock}>
                        {codeSent && (
                            <div>
                                <Notification type={'success'}>{t(`CabinetPage.noticeChangeEmail`)}</Notification>
                                <div>
                                    <br />
                                    <div className={styles.settingRow}>
                                        <label />
                                        <input
                                            placeholder={t(`CabinetPage.confirmCode`)}
                                            value={fields.confirmCode}
                                            onChange={(e) => handleSetFields(e.target)}
                                            type="text"
                                            name="confirmCode"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {actions[UPDATE_EMAIL.BASE]?.error?.errors?.map((error) => (
                            <Fragment>
                                <Notification type={'error'}>
                                    <p className={styles.notice}>{error.message}</p>
                                </Notification>
                                <br />
                            </Fragment>
                        ))}
                        {actions[UPDATE_EMAIL.BASE]?.error && !actions[UPDATE_EMAIL.BASE]?.error?.errors && (
                            <Fragment>
                                <Notification type={'error'}>
                                    <p className={styles.notice}>{actions[UPDATE_EMAIL.BASE]?.error?.message}</p>
                                </Notification>
                                <br />
                            </Fragment>
                        )}
                        {emailUpdated && (
                            <div className={styles.noticeBlock}>
                                <Notification type={'success'}>{t(`CabinetPage.emailUpdated`)}</Notification>
                            </div>
                        )}
                    </div>
                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            return dispatch(
                                UPDATE_EMAIL.base({
                                    email: newEmail,
                                    code: fields.confirmCode
                                })
                            );
                        }}
                        disabled={!isEmail(newEmail) || (codeSent && isEmpty(fields.confirmCode))}
                        type="submit"
                    >
                        {t(`Common.send`)}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default React.memo(CabinetPage);
