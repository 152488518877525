import React from 'react';
import styles from './Modal.module.css';
import Icon from 'components/icon/Icon';
import closeModalIcon from 'themes/icons/close.svg';
import classNames from 'classnames';

const Modal = React.forwardRef((props: any, ref: any) => {
    if (props.show) {
        return (
            <div className={classNames(styles.wrapper, props.classNameWrapper)}>
                <div ref={ref} className={classNames(styles.container, props.className)}>
                    {(props.onClose || props.title) && (
                        <div className={styles.head}>
                            {props.title && <h1 className={styles.title}>{props.title}</h1>}
                            {props.onClose && (
                                <Icon
                                    className={classNames(styles.closeModalIcon, props.classCloseIcon)}
                                    onClick={props.onClose}
                                    width={'20px'}
                                    height={'18px'}
                                    icon={`${closeModalIcon}#close`}
                                />
                            )}
                        </div>
                    )}
                    <div className={styles.content}>{props.children}</div>
                </div>
            </div>
        );
    } else {
        return null;
    }
});

export default React.memo(Modal);
