import React, { Fragment, ReactNode, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './HeaderSubMenu.module.css';
import { useSelector } from 'react-redux';
import { NEWS_CATEGORIES } from 'constants/newsCategories';
import { newsCountersSelector } from 'redux/news/selectors';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import IsAvailable from 'components/access/IsAvailable';
import { AppTypes } from 'types/AppTypes';
import { ThemeContext } from 'themes/Themes';
const queryString = require('query-string');

const HeaderSubMenu = () => {
    const newsCounters = useSelector(newsCountersSelector);
    const { siteSetting } = useSelector((state: AppTypes.State) => state.startup.settings);
    const { t } = useTranslation('common');
    const { nameCompany } = useContext(ThemeContext);
    const { authenticated } = useSelector((state: any) => state.auth);
    const location: any = useLocation();

    if (!authenticated) {
        return null;
    }

    const renderNewsSubMenu = () => {
        const parsedLocationString = queryString.parse(location.search);
        const keyByCategory = keyBy(newsCounters, 'category');
        const newsSubMenu: ReactNode[] = [];
        for (let item in NEWS_CATEGORIES) {
            // @ts-ignore
            if (NEWS_CATEGORIES[item].siteStyle === siteSetting.siteStyle || !NEWS_CATEGORIES[item].siteStyle) {
                newsSubMenu.push(
                    <li className={styles.menuItem} key={item}>
                        <NavLink
                            // @ts-ignore
                            className={() => (parsedLocationString.category === NEWS_CATEGORIES[item].path ? styles.activeMenu : undefined)}
                            to={`/news?category=${
                                // @ts-ignore
                                NEWS_CATEGORIES[item].path
                            }`}
                        >
                            {
                                // @ts-ignore
                                (NEWS_CATEGORIES[item]?.localeKey && t(`${NEWS_CATEGORIES[item].localeKey}`, { nameCompany })) || NEWS_CATEGORIES[item]?.name
                            }
                            {keyByCategory[item]?.count > 0 && <span className={styles.messageCounter}>{keyByCategory[item]?.count}</span>}
                        </NavLink>
                    </li>
                );
            }
        }
        return newsSubMenu;
    };

    return (
        <div className={styles.container}>
            <ul className={styles.menu}>
                {location.pathname?.search('/requests') >= 0 && (
                    <Fragment>
                        <IsAvailable options={[{ orders: { enable: true, materials: true } }]} notAvailableMessage={false}>
                            <li className={styles.menuItem}>
                                <NavLink className={({ isActive }) => (isActive ? styles.activeMenu : undefined)} to={'/requests/materials'}>
                                    {t(`HeaderSubMenu.requestMaterials`)}
                                </NavLink>
                            </li>
                        </IsAvailable>
                        <IsAvailable options={[{ orders: { enable: true, materials: true } }]} notAvailableMessage={false}>
                            <li className={styles.menuItem}>
                                <NavLink className={({ isActive }) => (isActive ? styles.activeMenu : undefined)} to={'/requests/history'}>
                                    {t(`HeaderSubMenu.requestsHistory`)}
                                </NavLink>
                            </li>
                        </IsAvailable>
                        <IsAvailable options={[{ orders: { enable: true, couriers: true } }]} notAvailableMessage={false}>
                            <li className={styles.menuItem}>
                                <NavLink className={({ isActive }) => (isActive ? styles.activeMenu : undefined)} to={'/requests/courier'}>
                                    {t(`HeaderSubMenu.requestCourier`)}
                                </NavLink>
                            </li>
                        </IsAvailable>
                    </Fragment>
                )}
                {location.pathname?.search('/news') >= 0 && renderNewsSubMenu()}
            </ul>
        </div>
    );
};

export default React.memo(HeaderSubMenu);
