export default function createRequestTypes(type: any) {
    const BASE = type;
    const STARTED = `${type}_STARTED`;
    const SUCCEEDED = `${type}_SUCCEEDED`;
    const ERRORED = `${type}_ERRORED`;
    const CANCELLED = `${type}_CANCELLED`;
    return {
        BASE,
        STARTED,
        SUCCEEDED,
        ERRORED,
        CANCELLED,

        base: (meta: any = null) => ({
            type: BASE,
            meta,
            typeName: BASE
        }),
        start: (meta: any) => ({
            type: STARTED,
            meta,
            typeName: BASE
        }),
        success: (payload: any = null, meta: any = null) => ({
            type: SUCCEEDED,
            payload,
            meta,
            typeName: BASE
        }),
        error: (caught: any = null, meta = null) => ({
            type: ERRORED,
            error: true,
            payload: caught,
            meta,
            typeName: BASE
        }),
        cancel: (meta: any) => ({
            type: CANCELLED,
            meta,
            typeName: BASE
        })
    };
}
