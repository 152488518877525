import { LOGIN, LOGOUT, AUTHENTICATE, CLEAR_AUTHENTICATE } from './actions';
import { TOKEN_HEADER, TOKEN_KEY } from './constants';
import localStorageService from 'services/LocalStorageService';
import { AuthTypes } from 'types/AuthTypes';

const initialState: AuthTypes.State = {
    login: {
        token: localStorageService.getItem(TOKEN_KEY) || null
    }
};

const authReducer = (state = initialState, action: AuthTypes.Action) => {
    switch (action.type) {
        case LOGIN.SUCCEEDED: {
            const payload = action.payload;
            return {
                ...state,
                token: payload.headers[TOKEN_HEADER],
                authenticated: true,
                error: false
            };
        }
        case LOGIN.ERRORED:
            const caught = action.payload;
            const status = caught?.response?.status;
            return {
                ...state,
                authenticated: false,
                errorCode: status,
                error: true
            };
        case AUTHENTICATE.SUCCEEDED: {
            return {
                ...state,
                authenticated: true
            };
        }
        case AUTHENTICATE.ERRORED:
            return {
                ...state,
                authenticated: false
            };
        case CLEAR_AUTHENTICATE.SUCCEEDED: {
            return {
                login: {},
                authenticated: false
            };
        }
        case LOGOUT.SUCCEEDED: {
            return {
                ...state,
                login: {},
                authenticated: false
            };
        }
        default:
            return state;
    }
};

export default authReducer;
