import { Gender } from 'constants/gender';
import { PermissionType } from 'constants/PermissionType';
import isEmptyString from 'validator/lib/isEmpty';

export function getGenderMessage(gender: any) {
    switch (gender) {
        case Gender.MALE:
            return 'male';
        case Gender.FEMALE:
            return 'female';
        default:
            return null;
    }
}

export function getPermissions() {
    return [
        PermissionType.MANAGE_USERS,
        PermissionType.RESULTS,
        PermissionType.ORDERS_COURIER,
        PermissionType.ORDERS_MATERIALS,
        PermissionType.PREORDERS_READ_ONLY,
        PermissionType.PREORDERS
    ];
}

export function getHiddenEmail(value: string) {
    const s1 = value?.split('@')[0];
    const s2 = value?.split('@')[1];
    if (s1 && s2) {
        return `${s1.length >= 5 ? s1.replace(/.{1,3}$/g, '***') : s1.replace(/.$/g, '*')}@${s2.replace(/\w/g, '*')}`;
    } else {
        return;
    }
}

export const capitalizeFirstLowerCaseRest = (str: string | null) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
};

export const trimming = (obj: any) => {
    const newObj = { ...obj };
    for (let prop in newObj) {
        if (typeof newObj[prop] === 'string') {
            newObj[prop] = newObj[prop].trim();
        } else {
            trimming(newObj[prop]);
        }
    }
    return newObj;
};

export const declOfNum = (number: number, words: string[]) => {
    return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]];
};

export const createMarkup = (value: any) => ({
    __html: value
});

export const isEmpty = (value: string | null | undefined) => {
    return isEmptyString(value?.toString() || '');
};

export const parseFIO = (patient: any) => {
    return (
        patient &&
        `${capitalizeFirstLowerCaseRest(patient.lastName)} ${capitalizeFirstLowerCaseRest(patient.firstName)} ${capitalizeFirstLowerCaseRest(
            patient.middleName
        )}`
    );
};

export const enforceMinMax = (element: any, value: string) => {
    if (value !== '') {
        if (parseInt(value) < parseInt(element.min)) {
            return element.min.toString();
        }
        if (parseInt(value) > parseInt(element.max)) {
            return element.max.toString();
        }
        return value;
    } else {
        return element.min.toString();
    }
};

export const isContainsNumbers = (value: string) => {
    return value.match(/[0-9+]/gi);
};
