import { createSelector } from '@reduxjs/toolkit';
import { groupBy, sumBy } from 'lodash';
import { AppTypes } from 'types/AppTypes';

export const materialsSelector = (state: AppTypes.State) => state.requests.materials;
export const orderSelector = (state: AppTypes.State) => state.requests.order;
export const orderHistorySelector = (state: AppTypes.State) => state.requests.orderHistory;
export const notAvailableMaterialsSelector = (state: AppTypes.State) => state.requests.notAvailableMaterials;

export const materialsByFolderSelector = createSelector([materialsSelector], (materials) => {
    const itemsGroupByFolder = groupBy(materials, 'folder');
    const materialsByFolder = [];
    for (let prop in itemsGroupByFolder) {
        materialsByFolder.push({ folder: prop, materials: [...itemsGroupByFolder[prop]] });
    }
    return materialsByFolder;
});

export const orderHistoryByFolderSelector = createSelector([orderHistorySelector], (orders) => {
    //@ts-ignore
    const materialsByFolder = [];
    orders.forEach((order: any) => {
        const itemsGroupByFolder = groupBy(order.materials, 'folder');
        for (let prop in itemsGroupByFolder) {
            materialsByFolder.push({ folder: prop, ...order, materials: [...itemsGroupByFolder[prop]] });
        }
    });
    //@ts-ignore
    return materialsByFolder;
});

export const totalQuantitySelector = createSelector([orderSelector], (order) => {
    return sumBy(order.materials, 'quantity');
});
