import apiClient from 'client/ApiClient';
import { BASE_URL } from './constants';
import { NewsTypes } from 'types/NewsTypes';
import { trimming } from 'utils/messageUtils';
const queryString = require('query-string');

const NEWS_URL = `${BASE_URL}/news`;
const DOWNLOAD_URL = `${BASE_URL}/download`;

export const getNewsById = (data: NewsTypes.DataGetNewsById) => {
    return apiClient.get<NewsTypes.NewsAPI.NewsDTO>(`${NEWS_URL}/${data.newsId}`);
};

export const search = (data: NewsTypes.DataSearch) => {
    return apiClient.get<NewsTypes.NewsAPI.NewsDTO[]>(`${NEWS_URL}/?${queryString.stringify(trimming(data))}`);
};

export const getCounters = () => {
    return apiClient.get<NewsTypes.Count[]>(`${NEWS_URL}/counters`);
};

export const downloadFile = (data: NewsTypes.DataDownloadFile) => {
    return apiClient.get<BlobPart>(`${DOWNLOAD_URL}/${data.id}`, { responseType: 'blob' });
};
