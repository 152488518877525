import React from 'react';
import preloaderIcon from 'themes/icons/loading.svg';
import styles from './Preloader.module.css';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';
import Modal from 'components/modal/Modal';

const Preloader = ({
    overlay = false,
    show = false,
    showText = true,
    width = '25px',
    height = '25px'
}: {
    overlay?: boolean;
    show?: boolean;
    showText?: boolean;
    width?: string;
    height?: string;
}) => {
    const { t } = useTranslation('common');
    return overlay ? (
        <Modal className={styles.transparent} show={show}>
            <div className={styles.overlay} />
        </Modal>
    ) : showText ? (
        <div className={styles.container}>
            <div className={styles.loadingBlock}>
                <Icon height={height} width={width} icon={`${preloaderIcon}#loading`} className={styles.loadingIcon} />
                {showText && <div className={styles.loadingText}>{t(`Preloader.loading`)}</div>}
            </div>
        </div>
    ) : (
        <Icon height={height} width={width} icon={`${preloaderIcon}#loading`} className={styles.loadingIcon} />
    );
};

export default React.memo(Preloader);
