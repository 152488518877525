import apiClient from 'client/ApiClient';
import { BASE_URL } from './constants';
import { RequestsTypes } from 'types/RequestsTypes';
const queryString = require('query-string');

const REQUESTS_URL = `${BASE_URL}/orders`;

export const fetchRequestCourier = (data?: { all?: boolean }) => {
    return apiClient.get<RequestsTypes.RequestAPI.RequestDTO[]>(`${REQUESTS_URL}/courier?${queryString.stringify(data)}`);
};

export const createRequestCourier = (data: RequestsTypes.RequestAPI.DataCreate) => {
    return apiClient.post<RequestsTypes.RequestAPI.RequestDTO>(`${REQUESTS_URL}/courier`, data);
};

export const changeRequestCourier = (data: RequestsTypes.RequestAPI.DataChange) => {
    return apiClient.patch<RequestsTypes.RequestAPI.RequestDTO>(`${REQUESTS_URL}/courier`, data);
};

export const cancelRequestCourier = (requestId: string) => {
    return apiClient.delete<RequestsTypes.RequestAPI.RequestDTO>(`${REQUESTS_URL}/courier/${requestId}`);
};

export const fetchMaterialsAll = () => {
    return apiClient.get<RequestsTypes.OrderAPI.MaterialDTO[]>(`${REQUESTS_URL}/materials/catalog`);
};

export const getMaterialPhoto = (data: RequestsTypes.RequestAPI.DataGetMaterialPhoto) => {
    return apiClient.get<BlobPart>(`${REQUESTS_URL}/materials/${data.picture}/image`, {
        responseType: 'blob'
    });
};

export const fetchOrderHistory = (data?: { all?: boolean }) => {
    return apiClient.get<RequestsTypes.OrderAPI.OrderDTO[]>(`${REQUESTS_URL}/materials?${queryString.stringify(data)}`);
};

export const sendMaterialOrder = (data: RequestsTypes.OrderAPI.OrderDTO) => {
    return apiClient.post<RequestsTypes.OrderAPI.OrderDTO>(`${REQUESTS_URL}/materials`, data);
};
