import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from 'page/settings/officeGroups/OfficeGroupsPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { FETCH_ALL_OFFICE_GROUPS, UPDATE_OFFICE_GROUP, DELETE_OFFICE_GROUP } from 'redux/office/actions';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import Modal from 'components/modal/Modal';
import ReactTooltip from 'react-tooltip';
import { declOfNum } from 'utils/messageUtils';
import { useTranslation } from 'react-i18next';

const OfficeGroupsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const actions = useSelector(actionsSelector);
    const [showModal, setShowModal] = useState(false);
    const [officeGroupId, setOfficeGroupId] = useState<string | null>(null);
    const { allOfficeGroups } = useSelector((state: AppTypes.State) => state.offices);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const location: any = useLocation();

    useEffect(() => ReactTooltip.rebuild() as () => void);

    useEffect(() => {
        // @ts-ignore
        if (!location?.state?.isForm) {
            dispatch(FETCH_ALL_OFFICE_GROUPS.base());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`OfficeGroupsPage.title`)}</h1>
            <div className={styles.settingRow}>
                <button onClick={() => navigate('/settings/officeGroups/create')}>{t(`OfficeGroupsPage.createGroupOffices`)}</button>
                {actions[FETCH_ALL_OFFICE_GROUPS.BASE]?.loading || actions[UPDATE_OFFICE_GROUP.BASE]?.loading || actions[DELETE_OFFICE_GROUP.BASE]?.loading ? (
                    <Preloader />
                ) : allOfficeGroups?.length > 0 ? (
                    <table className={styles.officeGroupList}>
                        <thead style={{ textAlign: 'left' }}>
                            <tr>
                                <th>{t(`OfficeGroupsPage.groupName`)}</th>
                                <th>{t(`OfficeGroupsPage.availableOffices`)}</th>
                                <th>{t(`OfficeGroupsPage.action`)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allOfficeGroups?.map((group) => {
                                const officeList: string[] = [];
                                group.offices.forEach((office) => officeList.push(office.clientCode));

                                return (
                                    <tr key={group.id} onClick={(e) => navigate(`/settings/officeGroups/${group.id}`)}>
                                        <td>{group.name}</td>
                                        <td>
                                            {group?.offices?.length > 3 ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {t(`OfficeGroupsPage.available`, {
                                                        counts: `${group?.offices.length}
                                                    ${declOfNum(group?.offices.length, [
                                                        t(`OfficeGroupsPage.office1`),
                                                        t(`OfficeGroupsPage.office2`),
                                                        t(`OfficeGroupsPage.office3`)
                                                    ])}`
                                                    })}
                                                    <div data-for={'officeGroups'} data-tip={`<div>${officeList.join('<br/>')}</div>`}>
                                                        <div className={styles.marker}>!</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                group?.offices?.map((office) => <div key={office.id}>{office.clientCode}</div>)
                                            )}
                                        </td>
                                        <td>
                                            <Link
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    setOfficeGroupId(() => {
                                                        setShowModal(true);
                                                        return group.id || null;
                                                    });
                                                }}
                                                to={`/settings/officeGroups/${group.id}`}
                                            >
                                                {t(`Common.remove`)}
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div style={{ marginTop: '30px' }}>{t(`OfficeGroupsPage.officeGroupsNotFound`)}</div>
                )}
            </div>
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                {selectedOfficeOrGroup?.id !== officeGroupId ? (
                    <Fragment>
                        <div className={styles.actionModal}>
                            <h2>{t(`OfficeGroupsPage.question1`)}</h2>
                            <p style={{ fontSize: '18px' }}>{t(`OfficeGroupsPage.question2`)}</p>
                        </div>
                        <div className={styles.actionButtons}>
                            <button
                                onClick={() => {
                                    setShowModal(false);
                                    setOfficeGroupId(null);
                                    dispatch(
                                        DELETE_OFFICE_GROUP.base({
                                            officeGroupId
                                        })
                                    );
                                }}
                                type="button"
                            >
                                {t(`Common.yes`)}
                            </button>
                            <button onClick={() => setShowModal(false)} type="button">
                                {t(`Common.no`)}
                            </button>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div style={{ textAlign: 'center' }} className={styles.actionModal}>
                            <p style={{ fontSize: '18px', marginBottom: '30px' }}>{t(`OfficeGroupsPage.removeGroupError`)}</p>
                            <button onClick={() => setShowModal(false)} type="button">
                                {t(`Common.cancel`)}
                            </button>
                        </div>
                    </Fragment>
                )}
            </Modal>
            {
                // @ts-ignore
                <ReactTooltip
                    textColor={'#ffffff'}
                    backgroundColor={'#8a8a8a'}
                    id={'officeGroups'}
                    resizeHide={true}
                    html={true}
                    type={'light'}
                    effect={'solid'}
                    place={'top'}
                />
            }
        </div>
    );
};

export default React.memo(OfficeGroupsPage);
