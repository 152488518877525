import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import * as officeClient from 'client/OfficeClient';
import {
    CREATE_OFFICE_GROUP,
    DELETE_OFFICE_GROUP,
    FETCH_ALL_OFFICE_GROUPS,
    FETCH_OFFICE_GROUP,
    FETCH_OFFICES,
    FETCH_OFFICES_FOR_GROUP,
    HAS_EMAIL_COURIER,
    HAS_EXTERNAL_PREORDER_POINTS,
    SELECT_OFFICE_OR_GROUP,
    SET_FAVORITE_OFFICE,
    UPDATE_OFFICE_GROUP
} from './actions';
import LocalStorageService from 'services/LocalStorageService';
import { USERS_ID_SELECTED_OFFICES_ID } from './constants';
import { AxiosResponse } from 'axios';
import { OfficesTypes } from 'types/OfficesTypes';
import { AUTH_USER, GET_CURRENT_USER } from 'redux/user/actions';
import localStorageService from 'services/LocalStorageService';
import { AppTypes } from 'types/AppTypes';
import * as userClient from 'client/UserClient';
import { CREATE_REQUEST_COURIER } from 'redux/requests/actions';
import { SELECT_MENU_TYPE } from 'constants/SelectMenuType';

export function* fetchOffices() {
    try {
        const responseOffices: AxiosResponse<OfficesTypes.OfficesAPI.OfficeDTO[]> = yield call(officeClient.fetchOffices);
        const responseOfficeGroups: AxiosResponse<OfficesTypes.OfficesAPI.OfficeDTO[]> = yield call(officeClient.fetchAllOfficeGroups);
        const responseCurrentUser: AxiosResponse = yield call(userClient.getCurrentUser);
        yield put(GET_CURRENT_USER.success(responseCurrentUser));
        if (responseOffices?.data?.length > 0) {
            const { currentUser } = yield select((state: AppTypes.State) => state.user);
            const usersIdSelectedOfficesId = getUsersSelectedOfficesId();
            if (usersIdSelectedOfficesId[currentUser.id]) {
                const selected: OfficesTypes.OfficeType = yield [...responseOffices?.data, ...responseOfficeGroups.data].filter(
                    (office) => office.id === usersIdSelectedOfficesId[currentUser.id].id
                )[0];
                if (selected?.id) {
                    yield put(SELECT_OFFICE_OR_GROUP.base({ id: selected.id, type: usersIdSelectedOfficesId[currentUser.id].type }));
                } else {
                    yield put(SELECT_OFFICE_OR_GROUP.base({ id: responseOffices?.data[0].id, type: SELECT_MENU_TYPE.OFFICE }));
                }
            } else {
                yield put(SELECT_OFFICE_OR_GROUP.base({ id: responseOffices?.data[0].id, type: SELECT_MENU_TYPE.OFFICE }));
            }
            yield put(FETCH_OFFICES.success(responseOffices));
            yield put(FETCH_ALL_OFFICE_GROUPS.success(responseOfficeGroups));
        } else {
            yield put(FETCH_OFFICES.error());
        }
    } catch (err: any) {
        yield put(FETCH_OFFICES.error(err?.response));
    }
}

function* selectOfficeOrGroup(action: OfficesTypes.ActionSelectOfficeOrGroup) {
    try {
        const { currentUser } = yield select((state: AppTypes.State) => state.user);
        const usersIdSelectedOfficesId = getUsersSelectedOfficesId();
        LocalStorageService.setItem(
            USERS_ID_SELECTED_OFFICES_ID,
            JSON.stringify({ ...usersIdSelectedOfficesId, [currentUser.id]: { id: action.meta.id, type: action.meta.type } })
        );
        yield put(AUTH_USER.base({ id: action.meta.id, type: action.meta.type }));
    } catch (err: any) {
        yield put(SELECT_OFFICE_OR_GROUP.error(err?.response));
    }
}

export function* hasEmailCourier() {
    try {
        yield call(officeClient.hasEmailCourier);
        yield put(HAS_EMAIL_COURIER.success(true));
    } catch (err: any) {
        yield put(HAS_EMAIL_COURIER.error(err?.response));
    }
}

export function* hasExternalPreorderPoints() {
    try {
        yield call(officeClient.hasExternalPreorderPoints);
        yield put(HAS_EXTERNAL_PREORDER_POINTS.success(true));
    } catch (err: any) {
        yield put(HAS_EXTERNAL_PREORDER_POINTS.error(err?.response));
    }
}

const getUsersSelectedOfficesId = () => {
    const usersIdSelectedOfficesId = localStorageService.getItem(USERS_ID_SELECTED_OFFICES_ID);
    try {
        return usersIdSelectedOfficesId ? JSON.parse(usersIdSelectedOfficesId) : {};
    } catch (e) {
        return {};
    }
};

function* fetchAllOfficeGroups() {
    try {
        const response: AxiosResponse<OfficesTypes.OfficesAPI.OfficeGroupDTO[]> = yield call(officeClient.fetchAllOfficeGroups);
        yield put(FETCH_ALL_OFFICE_GROUPS.success(response));
    } catch (err: any) {
        yield put(FETCH_ALL_OFFICE_GROUPS.error(err?.response));
    }
}

function* fetchOfficeGroup(action: OfficesTypes.ActionOfficeGroup) {
    try {
        const response: AxiosResponse<OfficesTypes.OfficesAPI.OfficeGroupDTO> = yield call(officeClient.fetchOfficeGroup, action.meta);
        yield put(FETCH_OFFICE_GROUP.success(response));
    } catch (err: any) {
        yield put(FETCH_OFFICE_GROUP.error(err?.response));
    }
}

function* fetchOfficesForGroup(action: OfficesTypes.ActionOfficesForGroup) {
    try {
        const response: AxiosResponse<OfficesTypes.OfficesAPI.OfficesForGroupDTO> = yield call(officeClient.fetchOfficesForGroup, action.meta);
        yield put(FETCH_OFFICES_FOR_GROUP.success(response));
    } catch (err: any) {
        yield put(FETCH_OFFICES_FOR_GROUP.error(err?.response));
    }
}

function* createOfficeGroup(action: OfficesTypes.ActionCreateOfficeGroup) {
    try {
        yield call(officeClient.createOfficeGroup, action.meta);
        yield put(CREATE_REQUEST_COURIER.success());
        yield call(fetchAllOfficeGroups);
    } catch (err: any) {
        yield put(CREATE_REQUEST_COURIER.error(err?.response));
    }
}

function* updateOfficeGroup(action: OfficesTypes.ActionUpdateOfficeGroup) {
    try {
        yield call(officeClient.updateOfficeGroup, action.meta);
        yield put(UPDATE_OFFICE_GROUP.success());
        yield call(fetchAllOfficeGroups);
    } catch (err: any) {
        yield put(UPDATE_OFFICE_GROUP.error(err?.response));
    }
}

function* deleteOfficeGroup(action: OfficesTypes.ActionOfficeGroup) {
    try {
        yield call(officeClient.deleteOfficeGroup, action.meta);
        yield put(DELETE_OFFICE_GROUP.success());
        yield call(fetchAllOfficeGroups);
    } catch (err: any) {
        yield put(DELETE_OFFICE_GROUP.error(err?.response));
    }
}

export function* setFavoriteOffice(action: OfficesTypes.ActionSetFavoriteOffice) {
    try {
        yield call(officeClient.setFavoriteOffice, action.meta);
        yield call(fetchOffices);
        yield put(SET_FAVORITE_OFFICE.success(true));
    } catch (err: any) {
        yield put(SET_FAVORITE_OFFICE.error(err?.response));
    }
}

export default function* authSagas() {
    yield all([takeLatest(FETCH_OFFICES.BASE, fetchOffices)]);
    yield all([takeLatest(SELECT_OFFICE_OR_GROUP.BASE, selectOfficeOrGroup)]);
    yield all([takeLatest(HAS_EMAIL_COURIER.BASE, hasEmailCourier)]);
    yield all([takeLatest(HAS_EXTERNAL_PREORDER_POINTS.BASE, hasExternalPreorderPoints)]);
    yield all([takeLatest(FETCH_ALL_OFFICE_GROUPS.BASE, fetchAllOfficeGroups)]);
    yield all([takeLatest(FETCH_OFFICE_GROUP.BASE, fetchOfficeGroup)]);
    yield all([takeLatest(FETCH_OFFICES_FOR_GROUP.BASE, fetchOfficesForGroup)]);
    yield all([takeLatest(CREATE_OFFICE_GROUP.BASE, createOfficeGroup)]);
    yield all([takeLatest(UPDATE_OFFICE_GROUP.BASE, updateOfficeGroup)]);
    yield all([takeLatest(DELETE_OFFICE_GROUP.BASE, deleteOfficeGroup)]);
    yield all([takeLatest(SET_FAVORITE_OFFICE.BASE, setFavoriteOffice)]);
}
