import React, { Suspense } from 'react';
import IsInvitro from 'themes/invitro/IsInvitro';
import IsVetunion from 'themes/vetunion/IsVetunion';
const Invitro = React.lazy(() => import('./invitro/Invitro'));
const Vetunion = React.lazy(() => import('./vetunion/Vetunion'));

export const ThemeContext = React.createContext({
    nameCompany: ``
});

const Themes = ({ children }: { children: React.ReactElement }) => {
    return (
        <Suspense fallback={null}>
            <IsInvitro>
                <Invitro>{children}</Invitro>
            </IsInvitro>
            <IsVetunion>
                <Vetunion>{children}</Vetunion>
            </IsVetunion>
        </Suspense>
    );
};

export default React.memo(Themes);
