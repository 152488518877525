import apiClient from 'client/ApiClient';
import { BASE_URL } from './constants';
import { BannersTypes } from 'types/BannersTypes';
const BANNERS_URL = `${BASE_URL}/banner`;
const DOWNLOAD_VIEW_URL = `${BASE_URL}/download/view` as const;

export const fetchAllBanners = () => {
    return apiClient.get<BannersTypes.BannersAPI.BannerDTO[]>(`${BANNERS_URL}`);
};

export const fetchFileBanner = (data: BannersTypes.DataFetchFileBanner) => {
    return apiClient.get<Blob>(`${DOWNLOAD_VIEW_URL}/${data.fileId}`, { responseType: 'blob' });
};
