import React, { Fragment, useState } from 'react';
import classNames from 'classnames/bind';
import ButtonLink from 'components/button/link/ButtonLink';
import ButtonLinkSvg from 'components/button/link/svg/ButtonLinkSvg';
import iconCancel from './cancel.svg';
import { ClassifierType } from 'constants/classifierType';
import ProductModal from '../modal/ProductModal';
import styles from './PreorderProduct.module.css';
import GroupedProductList from 'page/preorders/components/formProducts/groupedProductList/groupedProductList';
import BiomaterialChooser from 'page/preorders/components/formProducts/biomaterialChooser/BiomaterialChooser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { preorderBiomaterialSetsSelector, preorderDetailsBiomaterialSetsSelector, preorderDetailsProductsSelector } from 'redux/preorders/selectors';
import { AppTypes } from 'types/AppTypes';
const cx = classNames.bind(styles);

const PreorderProduct = ({ preorder, product, products = [], orderProducts = [], currency, onRemoveProduct, onSelectBiomaterial, invalidProducts }: any) => {
    const { t } = useTranslation('common');
    const [openedProductList, setOpenedProductList] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [currentProduct, setCurrentProduct] = useState();
    const preorderSets = useSelector((state: AppTypes.State) => preorderBiomaterialSetsSelector(state));
    const preorderDetailsSets = useSelector((state: AppTypes.State) => preorderDetailsBiomaterialSetsSelector(state));
    const preorderDetailsProducts = useSelector((state: AppTypes.State) => preorderDetailsProductsSelector(state));

    const getFilteredOrderProducts = (product: any) => {
        return (preorder?.id ? [...preorderDetailsProducts] : [...orderProducts])?.filter((item: any) => product.id === item.profileId);
    };

    const renderTitle = (product: any) => {
        return (
            <div className={styles.titleContainer}>
                {product.textInterpretation ? (
                    <ButtonLink className={styles.productName} text={product.name} onClick={showProductDescription} />
                ) : (
                    <span className={styles.productName}>{product.name}</span>
                )}
            </div>
        );
    };

    const renderComment = () => {
        if (product.classifierData?.classifierType === ClassifierType.TEST && product.duration) {
            return t(`PreorderProduct.duration`, { days: product.duration });
        } else if (product?.classifierData !== null && product?.classifierData?.service) {
            return t(`PreorderProduct.serviceRequired`);
        } else {
            return null;
        }
    };

    const showProductDescription = () => {
        setShowDescriptionModal(true);
        setCurrentProduct(product);
    };

    const hideProductDescription = () => {
        setShowDescriptionModal(false);
    };

    const openProductList = () => {
        setOpenedProductList(!openedProductList);
    };

    return (
        <div className={cx({ product: true })}>
            <div className={styles.topContainer}>
                <div className={styles.productLeft}>{renderTitle(product)}</div>
                <div className={styles.productRight}>
                    {getFilteredOrderProducts(product)?.length > 0 && !openedProductList && (!invalidProducts || invalidProducts?.length === 0) && (
                        <div className={styles.summaryListInfo}>
                            <div className={styles.quantityProductList}>
                                {getFilteredOrderProducts(product)?.length} {t(`PreorderProduct.quantity`)}
                            </div>
                        </div>
                    )}
                    <Fragment>
                        {onRemoveProduct && product.removable ? (
                            <ButtonLinkSvg className={styles.productDelete} svg={`${iconCancel}#icon-cancel`} onClick={() => onRemoveProduct(product)} />
                        ) : null}
                    </Fragment>
                </div>
            </div>
            <div className={styles.productBottom}>
                <div className={styles.productTags}>
                    <span>{t(`PreorderProduct.article`, { article: product.article })}</span>
                    <span>{renderComment()}</span>
                </div>
                <BiomaterialChooser product={product} onSelectBiomaterial={onSelectBiomaterial} />
            </div>
            {showDescriptionModal ? <ProductModal show={showDescriptionModal} product={currentProduct} onClose={hideProductDescription} /> : null}
            {product?.classifierData?.classifierType === ClassifierType.PROFILE && (
                <GroupedProductList
                    product={product}
                    products={products}
                    orderProducts={preorder?.id ? preorderDetailsProducts : orderProducts}
                    currency={currency}
                    onSelectBiomaterial={onSelectBiomaterial}
                    sets={preorder?.id ? preorderDetailsSets : preorderSets}
                    openProductList={openProductList}
                />
            )}
        </div>
    );
};

export default React.memo(PreorderProduct);
