import { all, takeLatest, call, put, debounce } from 'redux-saga/effects';
import * as resultClient from 'client/ResultClient';
import { FETCH_ALL, FETCH_RESULT_DETAILS, GET_PDF_RESULT, GET_RESULT_DETAILS_FILE, MARK_DOCUMENT, SEND_BY_EMAIL } from './actions';
import { AxiosResponse } from 'axios';
import { ResultsTypes } from 'types/ResultsTypes';
import { loadFile, printFile } from 'utils/fileUtils';
import { takeEvery } from '@redux-saga/core/effects';

function* fetchAll(action: ResultsTypes.ResultsAPI.ActionFetchAll) {
    try {
        const response: AxiosResponse<ResultsTypes.ResultsAPI.ResultDTO[]> = yield call(resultClient.fetchAll, action.meta.serverSideFilter);
        yield put(FETCH_ALL.success(response));
    } catch (err: any) {
        yield put(FETCH_ALL.error(err?.response));
    }
}

function* getPdfResult(action: ResultsTypes.ResultsAPI.ActionGetPdfResult) {
    try {
        const response: AxiosResponse<BlobPart> = yield call(resultClient.getPdfResult, action);
        if (action.meta.download) {
            yield loadFile(response);
        } else {
            if (action.meta.view) {
                yield put(GET_PDF_RESULT.success({ responseData: response.data, response }));
                return true;
            } else {
                yield printFile(response);
            }
        }
        yield put(GET_PDF_RESULT.success());
    } catch (err: any) {
        yield put(GET_PDF_RESULT.error(err?.response));
    }
}

function* markDocument(action: ResultsTypes.ActionMarkDocument) {
    try {
        const response: AxiosResponse<void> = yield call(resultClient.markDocument, action.meta);
        yield put(MARK_DOCUMENT.success(response));
    } catch (err: any) {
        yield put(MARK_DOCUMENT.error(err?.response));
    }
}

function* fetchResultDetails(action: ResultsTypes.ResultsAPI.ActionFetchResultDetails) {
    try {
        const response: AxiosResponse<ResultsTypes.TypeResultDetails[]> = yield call(resultClient.fetchResultDetails, action.meta);
        yield put(FETCH_RESULT_DETAILS.success(response));
    } catch (err: any) {
        yield put(FETCH_RESULT_DETAILS.error(err?.response));
    }
}

function* getResultDetailsFile(action: ResultsTypes.ResultsAPI.ActionGetResultDetailsFile) {
    try {
        const response: AxiosResponse<BlobPart> = yield call(resultClient.getResultDetailsFile, action.meta);
        yield put(
            GET_RESULT_DETAILS_FILE.success({
                inz: action.meta.inz,
                fileId: action.meta.fileId,
                size: action.meta.size,
                responseData: response.data,
                type: response.headers.type
            })
        );
    } catch (err: any) {
        yield put(GET_RESULT_DETAILS_FILE.error(err?.response));
    }
}

function* sendByEmail(action: ResultsTypes.ResultsAPI.ActionSendByEmail) {
    try {
        yield call(resultClient.sendByEmail, action.meta);
        yield put(SEND_BY_EMAIL.success());
    } catch (err: any) {
        yield put(SEND_BY_EMAIL.error(err?.response));
    }
}

export default function* resultsSagas() {
    yield debounce(500, FETCH_ALL.BASE, fetchAll);
    yield all([takeLatest(GET_PDF_RESULT.BASE, getPdfResult)]);
    yield all([takeLatest(MARK_DOCUMENT.BASE, markDocument)]);
    yield all([takeLatest(FETCH_RESULT_DETAILS.BASE, fetchResultDetails)]);
    yield all([takeEvery(GET_RESULT_DETAILS_FILE.BASE, getResultDetailsFile)]);
    yield all([takeEvery(SEND_BY_EMAIL.BASE, sendByEmail)]);
}
