import { SET_ACTIONS } from 'redux/app/actions';
import { AppTypes } from 'types/AppTypes';
const initialState: {} = {
    actions: {}
};

const appReducer = (state: AppTypes.StateActions = initialState, action: AppTypes.Action): AppTypes.StateActions => {
    switch (action.type) {
        case SET_ACTIONS: {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    ...action.meta
                }
            };
        }
        default:
            return state;
    }
};

export default appReducer;
