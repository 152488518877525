import React from 'react';
import classNames from 'classnames';
import Button from 'components/button/Button';
import styles from './ButtonLink.module.css';

const ButtonLink = ({ className = '', ...rest }: any) => {
    return <Button className={classNames(styles.button, className)} {...rest} />;
};

export default React.memo(ButtonLink);
