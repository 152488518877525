export const Gender = {
    /**
     * Мужской
     */
    MALE: 'MALE',

    /**
     * Женский
     */
    FEMALE: 'FEMALE',

    /**
     * Не определен
     */
    UNDEFINED: 'UNDEFINED'
};
