import React, { Fragment } from 'react';
import TestValue from '../components/value/TestValue';
import Table from 'components/table/Table';
import UserCommentCell from '../components/comment/UserCommentCell';
import TableFootnote from '../components/table/footnote/TableFootnote';
import { Area, CartesianGrid, ComposedChart, Dot, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { renderReference } from 'utils/testUtils';
import { add, differenceInYears, formatDate, formatDateTime, fromUnixTime, startOfMonth, startOfYear, parseISO, startOfDay } from 'utils/timeUtils';
import styles from './DynamicsDetailsPage.module.css';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { CREATE_COMMENT, FETCH_BY_CODE, UPDATE_COMMENT } from 'redux/dynamics/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { actionsSelector } from 'redux/app/selectors';
import Preloader from 'components/preloader/Preloader';
import PatientCard from 'components/card/PatientCard';
import printIcon from 'themes/icons/printed.svg';
import { ResultsTypes } from 'types/ResultsTypes';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';
import IsInvitro from 'themes/invitro/IsInvitro';
import IsVetunion from 'themes/vetunion/IsVetunion';
import VetunionPatientCard from 'components/card/VetunionPatientCard';
const queryString = require('query-string');

const DynamicsDetailsPage = () => {
    const dispatch = useDispatch();
    const { details } = useSelector((state: AppTypes.State) => state.dynamics);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [tests, setTests] = useState<ResultsTypes.TypeTestResult[]>([]);
    const location: any = useLocation();
    const actions = useSelector(actionsSelector);
    const parsedLocationString = queryString.parse(location.search);
    const { t } = useTranslation('common');

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(
                FETCH_BY_CODE.base({
                    nss: parsedLocationString.nss,
                    code: parsedLocationString.code
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id]);

    useEffect(() => {
        if (details.testResults && details.testResults.length > 0) {
            const entities = details.testResults?.reduce((map: any, test: any) => {
                if (test.time) {
                    const code = test.code;
                    map[code] = map[code] || {};
                    const time = parseISO(test.time);
                    const date = startOfDay(time).getTime();
                    map[code][date] = map[code][date] || [];
                    map[code][date].push({ ...test, time: time.getTime() });
                }
                return map;
            }, {});

            const testsByDate = entities[parsedLocationString.code];

            if (testsByDate && testsByDate.length !== 0) {
                const startTime = null;
                const endTime = null;
                const tests = Object.keys(testsByDate)
                    .filter((date) => (!startTime || startTime <= date) && (!endTime || endTime >= date))
                    .flatMap((date) => testsByDate[date])
                    .sort((item1, item2) => {
                        if (item1.time < item2.time) {
                            return -1;
                        } else if (item1.time > item2.time) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                setTests(tests);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details]);

    const renderGraph = (tests: ResultsTypes.TypeTestResult[]) => {
        const firstTest = tests[0];
        let minTime = firstTest?.time;
        let maxTime = firstTest?.time;
        let minValue = firstTest?.value;
        let maxValue = firstTest?.value;
        const data = tests
            .filter((test) => !isNaN(parseFloat(String(test.value))))
            .map((test) => {
                const value = parseFloat(String(test.value));
                const time = test.time;
                const refMin = (test.refMin && parseFloat(test.refMin)) || 0;
                const refMax = (test.refMax && parseFloat(test.refMax)) || 0;
                minTime = minTime < time ? minTime : time;
                maxTime = maxTime > time ? maxTime : time;
                minValue = minValue < value ? minValue : value;
                minValue = minValue < refMin ? minValue : refMin;
                maxValue = maxValue > value ? maxValue : value;
                maxValue = maxValue > refMax ? maxValue : refMax;
                return {
                    time: time,
                    value: parseFloat(String(value)),
                    unit: test.unit,
                    refText: renderReference(test),
                    reference: [refMin, refMax],
                    test: test
                };
            });
        if (data.length <= 1) {
            return null;
        }
        const ticks: Set<number> = new Set();
        const minDate = fromUnixTime(minTime);
        const maxDate = fromUnixTime(maxTime);
        const years = differenceInYears(maxDate, minDate);
        let format: string | undefined;
        if (years > 1) {
            format = 'yyyy';
            let date = add(minDate, { years: 1 });
            while (date < maxDate) {
                date = startOfYear(date);
                ticks.add(date.getTime());
                date = add(date, { years: 1 });
            }
        } else {
            format = 'LLL yyyy';
            let date = add(minDate, { months: 1 });
            while (date < maxDate) {
                date = startOfMonth(date);
                ticks.add(date.getTime());
                date = add(date, { months: 1 });
            }
        }

        // @ts-ignore
        const ticksArr = [...ticks].sort();
        const timeDifference = parseInt(maxTime) - parseInt(minTime);
        const xAxisPadding = timeDifference * 0.01;
        const valueDifference = maxValue - minValue;
        const yAxisPadding = valueDifference * 0.01;
        return (
            <div className={styles.chartResponsiveContainer}>
                <ResponsiveContainer width={'100%'} height={350}>
                    <ComposedChart data={data}>
                        <Area type="monotone" dataKey="reference" fill="#E5F4F7" stroke="#CEEBF0" isAnimationActive={false} />
                        <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#0098B3"
                            isAnimationActive={false}
                            dot={(props) => renderDot(props)}
                            // @ts-ignore
                            activeDot={(props) => renderDot(props)}
                        />
                        <CartesianGrid stroke="#ccc" horizontal={false} />
                        <XAxis
                            height={35}
                            type="number"
                            dataKey="time"
                            axisLine={false}
                            tickLine={false}
                            domain={[(dataMin: number) => dataMin - xAxisPadding, (dataMax: number) => dataMax + xAxisPadding]}
                            tickFormatter={(time) => formatDate(fromUnixTime(time), 'ru', format) as string}
                            tickSize={ticksArr.length}
                            tickMargin={15}
                            ticks={ticksArr}
                        />
                        <YAxis
                            type="number"
                            dataKey="value"
                            axisLine={false}
                            tickLine={false}
                            domain={[minValue - yAxisPadding, maxValue + yAxisPadding]}
                            tickFormatter={(value) => value.toFixed(2)}
                            width={35}
                        />
                        <Tooltip
                            isAnimationActive={false}
                            cursor={false}
                            // @ts-ignore
                            position={{ x: 'auto', y: yAxisPadding }}
                            content={(props: any) => renderTooltip(props)}
                            wrapperStyle={{ zIndex: 2 }}
                        />
                        <Legend
                            verticalAlign="top"
                            align="right"
                            iconType="rect"
                            formatter={(value) => {
                                switch (value) {
                                    case 'reference':
                                        return t(`DynamicsDetailsPage.reference`);
                                    default:
                                        return t(`DynamicsDetailsPage.value`);
                                }
                            }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    };
    // @ts-ignore
    const renderTooltip = ({ payload, active }) => {
        if (active) {
            const data = payload[0].payload;
            const time = data.time;
            const test = data.test;
            return (
                <div className={styles.graphTooltip}>
                    <span className={classNames(styles.date, test.outbound ? styles.outbound : null)}>{formatDateTime(fromUnixTime(time), 'ru', 'PPp')}</span>
                    <TestValue test={data.test} />
                    {data.refText ? (
                        <span>
                            {t(`DynamicsDetailsPage.shortValue`)} {data.refText}
                        </span>
                    ) : null}
                    {test.userComment ? <span className={styles.comment}>{test.userComment}</span> : null}
                </div>
            );
        }

        return null;
    };

    const renderDot = (props: any) => {
        const color = props.payload.test.outbound ? '#FF6A13' : '#0098B3';
        return <Dot {...props} style={{ stroke: color, fill: color }} />;
    };

    const renderTable = (tests: any) => {
        const columns = [
            {
                Header: t(`DynamicsDetailsPage.time`),
                accessor: 'time',
                sticky: 'left',
                Cell: (props: any) => {
                    const time = props.cell?.value || '';
                    return time && formatDateTime(fromUnixTime(time), 'ru', 'PPp');
                }
            },
            {
                Header: t(`Common.result`),
                accessor: 'value',
                Cell: (props: any) => {
                    const test = props.cell.value;
                    return <TestValue mark={true} test={test} />;
                }
            },
            {
                Header: t(`Common.referenceValue`),
                accessor: 'reference',
                Cell: (props: any) => {
                    return props.cell.value;
                }
            },
            {
                Header: t(`Common.laboratoryComment`),
                accessor: 'laboratoryComment',
                Cell: (props: any) => {
                    return props.cell.value || null;
                }
            },
            {
                Header: t(`Common.myComment`),
                accessor: 'userComment',
                Cell: (props: any) => (
                    <div className={styles.userComment}>
                        <UserCommentCell
                            {...props}
                            onChange={(data: any) => {
                                if (data.newComment) {
                                    dispatch(CREATE_COMMENT.base({ inz: data.test.inz, code: data.test.code, comment: data.comment }));
                                } else {
                                    dispatch(UPDATE_COMMENT.base({ inz: data.test.inz, code: data.test.code, comment: data.comment }));
                                }
                            }}
                        />
                    </div>
                )
            }
        ];

        const data: any = [];
        tests.forEach((test: any) => {
            const dataItem: any = {};
            dataItem['time'] = test.time;
            dataItem['value'] = test;
            dataItem['reference'] = renderReference(test);
            dataItem['laboratoryComment'] = test.laboratoryComment;
            dataItem['userComment'] = test;
            data.push(dataItem);
        });

        return (
            <React.Fragment>
                <Table className={styles.table} columns={columns} data={data} />
                <TableFootnote />
            </React.Fragment>
        );
    };

    return (
        <div className={styles.container}>
            <IsAvailable options={[{ results: true }]}>
                <Breadcrumbs
                    links={[
                        {
                            link: '/results',
                            name: t(`Common.results`),
                            active: false
                        },
                        {
                            link: `/results/details?inz=${parsedLocationString.inz}&nss=${parsedLocationString.nss}`,
                            name: t(`Common.inz`),
                            active: false
                        },
                        {
                            link: `/results/dynamics?inz=${parsedLocationString.inz}&nss=${parsedLocationString.nss}&codes=${
                                // @ts-ignore
                                location?.state?.codes || parsedLocationString.code
                            }`,
                            name: t(`Common.dynamics`),
                            active: false
                        },
                        {
                            link: ``,
                            name: details?.testResults && details?.testResults?.length > 0 ? details?.testResults[0]?.shortName : undefined,
                            active: true
                        }
                    ]}
                />
                <Access permissions={[PermissionType.RESULTS]} message={t(`Common.noAccess`)} errors={[actions[FETCH_BY_CODE.BASE]?.error]}>
                    {actions[FETCH_BY_CODE.BASE]?.success && (
                        <div className={styles.patientCard}>
                            <IsInvitro>
                                <PatientCard dynamics={true} patient={details.patient} officeName={details?.officeFullName} />
                            </IsInvitro>
                            <IsVetunion>
                                <VetunionPatientCard patient={details.patient} officeName={details?.officeFullName} />
                            </IsVetunion>
                        </div>
                    )}
                    <h2>
                        {t(`Common.dynamicsTest`)} {details?.testResults && details?.testResults?.length > 0 && `«${details?.testResults[0]?.shortName}»`}
                    </h2>
                    {actions[FETCH_BY_CODE.BASE]?.loading && <Preloader />}
                    {actions[FETCH_BY_CODE.BASE]?.success && (
                        <Fragment>
                            {tests?.length > 0 && (
                                <button className={styles.print} onClick={() => window.print()}>
                                    <Icon icon={`${printIcon}#printed`} className={styles.printIcon} />
                                    <span>{t(`Common.print`)}</span>
                                </button>
                            )}
                            {tests?.length > 0 && renderGraph(tests)}
                            {tests?.length > 0 && renderTable(tests)}
                            {tests?.length < 1 && <p>{t(`Common.resultsNotFound`)}</p>}
                        </Fragment>
                    )}
                </Access>
            </IsAvailable>
        </div>
    );
};

export default React.memo(DynamicsDetailsPage);
