import React from 'react';
import { useBlockLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import styles from './Table.module.css';
import classNames from 'classnames/bind';

const Table = (props) => {
    const { columns, data, emptyText } = props;
    const defaultColumn = { width: '100%' };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, defaultColumn }, useBlockLayout, useSticky);

    return (
        <div {...getTableProps()} className={classNames(styles.table, styles.sticky, props.className)}>
            <div className={styles.header}>
                {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className={styles.tr} style={{ display: 'inline-flex' }}>
                        {headerGroup.headers.map((column) => (
                            <div {...column.getHeaderProps()} className={styles.th}>
                                {column.render('Header')}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div {...getTableBodyProps()} className={styles.body}>
                {!rows || rows.length === 0
                    ? emptyText
                    : rows.map((row, i) => {
                          prepareRow(row);
                          return (
                              <div
                                  {...row.getRowProps({ onClick: () => props.onRowClick && props.onRowClick(row) })}
                                  className={styles.tr}
                                  style={{ display: 'inline-flex' }}
                              >
                                  {row.cells.map((cell) => {
                                      return (
                                          <div {...cell.getCellProps()} className={styles.td}>
                                              {cell.render('Cell')}
                                          </div>
                                      );
                                  })}
                              </div>
                          );
                      })}
            </div>
        </div>
    );
};

export default React.memo(Table);
