import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Routes as ReactRoutes, Route, Navigate, RouteProps, useLocation } from 'react-router-dom';
import AuthPage from 'page/auth/AuthPage';
import RecoveryPage from 'page/auth/recovery/RecoveryPage';
import ChangePassword from 'page/auth/confirm/ChangePasswordPage';
import UpdateEmailPage from 'page/auth/confirm/UpdateEmailPage';
import SettingsPage from 'page/settings/SettingsPage';
import ResultsPage from 'page/results/ResultsPage';
import ResultDetailsPage from 'page/results/details/ResultDetailsPage';
import ResultPdfPage from 'page/pdf/ResultPdfPage';
import DynamicsPage from 'page/dynamics/DynamicsPage';
import DynamicsDetailsPage from 'page/dynamics/details/DynamicsDetailsPage';
import RequestsPage from 'page/requests/RequestsPage';
import RequestsMaterialsPage from 'page/requests/materials/RequestsMaterialsPage';
import RequestsHistoryPage from 'page/requests/history/RequestsHistoryPage';
import RequestsHistoryDetailsPage from 'page/requests/history/details/RequestsHistoryDetailsPage';
import RequestsCourierPage from 'page/requests/courier/RequestsCourierPage';
import NewsPage from 'page/news/NewsPage';
import NewsDetailsPage from 'page/news/details/NewsDetailsPage';
import PreordersPage from 'page/preorders/PreordersPage';
import NotFoundPage from 'page/error/NotFoundPage';
import { AppTypes } from 'types/AppTypes';
import CreatePreorderPage from 'page/preorders/create/CreatePreorderPage';
import PreorderDetailsPage from 'page/preorders/details/PreorderDetailsPage';

type TProps = { children: JSX.Element } & RouteProps;

export const PrivateRoute: FC<TProps> = ({ children }) => {
    const location: any = useLocation();
    const { authenticated } = useSelector((state: AppTypes.State) => state.auth);
    return authenticated ? (
        children
    ) : (
        <Navigate
            replace
            to={{
                pathname: '/login'
            }}
            state={{ referrer: location }}
        />
    );
};

const Routes = () => (
    <ReactRoutes>
        <Route
            path={'/'}
            element={
                <Navigate
                    replace
                    to={{
                        pathname: '/results'
                    }}
                />
            }
        />
        <Route path={'/login'} element={<AuthPage />} />
        <Route path={'/changePassword'} element={<ChangePassword />} />
        <Route path={'/recovery'} element={<RecoveryPage />} />
        <Route
            path={'/settings/*'}
            element={
                <PrivateRoute>
                    <SettingsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/updateEmail'}
            element={
                <PrivateRoute>
                    <UpdateEmailPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/results'}
            element={
                <PrivateRoute>
                    <ResultsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/results/details'}
            element={
                <PrivateRoute>
                    <ResultDetailsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/results/pdf'}
            element={
                <PrivateRoute>
                    <ResultPdfPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/results/dynamics'}
            element={
                <PrivateRoute>
                    <DynamicsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/results/dynamics/:testCode'}
            element={
                <PrivateRoute>
                    <DynamicsDetailsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/requests'}
            element={
                <PrivateRoute>
                    <RequestsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/requests/materials'}
            element={
                <PrivateRoute>
                    <RequestsMaterialsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/requests/history'}
            element={
                <PrivateRoute>
                    <RequestsHistoryPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/requests/history/:orderId'}
            element={
                <PrivateRoute>
                    <RequestsHistoryDetailsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/requests/courier'}
            element={
                <PrivateRoute>
                    <RequestsCourierPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/news'}
            element={
                <PrivateRoute>
                    <NewsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/news/:newsId'}
            element={
                <PrivateRoute>
                    <NewsDetailsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/preorders'}
            element={
                <PrivateRoute>
                    <PreordersPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/preorders/details'}
            element={
                <PrivateRoute>
                    <PreorderDetailsPage />
                </PrivateRoute>
            }
        />
        <Route
            path={'/preorders/create'}
            element={
                <PrivateRoute>
                    <CreatePreorderPage />
                </PrivateRoute>
            }
        />
        <Route path={'*'} element={<NotFoundPage />} />
    </ReactRoutes>
);

export default React.memo(Routes);
