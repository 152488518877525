import * as React from 'react';
import classNames from 'classnames/bind';
import Button from 'components/button/Button';
import Modal from 'components/modal/Modal';
import styles from './BiomaterialSelectionModal.module.css';
import linkBackIcon from './linkBackIcon.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { preorderBiomaterialSetsSelector } from 'redux/preorders/selectors';
import { useTranslation } from 'react-i18next';
import { SET_BIOMATERIALS, SHOW_BIOMATERIAL_SELECTION_MODAL } from 'redux/preorders/actions';
import { AppTypes } from 'types/AppTypes';
import { actionsSelector } from 'redux/app/selectors';
import { SET_ACTIONS } from 'redux/app/actions';

const cx = classNames.bind(styles);

const BiomaterialSelectionModal = () => {
    const { show } = useSelector((state: AppTypes.State) => state.preorders.preorder?.biomaterialModal);
    const preorderSets = useSelector((state: AppTypes.State) => preorderBiomaterialSetsSelector(state));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedBiomaterials, setSelectedBiomaterials] = useState<any>({});
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    useEffect(() => {
        if (actions[SET_BIOMATERIALS.BASE]?.success) {
            close();
        }
        if (!show) {
            setCurrentIndex(0);
            setSelectedBiomaterials({});
        } else if (preorderSets && preorderSets.length > 0) {
            const selectedBiomaterials = {};
            for (let i = 0; i < preorderSets.length; i++) {
                const set = preorderSets[i];
                const selected = set.biomaterials.find((biomaterial: any) => biomaterial.selected);
                if (selected) {
                    // @ts-ignore
                    selectedBiomaterials[i] = selected.id;
                }
            }
            setSelectedBiomaterials(selectedBiomaterials);
        }
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [SET_BIOMATERIALS.BASE]: null
                }
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, preorderSets, actions[SET_BIOMATERIALS.BASE]?.success]);

    const selectBiomaterial = (id: any) => {
        setSelectedBiomaterials((prevState: any) => ({ ...prevState, [currentIndex]: id }));
    };

    const hasPreviousStep = () => {
        return currentIndex > 0;
    };

    const hasNextStep = () => {
        return currentIndex < preorderSets.length;
    };

    const previousSet = () => {
        if (hasPreviousStep()) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const nextSet = () => {
        if (hasNextStep()) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const isBiomaterialSelected = () => {
        return selectedBiomaterials[currentIndex];
    };

    const isLastStep = () => {
        return currentIndex === preorderSets.length - 1;
    };

    const close = () => {
        dispatch(SHOW_BIOMATERIAL_SELECTION_MODAL.base({ show: !show }));
    };

    const save = () => {
        const biomaterialByProduct: any = {};
        Object.entries(selectedBiomaterials).forEach(([index, selectedBiomaterialId]) => {
            // @ts-ignore
            const set = preorderSets[index];
            set.products.forEach((product: any) => (biomaterialByProduct[product.id] = selectedBiomaterialId));
        });
        dispatch(SET_BIOMATERIALS.base(biomaterialByProduct));
    };

    return preorderSets?.length > 0 ? (
        <Modal show={show} className={styles.modal} onClose={close}>
            <div className={styles.top}>
                <img alt="" className={styles.linkBackIcon} src={linkBackIcon} onClick={() => close()} />
                <h1 className={styles.title}>{t(`BiomaterialSelectionModal.title`)}</h1>
            </div>
            <div className={styles.container}>
                <div className={styles.products}>
                    {preorderSets[currentIndex]?.products.map((product: any) => {
                        return (
                            <div className={styles.product} key={product.id}>
                                <span className={styles.article}>{t(`Common.formattedNumber`, { number: product.article })}</span>
                                <span className={styles.productName}>{product.name}</span>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.biomaterials}>
                    {preorderSets[currentIndex]?.biomaterials.map((biomaterial: any) => {
                        const biomaterialClassName = cx({
                            biomaterial: true,
                            selected: biomaterial.id === selectedBiomaterials[currentIndex]
                        });
                        return (
                            <div key={biomaterial.id} onClick={() => selectBiomaterial(biomaterial.id)} className={biomaterialClassName}>
                                <input
                                    onChange={() => selectBiomaterial(biomaterial.id)}
                                    type="radio"
                                    id={biomaterial.id}
                                    value={biomaterial.id}
                                    name={'biomaterialSelection'}
                                    checked={biomaterial.id === selectedBiomaterials[currentIndex]}
                                />
                                <label htmlFor={biomaterial.id}>{biomaterial.name}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.buttonsContainer}>
                <div>
                    {preorderSets.length > 1 ? (
                        <div className={styles.stepContainer}>
                            <Button
                                text={t(`BiomaterialSelectionModal.btnBack`)}
                                className={styles.btnPrev}
                                disabled={!hasPreviousStep()}
                                onClick={previousSet}
                            />
                            <span>{t(`BiomaterialSelectionModal.step`, { number: currentIndex + 1, total: preorderSets.length })}</span>
                            <Button
                                className={styles.btnNext}
                                text={isLastStep() ? t(`BiomaterialSelectionModal.btnReady`) : t(`BiomaterialSelectionModal.btnNext`)}
                                disabled={(!hasNextStep() && !isLastStep()) || !isBiomaterialSelected()}
                                onClick={() => (isLastStep() ? save() : nextSet())}
                            />
                        </div>
                    ) : (
                        <Button text={t(`BiomaterialSelectionModal.btnReady`)} onClick={save} disabled={!isBiomaterialSelected()} />
                    )}
                </div>
            </div>
        </Modal>
    ) : null;
};

export default React.memo(BiomaterialSelectionModal);
