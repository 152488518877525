import React, { FC } from 'react';
import { INIT } from 'redux/startup/actions';
import styles from './Init.module.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { useTranslation } from 'react-i18next';
import Themes from 'themes/Themes';

type TProps = {
    children: any;
};

const Init: FC<TProps> = ({ children }) => {
    const { initialized, error } = useSelector((state: AppTypes.State) => state.startup);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    useEffect(() => {
        if (!initialized && !error) {
            dispatch(INIT.base());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    const renderPage = () => {
        return <div className={styles.container}>{error ? <p>{t(`Common.error`)}</p> : <p>{t(`Common.loading`)}</p>}</div>;
    };

    return initialized ? <Themes>{children}</Themes> : renderPage();
};

export default React.memo(Init);
