import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { STATUS_CODES } from 'constants/StatusCodes';
import { createMarkup } from 'utils/messageUtils';

type TProps = {
    permissions?: string[] | [];
    errors?: any;
    message?: string;
    children: any;
    className?: string;
    officeGroup?: { selected: boolean; message?: string };
};

const Access: FC<TProps> = (props) => {
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [access, setAccess] = useState<boolean>();
    const { permissions, officeGroup, errors, children, message, className } = props;

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            if (officeGroup?.selected) {
                setAccess(false);
            } else {
                const checkPermissions: boolean[] = [];
                const checkErrors: boolean[] = [];
                permissions?.forEach((permission: string) =>
                    selectedOfficeOrGroup?.userPermissions
                        ? selectedOfficeOrGroup?.userPermissions?.includes(permission)
                            ? checkPermissions.push(true)
                            : checkPermissions.push(false)
                        : checkPermissions.push(true)
                );
                errors?.forEach((error: any) => (error?.status === STATUS_CODES.FORBIDDEN ? checkErrors.push(false) : checkErrors.push(true)));
                if ((checkPermissions?.includes(false) && checkErrors.includes(false)) || checkErrors.includes(false) || checkPermissions?.includes(false)) {
                    setAccess(false);
                } else {
                    setAccess(true);
                }
            }
        }
    }, [selectedOfficeOrGroup, officeGroup, permissions, errors]);

    if (access === false) {
        return message ? (
            <div className={className} style={{ color: 'red' }}>
                {officeGroup?.selected ? officeGroup?.message : <div dangerouslySetInnerHTML={createMarkup(message)} />}
            </div>
        ) : null;
    }
    if (access) {
        return children;
    }

    return null;
};

export default React.memo(Access);
