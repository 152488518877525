import React, { Fragment, useEffect, useState } from 'react';
import styles from './ProductList.module.css';
import { groupBy, map } from 'lodash';
import classNames from 'classnames';
import { ADD_PRODUCT, DELETE_PRODUCT, FETCH_ALL_PRODUCTS, GET_INFO_PRODUCT } from 'redux/preorders/actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/button/Button';
import { allProductsSelector, preorderDataSelector } from 'redux/preorders/selectors';
import { isEmpty } from 'utils/messageUtils';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import ProductModal from 'page/preorders/components/formProducts/modal/ProductModal';
import { SET_ACTIONS } from 'redux/app/actions';
import Icon from 'components/icon/Icon';
import analysisIcon from 'themes/icons/analysis.svg';

const ProductList = ({ setShowProductsModal, productsModalRef }: { setShowProductsModal: any; productsModalRef: any }) => {
    const dispatch = useDispatch();
    const allProducts = useSelector(allProductsSelector);
    const preorder = useSelector(preorderDataSelector);
    const actions = useSelector(actionsSelector);
    const [showCategoryIdList, setShowCategoryIdList] = useState<string[]>([]);
    const [currentProduct, setCurrentProduct] = useState<any>();
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [filteredProducts, setFilteredProducts] = useState<any>([]);
    const [searchString, setSearchString] = useState('');
    const [found, setFound] = useState<any>({});
    let modifySelectedCategory: any;

    useEffect(() => {
        dispatch(FETCH_ALL_PRODUCTS.base());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (actions[GET_INFO_PRODUCT.BASE]?.success) {
            const product = actions[GET_INFO_PRODUCT.BASE]?.payload[0];
            if (
                !isEmpty(product?.interpretationDescription) ||
                !isEmpty(product?.textPreparation) ||
                !isEmpty(product?.textStatement) ||
                !isEmpty(product?.textInterpretation)
            ) {
                setCurrentProduct({ ...product });
                setShowDescriptionModal(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[GET_INFO_PRODUCT.BASE]?.success]);

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [GET_INFO_PRODUCT.BASE]: null
                }
            });
            setShowProductsModal(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEmpty(searchString)) {
            const products = filteredProducts?.filter(
                (product: any) =>
                    product.name?.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
                    product.fullName?.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
                    product.article?.toUpperCase().indexOf(searchString.toUpperCase()) >= 0
            );

            const rootCategoryById = map(products, 'rootCategory');
            const thirdCategoryById = map(products, 'thirdCategory');

            setFound({
                rootCategoryById,
                thirdCategoryById,
                products
            });
        }
    }, [searchString, allProducts]);

    const handleShowCategory = (categoryId: string) => {
        if (!showCategoryIdList.includes(categoryId)) {
            setShowCategoryIdList((prevState) => [...prevState, categoryId]);
        } else {
            setShowCategoryIdList((prevState) => [...prevState.filter((item: string) => item !== categoryId)]);
        }
    };

    const renderRootCategory = (category: any) => {
        const isSearch = !isEmpty(searchString);
        const show = true;
        const isFound = found?.rootCategoryById?.includes(category.categoryId);

        if ((isSearch && isFound) || !isSearch) {
            return (
                <ul key={category.categoryId}>
                    <li className={styles.items}>
                        <div
                            onClick={() => handleShowCategory(category.categoryId)}
                            className={classNames([styles.link, show ? styles.open : '', styles.linkSecond])}
                        >
                            <span>{category.name}</span>
                        </div>
                        {category.categories && renderThirdCategory(category.categoryId, category.categories)}
                    </li>
                </ul>
            );
        }
    };

    const renderThirdCategory = (rootCategoryId: any, categories: any) => {
        let tempFilteredProducts = map(filteredProducts, 'thirdCategory');

        return (
            <div className={styles.third}>
                <ul className={styles.thirdList}>
                    {categories?.map((category: any) => {
                        const isSearch = !isEmpty(searchString);
                        const isFound = found?.thirdCategoryById?.includes(category.categoryId);
                        const show = selectedCategory?.categoryId === category.categoryId;
                        if (!tempFilteredProducts.includes(category.categoryId)) {
                            let modifyProduct: any = [];
                            category?.products?.forEach((product: any) =>
                                modifyProduct.push({ rootCategory: rootCategoryId, thirdCategory: category.categoryId, ...product })
                            );
                            setFilteredProducts((prevState: any) => [...prevState, ...modifyProduct]);

                            if (!modifySelectedCategory) {
                                modifySelectedCategory = category;
                                setSelectedCategory(modifySelectedCategory);
                            }
                        }

                        if ((isSearch && isFound) || !isSearch || show) {
                            return (
                                <li
                                    className={classNames([show || (isSearch && isFound) ? styles.selectedCategory : ''])}
                                    key={category.categoryId}
                                    onClick={() => {
                                        productsModalRef.current.scrollTo(0, 0);
                                        setSelectedCategory(category);
                                        setSearchString('');
                                    }}
                                >
                                    <div className={styles.thirdLink}>{category.name}</div>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        );
    };

    const renderSecondaryCategory = (categories: any) => {
        return (
            <div className={styles.second} style={{ display: 'block' }}>
                <ul className={styles.secondList}>
                    {categories.map((category: any) => {
                        return (
                            <li>
                                <div className={styles.secondLink}>{category.name}</div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const renderProduct = (product: any) => {
        const productsGroupById = groupBy(preorder?.products, 'id');
        const isAdded = productsGroupById[product.id];
        return (
            <li key={product.id}>
                <div>
                    <div onClick={() => dispatch(GET_INFO_PRODUCT.base({ articles: [product?.article] }))} className={styles.name}>
                        {product.name}
                    </div>
                    <div className={styles.fullName}>{product.fullName}</div>
                    <div className={styles.article}>№ {product.article}</div>
                </div>
                <div>
                    <Button
                        className={classNames(isAdded && styles.activeButton)}
                        onClick={() => (!isAdded ? dispatch(ADD_PRODUCT.base({ product })) : dispatch(DELETE_PRODUCT.base({ article: product.article })))}
                    >
                        {isAdded ? 'Удалить' : 'Добавить'}
                    </Button>
                </div>
            </li>
        );
    };

    return (
        <Fragment>
            {!actions[FETCH_ALL_PRODUCTS.BASE]?.loading && (
                <div className={styles.top}>
                    <div className={styles.topInner}>
                        <div className={styles.counter}>
                            <Icon height={'23px'} width={'23px'} icon={`${analysisIcon}#analysis`} />
                            {preorder.products.length || 0}
                        </div>
                        <div className={styles.continue} onClick={() => setShowProductsModal(false)}>
                            Продолжить
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.container}>
                <div className={styles.sideBarBlock}>
                    {allProducts.map((category: any) => {
                        return renderRootCategory(category);
                    })}
                </div>
                <div className={styles.productList}>
                    <input
                        onChange={(e) => {
                            setSearchString(e.target.value);
                        }}
                        value={searchString}
                        className={styles.searchString}
                        type="search"
                        name="searchString"
                        placeholder={'Найти продукт'}
                    />
                    {actions[FETCH_ALL_PRODUCTS.BASE]?.loading ? (
                        <Preloader />
                    ) : (
                        <Fragment>
                            <h2>{!isEmpty(searchString) ? 'Результаты поиска' : selectedCategory.name}</h2>
                            <ul className={styles.list}>
                                {(!isEmpty(searchString) && found?.products?.length > 0) ||
                                (isEmpty(searchString) && selectedCategory?.products?.length > 0) ? (
                                    (!isEmpty(searchString) ? [...(found?.products || [])] : [...(selectedCategory?.products || [])])?.map((product: any) =>
                                        renderProduct(product)
                                    )
                                ) : (
                                    <div>Продукты не найдены</div>
                                )}
                            </ul>
                        </Fragment>
                    )}
                </div>
                {showDescriptionModal ? (
                    <ProductModal show={showDescriptionModal} product={currentProduct} onClose={() => setShowDescriptionModal(false)} />
                ) : null}
                <Preloader show={actions[GET_INFO_PRODUCT.BASE]?.loading || actions[FETCH_ALL_PRODUCTS.BASE]?.loading} overlay={true} />
            </div>
        </Fragment>
    );
};
export default React.memo(ProductList);
