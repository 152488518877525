import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EmployeesPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { actionsSelector } from 'redux/app/selectors';
import { AppTypes } from 'types/AppTypes';
import { FIND_USERS_BY_OFFICE, SEARCH_USERS } from 'redux/user/actions';
import Preloader from 'components/preloader/Preloader';
import isEmail from 'validator/lib/isEmail';
import { UserTypes } from 'types/UserTypes';
import Permissions from './components/permissions/Permissions';
import { PermissionType } from 'constants/PermissionType';
import Access from 'components/access/Access';
import { declOfNum } from 'utils/messageUtils';
import { useTranslation } from 'react-i18next';

const EmployeesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const { usersByOffice, foundUsers } = useSelector((state: AppTypes.State) => state.user);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(FIND_USERS_BY_OFFICE.base());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id]);

    useEffect(() => {
        if (email.length > 0) {
            dispatch(SEARCH_USERS.base({ email }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, email]);

    const renderUserList = (userList: UserTypes.UserType[]) => {
        return (
            <div>
                {usersByOffice?.length < 1 && email.length < 1 ? <p>{t(`EmployeesPage.usersNotfound`)}</p> : null}
                {email.length > 0 ? (
                    <p>
                        {t(`EmployeesPage.found`, {
                            counts: `${foundUsers.length} ${declOfNum(foundUsers.length, [
                                t(`EmployeesPage.result1`),
                                t(`EmployeesPage.result2`),
                                t(`EmployeesPage.result3`)
                            ])}`
                        })}
                    </p>
                ) : null}
                {usersByOffice?.length > 0 && email.length < 1 ? <p>{t(`EmployeesPage.addedUsers`)}</p> : null}
                <table className={styles.addedUsersList}>
                    <tbody>
                        {userList?.map((user) => {
                            return (
                                <tr onClick={(e) => navigate(`/settings/employees/${user.id}?email=${user.email}`, { state: { user: user } })} key={user.id}>
                                    <td>{user.email}</td>
                                    <td>
                                        <Permissions permissions={user.permissions} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {foundUsers.length < 1 && isEmail(email) && (
                    <Fragment>
                        <div className={styles.createUserBlock}>
                            <p>{t(`EmployeesPage.noSuchUser`)}</p>
                        </div>
                        <button onClick={() => navigate('/settings/employees/create', { state: email })}>{t(`EmployeesPage.createNewUser`)}</button>
                    </Fragment>
                )}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`Common.staff`)}</h1>
            <Access
                officeGroup={{
                    selected: Array.isArray(selectedOfficeOrGroup?.offices),
                    message: t(`EmployeesPage.noticeOffice`)
                }}
                permissions={[PermissionType.MANAGE_USERS]}
                message={t(`Common.noAccess`)}
                errors={[actions[FIND_USERS_BY_OFFICE.BASE]?.error]}
            >
                <p>{t(`EmployeesPage.enterEmailToSearch`)}</p>
                <div className={styles.settingRow}>
                    <label>Email: </label>
                    <input onChange={(e) => setEmail(e.target.value)} value={email} className={styles.email} type="search" name="email" />
                </div>
                {!actions[FIND_USERS_BY_OFFICE.BASE]?.loading && !actions[SEARCH_USERS.BASE]?.loading ? (
                    renderUserList(email.length > 0 ? foundUsers : usersByOffice)
                ) : (
                    <Preloader />
                )}
            </Access>
        </div>
    );
};

export default React.memo(EmployeesPage);
