import React, { Fragment, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/umd/pdf.worker.entry';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styles from './ResultPdfPage.module.css';
import { FETCH_RESULT_DETAILS, GET_PDF_RESULT } from 'redux/results/actions';
import { useDispatch, useSelector } from 'react-redux';
import { actionsSelector } from 'redux/app/selectors';
import { AppTypes } from 'types/AppTypes';
import Preloader from 'components/preloader/Preloader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import downloadIcon from 'themes/icons/download.svg';
import printIcon from 'themes/icons/print.svg';
import { Link, useLocation } from 'react-router-dom';
import { loadFile, printFile } from 'utils/fileUtils';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';
const queryString = require('query-string');

const ResultPdfPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const actions = useSelector(actionsSelector);
    const { resultDetails, objectURLPdfResult, responsePdfData } = useSelector((state: AppTypes.State) => state.results);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const location: any = useLocation();
    const parsedLocationString = queryString.parse(location.search);
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id && !resultDetails) {
            dispatch(
                FETCH_RESULT_DETAILS.base({
                    inz: parsedLocationString.inz,
                    nss: parsedLocationString.nss
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, parsedLocationString.inz, parsedLocationString.nss]);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id && resultDetails) {
            dispatch(
                GET_PDF_RESULT.base({
                    orders: [
                        {
                            inz: parsedLocationString.inz,
                            nss: parsedLocationString.nss,
                            firstName: resultDetails?.patient?.firstName,
                            middleName: resultDetails?.patient?.middleName,
                            lastName: resultDetails?.patient?.lastName
                        }
                    ],
                    fileId: parsedLocationString.fileId,
                    download: false,
                    view: true,
                    markPrinted: false
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, resultDetails]);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    return (
        <div className={styles.container}>
            <IsAvailable options={[{ results: true }]}>
                <div className={styles.breadcrumbs}>
                    <Breadcrumbs
                        links={[
                            {
                                link: `/results/details?inz=${parsedLocationString.inz}&nss=${parsedLocationString.nss}`,
                                name: t(`Common.results`),
                                active: false
                            },
                            {
                                link: '/results',
                                name: `${t(`Common.inz`)} ${parsedLocationString.inz}`,
                                active: true
                            }
                        ]}
                    />
                </div>
                <Access
                    permissions={[PermissionType.RESULTS]}
                    message={t(`Common.noAccess`)}
                    className={styles.messagePermissions}
                    errors={[actions[GET_PDF_RESULT.BASE]?.error]}
                >
                    {actions[GET_PDF_RESULT.BASE]?.loading ? (
                        <div className={styles.preload}>
                            <Preloader />
                        </div>
                    ) : (
                        objectURLPdfResult && (
                            <Fragment>
                                <div className={styles.toolbar}>
                                    <Link
                                        title={t(`Common.download`)}
                                        to=""
                                        className={styles.downloadLInk}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            loadFile(responsePdfData);
                                        }}
                                    >
                                        <Icon setDefaultStyles={false} icon={`${downloadIcon}#download`} className={styles.downloadIcon} />
                                    </Link>
                                    <Link
                                        title={t(`Common.print`)}
                                        to=""
                                        className={styles.downloadLInk}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            printFile(responsePdfData);
                                            dispatch(
                                                GET_PDF_RESULT.base({
                                                    orders: [
                                                        {
                                                            inz: parsedLocationString.inz,
                                                            nss: parsedLocationString.nss,
                                                            firstName: resultDetails?.patient?.firstName,
                                                            middleName: resultDetails?.patient?.middleName,
                                                            lastName: resultDetails?.patient?.lastName
                                                        }
                                                    ],
                                                    fileId: parsedLocationString.fileId,
                                                    download: false,
                                                    view: true,
                                                    markPrinted: true
                                                })
                                            );
                                        }}
                                    >
                                        <Icon setDefaultStyles={false} icon={`${printIcon}#print`} className={styles.downloadIcon} />
                                    </Link>
                                </div>
                                <div className={styles.document}>
                                    <Document file={objectURLPdfResult} onLoadProgress={() => null} onLoadSuccess={(data) => onDocumentLoadSuccess(data)}>
                                        {Array.from(new Array(numPages), (el, index) => (
                                            <Page className={styles.page} scale={1.5} renderMode={'svg'} key={`page_${index + 1}`} pageNumber={index + 1} />
                                        ))}
                                    </Document>
                                </div>
                            </Fragment>
                        )
                    )}
                </Access>
            </IsAvailable>
        </div>
    );
};

export default React.memo(ResultPdfPage);
