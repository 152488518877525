import * as React from 'react';
import Tabs from 'components/tab/Tabs';
import Modal from 'components/modal/Modal';
import styles from './ProductModal.module.css';
import { useTranslation } from 'react-i18next';

const ProductModal = ({ show, product, onClose }: any) => {
    const { t } = useTranslation('common');
    const { interpretationDescription, textPreparation, textStatement, textInterpretation } = product;

    return (
        <Modal className={styles.modal} show={show} onClose={onClose}>
            <h1 className={styles.title}>{product.name}</h1>
            <p className={styles.interpretationWarning}>{t(`ProductModal.interpretationWarning`)}</p>
            <Tabs
                animated={true}
                rootClass={styles.root}
                tabDisabledClass={styles.tabDisabled}
                tabSelectedClass={styles.tabSelected}
                panelSelectedClass={styles.panelSelected}
                tabClass={styles.tab}
                titles={[
                    t(`ProductModal.interpretationDescription`),
                    t(`ProductModal.preparation`),
                    t(`ProductModal.statement`),
                    t(`ProductModal.interpretation`)
                ]}
                pages={[
                    interpretationDescription ? <div dangerouslySetInnerHTML={{ __html: product.interpretationDescription }} /> : <h3>Раздел не заполнен</h3>,
                    textPreparation ? <div dangerouslySetInnerHTML={{ __html: product.textPreparation }} /> : <h3>Раздел не заполнен</h3>,
                    textStatement ? <div dangerouslySetInnerHTML={{ __html: product.textStatement }} /> : <h3>Раздел не заполнен</h3>,
                    textInterpretation ? <div dangerouslySetInnerHTML={{ __html: product.textInterpretation }} /> : <h3>Раздел не заполнен</h3>
                ]}
            />
        </Modal>
    );
};

export default React.memo(ProductModal);
