import { call, put, debounce, all, select } from 'redux-saga/effects';
import * as preorderClient from 'client/PreorderClient';
import { AxiosResponse } from 'axios';
import {
    ADD_PRODUCT,
    CALCULATE_OFFICE,
    CANCEL,
    CHOOSE_OFFICE,
    CREATE,
    CREATE_PATIENT,
    DELETE_PRODUCT,
    DOWNLOAD_PREORDER,
    FETCH_ALL,
    FETCH_ALL_PRODUCTS,
    FETCH_OFFICES,
    FETCH_OFFICES_UPDATE,
    FETCH_PREORDER_DETAILS,
    FETCH_PREORDERS_HISTORY,
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_CALCULATE,
    FETCH_RESULTS,
    GET_INFO_PRODUCT,
    GET_STATUS,
    REMOVE_INVALID_PRODUCTS,
    REPEAT,
    RETRY_OFFICE_CALCULATION,
    SEARCH_PATIENTS,
    SELECT_OFFICE,
    SET_BIOMATERIALS,
    SET_PREORDER,
    UPDATE_AFTER_MODIFY_PRODUCTS
} from './actions';
import { PreordersTypes } from 'types/PreordersTypes';
import { takeEvery } from '@redux-saga/core/effects';
import {
    chosenOfficeSelector,
    selectedPatientSelector,
    productsSelector,
    cartDataProductsArticlesSelector,
    cartDataProductsSelector,
    officeDataSelector,
    officeCalculationSelector,
    preorderDataSelector,
    cartDataProductsIdsSelector
} from 'redux/preorders/selectors';
import { differenceInDays, parseISO } from 'date-fns';
import { loadFile } from 'utils/fileUtils';
import { localToUTC } from 'utils/timeUtils';

function* fetchAll(action: PreordersTypes.ActionFetchAll) {
    try {
        const response: AxiosResponse<PreordersTypes.PreordersAPI.PreorderDTO[]> = yield call(preorderClient.fetchAll, action.meta.serverSideFilter);
        yield put(FETCH_ALL.success(response));
    } catch (err: any) {
        yield put(FETCH_ALL.error(err?.response));
    }
}

function* fetchPreorderDetails(action: PreordersTypes.ActionFetchPreorderDetails) {
    try {
        const response: AxiosResponse<PreordersTypes.PreordersAPI.PreorderDTO> = yield call(preorderClient.fetchPreorderDetails, action.meta);
        yield put(FETCH_PREORDER_DETAILS.success(response));
    } catch (err: any) {
        yield put(FETCH_PREORDER_DETAILS.error(err?.response));
    }
}

function* searchPatients(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.searchPatients, action.meta);
        yield put(SEARCH_PATIENTS.success(response));
    } catch (err: any) {
        yield put(SEARCH_PATIENTS.error(err?.response));
    }
}

function* createPatient(action: any) {
    const data = {
        ...action.meta,
        birthday: localToUTC(action.meta.birthday)
    };

    try {
        const response: AxiosResponse<any> = yield call(preorderClient.createPatient, data);
        yield put(CREATE_PATIENT.success(response));
    } catch (err: any) {
        yield put(CREATE_PATIENT.error(err?.response));
    }
}

function* fetchAllProducts() {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.fetchAllProducts);
        yield put(FETCH_ALL_PRODUCTS.success(response));
    } catch (err: any) {
        yield put(FETCH_ALL_PRODUCTS.error(err?.response));
    }
}

function* fetchProducts(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.fetchProducts, action.meta);
        yield put(FETCH_PRODUCTS.success(response));
    } catch (err: any) {
        yield put(FETCH_PRODUCTS.error(err?.response));
    }
}

function* fetchProductsCalculate(action: any) {
    try {
        // @ts-ignore
        const preorderData = yield select(preorderDataSelector);
        const response: AxiosResponse<any> = yield call(preorderClient.fetchProducts, action.meta);
        yield put(FETCH_PRODUCTS.success(response));
        yield put(FETCH_PRODUCTS_CALCULATE.success({ orderProductsKeys: preorderData.orderProductsKeys }));
    } catch (err: any) {
        yield put(FETCH_PRODUCTS_CALCULATE.error(err?.response));
    }
}

function* addProduct(action: any) {
    try {
        // @ts-ignore
        const cartOffice = yield select(chosenOfficeSelector);
        const { product } = action.meta;
        yield put(ADD_PRODUCT.success({}, { product }));
        if (cartOffice?.office?.id) {
            yield fetchOfficesUpdate();
        }
    } catch (err: any) {
        yield put(ADD_PRODUCT.error(err?.response));
    }
}

function* deleteProduct(action: any) {
    try {
        // @ts-ignore
        const cartOffice = yield select(chosenOfficeSelector);
        yield put(DELETE_PRODUCT.success({}, action.meta));
        if (cartOffice?.office?.id) {
            yield fetchOfficesUpdate();
        }
    } catch (err: any) {
        yield put(DELETE_PRODUCT.error(err?.response));
    }
}

function* fetchOffices(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.fetchOffices, action.meta);
        yield put(FETCH_OFFICES.success(response));
    } catch (err: any) {
        yield put(FETCH_OFFICES.error(err?.response));
    }
}

function* fetchOfficesUpdate() {
    try {
        // @ts-ignore
        const articlesProducts = yield select(cartDataProductsArticlesSelector);
        const response: AxiosResponse<any> = yield call(preorderClient.fetchOffices, { articles: [...articlesProducts] });
        yield put(FETCH_OFFICES.success(response));
        yield put(FETCH_OFFICES_UPDATE.success());
    } catch (err: any) {
        yield put(FETCH_OFFICES.error(err?.response));
        yield put(FETCH_OFFICES_UPDATE.error(err?.response));
    }
}

function* selectOffice(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.validate, action.meta);
        yield put(SELECT_OFFICE.success(response, action.meta));
        if (action.meta.repeat) {
            yield put(CALCULATE_OFFICE.base());
        }
    } catch (err: any) {
        yield put(SELECT_OFFICE.error(err?.response));
    }
}

function* chooseOffice(action: any) {
    try {
        yield put(CHOOSE_OFFICE.success({}, action.meta));
        // @ts-ignore
        const preorderOffice = yield select(chosenOfficeSelector);
        if (preorderOffice.allProductsAvailable) {
            yield put(CALCULATE_OFFICE.base());
        }
    } catch (err: any) {
        yield put(CHOOSE_OFFICE.error(err?.response));
    }
}

function* calculateOfficeStep1() {
    try {
        // @ts-ignore
        const productsArticles = yield select(cartDataProductsArticlesSelector);
        yield put(FETCH_PRODUCTS_CALCULATE.base({ articles: productsArticles }));
    } catch (err: any) {
        yield put(CALCULATE_OFFICE.error(err?.response));
    }
}

function* calculateOfficeStep2(action: any) {
    try {
        // @ts-ignore
        const preorderOffice = yield select(chosenOfficeSelector);
        // @ts-ignore
        const officeProductsData = yield select(productsSelector);
        const orderProductsKeys = action?.payload?.orderProductsKeys;

        if (!preorderOffice || officeProductsData?.invalid?.length > 0) {
            yield put(CALCULATE_OFFICE.success());
            return;
        }

        // @ts-ignore
        const selectedPatient = yield select(selectedPatientSelector);
        let calculatedOrderProductsKeys = orderProductsKeys;

        if (!orderProductsKeys) {
            // Если ключи продуктов не переданы явно, то необходимо их рассчитать
            const officeProducts = (officeProductsData?.valid || []).filter((product: any) => !product.classifierData || !product.classifierData.service);
            const officeProductsIds = new Set(officeProducts.map((product: any) => product.id));

            // @ts-ignore
            const cartDataProducts = yield select(cartDataProductsSelector);
            const cartDataProductsIds: any = new Set(
                cartDataProducts
                    .filter((product: any) => product.id)
                    .filter((product: any) => !product.classifierData || !product.classifierData.service)
                    .map((product: any) => product.id) || []
            );
            let calculatedOrderProducts = [];
            if (officeProducts.some((officeProduct: any) => officeProduct.selected && !cartDataProductsIds.has(officeProduct.id))) {
                // Если любой из выбранных продуктов офиса отсутствует в исходной корзине, значит продукты корзины были удалены.
                // Необходимо пересчитать заказ только с продуктами соответствующими составу исходной корзины
                calculatedOrderProducts = officeProducts
                    .filter((orderProduct: any) => !orderProduct.classifierData || !orderProduct.classifierData.service)
                    .filter((orderProduct: any) => cartDataProductsIds.has(orderProduct.id));
            } else {
                calculatedOrderProducts = officeProducts.filter((orderProduct: any) => !orderProduct.classifierData || !orderProduct.classifierData.service);
            }

            // Если в корзине появились новые продукты, то необходимо их добавить в запрос
            const addedProductsIds = [...cartDataProductsIds].filter((id) => !officeProductsIds.has(id));
            addedProductsIds.forEach((id) => {
                calculatedOrderProducts.push({
                    id
                });
            });

            calculatedOrderProductsKeys = calculatedOrderProducts.map((orderProduct: any) => {
                return {
                    productId: orderProduct.id,
                    biomaterialId: orderProduct.biomaterial?.id
                };
            });
        }

        const data = {
            ...action.meta,
            officeId: preorderOffice.office.id,
            patientId: selectedPatient?.id,
            orderProductsKeys: calculatedOrderProductsKeys
        };

        if (calculatedOrderProductsKeys.length > 0) {
            const response: AxiosResponse<any> = yield call(preorderClient.calculateOffice, data);
            yield put(CALCULATE_OFFICE.success(response, data));
        } else {
            yield put(CALCULATE_OFFICE.success());
        }
    } catch (err: any) {
        yield put(CALCULATE_OFFICE.error(err?.response));
    }
}

function* calculateOfficeStep3(action: any) {
    try {
        // При пересечении профилей часть продуктов может пропасть из корзины,
        // другая же часть может добавиться. После расчёта корзины в МО необходимо
        // актуализировать данные корзины в соответствии с произошедшими изменениями

        // Получаем и группируем продукты корзины
        // @ts-ignore
        const cartDataProducts = yield select(cartDataProductsSelector);
        const cartProductsIds: any = new Set();
        const cartProductById: any = {};
        cartDataProducts
            .filter((product: any) => product.id)
            .filter((product: any) => !product.classifierData || !product.classifierData.service)
            .forEach((product: any) => {
                cartProductsIds.add(product.id);
                cartProductById[product.id] = product;
            });

        // Получаем и группируем продукты расчёта в МО
        const calculationResponse = action.payload.data;
        const officeProductsIds: any = new Set();
        const officeProductById: any = {};
        let modifyProducts: any = [];

        calculationResponse.orderProducts.forEach((orderProduct: any) => {
            const product = orderProduct.product;
            if ((!product.classifierData || !product.classifierData.service) && orderProduct.selected) {
                officeProductsIds.add(product.id);
                officeProductById[product.id] = orderProduct;
            }
        });

        // Если после расчёта продукт пропал из корзины - удаляем его из store
        const removedIds = [...cartProductsIds].filter((id) => !officeProductsIds.has(id));
        for (const removedId of removedIds) {
            modifyProducts.push({ ...cartProductById[removedId] });
        }
        if (modifyProducts.length > 0) {
            yield put(DELETE_PRODUCT.success({}, modifyProducts));
        }

        // Если после расчёта продукт был добавлен в корзину - добавляем его в store
        const addedIds = [...officeProductsIds].filter((id) => !cartProductsIds.has(id));
        modifyProducts = [];
        for (const addedId of addedIds) {
            const officeProduct = officeProductById[addedId];
            modifyProducts.push({ article: officeProduct.product.article });
        }
        if (modifyProducts.length > 0) {
            yield put(ADD_PRODUCT.success({}, modifyProducts));
        }
    } catch (err: any) {
        yield put(CALCULATE_OFFICE.error(err?.response));
    }
}

function* retryOfficeCalculation() {
    try {
        // @ts-ignore
        const calculation = yield select(officeCalculationSelector);
        if (calculation.request) {
            yield put(CALCULATE_OFFICE.base(calculation.request));
        }
    } catch (err: any) {
        yield put(CALCULATE_OFFICE.error(err?.response));
    }
}

function* removeInvalidProducts() {
    try {
        // @ts-ignore
        const productsData = yield select(productsSelector);
        const invalidProductsArticles = productsData.invalid.map((product: any) => product.article);
        yield removeCartProducts(invalidProductsArticles);
        yield fetchOfficesUpdate();
    } catch (err: any) {
        yield put(REMOVE_INVALID_PRODUCTS.error(err?.response));
    }
}

function* removeCartProducts(removeProductArticles: any) {
    try {
        if (removeProductArticles) {
            yield put(REMOVE_INVALID_PRODUCTS.success({}, { articles: removeProductArticles }));
        }
    } catch (err: any) {
        yield put(REMOVE_INVALID_PRODUCTS.error(err?.response));
    }
}

function* updateAfterModifyProducts() {
    try {
        // @ts-ignore
        const cartOffice = yield select(chosenOfficeSelector);
        // @ts-ignore
        const productsIds = yield select(cartDataProductsIdsSelector);
        if (cartOffice?.office?.id) {
            yield put(
                SELECT_OFFICE.base({
                    officeId: cartOffice.office.id,
                    productsIds: productsIds
                })
            );
            yield put(CHOOSE_OFFICE.base(cartOffice.office));
            yield put(UPDATE_AFTER_MODIFY_PRODUCTS.success());
        }
    } catch (err: any) {
        yield put(UPDATE_AFTER_MODIFY_PRODUCTS.error(err?.response));
    }
}

function* setBiomaterials(action: any) {
    try {
        const biomaterialByProduct = action.meta;
        // @ts-ignore
        const officeData = yield select(officeDataSelector);
        const orderProducts = officeData?.orderProducts || [];
        const data = {
            orderProductsKeys: orderProducts
                .filter((orderProduct: any) => !orderProduct.product.classifierData || !orderProduct.product.classifierData.service)
                .map((orderProduct: any) => {
                    return {
                        productId: orderProduct.product.id,
                        biomaterialId: biomaterialByProduct[orderProduct.product.id] || orderProduct.biomaterial?.id
                    };
                })
        };
        yield put(SET_BIOMATERIALS.success(data));
        yield put(CALCULATE_OFFICE.base());
    } catch (err: any) {
        yield put(SET_BIOMATERIALS.error(err?.response));
    }
}

function* createPreorder(action: any) {
    try {
        // @ts-ignore
        const officeData = yield select<any>(officeDataSelector);
        const orderProducts = officeData?.orderProducts || [];
        const { officeId, patient, insuranceType, insuranceNumber, sendResults, textForResults, daysOfLifePreorder } = action.meta;

        const data = {
            patient,
            officeId,
            products: orderProducts.map((item: any) => ({ productId: item.product.id, biomaterialId: item.biomaterial?.id })),
            daysOfLifePreorder,
            insuranceType,
            insuranceNumber,
            sendResults,
            textForResults
        };
        const response: AxiosResponse<any> = yield call(preorderClient.create, data);
        yield put(CREATE.success(response));
    } catch (err: any) {
        yield put(CREATE.error(err?.response));
    }
}

function* cancelPreorder(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.cancel, action.meta);
        yield put(CANCEL.success(response));
    } catch (err: any) {
        yield put(CANCEL.error(err?.response));
    }
}

function* repeatPreorder(action: any) {
    try {
        const preorder = action.meta;
        yield put(
            SET_PREORDER.base({
                ...preorder,
                id: null,
                products: preorder.products?.map(({ id, productId, name, productName, article, productArticle, type, productType }: any) => ({
                    id: id || productId,
                    name: name || productName,
                    article: article || productArticle,
                    type: type || productType
                })),
                daysOfLifePreorder: differenceInDays(parseISO(preorder.activeToDate as string), parseISO(preorder.createdDate as string)).toString(),
                officeId: preorder.dstOffice.id
            })
        );
        yield put(REPEAT.success());
    } catch (err: any) {
        yield put(REPEAT.error(err?.response));
    }
}

function* getStatus(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.getStatus, action.meta);
        yield put(GET_STATUS.success({ response, REQUEST_ID: action.meta.REQUEST_ID }));
    } catch (err: any) {
        yield put(GET_STATUS.error(err?.response));
    }
}

function* fetchResults(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.fetchResults, action.meta);
        yield put(FETCH_RESULTS.success(response));
    } catch (err: any) {
        yield put(FETCH_RESULTS.error(err?.response));
    }
}

function* fetchPreordersHistory(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.fetchPreordersHistory, action.meta);
        yield put(FETCH_PREORDERS_HISTORY.success(response));
    } catch (err: any) {
        yield put(FETCH_PREORDERS_HISTORY.error(err?.response));
    }
}

function* downloadPreorder(action: any) {
    try {
        const response: AxiosResponse<BlobPart> = yield call(preorderClient.downloadPreorder, action.meta);
        yield loadFile(response);
        yield put(DOWNLOAD_PREORDER.success());
    } catch (err: any) {
        yield put(DOWNLOAD_PREORDER.error(err?.response));
    }
}

function* getInfoProduct(action: any) {
    try {
        const response: AxiosResponse<any> = yield call(preorderClient.fetchProducts, action.meta);
        yield put(GET_INFO_PRODUCT.success(response.data));
    } catch (err: any) {
        yield put(GET_INFO_PRODUCT.error(err?.response));
    }
}

export default function* preordersSagas() {
    yield debounce(500, FETCH_ALL.BASE, fetchAll);
    yield debounce(500, SEARCH_PATIENTS.BASE, searchPatients);
    yield all([takeEvery(FETCH_PREORDER_DETAILS.BASE, fetchPreorderDetails)]);
    yield all([takeEvery(CREATE_PATIENT.BASE, createPatient)]);
    yield all([takeEvery(FETCH_ALL_PRODUCTS.BASE, fetchAllProducts)]);
    yield all([takeEvery(FETCH_PRODUCTS.BASE, fetchProducts)]);
    yield all([takeEvery(FETCH_PRODUCTS_CALCULATE.BASE, fetchProductsCalculate)]);
    yield all([takeEvery(ADD_PRODUCT.BASE, addProduct)]);
    yield all([takeEvery(DELETE_PRODUCT.BASE, deleteProduct)]);
    yield all([takeEvery(FETCH_OFFICES.BASE, fetchOffices)]);
    yield all([takeEvery(FETCH_OFFICES_UPDATE.SUCCEEDED, updateAfterModifyProducts)]);
    yield all([takeEvery(SELECT_OFFICE.BASE, selectOffice)]);
    yield all([takeEvery(CHOOSE_OFFICE.BASE, chooseOffice)]);
    yield all([takeEvery(CALCULATE_OFFICE.BASE, calculateOfficeStep1)]);
    yield all([takeEvery(FETCH_PRODUCTS_CALCULATE.SUCCEEDED, calculateOfficeStep2)]);
    yield all([takeEvery(CALCULATE_OFFICE.SUCCEEDED, calculateOfficeStep3)]);
    yield all([takeEvery(RETRY_OFFICE_CALCULATION.BASE, retryOfficeCalculation)]);
    yield all([takeEvery(REMOVE_INVALID_PRODUCTS.BASE, removeInvalidProducts)]);
    yield all([takeEvery(SET_BIOMATERIALS.BASE, setBiomaterials)]);
    yield all([takeEvery(CREATE.BASE, createPreorder)]);
    yield all([takeEvery(CANCEL.BASE, cancelPreorder)]);
    yield all([takeEvery(REPEAT.BASE, repeatPreorder)]);
    yield all([takeEvery(GET_STATUS.BASE, getStatus)]);
    yield all([takeEvery(FETCH_RESULTS.BASE, fetchResults)]);
    yield all([takeEvery(FETCH_PREORDERS_HISTORY.BASE, fetchPreordersHistory)]);
    yield all([takeEvery(DOWNLOAD_PREORDER.BASE, downloadPreorder)]);
    yield all([takeEvery(GET_INFO_PRODUCT.BASE, getInfoProduct)]);
}
