import { GET_COUNTERS, GET_NEWS_BY_ID, SEARCH } from './actions';
import { NewsTypes } from 'types/NewsTypes';

const initialState: NewsTypes.State = {
    newsByCategory: [],
    newsById: null,
    counters: [],
    loading: false,
    success: false,
    error: false
};

const newsReducer = (state = initialState, action: NewsTypes.Action): NewsTypes.State => {
    switch (action.type) {
        case GET_NEWS_BY_ID.STARTED:
        case GET_NEWS_BY_ID.ERRORED:
        case GET_NEWS_BY_ID.SUCCEEDED: {
            const newsById = action?.payload?.data;
            return {
                ...state,
                newsById: newsById ? { ...newsById } : null
            };
        }
        case SEARCH.STARTED:
        case SEARCH.ERRORED:
        case SEARCH.SUCCEEDED: {
            const foundNews = action?.payload?.data;
            return {
                ...state,
                newsByCategory: foundNews?.length > 0 ? [...foundNews] : []
            };
        }
        case GET_COUNTERS.STARTED:
        case GET_COUNTERS.ERRORED:
        case GET_COUNTERS.SUCCEEDED: {
            const counters = action?.payload?.data;
            return {
                ...state,
                counters: counters?.length > 0 ? [...counters] : []
            };
        }
        default:
            return state;
    }
};

export default newsReducer;
