import React, { FC } from 'react';
import styles from './Permissions.module.css';
import { PermissionType } from 'constants/PermissionType';
import { useTranslation } from 'react-i18next';

type TProps = {
    permissions: any;
    selectedPermissions?: any;
    setSelectedPermissions?: (permission: any) => any;
};

const Permissions: FC<TProps> = (props) => {
    const { permissions, selectedPermissions, setSelectedPermissions } = props;
    const { t } = useTranslation('common');

    const permissionsTitle: any = {
        [PermissionType.MANAGE_USERS]: t(`Permissions.editingListEmployees`),
        [PermissionType.RESULTS]: t(`Permissions.accessResults`),
        [PermissionType.ORDERS_COURIER]: t(`Permissions.accessCourierOrders`),
        [PermissionType.ORDERS_MATERIALS]: t(`Permissions.accessMaterialOrders`),
        [PermissionType.PREORDERS_READ_ONLY]: t(`Permissions.viewingPreOrders`),
        [PermissionType.PREORDERS]: t(`Permissions.editingPreOrders`)
    };

    return (
        <div className={styles.permissions}>
            {permissions.map((permission: string) => (
                <div className={styles.permission} key={permission}>
                    {setSelectedPermissions && (
                        <input
                            type="checkbox"
                            checked={selectedPermissions?.includes(permission)}
                            onChange={(event) => {
                                event.stopPropagation();
                                setSelectedPermissions &&
                                    selectedPermissions &&
                                    setSelectedPermissions((prevState: string[]) => {
                                        if (event.target.checked) {
                                            return [...prevState, permission];
                                        } else {
                                            return [...prevState.filter((item: string) => item !== permission)];
                                        }
                                    });
                            }}
                        />
                    )}
                    <span key={permission}>{permissionsTitle[permission]}</span>
                </div>
            ))}
        </div>
    );
};

export default React.memo(Permissions);
