import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './DynamicsPage.module.css';
import testIcon from 'themes/icons/dinamics.svg';
import xlsxIcon from 'themes/icons/xlsx.svg';
import { useDispatch, useSelector } from 'react-redux';
import { DOWNLOAD, FETCH_BY_CODES } from 'redux/dynamics/actions';
import { AppTypes } from 'types/AppTypes';
import { groupBy } from 'lodash';
import { formatDate, parseISO } from 'utils/timeUtils';
import dynamicsCommentIcon from './dynamicsCommentIcon.png';
import Preloader from 'components/preloader/Preloader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import ReactTooltip from 'react-tooltip';
import PatientCard from 'components/card/PatientCard';
import { actionsSelector } from 'redux/app/selectors';
import { ResultsTypes } from 'types/ResultsTypes';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';
import IsInvitro from 'themes/invitro/IsInvitro';
import IsVetunion from 'themes/vetunion/IsVetunion';
import VetunionPatientCard from 'components/card/VetunionPatientCard';
import { isEmpty } from 'utils/messageUtils';

const queryString = require('query-string');

const DynamicsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const { dynamics } = useSelector((state: AppTypes.State) => state.dynamics);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [itemsGroupByDate, setItemsGroupByDate] = useState<{ [key: string]: ResultsTypes.TypeTestResult[] }>();
    const location: any = useLocation();
    const parsedLocationString = queryString.parse(location.search);
    const dynamicsTableNamesRef = useRef<any>();
    const dynamicsTableRef = useRef<any>();

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            const parsedLocationString = queryString.parse(location.search);
            dispatch(
                FETCH_BY_CODES.base({
                    nss: parsedLocationString.nss,
                    codes: parsedLocationString.codes
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id]);

    useEffect(() => {
        const groupByDate = groupBy(dynamics?.testResults, (o) => o.time);
        setItemsGroupByDate(groupByDate);
    }, [dynamics, actions]);

    useEffect(() => ReactTooltip.rebuild() as () => void);

    const renderItemsDate = (items: { [key: string]: ResultsTypes.TypeTestResult[] } | undefined) => {
        const itemsDate = [];
        for (let item in items) {
            itemsDate.push(
                <th className={styles.tableItemsDate} key={item}>
                    <div>{formatDate(parseISO(item), 'ru', 'd MMMM')}</div>
                    <div>{formatDate(parseISO(item), 'ru', 'R')}</div>
                </th>
            );
        }
        return itemsDate;
    };

    const onMouseEnter = (id: number) => {
        dynamicsTableNamesRef.current.querySelectorAll('tr')[id]?.setAttribute('style', 'background: #fff8d6');
        dynamicsTableRef.current.querySelectorAll('tr')[id]?.setAttribute('style', 'background: #fff8d6');
    };

    const onMouseLeave = (id: number) => {
        dynamicsTableNamesRef.current.querySelectorAll('tr')[id]?.removeAttribute('style');
        dynamicsTableRef.current.querySelectorAll('tr')[id]?.removeAttribute('style');
    };

    const renderItemDynamics = (items: { [key: string]: ResultsTypes.TypeTestResult[] } | undefined) => {
        if (dynamicsTableRef.current) {
            dynamicsTableRef.current.innerHTML = '';
        }

        const setCodes = new Set();

        return dynamics?.testResults?.map((dynamic: ResultsTypes.TypeTestResult, i: number) => {
            if (!setCodes.has(dynamic.code)) {
                const tr = document.createElement('tr');
                tr.addEventListener('click', () =>
                    navigate(`/results/dynamics/details?inz=${parsedLocationString.inz}&nss=${parsedLocationString.nss}&code=${dynamic.code}`)
                );
                tr.addEventListener('mouseenter', () => onMouseEnter(i));
                tr.addEventListener('mouseleave', () => onMouseLeave(i));
                for (let item in items) {
                    const td = document.createElement('td');
                    const foundItem = items[item].filter((item) => item.code === dynamic.code)[0];
                    td.innerHTML = `${
                        foundItem
                            ? `<span class=${foundItem.outbound ? styles.outbound : ''}>${foundItem?.value?.toString().length > 0 ? foundItem.value : ''}${
                                  foundItem.outbound ? '*' : ''
                              }</span>${
                                  !isEmpty(foundItem.laboratoryComment) || !isEmpty(foundItem.userComment)
                                      ? `<span data-for='dynamics' data-tip='${
                                            !isEmpty(foundItem.laboratoryComment)
                                                ? `${t(`Common.laboratoryComment`)}:<br/> ${foundItem.laboratoryComment}<br/>`
                                                : ''
                                        }${!isEmpty(foundItem.userComment) ? `${t(`Common.myComment`)}:<br/> ${foundItem.userComment}` : ''}'>
                                    <img alt="" class=${styles.dynamicsCommentIcon}  src='${dynamicsCommentIcon}'/></span>`
                                      : ''
                              }`
                            : ''
                    }`;
                    tr.appendChild(td);
                }
                if (dynamicsTableRef.current) {
                    // @ts-ignore
                    dynamicsTableRef.current.appendChild(tr);
                }

                setCodes.add(dynamic.code);

                return (
                    <tr
                        onMouseEnter={() => {
                            onMouseEnter(i);
                        }}
                        onMouseLeave={() => {
                            onMouseLeave(i);
                        }}
                        key={dynamic.code}
                        onClick={() =>
                            navigate(`/results/dynamics/details?inz=${parsedLocationString.inz}&nss=${parsedLocationString.nss}&code=${dynamic.code}`, {
                                state: { codes: parsedLocationString.codes }
                            })
                        }
                    >
                        <td className={styles.dynamicsTableCell}>
                            <Link to={`/results/dynamics/details?inz=${parsedLocationString.inz}&nss=${parsedLocationString.nss}&code=${dynamic.code}`}>
                                <Icon width={'11px'} height={'16px'} icon={`${testIcon}#dynamics`} className={styles.testIcon} />
                                <span className={styles.dynamicsTableTitle}>{dynamic.shortName}</span>
                                {', '}
                                <span className={styles.dynamicsTableUnit}>{dynamic.unit}</span>
                            </Link>
                        </td>
                    </tr>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <div className={styles.container}>
            <IsAvailable options={[{ results: true }]}>
                <Breadcrumbs
                    links={[
                        {
                            link: '/results',
                            name: t(`Common.results`),
                            active: false
                        },
                        {
                            link: `/results/details?inz=${parsedLocationString.inz}&nss=${parsedLocationString.nss}`,
                            name: t(`Common.inz`),
                            active: false
                        },
                        {
                            link: ``,
                            name: t(`Common.dynamics`),
                            active: true
                        }
                    ]}
                />

                <Fragment>
                    <Access permissions={[PermissionType.RESULTS]} message={t(`Common.noAccess`)} errors={[actions[FETCH_BY_CODES.BASE]?.error]}>
                        {actions[FETCH_BY_CODES.BASE]?.success && (
                            <div className={styles.patientCard}>
                                <IsInvitro>
                                    <PatientCard dynamics={true} patient={dynamics.patient} officeName={dynamics?.officeFullName} />
                                </IsInvitro>
                                <IsVetunion>
                                    <VetunionPatientCard patient={dynamics.patient} officeName={dynamics?.officeFullName} />
                                </IsVetunion>
                            </div>
                        )}
                        {dynamics?.testResults && dynamics?.testResults?.length > 0 && (
                            <div className={styles.downloadBlock}>
                                <button
                                    type={'button'}
                                    disabled={actions[DOWNLOAD.BASE]?.loading}
                                    className={styles.download}
                                    onClick={() => dispatch(DOWNLOAD.base({ nss: parsedLocationString.nss, codes: parsedLocationString.codes }))}
                                >
                                    <Icon width={'11px'} height={'16px'} icon={`${xlsxIcon}#xslx`} className={styles.xlsxIcon} />
                                    <span>{t(`Common.download`)}</span>
                                </button>
                                {actions[DOWNLOAD.BASE]?.loading && <Preloader />}
                            </div>
                        )}
                        <h2>{t(`Common.dynamicsTests`)}</h2>
                        {actions[FETCH_BY_CODES.BASE]?.success ? (
                            dynamics?.testResults && dynamics?.testResults?.length > 0 ? (
                                <Fragment>
                                    <div className={styles.dynamicsTables}>
                                        <div className={styles.dynamicsTableNameAnalysis}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>{t(`Common.dynamicsTest`)}</th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    // @ts-ignore
                                                    ref={dynamicsTableNamesRef}
                                                >
                                                    {renderItemDynamics(itemsGroupByDate)}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={styles.dynamicsTableResultsTests}>
                                            <table>
                                                <thead>
                                                    <tr>{renderItemsDate(itemsGroupByDate)}</tr>
                                                </thead>
                                                <tbody ref={dynamicsTableRef} />
                                            </table>
                                        </div>
                                    </div>
                                    <div className={styles.dynamicsResultsNotice}>
                                        <span className={styles.dynamicsOutsideMarker}>*</span> — {t(`DynamicsPage.dynamicsOutsideMarker`)}
                                    </div>
                                </Fragment>
                            ) : (
                                <p>{t(`Common.resultsNotFound`)}</p>
                            )
                        ) : (
                            <Preloader />
                        )}
                    </Access>
                </Fragment>
                {
                    // @ts-ignore
                    <ReactTooltip
                        border={true}
                        borderColor={'#939393'}
                        id={'dynamics'}
                        resizeHide={true}
                        html={true}
                        type={'light'}
                        effect={'solid'}
                        place={'top'}
                    />
                }
            </IsAvailable>
        </div>
    );
};

export default React.memo(DynamicsPage);
