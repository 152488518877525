import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './RequestsHistoryPage.module.css';
import HeaderSubMenu from 'page/layout/menu/HeaderSubMenu';
import { FETCH_MATERIALS_ALL, FETCH_ORDER_HISTORY, SET_ORDER } from 'redux/requests/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { orderHistorySelector } from 'redux/requests/selectors';
import { formatDate, parseISO } from 'utils/timeUtils';
import { RequestsTypes } from 'types/RequestsTypes';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { actionsSelector } from 'redux/app/selectors';
import { useTranslation } from 'react-i18next';

const RequestsHistoryPage = () => {
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [allOfficeHistory, setAllOfficeHistory] = useState(false);
    const { currentUser } = useSelector((state: AppTypes.State) => state.user);
    const orderHistory = useSelector(orderHistorySelector);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actions = useSelector(actionsSelector);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(
                FETCH_MATERIALS_ALL.base({
                    officeId: selectedOfficeOrGroup?.id,
                    userId: currentUser?.id
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id]);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(FETCH_ORDER_HISTORY.base({ all: allOfficeHistory }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, allOfficeHistory]);

    const handleSetOrder = (order: RequestsTypes.Order) => {
        if (orderHistory.length > 0) {
            const orderById = orderHistory.filter((item: any) => item.id === order.id)[0];
            if (orderById) {
                dispatch(
                    SET_ORDER.base({
                        order,
                        repeat: true
                    })
                );
                navigate('/requests/materials', { state: { repeatOrder: true } });
            }
        }
    };

    return (
        <div className={styles.container}>
            <HeaderSubMenu />
            <div className={styles.content}>
                <h1 className={styles.title}>{t(`RequestsHistoryPage.title`)}</h1>
                <IsAvailable options={[{ orders: { enable: true, materials: true } }]}>
                    <Access
                        officeGroup={{
                            selected: Array.isArray(selectedOfficeOrGroup?.offices),
                            message: t(`Common.requestOfficeNotice`)
                        }}
                        permissions={[PermissionType.ORDERS_MATERIALS]}
                        message={t(`Common.noAccess`)}
                        errors={[actions[FETCH_ORDER_HISTORY.BASE]?.error]}
                    >
                        <div className={styles.allOrders}>
                            <input onChange={() => setAllOfficeHistory(!allOfficeHistory)} checked={allOfficeHistory} type="checkbox" />
                            <label>{t(`RequestsHistoryPage.allOfficeHistory`)}</label>
                        </div>
                        {orderHistory.length > 0 ? (
                            <table className={styles.tableOrderHistory}>
                                <tbody>
                                    <tr>
                                        <th className={styles.tableOrderHistoryTitle}>{t(`Common.date`)}</th>
                                        <th />
                                        <th />
                                    </tr>
                                    {orderHistory?.map((order: any, i: number) => (
                                        <tr key={i}>
                                            <td className={styles.tableOrderHistoryDate}>
                                                {order.createdTime ? formatDate(parseISO(order.createdTime), 'ru') : null}
                                            </td>
                                            <td>
                                                <NavLink to={`/requests/history/${order.id}${allOfficeHistory ? '?allOfficeHistory=true' : ''}`}>
                                                    {t(`Common.browse`)}
                                                </NavLink>
                                            </td>
                                            <td>
                                                <button disabled={order.materials.length < 1} onClick={() => handleSetOrder(order)}>
                                                    {t(`Common.repeat`)}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>{t(`RequestsHistoryPage.ordersNotFound`)}</p>
                        )}
                    </Access>
                </IsAvailable>
            </div>
        </div>
    );
};

export default React.memo(RequestsHistoryPage);
