import { all, takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import * as bannersClient from 'client/BannerClient';
import { FETCH_ALL_BANNERS, FETCH_FILE_BANNER } from './actions';
import { AxiosResponse } from 'axios';
import { BannersTypes } from 'types/BannersTypes';

function* fetchAllBanners() {
    try {
        const response: AxiosResponse<BannersTypes.BannersAPI.BannerDTO[]> = yield call(bannersClient.fetchAllBanners);
        yield put(FETCH_ALL_BANNERS.success(response));
    } catch (err: any) {
        yield put(FETCH_ALL_BANNERS.error(err?.response));
    }
}

function* fetchFileBanner(action: any) {
    try {
        const response: AxiosResponse<BlobPart> = yield call(bannersClient.fetchFileBanner, action.meta);
        yield put(
            FETCH_FILE_BANNER.success({
                file: {
                    fileId: action.meta.fileId,
                    data: response.data
                }
            })
        );
    } catch (err: any) {
        yield put(FETCH_FILE_BANNER.error(err?.response));
    }
}

export default function* bannersSagas() {
    yield all([takeLatest(FETCH_ALL_BANNERS.BASE, fetchAllBanners)]);
    yield all([takeEvery(FETCH_FILE_BANNER.BASE, fetchFileBanner)]);
}
