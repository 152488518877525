import React, { useEffect } from 'react';
import styles from './Banners.module.css';
import { FETCH_ALL_BANNERS, FETCH_FILE_BANNER } from 'redux/banners/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import Carousel from 'page/layout/header/banners/Carousel';
import { AUTH_USER } from 'redux/user/actions';
import { actionsSelector } from 'redux/app/selectors';

const Banners = () => {
    const dispatch = useDispatch();
    const actions = useSelector(actionsSelector);
    const { allBanners, filesBanners } = useSelector((state: AppTypes.State) => state.banners);

    useEffect(() => {
        if (actions[AUTH_USER.BASE]?.success) {
            dispatch(FETCH_ALL_BANNERS.base());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[AUTH_USER.BASE]]);

    useEffect(() => {
        allBanners.forEach((banner) => {
            if (!filesBanners[banner.file.id]) {
                dispatch(FETCH_FILE_BANNER.base({ fileId: banner.file.id }));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allBanners]);

    return (
        <div className={styles.container}>
            <Carousel banners={allBanners} filesBanners={filesBanners} />
        </div>
    );
};

export default React.memo(Banners);
