/**
 * Разрешения
 */
export enum PermissionType {
    /**
     * Редактирование списка сотрудников
     */
    MANAGE_USERS = 'MANAGE_USERS',

    /**
     * Доступ к результатам
     */
    RESULTS = 'RESULTS',

    /**
     * Доступ к заказам курьера
     */
    ORDERS_COURIER = 'ORDERS_COURIER',

    /**
     * Доступ к заказам материалов
     */
    ORDERS_MATERIALS = 'ORDERS_MATERIALS',

    /**
     * Просмотр предзаказов
     */
    PREORDERS_READ_ONLY = 'PREORDERS_READ_ONLY',

    /**
     * Редактирование предзаказов
     */
    PREORDERS = 'PREORDERS'
}
