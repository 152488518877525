import {
    FETCH_ALL_OFFICE_GROUPS,
    FETCH_OFFICE_GROUP,
    FETCH_OFFICES,
    FETCH_OFFICES_FOR_GROUP,
    HAS_EMAIL_COURIER,
    SELECT_OFFICE_OR_GROUP
} from 'redux/office/actions';
import { OfficesTypes } from 'types/OfficesTypes';

const initialState: OfficesTypes.State = {
    offices: [],
    allOfficeGroups: [],
    officeGroup: null,
    selectedOfficeOrGroup: null,
    loading: false,
    success: false,
    error: false,
    hasEmailCourier: false,
    officesForGroup: null
};

const userReducer = (state: OfficesTypes.State = initialState, action: OfficesTypes.Action) => {
    switch (action.type) {
        case FETCH_OFFICES.STARTED:
        case FETCH_OFFICES.ERRORED:
        case FETCH_OFFICES.SUCCEEDED: {
            const offices = action?.payload?.data;
            return {
                ...state,
                offices: offices && offices.length > 0 ? [...offices] : []
            };
        }
        case FETCH_ALL_OFFICE_GROUPS.STARTED:
        case FETCH_ALL_OFFICE_GROUPS.ERRORED:
        case FETCH_ALL_OFFICE_GROUPS.SUCCEEDED: {
            const officeGroups = action?.payload?.data;
            return {
                ...state,
                allOfficeGroups: officeGroups && officeGroups.length > 0 ? [...officeGroups] : []
            };
        }
        case FETCH_OFFICE_GROUP.STARTED:
        case FETCH_OFFICE_GROUP.ERRORED:
        case FETCH_OFFICE_GROUP.SUCCEEDED: {
            const officeGroup = action?.payload?.data;
            return {
                ...state,
                officeGroup: officeGroup ? { ...officeGroup } : null
            };
        }
        case FETCH_OFFICES_FOR_GROUP.STARTED:
        case FETCH_OFFICES_FOR_GROUP.ERRORED:
        case FETCH_OFFICES_FOR_GROUP.SUCCEEDED: {
            const officesForGroup = action?.payload?.data;
            return {
                ...state,
                officesForGroup: officesForGroup ? [...officesForGroup] : null
            };
        }
        case SELECT_OFFICE_OR_GROUP.STARTED:
        case SELECT_OFFICE_OR_GROUP.ERRORED:
        case SELECT_OFFICE_OR_GROUP.SUCCEEDED: {
            const data = action?.payload?.data;
            return {
                ...state,
                selectedOfficeOrGroup: data ? { ...data } : null
            };
        }
        case HAS_EMAIL_COURIER.STARTED:
        case HAS_EMAIL_COURIER.ERRORED:
            return {
                ...state,
                hasEmailCourier: false
            };
        case HAS_EMAIL_COURIER.SUCCEEDED: {
            return {
                ...state,
                hasEmailCourier: true
            };
        }
        default:
            return state;
    }
};

export default userReducer;
