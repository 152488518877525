import React, { Fragment, useContext, useEffect, useState } from 'react';
import styles from './RequestsMaterialsPage.module.css';
import HeaderSubMenu from 'page/layout/menu/HeaderSubMenu';
import Modal from 'components/modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import OrdersPanel from 'page/requests/components/panel/OrdersPanel';
import MaterialList from 'page/requests/components/materials/MaterialList';
import { SET_MATERIAL_ORDER, FETCH_MATERIALS_ALL, SEND_MATERIAL_ORDER, SET_COMMENT_ORDER, SET_NOT_AVAILABLE_MATERIALS } from 'redux/requests/actions';
import { materialsByFolderSelector, notAvailableMaterialsSelector, orderSelector, totalQuantitySelector } from 'redux/requests/selectors';
import { RequestsTypes } from 'types/RequestsTypes';
import { AppTypes } from 'types/AppTypes';
import { actionsSelector } from 'redux/app/selectors';
import Preloader from 'components/preloader/Preloader';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { useLocation, useNavigate } from 'react-router-dom';
import { SET_ACTIONS } from 'redux/app/actions';
import { useTranslation } from 'react-i18next';
import { createMarkup } from 'utils/messageUtils';
import { ThemeContext } from 'themes/Themes';

const RequestsMaterialsPage = () => {
    const materialsByFolder = useSelector(materialsByFolderSelector);
    const order = useSelector(orderSelector);
    const notAvailableMaterials = useSelector(notAvailableMaterialsSelector);
    const actions = useSelector(actionsSelector);
    const totalQuantity = useSelector(totalQuantitySelector);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const { currentUser } = useSelector((state: AppTypes.State) => state.user);
    const [showModalConfirmOrder, setShowModalConfirmOrder] = useState(false);
    const [showModalNotAvailableMaterials, setShowModalNotAvailableMaterials] = useState(false);
    const [activeFolderList, setActiveFolderList] = useState<string[] | null | undefined>(null);
    const [changeAddress, setChangeAddress] = useState<boolean>(false);
    const [resetForm, setResetForm] = useState<boolean>(false);
    const { nameCompany } = useContext(ThemeContext);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    useEffect(() => {
        if (selectedOfficeOrGroup?.id && !location?.state?.repeatOrder) {
            dispatch(
                FETCH_MATERIALS_ALL.base({
                    officeId: selectedOfficeOrGroup?.id,
                    userId: currentUser?.id
                })
            );
            setResetForm(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id]);

    useEffect(() => {
        if (actions[SEND_MATERIAL_ORDER.BASE]?.success) {
            navigate('/requests/history');
        }
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [SEND_MATERIAL_ORDER.BASE]: null
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[SEND_MATERIAL_ORDER.BASE]]);

    useEffect(() => {
        if (notAvailableMaterials.length > 0) {
            setShowModalNotAvailableMaterials(true);
        }
        return () => {
            dispatch(SET_NOT_AVAILABLE_MATERIALS.base());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notAvailableMaterials.length]);

    const handleSetMaterialOrder = (material: RequestsTypes.Material) => {
        dispatch(
            SET_MATERIAL_ORDER.base({
                officeId: selectedOfficeOrGroup?.id,
                userId: currentUser?.id,
                material
            })
        );
    };

    const handleSetShowModalConfirmOrder = (value: boolean) => setShowModalConfirmOrder(value);

    return (
        <div className={styles.container}>
            <HeaderSubMenu />
            <div className={styles.content}>
                <h1 className={styles.title}>{t(`RequestsMaterialsPage.title`)}</h1>
                <IsAvailable options={[{ orders: { enable: true, materials: true } }]}>
                    <Access
                        officeGroup={{
                            selected: Array.isArray(selectedOfficeOrGroup?.offices),
                            message: t(`Common.requestOfficeNotice`)
                        }}
                        permissions={[PermissionType.ORDERS_MATERIALS]}
                        message={t(`Common.noAccess`)}
                        errors={[actions[FETCH_MATERIALS_ALL.BASE]?.error]}
                    >
                        <article
                            className={styles.pageDescription}
                            dangerouslySetInnerHTML={createMarkup(t(`RequestsMaterialsPage.pageDescription`, { nameCompany }))}
                        />
                        {actions[FETCH_MATERIALS_ALL.BASE]?.loading ? (
                            <Preloader />
                        ) : (
                            <Fragment>
                                <Fragment>
                                    <MaterialList
                                        activeFolderList={activeFolderList}
                                        materials={materialsByFolder}
                                        order={order}
                                        setOrder={handleSetMaterialOrder}
                                    />
                                    {materialsByFolder?.length > 0 && (
                                        <div className={styles.materialsComment}>
                                            <div className={styles.materialsCommentTitle}>{t(`Common.comment`)}</div>
                                            <textarea
                                                value={order.comment}
                                                onChange={(event) => dispatch(SET_COMMENT_ORDER.base({ comment: event.target.value }))}
                                                className={styles.materialsCommentField}
                                                placeholder={t(`RequestsMaterialsPage.writeYourComment`)}
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            </Fragment>
                        )}
                    </Access>
                </IsAvailable>
            </div>
            {actions[FETCH_MATERIALS_ALL.BASE]?.success && materialsByFolder?.length > 0 && (
                <OrdersPanel
                    selectedOfficeOrGroup={selectedOfficeOrGroup}
                    order={order}
                    setActiveFolderList={setActiveFolderList}
                    totalQuantity={totalQuantity}
                    address={
                        changeAddress || location?.state?.repeatOrder
                            ? order.address
                            : selectedOfficeOrGroup?.address && ((order?.address?.length > 0 && order.address) || !resetForm)
                            ? selectedOfficeOrGroup?.address
                            : ''
                    }
                    setShowModalConfirmOrder={handleSetShowModalConfirmOrder}
                    setChangeAddress={setChangeAddress}
                    setResetForm={setResetForm}
                    dispatch={dispatch}
                />
            )}
            <Modal show={showModalConfirmOrder} onClose={() => setShowModalConfirmOrder(false)}>
                <div className={styles.confirmOrderModal}>
                    <h1>{t(`RequestsMaterialsPage.yourOrder`)}</h1>
                    <div className={styles.confirmOrderListWrap}>
                        <table className={styles.confirmOrderList}>
                            <thead>
                                <tr className={styles.confirmOrderListTitle}>
                                    <th>
                                        <div>{t(`RequestsMaterialsPage.name`)}</div>
                                    </th>
                                    <th className={styles.confirmOrderListValue}>
                                        <div>{t(`RequestsMaterialsPage.quantity`)}</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {order?.materials?.map((material: RequestsTypes.Material, i) => (
                                    <tr key={i}>
                                        <td>{material.name}</td>
                                        <td>{material.quantity}</td>
                                    </tr>
                                ))}
                                <tr className={styles.confirmOrderTotal}>
                                    <td>{t(`RequestsMaterialsPage.totalNumber`)}</td>
                                    <td>
                                        <div>{totalQuantity}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.confirmOrderAddress}>
                        <span>{t(`Common.address`)}</span>
                        <p>{order.address}</p>
                    </div>
                    <div className={styles.confirmOrderComment}>
                        <div className={styles.materialsCommentTitle}>{t(`Common.comment`)}</div>
                        <textarea
                            value={order.comment}
                            onChange={(event) => dispatch(SET_COMMENT_ORDER.base({ comment: event.target.value }))}
                            className={styles.materialsCommentField}
                            placeholder={t(`RequestsMaterialsPage.writeYourComment`)}
                        />
                    </div>
                    <div className={styles.confirmOrderButtons}>
                        <button onClick={() => setShowModalConfirmOrder(false)}>{t(`Common.change`)}</button>
                        <button
                            disabled={!(order?.materials?.length > 0)}
                            onClick={() => {
                                setShowModalConfirmOrder(false);
                                dispatch(SEND_MATERIAL_ORDER.base(order));
                            }}
                        >
                            {t(`Common.confirm`)}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showModalNotAvailableMaterials} onClose={() => setShowModalNotAvailableMaterials(false)}>
                <div className={styles.notAvailableMaterialsModal}>
                    <h3>{t(`RequestsMaterialsPage.notAvailableMaterialsNotice`, { nameCompany })}</h3>
                    <div className={styles.confirmOrderListWrap}>
                        <table className={styles.confirmOrderList}>
                            <thead>
                                <tr className={styles.confirmOrderListTitle}>
                                    <th>
                                        <div>{t(`RequestsMaterialsPage.name`)}</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {notAvailableMaterials?.map((material: RequestsTypes.Material, i) => (
                                    <tr key={i}>
                                        <td>{material.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.notAvailableMaterialsButton}>
                        <button onClick={() => setShowModalNotAvailableMaterials(false)}>{t(`Common.continue`)}</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default React.memo(RequestsMaterialsPage);
