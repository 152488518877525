import React from 'react';
import classnames from 'classnames/bind';
import { OfficeTag } from 'constants/officeTag';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import officeNameIconActive from './office-name-icon-active.svg';
import officeNameIcon from './office-name-icon.svg';
import styles from './OfficeList.module.css';
import { useTranslation } from 'react-i18next';
const classNames = classnames.bind(styles);

const OfficeList = ({ offices, selectedOfficeId, selectOffice }: any) => {
    const { t } = useTranslation('common');

    const scrollToSelectedOffice = (ref: any) => {
        if (selectedOfficeId) {
            const index = offices.findIndex((itemOffice: any) => itemOffice.office.id === selectedOfficeId);
            index >= 0 && ref.scrollToItem(index, 'start');
        }
    };

    const officeRenderer = ({ index, style }: any) => {
        const itemOffice = offices[index];
        const office = itemOffice.office;
        const truncated = !itemOffice.allProductsAvailable;

        const currentOfficeId = office.id;

        const officeListItem = classNames({
            officeListItem: true,
            officeTruncatedSelected: selectedOfficeId && truncated && selectedOfficeId === currentOfficeId,
            officeSelected: selectedOfficeId && !truncated && selectedOfficeId === currentOfficeId
        });

        return (
            <div style={style} key={office.id} className={officeListItem} onClick={() => selectOffice(itemOffice)}>
                <div className={styles.itemBodyContainer}>
                    <div className={styles.officeHeader}>
                        <img className={styles.officeNameIcon} alt="" src={selectedOfficeId === currentOfficeId ? officeNameIconActive : officeNameIcon} />
                        <span className={styles.officeNameTitle}>{office.name?.replace(/МО /i, '')}</span>
                    </div>
                    <div className={styles.officeAddress}>{office.address}</div>
                    <div className={styles.officeTags}>
                        {office.tags &&
                            office.tags.map((tag: string) => (
                                <span key={tag} className={styles.officeTag}>
                                    {tagMessage(tag)}
                                </span>
                            ))}
                    </div>
                </div>
            </div>
        );
    };

    const tagMessage = (tag: string) => {
        let message;
        switch (tag) {
            case OfficeTag.CARD_PAYMENT_AVAILABLE:
                message = t(`OfficeList.tagCardPaymentAvailable`);
                break;
            case OfficeTag.WORK_WITHOUT_HOLIDAYS:
                message = t(`OfficeList.tagWorkWithoutHolidays`);
                break;
            case OfficeTag.ENTRANCE_WITH_TROLLEYS:
                message = t(`OfficeList.tagEntranceWithTrolleys`);
                break;
            case OfficeTag.WITHOUT_QUEUING:
                message = t(`OfficeList.tagWithoutQueuing`);
                break;
            case OfficeTag.URGENT_TESTS:
                message = t(`OfficeList.tagUrgentTests`);
                break;
            case OfficeTag.FAST_RESULTS:
                message = t(`OfficeList.tagFastResults`);
                break;
            case OfficeTag.RESULTS_IN_FOUR_HOURS:
                message = t(`OfficeList.tagResultsInFourHours`);
                break;
            case OfficeTag.PAID_PARKING:
                message = t(`OfficeList.tagPaidParking`);
                break;
            case OfficeTag.FREE_PARKING:
                message = t(`OfficeList.tagFreeParking`);
                break;
            case OfficeTag.FREE_WI_FI:
                message = t(`OfficeList.tagFreeWiFi`);
                break;
            default:
                return tag;
        }
        return message;
    };

    return (
        <div className={styles.officeList}>
            <
            // @ts-ignore
            AutoSizer>
                {({ height, width }: any) => (
                    <List height={height} width={width} itemCount={offices.length} itemSize={212} ref={(ref: any) => ref && scrollToSelectedOffice(ref)}>
                        {officeRenderer}
                    </List>
                )}
            </AutoSizer>
        </div>
    );
};

export default React.memo(OfficeList);
