import createRequestTypes from 'redux/helpers';
export const SELECT_OFFICE_OR_GROUP = createRequestTypes('OFFICE/SELECT_OFFICE_OR_GROUP');
export const FETCH_OFFICES = createRequestTypes('OFFICE/FETCH_OFFICES');
export const FETCH_OFFICES_FOR_GROUP = createRequestTypes('OFFICE/FETCH_OFFICES_FOR_GROUP');
export const HAS_EMAIL_COURIER = createRequestTypes('OFFICE/HAS_EMAIL_COURIER');
export const HAS_EXTERNAL_PREORDER_POINTS = createRequestTypes('OFFICE/HAS_EXTERNAL_PREORDER_POINTS');
export const FETCH_ALL_OFFICE_GROUPS = createRequestTypes('OFFICE/FETCH_ALL_OFFICE_GROUPS');
export const FETCH_OFFICE_GROUP = createRequestTypes('OFFICE/FETCH_OFFICE_GROUP');
export const CREATE_OFFICE_GROUP = createRequestTypes('OFFICE/CREATE_OFFICE_GROUP');
export const UPDATE_OFFICE_GROUP = createRequestTypes('OFFICE/UPDATE_OFFICE_GROUP');
export const DELETE_OFFICE_GROUP = createRequestTypes('OFFICE/DELETE_OFFICE_GROUP');
export const SET_FAVORITE_OFFICE = createRequestTypes('OFFICE/SET_FAVORITE_OFFICE');
