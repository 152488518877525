import React from 'react';
import styles from './OfficeGroupsCreatePage.module.css';
import FormOfficeGroups from '../components/form/FormOfficeGroups';
import { useTranslation } from 'react-i18next';

const OfficeGroupsCreatePage = () => {
    const { t } = useTranslation('common');
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`OfficeGroupsCreatePage.title`)}</h1>
            <FormOfficeGroups edit={false} officeGroup={null} />
        </div>
    );
};

export default React.memo(OfficeGroupsCreatePage);
