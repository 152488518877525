import * as React from 'react';
import classNames from 'classnames/bind';
import ButtonLink from 'components/button/link/ButtonLink';
import changeBiomaterialIcon from './changeBiomaterialIcon.svg';
import styles from './BiomaterialChooser.module.css';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useEffect } from 'react';
const cx = classNames.bind(styles);

const BiomaterialChooser = ({ onSelectBiomaterial, product }: any) => {
    const { t } = useTranslation('common');
    useEffect(() => ReactTooltip.rebuild() as () => void);

    if (product.availableBiomaterials || product?.biomaterialId) {
        const className = cx({
            chooseBiomaterial: !product.biomaterial && !product?.biomaterialId,
            chosenBiomaterial: product.biomaterial || product?.biomaterialId,
            inactive: product?.availableBiomaterials?.length === 1 && product.biomaterial
        });
        if (product?.availableBiomaterials?.length > 1) {
            return (
                <div className={styles.container}>
                    <ButtonLink className={className} onClick={() => onSelectBiomaterial(product.id)}>
                        <span>{product.biomaterial?.name || t(`BiomaterialChooser.chooseMethod`)}</span>
                        <img className={styles.changeBiomaterialIcon} src={changeBiomaterialIcon} alt="" />
                    </ButtonLink>
                </div>
            );
        } else if ((product?.availableBiomaterials?.length === 1 && product.biomaterial) || product?.biomaterialId) {
            return (
                <div className={styles.container}>
                    <ButtonLink
                        disabled="disabled"
                        className={className}
                        onClick={() => false}
                        data-for={'biomaterial'}
                        data-tip={`${product.biomaterial?.name || product.biomaterialShortName}`}
                    >
                        <span>{product.biomaterial?.name || product.biomaterialShortName || t(`BiomaterialChooser.chooseMethod`)}</span>
                    </ButtonLink>
                    {
                        // @ts-ignore
                        <ReactTooltip
                            id={'biomaterial'}
                            border={true}
                            borderColor={'#939393'}
                            resizeHide={true}
                            html={true}
                            type={'light'}
                            effect={'solid'}
                            place={'top'}
                        />
                    }
                </div>
            );
        }
    }

    return null;
};

export default React.memo(BiomaterialChooser);
