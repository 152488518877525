import React, { Fragment, useEffect, useState, FC, useContext } from 'react';
import styles from 'page/requests/courier/RequestsCourierPage.module.css';
import HeaderSubMenu from 'page/layout/menu/HeaderSubMenu';
import Modal from 'components/modal/Modal';
import { RequestsTypes } from 'types/RequestsTypes';
import { AppTypes } from 'types/AppTypes';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_TYPE } from 'constants/requestType';
import { formatDate, parseISO } from 'utils/timeUtils';
import { addDays, differenceInMinutes, format, isToday, startOfTomorrow, addHours, subHours } from 'date-fns';
import { FETCH_REQUEST_COURIER, CREATE_REQUEST_COURIER, CHANGE_REQUEST_COURIER, CANCEL_REQUEST_COURIER } from 'redux/requests/actions';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { actionsSelector } from 'redux/app/selectors';
import { ru } from 'date-fns/locale';
import { HAS_EMAIL_COURIER } from 'redux/office/actions';
import Notification from 'components/notification/Notification';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { useTranslation } from 'react-i18next';
import { createMarkup } from 'utils/messageUtils';
import CustomDatePickerInput from 'components/input/datepicker/DatePickerInput';
import IsInvitro from 'themes/invitro/IsInvitro';
import { ThemeContext } from 'themes/Themes';

const RequestsCourierPage: FC = () => {
    const { requests } = useSelector((state: AppTypes.State) => state.requests);
    const { selectedOfficeOrGroup, hasEmailCourier } = useSelector((state: AppTypes.State) => state.offices);
    const [currentRequest, setCurrentRequest] = useState<RequestsTypes.TypeRequest>();
    const [showModalCreateRequest, setShowModalCreateRequest] = useState(false);
    const [showModalChangeRequest, setShowModalChangeRequest] = useState(false);
    const [showModalCancelRequest, setShowModalCancelRequest] = useState(false);
    const [showModalButtonCancelRequest, setShowModalButtonCancelRequest] = useState(false);
    const [checkboxComment, setCheckboxComment] = useState(false);
    const [allOfficeHistory, setAllOfficeHistory] = useState(false);

    const [requestDate, setRequestDate] = useState<any>();
    const [requestFromTime, setRequestFromTime] = useState<any>();
    const [requestToTime, setRequestToTime] = useState<any>();

    const [comment, setComment] = useState<string>('');
    const { nameCompany } = useContext(ThemeContext);
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(HAS_EMAIL_COURIER.base());
            dispatch(FETCH_REQUEST_COURIER.base({ all: allOfficeHistory }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, allOfficeHistory]);

    useEffect(() => {
        if (currentRequest && currentRequest.comment.length > 0) {
            setCheckboxComment(true);
            setComment(currentRequest?.comment);
        } else {
            setCheckboxComment(false);
            setComment('');
        }
    }, [currentRequest]);

    useEffect(() => {
        if (new Date() > setMinutes(setHours(new Date(), 17), 0)) {
            setRequestDate(new Date(startOfTomorrow()));
        } else {
            setRequestDate(new Date());
        }
    }, []);

    useEffect(() => {
        if (showModalChangeRequest && currentRequest?.id) {
            setRequestDate(
                setMinutes(setHours(parseISO(currentRequest.fromDate), 0), 0) >= setMinutes(setHours(new Date(), 0), 0)
                    ? setMinutes(setHours(parseISO(currentRequest.fromDate), 0), 0)
                    : new Date()
            );
            setRequestFromTime(parseISO(currentRequest.fromDate));
            setRequestToTime(parseISO(currentRequest.toDate));
        } else {
            setRequestDate(new Date());
            setRequestFromTime(null);
            setRequestToTime(null);
        }
    }, [showModalChangeRequest, currentRequest]);

    useEffect(() => {
        if (!checkboxComment) {
            setComment('');
        }
    }, [checkboxComment]);

    const getFormatDate = (date: Date) => {
        return `${format(requestDate, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}`;
    };

    const renderDateCommentBlocks = (requestType?: REQUEST_TYPE) => {
        return (
            <Fragment>
                <div className={styles.courierDateTime}>
                    <DatePicker
                        customInput={<CustomDatePickerInput />}
                        isClearable={false}
                        locale={ru}
                        required={true}
                        selected={requestDate}
                        adjustDateOnChange={true}
                        startDate={requestDate}
                        dateFormat="dd.MM.yyyy"
                        onChange={(date) => {
                            setRequestDate(date);
                            setRequestToTime(null);
                            setRequestFromTime(null);
                        }}
                        minDate={new Date()}
                        maxDate={addDays(requestDate, 7)}
                    />
                    <DatePicker
                        locale={ru}
                        required={true}
                        startDate={requestDate}
                        onChange={(date: any) => {
                            if (!date) {
                                return false;
                            }
                            setRequestFromTime(date);
                            setRequestToTime(addHours(date, 2));
                        }}
                        adjustDateOnChange={true}
                        showTimeSelect
                        selected={requestFromTime}
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption=""
                        timeFormat="p"
                        placeholderText={t(`RequestsCourierPage.requestFromTime`)}
                        dateFormat="HH:mm"
                        minTime={setHours(setMinutes(requestDate, 0), 9)}
                        maxTime={setHours(setMinutes(requestDate, 0), 17)}
                        filterTime={(time) => {
                            return differenceInMinutes(time, requestDate) >= 0 || !isToday(requestDate);
                        }}
                    />
                    <DatePicker
                        locale={ru}
                        required={true}
                        onChange={(date: any) => {
                            if (!date) {
                                return false;
                            }
                            setRequestToTime(date);
                            setRequestFromTime(subHours(date, 2));
                        }}
                        adjustDateOnChange={true}
                        startDate={requestDate}
                        selected={requestToTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption=""
                        timeFormat="p"
                        placeholderText={t(`RequestsCourierPage.requestToTime`)}
                        dateFormat="HH:mm"
                        minTime={setHours(setMinutes(requestDate, 0), 11)}
                        maxTime={setHours(setMinutes(requestDate, 0), 19)}
                        filterTime={(time) => {
                            return differenceInMinutes(time, requestDate) >= 120 || !isToday(requestDate);
                        }}
                    />
                </div>
                <div className={styles.specialConditionsBlock}>
                    <div>
                        <input
                            onChange={() => setCheckboxComment((prevState: boolean) => !prevState)}
                            type="checkbox"
                            className={styles.hasSpecialConditions}
                            checked={checkboxComment}
                        />
                        <label>{showModalButtonCancelRequest ? t(`RequestsCourierPage.addComment`) : t(`RequestsCourierPage.specialConditions`)}</label>
                    </div>
                    {checkboxComment && (
                        <div>
                            <textarea onChange={(event) => setComment(event.target.value)} className={styles.specialConditionsText} value={comment} />
                            {requestType === REQUEST_TYPE.CALL && (
                                <div className={styles.specialConditionsRemark}>{t(`RequestsCourierPage.specialConditionsRemark`)}</div>
                            )}
                        </div>
                    )}
                </div>
            </Fragment>
        );
    };

    return (
        <div className={styles.container}>
            <HeaderSubMenu />
            <div className={styles.content}>
                <h1 className={styles.title}>{t(`RequestsCourierPage.courierCallOrders`)}</h1>
                <IsAvailable options={[{ orders: { enable: true, couriers: true } }]}>
                    <Access
                        officeGroup={{
                            selected: Array.isArray(selectedOfficeOrGroup?.offices),
                            message: t(`Common.requestOfficeNotice`)
                        }}
                        permissions={[PermissionType.ORDERS_COURIER]}
                        message={t(`Common.noAccess`)}
                        errors={[actions[FETCH_REQUEST_COURIER.BASE]?.error]}
                    >
                        {actions[HAS_EMAIL_COURIER.BASE]?.error && (
                            <Fragment>
                                <Notification type={'error'}>{t(`Common.emailCourierNotice`)}</Notification>
                                <br />
                            </Fragment>
                        )}
                        <IsInvitro>
                            <p className={styles.noticeStatus} dangerouslySetInnerHTML={createMarkup(t(`RequestsCourierPage.noticeStatus`))} />
                        </IsInvitro>
                        <p className={styles.noticeStatus} dangerouslySetInnerHTML={createMarkup(t(`RequestsCourierPage.noticeHelpStatus`, { nameCompany }))} />
                        <div className={styles.buttons}>
                            <button
                                className={styles.call}
                                disabled={!hasEmailCourier}
                                onClick={() => {
                                    setCheckboxComment(false);
                                    setComment('');
                                    setShowModalCreateRequest(true);
                                }}
                            >
                                {t(`RequestsCourierPage.callCourier`)}
                            </button>
                            <button
                                className={styles.cancel}
                                disabled={!hasEmailCourier}
                                onClick={() => {
                                    setCheckboxComment(false);
                                    setComment('');
                                    setShowModalButtonCancelRequest(true);
                                }}
                            >
                                {t(`RequestsCourierPage.cancelCourierCall`)}
                            </button>
                        </div>
                        <div className={styles.allOrders}>
                            <input onChange={() => setAllOfficeHistory(!allOfficeHistory)} checked={allOfficeHistory} type="checkbox" />
                            <label>{t(`RequestsCourierPage.allOfficeHistory`)}</label>
                        </div>
                        {requests.length > 0 ? (
                            <table className={styles.tableOrders}>
                                <thead>
                                    <tr className={styles.tableOrdersTitles}>
                                        <th className={styles.tableOrdersDate}>{t(`Common.date`)}</th>
                                        <th className={styles.tableOrdersOrder}>{t(`Common.request`)}</th>
                                        <th className={styles.tableOrdersStatus}>{t(`Common.status`)}</th>
                                        <th className={styles.tableOrdersAction} />
                                    </tr>
                                </thead>
                                <tbody>
                                    {requests.map((request: RequestsTypes.TypeRequest) => {
                                        const createdTime = formatDate(parseISO(request.createdTime), 'ru', 'PP');
                                        const requestDate = formatDate(parseISO(request.fromDate), 'ru', 'PPP');
                                        const requestFromTime = formatDate(parseISO(request.fromDate), 'ru', 'p');
                                        const requestToTime = formatDate(parseISO(request.toDate), 'ru', 'p');
                                        return (
                                            <tr
                                                key={request.id}
                                                onClick={(event) => {
                                                    if (!request.deleted && hasEmailCourier) {
                                                        event.stopPropagation();
                                                        setShowModalChangeRequest(true);
                                                        setCurrentRequest(request);
                                                    }
                                                }}
                                            >
                                                <td>{createdTime}</td>
                                                <td>
                                                    {request.requestType === REQUEST_TYPE.CALL
                                                        ? t(`RequestsCourierPage.callingCourier`)
                                                        : `${t(`RequestsCourierPage.cancelingCourierCallTo`)} ${requestDate}, 
                                                ${requestFromTime} - ${requestToTime} ${
                                                              request.comment?.length > 0 ? t(`RequestsCourierPage.specialConditionsNotice`) : ''
                                                          }`}
                                                </td>
                                                <td className={styles.status}>
                                                    {request.deleted ? t(`RequestsCourierPage.cancelled`) : <span>{t(`RequestsCourierPage.execution`)}</span>}
                                                </td>
                                                <td>
                                                    {!request.deleted && (
                                                        <span
                                                            className={styles.actionButton}
                                                            onClick={(event) => {
                                                                if (!hasEmailCourier) {
                                                                    return false;
                                                                }
                                                                event.stopPropagation();
                                                                setShowModalCancelRequest(true);
                                                                setCurrentRequest(request);
                                                            }}
                                                        >
                                                            {t(`Common.cancel`)}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <p>{t(`RequestsCourierPage.requestsNotFound`)}</p>
                        )}
                        <Modal
                            show={showModalCreateRequest}
                            title={t(`RequestsCourierPage.requestCallCourier`)}
                            onClose={() => setShowModalCreateRequest(false)}
                        >
                            <div className={styles.requestModal}>
                                <h2 className={styles.modalSubTitle}>{t(`RequestsCourierPage.whenNeedCourier`)}</h2>
                                {renderDateCommentBlocks(REQUEST_TYPE.CALL)}
                                <button
                                    type={'button'}
                                    onClick={() => {
                                        setShowModalCreateRequest(false);
                                        dispatch(
                                            CREATE_REQUEST_COURIER.base({
                                                comment: comment,
                                                fromDate: getFormatDate(requestFromTime),
                                                toDate: getFormatDate(requestToTime),
                                                requestType: REQUEST_TYPE.CALL
                                            })
                                        );
                                    }}
                                    disabled={!requestFromTime || !requestToTime}
                                >
                                    {t(`RequestsCourierPage.confirm`)}
                                </button>
                                <p className={styles.notice}>{t(`RequestsCourierPage.impossibilitiesNotice`, { nameCompany })}</p>
                            </div>
                        </Modal>
                        <Modal show={showModalChangeRequest} title={t(`RequestsCourierPage.changingRequest`)} onClose={() => setShowModalChangeRequest(false)}>
                            <div className={styles.requestModal}>
                                <h2 className={styles.modalSubTitle}>{t(`RequestsCourierPage.whenNeedCourier`)}</h2>
                                {renderDateCommentBlocks(currentRequest?.requestType)}
                                <button
                                    type={'button'}
                                    onClick={() => {
                                        setShowModalChangeRequest(false);
                                        dispatch(
                                            CHANGE_REQUEST_COURIER.base({
                                                id: currentRequest?.id,
                                                comment: comment,
                                                fromDate: getFormatDate(requestFromTime),
                                                toDate: getFormatDate(requestToTime),
                                                requestType: currentRequest?.requestType
                                            })
                                        );
                                    }}
                                    disabled={!requestFromTime || !requestToTime}
                                >
                                    {t(`RequestsCourierPage.confirm`)}
                                </button>
                            </div>
                        </Modal>
                        <Modal
                            show={showModalButtonCancelRequest}
                            title={t(`RequestsCourierPage.requestCancelCourier`)}
                            onClose={() => setShowModalButtonCancelRequest(false)}
                        >
                            <div className={styles.requestModal}>
                                <h2 className={styles.modalSubTitle}>{t(`RequestsCourierPage.whenCancelCourier`)}</h2>
                                {renderDateCommentBlocks()}
                                <button
                                    type={'button'}
                                    onClick={() => {
                                        setShowModalButtonCancelRequest(false);
                                        dispatch(
                                            CREATE_REQUEST_COURIER.base({
                                                comment: comment,
                                                fromDate: getFormatDate(requestFromTime),
                                                toDate: getFormatDate(requestToTime),
                                                requestType: REQUEST_TYPE.CANCEL
                                            })
                                        );
                                    }}
                                    disabled={!requestFromTime || !requestToTime}
                                >
                                    {t(`RequestsCourierPage.confirm`)}
                                </button>
                            </div>
                        </Modal>
                        <Modal
                            show={showModalCancelRequest}
                            title={t(`RequestsCourierPage.cancelingCourierCall`)}
                            onClose={() => setShowModalCancelRequest(false)}
                        >
                            <div className={styles.closeRequestModal}>
                                <h2 className={styles.modalSubTitle}>{t(`RequestsCourierPage.questionCancelingCourierCall`)}</h2>
                                <button
                                    type={'button'}
                                    onClick={() => {
                                        setShowModalCancelRequest(false);
                                        dispatch(
                                            CANCEL_REQUEST_COURIER.base({
                                                requestId: currentRequest?.id,
                                                requestType: currentRequest?.requestType
                                            })
                                        );
                                    }}
                                >
                                    {t(`Common.cancel`)}
                                </button>
                            </div>
                        </Modal>
                    </Access>
                </IsAvailable>
            </div>
        </div>
    );
};

export default React.memo(RequestsCourierPage);
