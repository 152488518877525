import {
    FIND_USERS_BY_OFFICE,
    SEARCH_USERS,
    CREATE_USER_OFFICE,
    MODIFY_PERMISSIONS,
    CREATE_USER,
    REMOVE_USER_OFFICE,
    AUTH_USER,
    CHANGE_PASSWORD_BY_TOKEN,
    CHANGE_PASSWORD,
    CHECK_TOKEN,
    GET_CURRENT_USER,
    UPDATE_EMAIL
} from 'redux/user/actions';
import { UserTypes } from 'types/UserTypes';

const initialState: UserTypes.State = {
    foundUsers: [],
    usersByOffice: [],
    currentUser: null,
    loading: false,
    success: false,
    error: false
};

const userReducer = (state: UserTypes.State = initialState, action: UserTypes.Action) => {
    switch (action.type) {
        case FIND_USERS_BY_OFFICE.ERRORED:
        case FIND_USERS_BY_OFFICE.STARTED:
        case FIND_USERS_BY_OFFICE.SUCCEEDED: {
            const usersByOffice = action?.payload?.data;
            return {
                ...state,
                usersByOffice: usersByOffice && usersByOffice.length > 0 ? [...usersByOffice] : []
            };
        }
        case SEARCH_USERS.ERRORED:
        case SEARCH_USERS.STARTED:
        case SEARCH_USERS.SUCCEEDED: {
            const foundUsers = action?.payload?.data;
            return {
                ...state,
                foundUsers: foundUsers && foundUsers.length > 0 ? [...foundUsers] : []
            };
        }
        case CREATE_USER_OFFICE.ERRORED:
        case CREATE_USER_OFFICE.STARTED:
        case CREATE_USER_OFFICE.SUCCEEDED: {
            const createdUser = action?.payload?.data;
            return {
                ...state,
                foundUsers: createdUser && createdUser.length > 0 ? [...createdUser] : []
            };
        }
        case MODIFY_PERMISSIONS.ERRORED:
        case MODIFY_PERMISSIONS.STARTED:
        case MODIFY_PERMISSIONS.SUCCEEDED: {
            return {
                ...state
            };
        }
        case CREATE_USER.ERRORED:
        case CREATE_USER.STARTED:
        case CREATE_USER.SUCCEEDED: {
            return {
                ...state
            };
        }
        case REMOVE_USER_OFFICE.ERRORED:
        case REMOVE_USER_OFFICE.STARTED:
        case REMOVE_USER_OFFICE.SUCCEEDED: {
            return {
                ...state
            };
        }
        case CHANGE_PASSWORD.ERRORED:
        case CHANGE_PASSWORD.STARTED:
        case CHANGE_PASSWORD.SUCCEEDED: {
            return {
                ...state
            };
        }
        case CHANGE_PASSWORD_BY_TOKEN.ERRORED:
        case CHANGE_PASSWORD_BY_TOKEN.STARTED:
        case CHANGE_PASSWORD_BY_TOKEN.SUCCEEDED: {
            return {
                ...state
            };
        }
        case CHECK_TOKEN.ERRORED:
        case CHECK_TOKEN.STARTED:
        case CHECK_TOKEN.SUCCEEDED: {
            return {
                ...state
            };
        }
        case UPDATE_EMAIL.ERRORED:
        case UPDATE_EMAIL.STARTED:
        case UPDATE_EMAIL.SUCCEEDED: {
            return {
                ...state
            };
        }
        case GET_CURRENT_USER.ERRORED:
        case GET_CURRENT_USER.STARTED:
        case GET_CURRENT_USER.SUCCEEDED: {
            const currentUser = action?.payload?.data;
            return {
                ...state,
                currentUser: currentUser ? { ...currentUser } : null
            };
        }
        case AUTH_USER.ERRORED:
        case AUTH_USER.STARTED:
        case AUTH_USER.SUCCEEDED: {
            return {
                ...state
            };
        }
        default:
            return state;
    }
};

export default userReducer;
