import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_SETTINGS, INIT } from './actions';
import { AUTHENTICATE } from 'redux/auth/actions';
import { AxiosResponse } from 'axios';
import * as settingsClient from 'client/SettingsClient';
import { StartupTypes } from 'types/StartupTypes';

function* initialize() {
    try {
        const response: AxiosResponse<StartupTypes.Settings> = yield call(settingsClient.fetch);
        if (response) {
            yield put(FETCH_SETTINGS.success(response));
            yield put(INIT.success());
            yield put(AUTHENTICATE.base());
        } else {
            yield put(FETCH_SETTINGS.error());
        }
    } catch (err: any) {
        yield put(INIT.error(err?.response));
    }
}

export default function* startupSagas() {
    yield all([takeLatest(INIT.BASE, initialize)]);
}
