import React, { FC } from 'react';
import styles from './Pagination.module.css';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type TProps = {
    setServerSideFilter: any;
    limitItemsPage?: string | number | (string | null)[];
    hasNextPage?: boolean | null;
};

const Pagination: FC<TProps> = ({ setServerSideFilter, limitItemsPage, hasNextPage }) => {
    const { t } = useTranslation('common');

    const parsedLocationString = queryString.parse(window.location.search);
    const start = parseInt(String(parsedLocationString.start));
    const limit = parseInt(String(parsedLocationString.limit));

    return (
        <div className={styles.container}>
            <div className={styles.beginPageLink}>
                {start > 0 && (
                    <Link
                        onClick={() =>
                            setServerSideFilter({
                                ...parsedLocationString,
                                start: 0,
                                limit: limitItemsPage
                            })
                        }
                        to={`?${queryString.stringify(
                            {
                                ...parsedLocationString,
                                start: 0,
                                limit: limitItemsPage
                            },
                            { skipNull: true }
                        )}`}
                        className={styles.link}
                    >
                        {t(`Common.toBeginning`)}
                    </Link>
                )}
            </div>
            <div className={styles.pageLinks}>
                {setServerSideFilter && start > 0 && (
                    <Link
                        onClick={() =>
                            setServerSideFilter({
                                ...parsedLocationString,
                                start: start - limit,
                                limit: limitItemsPage
                            })
                        }
                        to={`?${queryString.stringify(
                            {
                                ...parsedLocationString,
                                start: start - limit,
                                limit: limitItemsPage
                            },
                            { skipNull: true }
                        )}`}
                        className={styles.link}
                    >
                        {t(`Common.previousPage`)}
                    </Link>
                )}
                {setServerSideFilter && hasNextPage && (
                    <Link
                        onClick={() =>
                            setServerSideFilter({
                                ...parsedLocationString,
                                start: start + limit || 1,
                                limit: limitItemsPage
                            })
                        }
                        to={`?${queryString.stringify(
                            {
                                ...parsedLocationString,
                                start: start + limit || 1,
                                limit: limitItemsPage
                            },
                            { skipNull: true }
                        )}`}
                        className={styles.link}
                    >
                        {t(`Common.nextPage`)}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default React.memo(Pagination);
