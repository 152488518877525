import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import styles from './EmployeesCreatePage.module.css';
import { CREATE_USER, CREATE_USER_OFFICE } from 'redux/user/actions';
import { actionsSelector } from 'redux/app/selectors';
import Notification from 'components/notification/Notification';
import { SET_ACTIONS } from 'redux/app/actions';
import Permissions from 'page/settings/employees/components/permissions/Permissions';
import { createMarkup, getPermissions } from 'utils/messageUtils';
import Modal from 'components/modal/Modal';
import { PermissionType } from 'constants/PermissionType';
import Access from 'components/access/Access';
import { AppTypes } from 'types/AppTypes';
import { useTranslation } from 'react-i18next';

const EmployeesCreatePage = () => {
    const location: any = useLocation();
    const [userEmail, setUserEmail] = useState(location.state?.email || '');
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const actions = useSelector(actionsSelector);
    const [userFullName, setUserFullName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setShowModal(actions[CREATE_USER.BASE]?.success && actions[CREATE_USER_OFFICE.BASE]?.success);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[CREATE_USER.BASE], actions[CREATE_USER_OFFICE.BASE]]);

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [CREATE_USER.BASE]: null,
                    [CREATE_USER_OFFICE.BASE]: null
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderModal = () => {
        return (
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <h2>{t(`EmployeesCreatePage.changesSaved`)}</h2>
                <div className={styles.actionModal}>
                    <p dangerouslySetInnerHTML={createMarkup(t(`EmployeesCreatePage.newUserReady`, { userEmail }))} />
                </div>
                <button onClick={() => navigate('/settings/employees')} type="button">
                    {t(`Common.continue`)}
                </button>
            </Modal>
        );
    };

    return (
        <div className={styles.container}>
            {renderModal()}
            <h1 className={styles.title}>{t(`Common.staff`)}</h1>
            <Access
                officeGroup={{
                    selected: Array.isArray(selectedOfficeOrGroup?.offices),
                    message: t(`EmployeesCreatePage.notice`)
                }}
                permissions={[PermissionType.MANAGE_USERS]}
                message={t(`Common.noAccess`)}
                errors={[actions[CREATE_USER.BASE]?.error]}
            >
                <div className={styles.backLink}>
                    ← <Link to={'/settings/employees'}>{t(`Common.back`)}</Link>
                </div>
                <div className={styles.settingRow}>
                    <form method="POST">
                        <div className={styles.changeNameModal}>
                            <h2 className={styles.subTitle}>{t(`EmployeesCreatePage.creatingNewUser`)}</h2>
                            {actions[CREATE_USER.BASE]?.error?.errors?.map((error) => (
                                <Fragment>
                                    <Notification type={'error'}>
                                        <p className={styles.notice}>{error.message}</p>
                                    </Notification>
                                    <br />
                                </Fragment>
                            ))}
                            {actions[CREATE_USER_OFFICE.BASE]?.error?.errors?.map((error) => (
                                <Fragment>
                                    <Notification type={'error'}>
                                        <p className={styles.notice}>{error.message}</p>
                                    </Notification>
                                    <br />
                                </Fragment>
                            ))}
                            <p>{t(`EmployeesCreatePage.fillUserCard`)}</p>
                            <div className={styles.settingRow}>
                                <label>{t(`EmployeesCreatePage.email`)}</label>
                                <input
                                    className={styles.fullName}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    type="text"
                                    name="fullName"
                                    value={userEmail}
                                />
                            </div>
                            <div className={styles.settingRow}>
                                <label>{t(`EmployeesCreatePage.fio`)}</label>
                                <input className={styles.fullName} onChange={(e) => setUserFullName(e.target.value)} type="text" value={userFullName} />
                            </div>
                        </div>
                        <div className={styles.permissions}>
                            <Permissions
                                permissions={getPermissions()}
                                selectedPermissions={selectedPermissions}
                                setSelectedPermissions={setSelectedPermissions}
                            />
                        </div>
                        <div className={styles.actions}>
                            <button
                                disabled={
                                    userEmail.length < 1 ||
                                    userFullName.length < 1 ||
                                    actions[CREATE_USER.BASE]?.loading ||
                                    actions[CREATE_USER_OFFICE.BASE]?.loading
                                }
                                onClick={() => {
                                    dispatch(
                                        CREATE_USER.base({
                                            email: userEmail,
                                            nickname: userFullName,
                                            permissions: [...selectedPermissions]
                                        })
                                    );
                                }}
                                type="button"
                            >
                                {t(`Common.create`)}
                            </button>
                        </div>
                    </form>
                </div>
            </Access>
        </div>
    );
};

export default React.memo(EmployeesCreatePage);
