import apiClient from 'client/ApiClient';
import { BASE_URL } from './constants';
import { OfficesTypes } from 'types/OfficesTypes';
const OFFICES_URL = `${BASE_URL}/offices`;
const OFFICE_GROUP_URL = `${BASE_URL}/office-group`;
const USER_OFFICES_URL = `${BASE_URL}/users-offices`;

export const fetchOffices = () => {
    return apiClient.get<OfficesTypes.OfficesAPI.OfficeDTO[]>(`${OFFICES_URL}`);
};

export const hasEmailCourier = () => {
    return apiClient.get<void>(`${OFFICES_URL}/hasEmailCourier`);
};

export const hasExternalPreorderPoints = () => {
    return apiClient.get<void>(`${OFFICES_URL}/hasExternalPreorderPoints`);
};

export const fetchAllOfficeGroups = () => {
    return apiClient.get<OfficesTypes.OfficesAPI.OfficeGroupDTO[]>(`${OFFICE_GROUP_URL}`);
};

export const fetchOfficeGroup = (data: OfficesTypes.DataOfficeGroup) => {
    return apiClient.get<OfficesTypes.OfficesAPI.OfficeGroupDTO>(`${OFFICE_GROUP_URL}/${data.officeGroupId}`);
};

export const fetchOfficesForGroup = (data: OfficesTypes.DataOfficesForGroup) => {
    return apiClient.post<OfficesTypes.OfficesAPI.OfficesForGroupDTO>(`${OFFICES_URL}`, data);
};

export const createOfficeGroup = (data: OfficesTypes.DataCreateOfficeGroup) => {
    return apiClient.post<void>(`${OFFICE_GROUP_URL}`, data);
};

export const updateOfficeGroup = (data: OfficesTypes.DataUpdateOfficeGroup) => {
    return apiClient.put<void>(`${OFFICE_GROUP_URL}`, data);
};

export const deleteOfficeGroup = (data: OfficesTypes.DataOfficeGroup) => {
    return apiClient.delete<void>(`${OFFICE_GROUP_URL}/${data.officeGroupId}`);
};

export const setFavoriteOffice = (data: OfficesTypes.DataSetFavoriteOffice) => {
    return apiClient.get<void>(`${USER_OFFICES_URL}/${data.officeId}/favorite/${data.flag}`);
};
