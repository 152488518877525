import * as React from 'react';
import AlertWarning from 'components/alert/AlertWarning';
import ButtonLink from 'components/button/link/ButtonLink';
import ButtonSecondary from 'components/button/secondary/ButtonSecondary';
import BiomaterialSelectionModal from './modal/BiomaterialSelectionModal';
import Product from 'page/preorders/components/formProducts/product/PreorderProduct';
import styles from './ProductsPanel.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { officeCalculationSelector, officeDataSelector, preorderDetailsProductsSelector } from 'redux/preorders/selectors';
import { useTranslation } from 'react-i18next';
import {
    DELETE_PRODUCT,
    REMOVE_INVALID_PRODUCTS,
    RETRY_OFFICE_CALCULATION,
    SHOW_BIOMATERIAL_SELECTION_MODAL,
    SHOW_OFFICE_SELECTION_MODAL
} from 'redux/preorders/actions';
import { AppTypes } from 'types/AppTypes';

const ProductsPanel = ({ preorder }: any) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const officeData = useSelector((state: AppTypes.State) => officeDataSelector(state));
    const officeCalculationError = useSelector((state: AppTypes.State) => officeCalculationSelector(state)?.error);
    const preorderDetailsProducts = useSelector((state: AppTypes.State) => preorderDetailsProductsSelector(state));

    const deleteProduct = (product: any) => {
        dispatch(DELETE_PRODUCT.base({ article: product.article }));
    };

    const restoreProduct = (product: any) => {
        restoreProduct({ article: product.article });
    };

    const onSelectBiomaterial = (productId: any) => {
        dispatch(
            SHOW_BIOMATERIAL_SELECTION_MODAL.base({
                show: true,
                productsIds: [productId]
            })
        );
    };

    const renderCartWarning = () => {
        const warnings = [];
        if (officeCalculationError) {
            warnings.push(
                <AlertWarning
                    key={1}
                    className={styles.cartWarning}
                    content={
                        <React.Fragment>
                            <span>{t(`ProductsPanel.officeCalculationFailed`)}</span>
                            <ButtonLink className={styles.retryButton} text={t(`Common.repeat`)} onClick={() => dispatch(RETRY_OFFICE_CALCULATION.base())} />
                        </React.Fragment>
                    }
                />
            );
        }
        if (warnings.length > 0) {
            window.scrollTo(0, 500);
            return <React.Fragment>{warnings}</React.Fragment>;
        } else {
            return null;
        }
    };

    const renderUnavailableWarning = () => {
        if (preorder?.invalidProducts?.length > 0) {
            window.scrollTo(0, 500);
            return (
                <div className={styles.unavailableProductsContainer}>
                    <AlertWarning className={styles.unavailableProductsWarning} content={t(`ProductsPanel.productsUnavailableOfficeWarning`)} />
                    {renderProducts(preorder?.invalidProducts, false)}
                    <div className={styles.unavailableProductsActionsBlock}>
                        <h2>{t(`ProductsPanel.chooseDifferentOffice`)}</h2>
                        <div>
                            <ButtonSecondary
                                onClick={() => dispatch(SHOW_OFFICE_SELECTION_MODAL.base({ show: !preorder?.showOfficeSelectionModal }))}
                                text={t(`ProductsPanel.chooseDifferentOffice`)}
                            />
                            <ButtonSecondary onClick={() => dispatch(REMOVE_INVALID_PRODUCTS.base())} text={t(`ProductsPanel.removeUnavailableProducts`)} />
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderProducts = (products: any, removable: any) => {
        if (!products?.length) {
            return null;
        }

        const notInProfileOrderProductPredicate = (productId: any) =>
            (preorder?.id ? [...preorderDetailsProducts] : [...(officeData?.orderProducts || [])]).find(
                (item: any) => item.product.id === productId && item.profileId === null
            );

        return (
            <ul>
                {products.map((product: any) => {
                    if (!preorder?.id || notInProfileOrderProductPredicate(product.id)) {
                        return (
                            <li key={product.article}>
                                <Product
                                    preorder={preorder}
                                    product={product}
                                    products={products}
                                    orderProducts={officeData.orderProducts}
                                    onRemoveProduct={removable && !preorder.id ? deleteProduct : undefined}
                                    onRestoreProduct={restoreProduct}
                                    onSelectBiomaterial={onSelectBiomaterial}
                                />
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
            </ul>
        );
    };

    return (
        <div className={styles.cartContainer}>
            <div className={styles.cartContent}>
                <div className={styles.cartProducts}>
                    {renderCartWarning()}
                    {renderUnavailableWarning()}
                    {renderProducts(preorder?.products, true)}
                </div>
            </div>
            <BiomaterialSelectionModal />
        </div>
    );
};

export default React.memo(ProductsPanel);
