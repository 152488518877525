import React, { FC } from 'react';
import styles from './Icon.module.css';
import classNames from 'classnames';

type TProps = {
    icon: any;
    className?: string;
    width?: string;
    height?: string;
    title?: string;
    onClick?: any;
    style?: any;
    setDefaultStyles?: boolean;
    rebuildTooltip?: boolean;
};

const Icon: FC<TProps> = ({ icon, className, setDefaultStyles = true, ...rest }) => {
    return (
        <svg className={classNames(className, setDefaultStyles && styles.icon)} {...rest}>
            <use href={icon} />
        </svg>
    );
};

export default React.memo(Icon);
