import React, { FC, useState } from 'react';
import styles from './AmountElementsSelector.module.css';
import { parseInt } from 'lodash';
import { useTranslation } from 'react-i18next';

type TProps = {
    currentValue?: number;
    handleSetServerSideFilter?: (value: any) => void;
    setLimitItemsPage?: (value: any) => void;
};

const AmountElementsSelector: FC<TProps> = ({ currentValue, handleSetServerSideFilter, setLimitItemsPage }) => {
    const { t } = useTranslation('common');
    const [amounts] = useState([10, 25, 50, 100]);

    return (
        <div className={styles.container}>
            <select
                defaultValue={currentValue}
                onChange={(event) => {
                    handleSetServerSideFilter && handleSetServerSideFilter({ limit: parseInt(event.target.value) });
                    if (setLimitItemsPage) {
                        setLimitItemsPage(parseInt(event.target.value));
                    }
                }}
            >
                {amounts.map((item) => (
                    <option key={item} value={item}>
                        {t(`AmountElementsSelector.amounts`, { item })}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default React.memo(AmountElementsSelector);
