import React, { FC, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'components/list/ResultList.module.css';
import resultUnprinted from 'themes/icons/unprinted.svg';
import resultPrinted from 'themes/icons/printed.svg';
import classNames from 'classnames';
import { RESULT_STATUS } from 'constants/ResultStatus';
import { formatDate, formatDateTime, parseISO } from 'utils/timeUtils';
import Pagination from 'components/pagination/Pagination';
import { ResultsTypes } from 'types/ResultsTypes';
import Preloader from 'components/preloader/Preloader';
import { PRINTED_TYPE } from 'constants/printedType';
import { capitalizeFirstLowerCaseRest } from 'utils/messageUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import Icon from 'components/icon/Icon';
import { FETCH_ALL } from 'redux/results/actions';
import Notification from 'components/notification/Notification';
import { actionsSelector } from 'redux/app/selectors';

type TProps = {
    loading?: boolean;
    onSelectResult?: (event: React.ChangeEvent<HTMLInputElement> | null, result?: ResultsTypes.TypeResult | null) => void;
    selectedResults?: ResultsTypes.TypeResult[] | [];
    results: ResultsTypes.TypeResult[];
    setServerSideFilter?: (param: ResultsTypes.ServerSideFilter) => void;
    limitItemsPage?: number;
    hasNextPage?: boolean | null;
    preorders?: boolean;
};

const ResultList: FC<TProps> = ({ loading, onSelectResult, selectedResults, results, setServerSideFilter, limitItemsPage, hasNextPage, preorders }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const actions = useSelector(actionsSelector);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);

    useEffect(() => ReactTooltip.rebuild() as () => void);

    const renderStatus = (statusType: RESULT_STATUS) => {
        switch (statusType) {
            case RESULT_STATUS.NOT_COMPLETED:
                return <span className={styles.statusNotCompleted}>{t(`Common.notCompleted`)}</span>;
            case RESULT_STATUS.PARTIAL:
                return <span className={styles.statusPartial}>{t(`Common.partial`)}</span>;
            case RESULT_STATUS.COMPLETED:
                return <span className={styles.statusCompleted}>{t(`Common.completed`)}</span>;
        }
    };

    return (
        <Fragment>
            {!loading && actions[FETCH_ALL.BASE]?.error && (
                <Notification style={{ margin: '10px 0' }} type={'error'}>
                    <p>{actions[FETCH_ALL.BASE]?.error?.message}</p>
                </Notification>
            )}
            {loading && <Preloader />}
            {results?.length > 0 && !loading ? (
                <Fragment>
                    <table className={styles.tableResults}>
                        <thead>
                            <tr>
                                {onSelectResult && (
                                    <th className={styles.tableResultsTitle}>
                                        <input
                                            type="checkbox"
                                            checked={selectedResults && selectedResults.length > 0 && selectedResults?.length === results.length}
                                            onChange={(event) => (onSelectResult ? onSelectResult(event, null) : null)}
                                        />
                                    </th>
                                )}
                                <th />
                                <th className={classNames([styles.tableResultsTitle, styles.tableResultsPatient])}>{t(`Common.patient`)}</th>
                                <th className={styles.tableResultsTitle} title={t(`ResultList.outReferenceValues`)}>
                                    {t(`ResultList.referenceShort`)}
                                </th>
                                <th className={styles.tableResultsTitle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>{t(`ResultList.orderDate`)}</div>
                                </th>
                                <th className={styles.tableResultsTitle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={t(`ResultList.expectedMaximumPeriod`)}>{t(`ResultList.readinessPeriod`)}</span>
                                        <div className={styles.hint} data-for={'resultList'} data-tip={t(`ResultList.expectedMaximumPeriod`)}>
                                            ?
                                        </div>
                                    </div>
                                </th>
                                {Array.isArray(selectedOfficeOrGroup?.offices) && (
                                    <th className={styles.tableResultsTitle} style={{ textAlign: 'center' }}>
                                        <div>
                                            <div title={t(`ResultList.office`)}>{t(`ResultList.office`)}</div>
                                            <div title={t(`ResultList.office`)} />
                                        </div>
                                    </th>
                                )}
                                <th className={styles.tableResultsTitle}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div title={t(`ResultList.dateFormationResult`)}>{t(`ResultList.dateExecution`)}</div>
                                        <div className={styles.hint} data-for={'resultList'} data-tip={t(`ResultList.dateFormationResult`)}>
                                            ?
                                        </div>
                                    </div>
                                </th>
                                <th className={styles.tableResultsTitle}>{t(`Common.inz`)}</th>
                                <th className={styles.tableResultsTitle}>{t(`Common.status`)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results?.map((result) => {
                                const { inz, patient, pathologies, printedType, statusType, clientCode } = result;
                                const isSelected = selectedResults?.filter((item) => item.inz === result.inz) || [];
                                const createdTime = result.createdTime && formatDateTime(parseISO(result.createdTime), 'ru', 'PP p');
                                const deadlineDateTime = result.deadlineDateTime && formatDateTime(parseISO(result.deadlineDateTime), 'ru', 'PP p');
                                const completeDateTime = result.completeDateTime && formatDateTime(parseISO(result.completeDateTime), 'ru', 'PP p');
                                return (
                                    <tr
                                        key={inz}
                                        className={styles.tableResultsRow}
                                        onClick={() => navigate(`/results/details?inz=${result.inz}&nss=${result.patient.nss}`)}
                                        onContextMenu={() => window.open(`/results/details?inz=${result.inz}&nss=${result.patient.nss}`)}
                                        onMouseDown={(event) =>
                                            event.button === 1 && window.open(`/results/details?inz=${result.inz}&nss=${result.patient.nss}`)
                                        }
                                    >
                                        {onSelectResult && (
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={isSelected.length > 0}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => {
                                                        if (onSelectResult) {
                                                            onSelectResult(event, result);
                                                        }
                                                        event.stopPropagation();
                                                    }}
                                                    disabled={statusType === RESULT_STATUS.NOT_COMPLETED}
                                                />
                                            </td>
                                        )}
                                        <td style={onSelectResult ? { padding: '0' } : { paddingLeft: preorders ? '0' : '15px' }}>
                                            {printedType === PRINTED_TYPE.PRINTED && (
                                                <Icon
                                                    icon={`${resultPrinted}#printed`}
                                                    className={styles.resultPrintedIcon}
                                                    title={t(`ResultList.allDocumentsPrinted`)}
                                                />
                                            )}
                                            {printedType === PRINTED_TYPE.PARTIALLY && (
                                                <Icon
                                                    setDefaultStyles={false}
                                                    icon={`${resultUnprinted}#unprinted`}
                                                    className={styles.resultPrintedIcon}
                                                    title={t(`ResultList.orderPartiallyPrinted`)}
                                                />
                                            )}
                                        </td>
                                        <td className={styles.tableResultsPatient}>
                                            <div>
                                                {capitalizeFirstLowerCaseRest(patient.lastName)} {capitalizeFirstLowerCaseRest(patient.firstName)}{' '}
                                                {capitalizeFirstLowerCaseRest(patient.middleName)}{' '}
                                                {patient.birthday && <span>{formatDate(parseISO(patient.birthday), 'ru')}</span>}
                                            </div>
                                        </td>
                                        <td>
                                            {pathologies && (
                                                <div
                                                    className={styles.referenceMarker}
                                                    data-for={'resultList'}
                                                    data-tip={`<div class=${styles.tooltip}>${t(`Common.resultsBeyondRefValues`)} ${pathologies.join(
                                                        ', '
                                                    )}</div>`}
                                                >
                                                    !
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <span>{createdTime}</span>
                                        </td>
                                        <td>
                                            <span>{deadlineDateTime}</span>
                                        </td>
                                        {Array.isArray(selectedOfficeOrGroup?.offices) && (
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                <span>{clientCode}</span>
                                            </td>
                                        )}
                                        <td>
                                            <span>{completeDateTime}</span>
                                        </td>
                                        <td>{inz}</td>
                                        <td>
                                            <div className={styles.resultStatus}>{renderStatus(statusType)}</div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Pagination setServerSideFilter={setServerSideFilter} limitItemsPage={limitItemsPage} hasNextPage={hasNextPage} />
                </Fragment>
            ) : (
                !loading && <p>{t(`Common.resultsNotFound`)}</p>
            )}
            {
                // @ts-ignore
                <ReactTooltip
                    border={true}
                    borderColor={'#939393'}
                    id={'resultList'}
                    resizeHide={true}
                    html={true}
                    type={'light'}
                    effect={'solid'}
                    place={'top'}
                />
            }
        </Fragment>
    );
};

export default React.memo(ResultList);
