import React from 'react';
import classNames from 'classnames';
import ButtonLink from 'components/button/link/ButtonLink';
import styles from './ButtonLinkSvg.module.css';

const ButtonLinkSvg = ({ className, svg, text, ...rest }: any) => {
    return (
        <ButtonLink className={classNames(styles.button, className)} {...rest}>
            <svg>
                <use href={svg} />
            </svg>
            {text ? <span>{text}</span> : null}
        </ButtonLink>
    );
};

export default React.memo(ButtonLinkSvg);
