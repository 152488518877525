import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './groupedProductList.module.css';
import leftArrow from './leftArrow.svg';
import hiddenListIcon from './hiddenListIcon.svg';
import showListIcon from './showListIcon.svg';
import BiomaterialChooser from '../biomaterialChooser/BiomaterialChooser';
import ProductModal from '../modal/ProductModal';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

const GroupedProductList = ({ product, products, orderProducts, onSelectBiomaterial, sets, openProductList }: any) => {
    const [hidden, setHidden] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(false);
    const [orderProfilesId, setOrderProfilesId] = useState<any>([]);

    const { t } = useTranslation('common');

    useEffect(() => {
        const tempOrderProfilesId: any = [];
        orderProducts.forEach((item: any) => {
            if (item.profileId === product.id) {
                tempOrderProfilesId.push(item.product.id);
            }
        });
        setOrderProfilesId(tempOrderProfilesId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderProducts, product]);

    const showDescriptionModal = (product: any) => {
        setShowModal(true);
        setCurrentProduct(product);
    };

    const hideDescriptionModal = () => {
        setShowModal(false);
    };

    return (
        <div className={styles.container}>
            {sets &&
                sets.map((set: any) => {
                    const currentTests = orderProducts.filter((item: any) => product.id === item.profileId);
                    const biomaterialChooserProduct = products.filter((item: any) => item.id === set.products[0].id)[0];
                    const currentTest = set.products.filter((checkProduct: any) => orderProfilesId.includes(checkProduct.id));

                    if (biomaterialChooserProduct && currentTests.length > 0 && currentTest.length > 0) {
                        return (
                            <div className={styles.wrapper} key={set.products[0].id}>
                                <img alt="" className={styles.leftArrow} src={leftArrow} />
                                <div className={styles.list}>
                                    <div className={cx({ productNumbers: true, hidden: !hidden })}>
                                        {set.products.map((item: any) => {
                                            const test = currentTests.filter((currentProduct: any) => currentProduct.product.id === item.id)[0];
                                            if (test) {
                                                return (
                                                    <div key={test.product.article} className={cx({ productNumber: true })}>
                                                        <div className={styles.productArticle}>
                                                            {t(`Common.formattedNumber`, { number: test.product.article })}
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            return null;
                                        })}
                                    </div>
                                    <div className={cx({ products: true, hidden: hidden })}>
                                        {set.products.map((item: any) => {
                                            const test = currentTests.filter((currentProduct: any) => currentProduct.product.id === item.id)[0];
                                            if (test) {
                                                return (
                                                    <div key={test.product.id} className={cx({ product: true })}>
                                                        <div className={styles.productLeftBlock}>
                                                            <div className={styles.productName} onClick={() => showDescriptionModal({ ...test.product })}>
                                                                {test.product.name}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={styles.productArticle}>
                                                                {t(`Common.formattedNumber`, { number: test.product.article })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            return null;
                                        })}
                                    </div>
                                    <div className={styles.buttons}>
                                        <div>
                                            <BiomaterialChooser product={biomaterialChooserProduct} onSelectBiomaterial={onSelectBiomaterial} />
                                        </div>
                                        <div>
                                            {hidden && (
                                                <img
                                                    alt=""
                                                    onClick={() => {
                                                        setHidden(!hidden);
                                                        openProductList();
                                                    }}
                                                    className={styles.showListIcon}
                                                    src={showListIcon}
                                                />
                                            )}
                                            {!hidden && (
                                                <img
                                                    alt=""
                                                    onClick={() => {
                                                        setHidden(!hidden);
                                                        openProductList();
                                                    }}
                                                    className={styles.hiddenListIcon}
                                                    src={hiddenListIcon}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }

                    return null;
                })}
            {showModal ? <ProductModal show={showModal} product={currentProduct} onClose={() => hideDescriptionModal()} /> : null}
        </div>
    );
};

export default React.memo(GroupedProductList);
