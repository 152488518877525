import {
    FETCH_MATERIALS_ALL,
    SET_MATERIAL_ORDER,
    SEND_MATERIAL_ORDER,
    CLEAR_MATERIAL_ORDER,
    SET_COMMENT_ORDER,
    FETCH_ORDER_HISTORY,
    SET_ADDRESS_ORDER,
    FETCH_REQUEST_COURIER,
    CREATE_REQUEST_COURIER,
    CHANGE_REQUEST_COURIER,
    CANCEL_REQUEST_COURIER,
    GET_MATERIAL_PHOTO,
    SET_NOT_AVAILABLE_MATERIALS
} from 'redux/requests/actions';

import { RequestsTypes } from 'types/RequestsTypes';

const initialState: RequestsTypes.State = {
    requests: [],
    materials: [],
    order: {
        comment: '',
        address: '',
        materials: []
    },
    orderHistory: [],
    notAvailableMaterials: [],
    materialPhotos: {},
    loading: false,
    success: false,
    error: false
};

//@ts-ignore
const requestsReducer = (state: RequestsTypes.State = initialState, action) => {
    switch (action.type) {
        case FETCH_REQUEST_COURIER.STARTED:
        case FETCH_REQUEST_COURIER.ERRORED:
        case FETCH_REQUEST_COURIER.SUCCEEDED: {
            const data = action?.payload?.data;
            return {
                ...state,
                requests: data.length > 0 ? [...data] : []
            };
        }
        case CREATE_REQUEST_COURIER.STARTED:
        case CREATE_REQUEST_COURIER.ERRORED:
        case CREATE_REQUEST_COURIER.SUCCEEDED: {
            return {
                ...state
            };
        }
        case CHANGE_REQUEST_COURIER.STARTED:
        case CHANGE_REQUEST_COURIER.ERRORED:
        case CHANGE_REQUEST_COURIER.SUCCEEDED: {
            return {
                ...state
            };
        }
        case CANCEL_REQUEST_COURIER.STARTED:
        case CANCEL_REQUEST_COURIER.ERRORED:
        case CANCEL_REQUEST_COURIER.SUCCEEDED: {
            return {
                ...state
            };
        }
        case FETCH_MATERIALS_ALL.STARTED:
        case FETCH_MATERIALS_ALL.ERRORED:
        case FETCH_MATERIALS_ALL.SUCCEEDED: {
            const { materials } = action?.payload;
            return {
                ...state,
                materials: materials?.length > 0 ? [...materials] : [],
                order: { ...state.order }
            };
        }
        case GET_MATERIAL_PHOTO.STARTED:
        case GET_MATERIAL_PHOTO.ERRORED:
        case GET_MATERIAL_PHOTO.SUCCEEDED: {
            if (action?.payload?.picture && action?.payload?.responseData) {
                const { picture, responseData } = action?.payload;
                const type = responseData?.type;
                const blob = new Blob([responseData], { type });
                const objectURL = window.URL.createObjectURL(blob);
                const newState: any = { ...state.materialPhotos };
                newState[picture] = objectURL;

                return {
                    ...state,
                    materialPhotos: { ...newState }
                };
            } else {
                return {
                    ...state
                };
            }
        }
        case FETCH_ORDER_HISTORY.STARTED:
        case FETCH_ORDER_HISTORY.ERRORED:
        case FETCH_ORDER_HISTORY.SUCCEEDED: {
            const orderHistory = action?.payload?.data;
            return {
                ...state,
                orderHistory: orderHistory.length > 0 ? [...orderHistory] : []
            };
        }
        case SET_MATERIAL_ORDER.BASE: {
            const { material } = action.meta;
            const filteredMaterials = state.order.materials.filter((item) => item?.article !== material.article);
            return {
                ...state,
                order: {
                    ...state.order,
                    materials: material.quantity > 0 ? [...filteredMaterials, { ...material }] : [...filteredMaterials]
                }
            };
        }
        case SET_COMMENT_ORDER.BASE: {
            const { comment } = action.meta;
            return {
                ...state,
                order: {
                    ...state.order,
                    comment
                }
            };
        }
        case SET_ADDRESS_ORDER.BASE: {
            const { address } = action.meta;
            return {
                ...state,
                order: {
                    ...state.order,
                    address
                }
            };
        }
        case CLEAR_MATERIAL_ORDER.SUCCEEDED: {
            return {
                ...state,
                order: { ...initialState.order, address: '' }
            };
        }
        case SEND_MATERIAL_ORDER.STARTED:
        case SEND_MATERIAL_ORDER.ERRORED:
        case SEND_MATERIAL_ORDER.SUCCEEDED: {
            return {
                ...state
            };
        }
        case SET_NOT_AVAILABLE_MATERIALS.BASE: {
            const notAvailableMaterials = action.meta?.notAvailableMaterials;
            return {
                ...state,
                notAvailableMaterials: notAvailableMaterials?.length > 0 ? [...notAvailableMaterials] : []
            };
        }
        default:
            return state;
    }
};

export default requestsReducer;
