import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const apiClient = {
    setGlobalHeader(name: string, value: string): void {
        axios.defaults.headers.common[name] = value;
    },
    getGlobalHeader(name: string): string | number | boolean {
        return axios.defaults.headers.common[name];
    },
    removeGlobalHeader(name: string) {
        delete axios.defaults.headers.common[name];
    },
    get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios.get(url, config);
    },
    post<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios.post(url, data, config);
    },
    put<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios.put(url, data, config);
    },
    delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios.delete(url, config);
    },
    patch<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios.patch(url, data, config);
    }
};

export default apiClient;
