import React from 'react';
import styles from './TableFootnote.module.css';
import { useTranslation } from 'react-i18next';

const TableFootnote = () => {
    const { t } = useTranslation('common');
    return <span className={styles.footnote}>{t(`TableFootnote.footnote`)}</span>;
};

export default React.memo(TableFootnote);
