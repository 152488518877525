import createRequestTypes from 'redux/helpers';
export const FETCH_ALL = createRequestTypes('PREORDERS/FETCH_ALL');
export const FETCH_PREORDER_DETAILS = createRequestTypes('PREORDERS/FETCH_PREORDER_DETAILS');
export const SEARCH_PATIENTS = createRequestTypes('PREORDERS/SEARCH_PATIENTS');
export const CREATE_PATIENT = createRequestTypes('PREORDERS/CREATE_PATIENT');
export const FETCH_ALL_PRODUCTS = createRequestTypes('PREORDERS/FETCH_ALL_PRODUCTS');
export const FETCH_OFFICES = createRequestTypes('PREORDERS/FETCH_OFFICES');
export const FETCH_OFFICES_UPDATE = createRequestTypes('PREORDERS/FETCH_OFFICES_UPDATE');
export const SET_PREORDER = createRequestTypes('PREORDERS/SET_PREORDER');
export const SET_CREATE_PATIENT_FORM = createRequestTypes('PREORDERS/SET_CREATE_PATIENT_FORM');
export const CLEAR_PREORDER = createRequestTypes('PREORDERS/CLEAR_PREORDER');
export const CLEAR_PREORDER_DETAILS = createRequestTypes('PREORDERS/CLEAR_PREORDER_DETAILS');
export const SET_FILTER = createRequestTypes('PREORDERS/SET_FILTER');
export const FETCH_PRODUCTS = createRequestTypes('PREORDERS/FETCH_PRODUCTS');
export const FETCH_PRODUCTS_CALCULATE = createRequestTypes('PREORDERS/FETCH_PRODUCTS_CALCULATE');
export const SELECT_OFFICE = createRequestTypes('PREORDERS/SELECT_OFFICE');
export const CHOOSE_OFFICE = createRequestTypes('PREORDERS/CHOOSE_OFFICE');
export const CALCULATE_OFFICE = createRequestTypes('PREORDERS/CALCULATE_OFFICE');
export const RETRY_OFFICE_CALCULATION = createRequestTypes('PREORDERS/RETRY_OFFICE_CALCULATION');
export const ADD_PRODUCT = createRequestTypes('PREORDERS/ADD_PRODUCT');
export const DELETE_PRODUCT = createRequestTypes('PREORDERS/DELETE_PRODUCT');
export const UPDATE_AFTER_MODIFY_PRODUCTS = createRequestTypes('PREORDERS/UPDATE_AFTER_MODIFY_PRODUCTS');
export const SHOW_OFFICE_SELECTION_MODAL = createRequestTypes('PREORDERS/SHOW_OFFICE_SELECTION_MODAL');
export const SHOW_BIOMATERIAL_SELECTION_MODAL = createRequestTypes('PREORDERS/SHOW_BIOMATERIAL_SELECTION_MODAL');
export const REMOVE_INVALID_PRODUCTS = createRequestTypes('PREORDERS/REMOVE_INVALID_PRODUCTS');
export const SET_BIOMATERIALS = createRequestTypes('PREORDERS/SET_BIOMATERIALS');
export const CREATE = createRequestTypes('PREORDERS/CREATE');
export const CANCEL = createRequestTypes('PREORDERS/CANCEL');
export const REPEAT = createRequestTypes('PREORDERS/REPEAT');
export const GET_STATUS = createRequestTypes('PREORDERS/GET_STATUS');
export const FETCH_RESULTS = createRequestTypes('PREORDERS/FETCH_RESULTS');
export const FETCH_PREORDERS_HISTORY = createRequestTypes('PREORDERS/FETCH_PREORDERS_HISTORY');
export const DOWNLOAD_PREORDER = createRequestTypes('PREORDERS/DOWNLOAD_PREORDER');
export const GET_INFO_PRODUCT = createRequestTypes('PREORDERS/GET_INFO_PRODUCT');
