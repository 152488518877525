import React, { useEffect } from 'react';
import './webflow.css';
import './invitro.webflow.css';
import doc from './images/doc.svg';
import video from './images/video.svg';
import close from './images/close.svg';
import burger from './images/burger.svg';
import link from './images/link.svg';
import time from './images/time.svg';
import upArr from './images/upArr.svg';
import mainIcon from './images/mainIcon.svg';

const TrainingCourse = () => {
    useEffect(() => {
        if (!document.getElementById('webflow')) {
            const jquery = document.createElement('script');
            jquery.src = `/js/jquery-3.5.1.min.js`;
            jquery.id = 'jquery';
            jquery.type = 'text/javascript';
            document.body.appendChild(jquery);

            const webflow = document.createElement('script');
            webflow.src = `/js/webflow.js`;
            webflow.id = 'webflow';
            webflow.type = 'text/javascript';
            document.body.appendChild(webflow);
        }

        return () => {
            document.getElementById('webflow')?.remove();
            document.getElementById('jquery')?.remove();
        };
    }, []);

    return (
        <>
            <div className="div-block menu_block">
                <div className="w-layout-vflex content_menu">
                    <a data-w-id="7e0cd3b7-dff0-af7a-fcce-62428dd78238" href="#01" className="link-block-3 w-inline-block">
                        <h2 className="heading-2 link_menu">Лаборатория</h2>
                    </a>
                    <a data-w-id="dd8ec0cf-690b-c114-41ad-e7b2425fea4d" href="#02" className="link-block-4 w-inline-block">
                        <h2 className="heading-2 link_menu">Расходный материал</h2>
                    </a>
                    <a data-w-id="cdd5a2a1-e4c3-99a6-22fa-4fdfc97cba46" href="#03" className="link-block-5 w-inline-block">
                        <h2 className="heading-2 link_menu">Центрифуга</h2>
                    </a>
                    <a data-w-id="0bfc2694-7afb-825b-230f-9526c9e77f03" href="#04" className="link-block-6 w-inline-block">
                        <h2 className="heading-2 link_menu">Ограничения приема биоматериала лаборатории МСК</h2>
                    </a>
                    <a data-w-id="019dbe35-34d6-f8ba-220c-014219587f64" href="#05" className="link-block-7 w-inline-block">
                        <h2 className="heading-2 link_menu">Перечень зависимых продуктов в прайсе Инвитро</h2>
                    </a>
                    <a data-w-id="9b0c0ff9-4273-b2b8-db36-d3f5e474a361" href="#06" className="link-block-8 w-inline-block">
                        <h2 className="heading-2 link_menu">Бесплатное выполнение исследований для пациентов</h2>
                    </a>
                    <a data-w-id="0160cd9d-9456-fcc4-af0a-544e9826c832" href="#07" className="link-block-9 w-inline-block">
                        <h2 className="heading-2 link_menu">Взятие крови</h2>
                    </a>
                    <a data-w-id="2bc180f1-4fd7-6f98-a303-9c7dc9591206" href="#08" className="link-block-10 w-inline-block">
                        <h2 className="heading-2 link_menu">Бланки и правила заполнения</h2>
                    </a>
                    <a data-w-id="f4174193-327f-057f-7b74-2cdc7ab36493" href="#09" className="link-block-11 w-inline-block">
                        <h2 className="heading-2 link_menu">Работа в АРМПС</h2>
                    </a>
                    <a data-w-id="e717c388-0886-f0b7-a766-6c3d149a5c1e" href="#10" className="link-block-12 w-inline-block">
                        <h2 className="heading-2 link_menu">Перечень исследований выполняемых по Cito и правила их оформления</h2>
                    </a>
                    <a data-w-id="47b229f1-6355-25a1-0485-75d50b89e3a0" href="#11" className="link-block-13 w-inline-block">
                        <h2 className="heading-2 link_menu">Подготовка биоматериала для курьера</h2>
                    </a>
                    <a data-w-id="215c9974-f859-4b8a-028b-e2340002f9bd" href="#12" className="link-block-14 w-inline-block">
                        <h2 className="heading-2 link_menu">Сроки хранения биоматериала ЛГЦ и правила возврата</h2>
                    </a>
                    <a data-w-id="647e6615-a92f-2efa-cbc6-e464dc8b9c2c" href="#13" className="link-block-15 w-inline-block">
                        <h2 className="heading-2 link_menu">Инструкции по взятию биоматериала для пациентов и сотрудников лечебного учреждения</h2>
                    </a>
                    <a data-w-id="8df2e167-e143-88c2-9576-2a70e533a6e8" href="#Useful-links" className="link-block-16 w-inline-block">
                        <h2 className="heading-2 link_menu">Полезные ссылки</h2>
                    </a>
                </div>
                <img src={close} data-w-id="92f73a51-11a8-c4b4-9230-6640b2ed305d" alt="" className="image" />
            </div>
            <div id="Main" className="w-layout-blockcontainer container w-container">
                <div className="w-layout-vflex flex-block-9">
                    <div id="Header" className="w-layout-hflex flex-block">
                        <a href="/" aria-current="page" className="link-block w-inline-block"></a>
                        <img src={burger} loading="lazy" data-w-id="75e8fab7-25d7-810c-6c3a-3d8708e3e812" alt="" className="image-2" />
                    </div>
                    <div className="w-layout-hflex flex-block-7">
                        <div className="w-layout-vflex flex-block-6">
                            <h1 className="heading">Обучающий курс для корпоративных клиентов</h1>
                            <p className="paragraph">
                                Мы разработали обучающий курс специально для наших корпоративных клиентов. Это обучение поможет повысить эффективность работы,
                                оптимизировать процессы и сократить сроки адаптации.
                            </p>
                        </div>
                        <img src={mainIcon} alt="" />
                    </div>

                    <div className="line"></div>
                    <div className="w-layout-hflex flex-block-2">
                        <p className="paragraph">
                            Курс состоит из модулей, каждый из которых направлен на развитие определённых навыков и компетенций. <br />
                            <br />
                            Мы уверены, что это обучение станет для вас отличным стартом на пути к сотрудничеству с нашей компанией. В ходе обучения вы получите
                            уникальные знания.
                        </p>
                        <p className="paragraph">
                            Желаем вам удачи и успехов в обучении!
                            <br />
                            <br />
                            Если у вас возникнут вопросы или потребуется дополнительная информация, пожалуйста, обращайтесь к курирующему сотруднику ИНВИТРО. Он
                            будет рад ответить на все ваши вопросы.
                        </p>
                    </div>
                    <div id="01" className="line"></div>
                </div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Лаборатория</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">01</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4e2bf3dc-31f7-11ef-a274-8e1f54c5cf80"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">ИНВИТРО – это качество</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/38b9292a-31f7-11ef-a331-b65631f0283e"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Виртуальная экскурсия по лаборатории</p>
                        </a>
                    </div>
                </div>
                <div id="02" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Расходный материал</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">02</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/40b81cb2-3ec7-11ef-818c-462c0dfbf551"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Инструкция по хранению чистых расходных материалов</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/464bccdc-3ec7-11ef-b971-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Расходный материал и его применение</p>
                        </a>
                    </div>
                </div>
                <div id="03" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Центрифуга</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">03</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/31d8fd7e-3ec7-11ef-8996-3a05e7106c8f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Время до центрифугирования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/33e76416-3ec7-11ef-baf7-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Настройка стандартного режима центрифугирования для разных типов центрифуг</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/38c66fcc-3ec7-11ef-8dca-8e21ce489f88"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила расположения пробирок в центрифуге</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3962bd28-3ec7-11ef-bafc-de5f4c946423"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Руководство пользователя центрифуга ELMI CV 6M</p>
                        </a>
                    </div>
                </div>
                <div id="04" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Ограничения приема биоматериала лаборатории МСК</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">04</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3e4f6138-3ec7-11ef-b404-6aafa94a39f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Открыть PDF</p>
                        </a>
                    </div>
                </div>
                <div id="05" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Перечень зависимых продуктов в прайсе Инвитро</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">05</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4402423a-3ec7-11ef-bc2c-de5f4c946423"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Открыть PDF</p>
                        </a>
                    </div>
                </div>
                <div id="06" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Бесплатное выполнение исследований для пациентов</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">06</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3da156ba-3ec7-11ef-97e1-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Открыть PDF</p>
                        </a>
                    </div>
                </div>
                <div id="07" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Взятие крови</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">07</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/448cec5a-3ec7-11ef-bd1a-6e72af04663d"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Подготовка к исследованиям крови</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/368182ea-4e4b-11ef-bafe-e23a15cf044a"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Почему анализ крови их вены лучше, чем их пальца?</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4534496e-3ec7-11ef-aa28-6e72af04663d"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Последовательность взятия крови</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4008f480-3ec7-11ef-ac6d-462c0dfbf551"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Инструкция по сбору вакуумной системы для взятия венозной крови</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/45c5c68c-3ec7-11ef-aa6f-6e72af04663d"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила переноса сыворотки из первичной пробирки во вторичную</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4291fe0e-3ec7-11ef-8ff1-eeaee05fad8b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Особенности взятия крови у детей (максимальный возможный объем взятия крови в зависимости от веса)</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3f49c9de-3ec7-11ef-95d3-eeaee05fad8b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Инструкция по взятию капиллярной крови</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/b38ec9b8-31f0-11ef-aa82-1a17ce22477b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Взятие капиллярной крови</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/43445784-3ec7-11ef-8f9b-5e465c650965"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Памятка по работе с вакуумными пробирками</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4154fc9e-3ec7-11ef-adfb-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Наиболее частые ошибки на преаналитическом этапе</p>
                        </a>
                    </div>
                </div>
                <div id="08" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Бланки и правила заполнения</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">08</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a href="https://www.invitro.ru/doctors/directed_forms/" target="_blank" className="link-block-2 w-inline-block">
                            <img src={link} loading="lazy" alt="" />
                            <p className="paragraph-3">Все бланки доступны для скачивания здесь</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/f16b2272-3ec5-11ef-9741-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Как понять бланк?</p>
                        </a>
                        <p className="paragraph-3 orange">Образцы заполненных бланков:</p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/f1f27fb0-3ec5-11ef-ac46-eeaee05fad8b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Основной бланк</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/e6eb3756-3ec5-11ef-81fc-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Гистологические исследования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/e8e3f124-3ec5-11ef-b2ac-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Микробиологические исследования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/ec881b7a-3ec5-11ef-ada0-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">ПЦР-диагностика инфекционных заболеваний</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/ed658230-3ec5-11ef-af7c-3a05e7106c8f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">PRISKA</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/eecc360a-3ec5-11ef-997f-de5f4c946423"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Цитология ThinPrep</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/efea9220-3ec5-11ef-870f-42c69bd41cf7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">ВИЧ. Требования к заполнению направительного бланка</p>
                        </a>
                    </div>
                </div>
                <div id="09" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Работа в АРМПС</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">09</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <p className="paragraph-3 orange">Принтер печати штрихкодов</p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/94d1b56c-3394-11ef-99b2-a62a6a5017a4"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Обслуживание принтера штрихкодирования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/95a33b14-3394-11ef-b21d-4a5c9b83e961"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Руководство пользователя Zebra ZD410</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/a0ff7216-3394-11ef-897d-c6ef18ef706f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Руководство пользователя Баркодник GoDEX</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/a24ee7dc-3394-11ef-a64c-c6ef18ef706f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Руководство пользователя Zebra LP 2824 Plus</p>
                        </a>
                        <p className="paragraph-3 orange">Оформление биоматериала в АРМПС/возврат/дозаказ/CITO</p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/7932ee16-3394-11ef-b301-06c3c13fa700"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Краткая инструкция</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/54fadd56-3394-11ef-9ed8-b6ec4d3c42d7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Пошаговая инструкция</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/65c200d2-31ec-11ef-bde4-d2c40ad80020"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Особенности заполнения медицинской информации при оформлении сложных исследований</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/80f6a674-3394-11ef-b2b3-c6ef18ef706f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Алгоритм при оформлении дозаказа</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/8198d02a-3394-11ef-abf3-06c3c13fa700"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Особенности возврата/удаления ИНЗ</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3996420c-31ec-11ef-9959-5a6cfd254589"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Дополнительные возможности</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/847eec06-31ec-11ef-94eb-1a17ce22477b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Особенности оформления ВИЧ и Ковид в АРМПС</p>
                        </a>
                        <p className="paragraph-3 orange">
                            <strong>Маркировка биоматериала</strong>
                        </p>
                        <p data-w-id="1e483d76-b820-be27-26b5-a63dc2b8c01f" className="paragraph-3 bold">
                            Общие правила:
                        </p>
                        <p data-w-id="144ff999-9d49-61a8-4842-eceb2681cccf" className="paragraph-3">
                            Пробирки, емкости и стекла с материалом в обязательном порядке маркируют: указывают Ф.И.О. пациента, номер теста, место взятия
                            материала. Маркировка должна быть идентична данным на направительном бланке. Также на бланке должны быть заполнены все
                            информационные поля.
                        </p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/e66da404-4e4b-11ef-aece-4aa06efe5308"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Что обозначает штрихкод?</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/8fa056a8-4e4b-11ef-a343-7e9bb8d9c05e"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Инструкция по штрихкодированию</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/9544ae10-3ec4-11ef-b74b-6aafa94a39f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Маркировка материала на стекле</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/74cff036-4e4b-11ef-bd68-c2a7f7641159"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Маркировка биоматерила: мазки</p>
                        </a>
                    </div>
                </div>
                <div id="10" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <div className="w-layout-vflex">
                            <h2 className="heading-2">Перечень исследований выполняемых по Cito и правила их оформления</h2>
                            <h2 className="heading-2 gray">(наличие услуги необходимо уточнять у курирующего сотрудника Инвитро)</h2>
                        </div>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">10</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3aa39964-3ec7-11ef-af7f-42c69bd41cf7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Открыть PDF</p>
                        </a>
                    </div>
                </div>
                <div id="11" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Подготовка биоматериала для курьера</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">11</p>
                        </div>
                    </div>
                    <p data-w-id="ebdbd8fb-cffb-c4c4-a883-7ec9c2ecdbc8" className="paragraph-3">
                        Биоматериал помещают в пакеты в соответствии с температурным режимом: отдельно пакет на -17…-23 °C, отдельно пакеты (№1, 2, 3, 4) на
                        +2…+8 °C.
                    </p>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/2f997110-3ec7-11ef-8f7f-6e72af04663d"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Сортировка пакетов перед отправкой</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/2c47ffb8-3ec7-11ef-8c74-5e465c650965"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Инструкция по упаковке и отправке биоматериала</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/2df5dc18-3ec7-11ef-bc93-6ad3a19b53fa"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Сопроводительная накладная +2…+8 °C</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/2f0c6414-3ec7-11ef-a4aa-42c69bd41cf7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Сопроводительная накладная -17…-23 °C</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/2ae6b34e-3ec7-11ef-a245-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Бланки в АРХИВ (для АРМПС)</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/2b945378-3ec7-11ef-9635-462c0dfbf551"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Бланки в ЛАБОРАТОРИЮ (для АРМПС)</p>
                        </a>
                    </div>
                </div>
                <div id="12" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Сроки хранения биомила возврата</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">12</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4b628c26-3ec5-11ef-8c81-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Памятка по гистологическим исследованиям</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3ecb073e-3ec7-11ef-9b43-5e465c650965"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Заявление на возврат препаратов по гистологии</p>
                        </a>
                    </div>
                </div>
                <div id="13" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Инструкции по взятию биоматериала для пациентов и сотрудников лечебного учреждения</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">13</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <p className="paragraph-3 orange purple">
                            <strong>Для пациентов</strong>
                        </p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cacef512-3394-11ef-aec0-9af6a039a485"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Эякулят. Микроскопия</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cf38468a-3394-11ef-8473-aeb6294a336b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Клещи. Сбор биоматериала</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d7e9d9b0-3394-11ef-aaf2-fa97d56c0df8"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Мокрота. Микробиология, ПЦР</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d85ae3f8-3394-11ef-bfe9-4a5c9b83e961"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Молоко. Посев</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d90b8488-3394-11ef-b493-8ef7706583ae"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Ногти: микроэдементы</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d9a68ece-3394-11ef-aa34-9af6a039a485"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Пробы выдыхаемого воздуха. 13С-уреазный дыхательный тест</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/da1e39a6-3394-11ef-ad13-b67f5a9a4d7b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Пробы выдыхаемого воздуха. СИБР, подготовка</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/dbc858cc-3394-11ef-a10b-b6ec4d3c42d7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Соскоб эпителиальных клеток ротоглотки и носоглотки в микропробирки с транспортным раствором</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/dcaea7be-3394-11ef-ae44-fa97d56c0df8"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Соскоб. Энтеробиоз (яйца остриц)</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/dd3aba06-3394-11ef-8aec-0a5801e18a33"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Волосы. Микроэлементы</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/ddc5a972-3394-11ef-8a9b-9af6a039a485"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Памятка для пациента по проведению ГГТ</p>
                        </a>
                        <p className="paragraph-3 orange">
                            <strong>Кал:</strong>
                        </p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cb467a1a-3394-11ef-a645-a62a6a5017a4"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Биохимические исследования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cbea1be8-3394-11ef-b2a9-b6ec4d3c42d7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Дисбактериоз кишечника</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cc83a1aa-3394-11ef-99fc-4a5c9b83e961"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Инструкция для ПЦР-исследования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cd1105cc-3394-11ef-87db-0a5801e18a33"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Клинические исследования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cdf40714-3394-11ef-8362-d223982e2cd7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Посев транспортная среда</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/ce8cb676-3394-11ef-a6ee-daace2ab94d9"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Яйца гельминтов, простейшие</p>
                        </a>
                        <p className="paragraph-3 orange">
                            <strong>Моча:</strong>
                        </p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/cfcb2c34-3394-11ef-9fef-d223982e2cd7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Разовая или суточная</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d0934b56-3394-11ef-ab27-0a5801e18a33"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Разовая или суточная: микроэлементы в моче</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d1815a9e-3394-11ef-aa7e-daace2ab94d9"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Разовая: анализ мочи общий с микроскопией осадка</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d20af074-3394-11ef-a542-fa97d56c0df8"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Разовая. Цитология, ПЦР</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d2947cb8-3394-11ef-8806-a62a6a5017a4"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Разовая. Исследование мочи по методу Нечипоренко</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d314a1c2-3394-11ef-83dd-b6ec4d3c42d7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Разовая</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d4163cac-3394-11ef-a19a-daace2ab94d9"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Суточная</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d565ecba-3394-11ef-92a3-daace2ab94d9"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Антигенные тесты</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d5e3973c-3394-11ef-bdf9-d223982e2cd7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Посев на флору и дрожжевые грибы: взятие борная кислота</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d6607982-3394-11ef-8c54-06c3c13fa700"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Посев на флору и дрожжевые грибы: взятие Ури-Сваб</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/d717418a-3394-11ef-82ef-4a5c9b83e961"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Посев на микоплазму и уреаплазму</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/e13d6a74-3ec3-11ef-9c27-eeaee05fad8b"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Разовая или суточная: оксалаты</p>
                        </a>
                        <p className="paragraph-3 orange">
                            <strong>Слюна:</strong>
                        </p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/daaceee4-3394-11ef-a2ae-daace2ab94d9"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Стероидный профиль</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/db43abc2-3394-11ef-ab95-a62a6a5017a4"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">ПЦР-исследования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/df7e5598-3394-11ef-9934-06c3c13fa700"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Определение кортизола (Исследование №1508)</p>
                        </a>
                        <p className="paragraph-3 orange purple">
                            <strong>Для персонала</strong>
                        </p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/04513cc0-3ec4-11ef-9002-6ad3a19b53fa"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Абортивный материал: кариотип плода</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/050902ba-3ec4-11ef-b769-3a05e7106c8f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Абортивный материал: ХМА</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/05aa1394-3ec4-11ef-b858-6e72af04663d"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Эякулят: ПЦР-исследования, посев</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/064784ee-3ec4-11ef-a945-6e72af04663d"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Инструкция по взятию MyGenetics</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/07057c74-3ec4-11ef-9c76-42c69bd41cf7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Кал: антигенные тесты</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/07908756-3ec4-11ef-9b35-3a05e7106c8f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Капиллярная кровь: анализ спектра аминокислот и ацилкарнитинов</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/084c4b26-3ec4-11ef-b587-6aafa94a39f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Кожа, ногти, волосы: дермотофиты ПЦР</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/095676fe-3ec4-11ef-a593-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Кожа, ногти: микроскопия и посев на паразитарные грибы</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/09f6e76a-3ec4-11ef-9d3a-8e21ce489f88"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Кровь: посев на стерильность (флаконы Bactec)</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0ab07b9e-3ec4-11ef-a239-62cf7045d9de"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Кровь: посев на стерильность (флаконы Yukona)</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0b57a32e-3ec4-11ef-84ed-5e465c650965"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Материал на пренатальный анализ</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/69d3bb90-3ec4-11ef-a8f2-3a05e7106c8f"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Мазок со слизистой ротоглотки и носоглотки</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/6a9a9940-3ec4-11ef-bfe2-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Почечный камень: анализ химического состава мочевых камней методом рентгеноструктурного анализа</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/6b10a5e0-3ec4-11ef-8c5f-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Почечный камень: анализ химического состава мочевых камней методом инфракрасной спектрометрии</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/6b8a5ae8-3ec4-11ef-b60c-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Пробы выдыхаемого воздуха: 13С-уреазный дыхательный тест</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0bea3806-3ec4-11ef-9d7c-62cf7045d9de"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Пробы выдыхаемого воздуха: СИБР</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0cd6ac86-3ec4-11ef-8413-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Секрет простаты: ПЦР-исследования, посев</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0d642ac0-3ec4-11ef-bf3b-fe63a92de9f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Соскоб эпителиальных клеток ротоглотки и носоглотки в микропробирку с транспортным раствором</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0de8ffc0-3ec4-11ef-886a-42c69bd41cf7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Соскоб кожи: ПЦР-исследования</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0e9a1ab2-3ec4-11ef-8926-6aafa94a39f3"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Соскоб шейки матки: жидкостная цитология (NovaPrep)</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0f4f2eca-3ec4-11ef-b864-462c0dfbf551"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Соскоб шейки матки: жидкостная цитология (ThinPrep)</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/0ffeb5de-3ec4-11ef-92cb-de5f4c946423"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Желчный камень: рентгенофазовый анализ</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/1522b808-3ec4-11ef-95dd-62cf7045d9de"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Плазма крови: гастропанель</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/10aa60f0-3ec4-11ef-bf7c-de5f4c946423"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Глюкозотолерантный тест</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/1198c8e4-3ec4-11ef-8683-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Порядок группы ИНВИТРО по взятию проб биологического материала неинвазивными методами</p>
                        </a>
                        <p className="paragraph-3 orange">
                            <strong>Важно!</strong>
                        </p>
                        <p data-w-id="ce5fdeab-3918-b83b-5b4f-314de5dd066a" className="paragraph-3">
                            Приложение №2 (преаналитические требования) к договору оказания лабораторных услуг предоставляются курирующим сотрудником ИНВИТРО.
                        </p>
                        <p className="paragraph-3 orange purple">
                            <strong>Дополнения</strong>
                        </p>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/18d060a0-45b2-11ef-9c3d-962dfffbeadf"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила взятия биоматериала для микроскопии у женщин</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/27461346-45b2-11ef-8469-3e4de349c391"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила взятия биоматериала для микроскопии у мужчин</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3dd9dbee-3ec6-11ef-a013-462c0dfbf551"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила взятия капиллярной крови</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/54c13fc8-3ec6-11ef-aa0c-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила взятия общеклинических исследований</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/2e33da80-45b2-11ef-823a-425569bc8676"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила одновременного взятия микроскопии и посева у мужчин</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/341f364c-45b2-11ef-8ff2-962dfffbeadf"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={video} loading="lazy" alt="" />
                            <p className="paragraph-3">Правила сбора мочи</p>
                        </a>
                    </div>
                </div>
                <div id="Useful-links" className="line"></div>
                <div className="w-layout-vflex select_block">
                    <div className="w-layout-hflex flex-block-3">
                        <h2 className="heading-2">Полезные ссылки</h2>
                        <div className="w-layout-hflex flex-block-4">
                            <p className="paragraph-2">14</p>
                        </div>
                    </div>
                    <div className="w-layout-vflex content_block">
                        <a href="https://www.invitro.ru" target="_blank" className="link-block-2 w-inline-block">
                            <img src={link} loading="lazy" alt="" />
                            <p className="paragraph-3">Сайт ИНВИТРО</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/420eee38-3ec7-11ef-b33b-5e465c650965"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Ограничения в референсных значениях</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/276dc1aa-3ec5-11ef-8d4f-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Методичка по микробиологии</p>
                        </a>
                        <a href="#" target="_blank" className="link-block-2 w-inline-block">
                            <img src={time} loading="lazy" alt="" />
                            <p className="paragraph-3">Методичка по микробиологии обновленная – в работе</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3d7c9566-3ec5-11ef-beb4-42c69bd41cf7"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Методические рекомендации цитологии</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/3cc14d9c-3ec5-11ef-b4bb-6e72af04663d"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Цитология ThinPrep</p>
                        </a>
                        <a
                            href="https://new-acc-space-15805.ispring.ru/app/preview/4b628c26-3ec5-11ef-8c81-c6b754f08365"
                            target="_blank"
                            className="link-block-2 w-inline-block"
                        >
                            <img src={doc} loading="lazy" alt="" />
                            <p className="paragraph-3">Памятка по гистологическим исследованиям</p>
                        </a>
                    </div>
                </div>
                <a href="#Main" className="w-inline-block">
                    <div className="w-layout-vflex flex-block-8">
                        <img src={upArr} loading="lazy" alt="" />
                    </div>
                </a>
            </div>
            <a href="#Main" className="link-block-17 w-inline-block">
                <div className="w-layout-vflex flex-block-8 mini">
                    <img src={upArr} loading="lazy" alt="" />
                </div>
            </a>
        </>
    );
};

export default TrainingCourse;
