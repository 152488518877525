import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ACTIONS } from 'redux/app/actions';
import { FETCH_ALL, FETCH_RESULT_DETAILS, GET_RESULT_DETAILS_FILE, SEND_BY_EMAIL } from 'redux/results/actions';
import styles from './ResultDetailsPage.module.css';
import DocumentViewer from 'components/viewer/DocumentViewer';
import resultsDynamicsIcon from 'themes/icons/resultsDynamics.svg';
import resultsHistoryIcon from 'themes/icons/resultsHistory.svg';
import sendResultsNoticeIcon from 'themes/icons/checkMark.svg';
import messageIcon from 'themes/icons/message.svg';
import resultsIcon from 'themes/icons/researchResults.svg';
import { AppTypes } from 'types/AppTypes';
import { actionsSelector } from 'redux/app/selectors';
import Preloader from 'components/preloader/Preloader';
import { formatDate, parseISO } from 'utils/timeUtils';
import ResultList from 'components/list/ResultList';
import ReactTooltip from 'react-tooltip';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { ResultsTypes } from 'types/ResultsTypes';
import PatientCard from 'components/card/PatientCard';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { getHiddenEmail } from 'utils/messageUtils';
import Notification from 'components/notification/Notification';
import isEmail from 'validator/lib/isEmail';
import { useTranslation } from 'react-i18next';
import arrowLeft from 'themes/icons/arrow.svg';
import Icon from 'components/icon/Icon';
import IsInvitro from 'themes/invitro/IsInvitro';
import IsVetunion from 'themes/vetunion/IsVetunion';
import VetunionPatientCard from 'components/card/VetunionPatientCard';
import TableResults from 'components/table/TableResults';
const queryString = require('query-string');
type TFile = {
    id: number;
    printedType: string;
    language: string;
    hasLogo: boolean;
};

const ResultDetailsPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [patientEmail, setPatientEmail] = useState<null | string>();
    const [attacheHistory, setAttacheHistory] = useState(false);
    const [filesWithLogo, setFilesWithLogo] = useState(false);
    const [filesWithoutLogo, setFilesWithoutLogo] = useState(false);
    const dispatch = useDispatch();
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const { resultDetails } = useSelector((state: AppTypes.State) => state.results);
    const { results } = useSelector((state: AppTypes.State) => state.results);
    const loading = useSelector(actionsSelector)[FETCH_RESULT_DETAILS.BASE]?.loading;
    const actions = useSelector(actionsSelector);
    const location: any = useLocation();
    const { t } = useTranslation('common');
    const parsedLocationString = queryString.parse(location.search);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(FETCH_ALL.base({ serverSideFilter: { nss: parsedLocationString.nss } }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, parsedLocationString.nss]);

    useEffect(() => {
        const countFilesWithLogo = resultDetails?.files?.filter((file: TFile) => file.hasLogo);
        const countFilesWithoutLogo = resultDetails?.files?.filter((file: TFile) => !file.hasLogo);

        if (countFilesWithLogo?.length > 0) {
            setFilesWithLogo(true);
        }

        if (countFilesWithoutLogo?.length > 0) {
            setFilesWithoutLogo(true);
        }

        if (resultDetails?.patient?.email) {
            const parsePatientEmail = resultDetails?.patient?.email.split(';')[0] || resultDetails?.patient?.email;
            setPatientEmail(parsePatientEmail);
            setEmail(parsePatientEmail);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultDetails]);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(
                FETCH_RESULT_DETAILS.base({
                    inz: parsedLocationString.inz,
                    nss: parsedLocationString.nss
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup, parsedLocationString.inz, parsedLocationString.nss]);

    useEffect(() => {
        if (actions[SEND_BY_EMAIL.BASE]?.success) {
            setEmail('');
            setAttacheHistory(false);
            setTimeout(
                () =>
                    dispatch({
                        type: SET_ACTIONS,
                        meta: {
                            [SEND_BY_EMAIL.BASE]: null
                        }
                    }),
                5000
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions]);

    const renderDynamicsLink = () => {
        const resultDynamicsCodes: string[] = [];
        resultDetails?.testResults?.forEach((test: ResultsTypes.TypeTestResult) => {
            resultDynamicsCodes.push(test.code);
        });
        return resultDynamicsCodes;
    };

    const renderCriticalTest = () => {
        const criticalTests: string[] = [];
        resultDetails?.testResults?.forEach((test: ResultsTypes.TypeTestResult) => {
            test.outbound && criticalTests.push(test.shortName);
        });
        if (criticalTests.length > 0) {
            return (
                <div className={styles.criticalTest}>
                    <span className={styles.referenceMarker} data-for={'resultDetails'} data-tip={t(`Common.resultsBeyondRefValues`)}>
                        !
                    </span>
                    <div className={styles.criticalTestContent}>
                        {t(`Common.resultsBeyondRefValues`)}: «{criticalTests.join(', ')}»
                    </div>
                </div>
            );
        }
    };

    const renderCheckboxLogo = () => {
        const countFilesWithLogo = resultDetails?.files?.filter((file: TFile) => file.hasLogo);
        const countFilesWithoutLogo = resultDetails?.files?.filter((file: TFile) => !file.hasLogo);

        return (
            <div className={styles.documentsLogo}>
                {countFilesWithLogo?.length > 0 && countFilesWithoutLogo?.length > 0 && (
                    <Fragment>
                        <div>
                            <input type="checkbox" checked={filesWithLogo} onChange={() => setFilesWithLogo(!filesWithLogo)} />
                            <label>{t(`ResultDetailsPage.withLogo`)}</label>
                        </div>
                        <div>
                            <input type="checkbox" checked={filesWithoutLogo} onChange={() => setFilesWithoutLogo(!filesWithoutLogo)} />
                            <label>{t(`ResultDetailsPage.withoutLogo`)}</label>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <IsAvailable options={[{ results: true }]}>
                <Access
                    permissions={[PermissionType.RESULTS]}
                    message={t(`Common.noAccess`)}
                    className={styles.messagePermissions}
                    errors={[actions[FETCH_RESULT_DETAILS.BASE]?.error]}
                >
                    {loading && (
                        <div className={styles.resultContentBlock}>
                            <Preloader />
                        </div>
                    )}
                    {resultDetails && !loading && (
                        <Fragment>
                            <div className={styles.breadcrumbs}>
                                <Breadcrumbs
                                    links={[
                                        {
                                            link: '/results',
                                            name: t(`Common.results`),
                                            active: false
                                        },
                                        {
                                            link: '/results',
                                            name: `${t(`Common.inz`)} ${parsedLocationString.inz}`,
                                            active: true
                                        }
                                    ]}
                                />
                            </div>
                        </Fragment>
                    )}
                    {resultDetails && !loading && actions[FETCH_RESULT_DETAILS.BASE]?.error && (
                        <Fragment>
                            <div className={styles.resultContentBlock}>
                                <Notification type={'error'}>
                                    <p className={styles.notice}>{actions[FETCH_RESULT_DETAILS.BASE]?.error?.message}</p>
                                </Notification>
                            </div>
                        </Fragment>
                    )}
                    {resultDetails && !loading && !actions[FETCH_RESULT_DETAILS.BASE]?.error && (
                        <Fragment>
                            <h1 className={styles.title}>
                                {t(`Common.inz`)} {resultDetails.inz} {t(`ResultDetailsPage.from`)}{' '}
                                {resultDetails.createdTime && formatDate(parseISO(resultDetails.createdTime), 'ru')}
                            </h1>
                            <div className={styles.patientCard}>
                                <IsInvitro>
                                    <PatientCard patient={resultDetails.patient} officeName={resultDetails?.officeFullName} />
                                </IsInvitro>
                                <IsVetunion>
                                    <VetunionPatientCard patient={resultDetails.patient} officeName={resultDetails?.officeFullName} />
                                </IsVetunion>
                            </div>
                            {renderCriticalTest()}
                            <div className={styles.resultBlocks}>
                                <div className={styles.resultContentBlock}>
                                    <div className={styles.contentBlockHead}>
                                        <Icon width={'25px'} height={'30px'} icon={`${resultsIcon}#researchResults`} className={styles.blockTitleIcon} />
                                        <h2 className={styles.blockTitleResultFiles}>{t(`Common.researchResults`)}</h2>
                                    </div>
                                    <div className={styles.resultFiles}>
                                        <div className={styles.resultFilesHead}>{actions[GET_RESULT_DETAILS_FILE.BASE]?.loading && <Preloader />}</div>
                                        {renderCheckboxLogo()}
                                        <div className={styles.resultFilesContent}>
                                            {resultDetails?.files?.map((file: TFile, i: number) => {
                                                if ((filesWithLogo && file.hasLogo) || (filesWithoutLogo && !file.hasLogo)) {
                                                    return <DocumentViewer key={i} inz={resultDetails.inz} nss={resultDetails.patient.nss} file={file} />;
                                                } else return false;
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {resultDetails?.files?.length > 0 && (
                                    <div className={styles.resultContentBlock}>
                                        <div className={styles.contentBlockHead}>
                                            <Icon height={'32px'} width={'26px'} icon={`${messageIcon}#message`} className={styles.blockTitleIcon} />
                                            <h2 className={styles.blockTitle}>{t(`ResultDetailsPage.sendResults`)}</h2>
                                        </div>
                                        <div className={styles.sendResultsBlock}>
                                            <input
                                                placeholder={getHiddenEmail(email)}
                                                value={email !== patientEmail ? email : undefined}
                                                type="text"
                                                name="email"
                                                onChange={(event) =>
                                                    setEmail(event.target.value?.length < 1 && patientEmail ? patientEmail : event.target.value)
                                                }
                                                className={styles.sendResultInput}
                                            />
                                            <div className={styles.sendResultsCheckbox}>
                                                <input
                                                    checked={attacheHistory}
                                                    onChange={() => setAttacheHistory(!attacheHistory)}
                                                    type="checkbox"
                                                    name="sendAll"
                                                />
                                                <label>{t(`ResultDetailsPage.attachHistory`)}</label>
                                            </div>
                                            {actions[SEND_BY_EMAIL.BASE]?.success && (
                                                <div className={styles.sendResultsNotice}>
                                                    <Icon
                                                        height={'20px'}
                                                        width={'15px'}
                                                        icon={`${sendResultsNoticeIcon}#checkMark`}
                                                        className={styles.sendResultsNoticeIcon}
                                                    />
                                                    {t(`ResultDetailsPage.resultsSent`)}
                                                </div>
                                            )}
                                            {actions[SEND_BY_EMAIL.BASE]?.loading && <Preloader />}
                                            {actions[SEND_BY_EMAIL.BASE]?.error && <p style={{ color: 'red' }}>{t(`ResultDetailsPage.errorSendingResults`)}</p>}
                                            <button
                                                className={styles.sendResultButton}
                                                disabled={!isEmail(email) || actions[SEND_BY_EMAIL.BASE]?.loading || (!filesWithLogo && !filesWithoutLogo)}
                                                onClick={() => {
                                                    dispatch(
                                                        SEND_BY_EMAIL.base({
                                                            email: email,
                                                            nss: parsedLocationString.nss,
                                                            inz: parsedLocationString.inz,
                                                            attacheHistory: attacheHistory,
                                                            files:
                                                                resultDetails?.files.filter((file: TFile) => {
                                                                    if ((filesWithLogo && file.hasLogo) || (filesWithoutLogo && !file.hasLogo)) {
                                                                        return file;
                                                                    } else return false;
                                                                }) || []
                                                        })
                                                    );
                                                }}
                                            >
                                                {t(`Common.send`)}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <TableResults results={resultDetails} />
                                <div className={styles.resultContentBlock}>
                                    <div className={styles.contentBlockHead}>
                                        <Icon
                                            width={'32px'}
                                            height={'26px'}
                                            icon={`${resultsDynamicsIcon}#resultsDynamics`}
                                            className={styles.blockTitleIcon}
                                        />
                                        <h2 className={styles.blockTitle}>{t(`ResultDetailsPage.dynamicsResults`)}</h2>
                                    </div>
                                    <Link
                                        className={styles.resultsDynamicLink}
                                        to={`/results/dynamics?nss=${parsedLocationString.nss}&codes=${renderDynamicsLink().join(',')}&inz=${
                                            parsedLocationString.inz
                                        }`}
                                    >
                                        {t(`ResultDetailsPage.viewDynamicsResults`)}
                                    </Link>
                                </div>
                                <div className={styles.resultContentBlock}>
                                    <div className={styles.contentBlockHead}>
                                        <Icon width={'36px'} height={'25px'} icon={`${resultsHistoryIcon}#resultsHistory`} className={styles.blockTitleIcon} />
                                        <h2 className={styles.blockTitle}>{t(`Common.orderHistory`)}</h2>
                                    </div>
                                    <ResultList loading={actions[FETCH_ALL.BASE]?.loading} results={results} />
                                </div>
                                <button className={styles.buttonListOrder} onClick={() => navigate('/results')}>
                                    <Icon
                                        setDefaultStyles={false}
                                        width={'9px'}
                                        height={'16px'}
                                        icon={`${arrowLeft}#arrow`}
                                        className={styles.blockTitleIcon}
                                    />
                                    <div>{t(`ResultDetailsPage.commonListOrders`)}</div>
                                </button>
                            </div>
                            {
                                // @ts-ignore
                                <ReactTooltip
                                    border={true}
                                    borderColor={'#939393'}
                                    id={'resultDetails'}
                                    resizeHide={true}
                                    html={true}
                                    type={'light'}
                                    effect={'solid'}
                                    place={'right'}
                                />
                            }
                        </Fragment>
                    )}
                </Access>
            </IsAvailable>
        </div>
    );
};

export default React.memo(ResultDetailsPage);
