import React, { Fragment, useEffect, useState } from 'react';
import styles from './PreorderList.module.css';
import { PreordersTypes } from 'types/PreordersTypes';
import { isEmpty, parseFIO } from 'utils/messageUtils';
import Icon from 'components/icon/Icon';
import hintPreorderIcon from 'themes/icons/hintPreorder.svg';
import { formatDate } from 'utils/timeUtils';
import { parseISO } from 'date-fns';
import Status from 'page/preorders/components/form/Status';
import { PREORDER_STATUS } from 'constants/PreordersStatus';
import cancelPreorderIcon from 'themes/icons/cancelPreorder.svg';
import { CANCEL, REPEAT } from 'redux/preorders/actions';
import repeatPreorderIcon from 'themes/icons/repeatPreorder.svg';
import Pagination from 'components/pagination/Pagination';
import { LIMIT_ITEMS_DEFAULT } from 'constants/Application';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import Notification from 'components/notification/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { actionsSelector } from 'redux/app/selectors';
import Preloader from 'components/preloader/Preloader';
import { PermissionType } from 'constants/PermissionType';
import Access from 'components/access/Access';
import { SET_ACTIONS } from 'redux/app/actions';

const PreorderList = ({ preorders, hasNextPage, serverSideFilter, setServerSideFilter, loading }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedPreorder, setSelectedPreorder] = useState<any>();
    const [comment, setComment] = useState<any>();
    const actions = useSelector(actionsSelector);

    useEffect(() => {
        if (actions[CANCEL.BASE]?.success) {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [CANCEL.BASE]: null
                }
            });
            setShowCancelModal(false);
            setSelectedPreorder(null);
            setServerSideFilter({ ...serverSideFilter });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[CANCEL.BASE]?.success]);

    const renderPatientTooltip = (patient?: PreordersTypes.Patient) => {
        return `
            <div class="${styles.tooltip}">
                <div>${parseFIO(patient)}</div>
                <div><span>Дата рождения: </span> ${formatDate(parseISO(patient?.birthday), 'ru') || '-'}</div>
                <div><span>Телефон: </span> ${patient?.mobilePhone || '-'}</div>
                <div><span>Эл. почта: </span> ${patient?.email || '-'}</div>
            </div>`;
    };

    return (
        <Fragment>
            {loading ? (
                <Preloader />
            ) : (
                <Fragment>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Пациент</th>
                                <th>Предзаказ</th>
                                <th>Статус</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {preorders?.map((preorder: PreordersTypes.Preorder, i: number) => {
                                const { id, patient, number, armpsStatus, createdDate } = preorder;
                                return (
                                    <tr
                                        key={i}
                                        onClick={() => {
                                            setServerSideFilter((prevState: any) => ({ ...prevState, id }));
                                            navigate(`/preorders/details?id=${id}&patientId=${patient?.id}`);
                                        }}
                                    >
                                        <td>
                                            {parseFIO(patient)}
                                            <br />
                                            <Icon
                                                data-tip={`${renderPatientTooltip(patient)}`}
                                                data-for={'preorders'}
                                                height={'18px'}
                                                width={'18px'}
                                                icon={`${hintPreorderIcon}#hintPreorder`}
                                                className={styles.icon}
                                                setDefaultStyles={false}
                                            />
                                        </td>
                                        <td>
                                            № {number}
                                            <br />
                                            {createdDate && (
                                                <div className={styles.date}>Создан: {formatDate(parseISO(createdDate), 'ru', 'dd MMMM yyyy')}</div>
                                            )}
                                        </td>
                                        <td>
                                            <Status preorder={preorder} />
                                        </td>
                                        <td className={styles.actions}>
                                            <Access permissions={[PermissionType.PREORDERS]}>
                                                {armpsStatus === PREORDER_STATUS.CREATED && (
                                                    <Icon
                                                        onClick={(event: any) => {
                                                            event.stopPropagation();
                                                            setShowCancelModal(true);
                                                            setSelectedPreorder(preorder);
                                                        }}
                                                        data-tip={`Отменить`}
                                                        data-for={'preorders'}
                                                        height={'15px'}
                                                        width={'20px'}
                                                        icon={`${cancelPreorderIcon}#cancelPreorder`}
                                                        className={styles.icon}
                                                    />
                                                )}

                                                <Icon
                                                    onClick={(event: any) => {
                                                        event.stopPropagation();
                                                        dispatch(REPEAT.base(preorder));
                                                        navigate('/preorders/create', { state: { repeat: true } });
                                                    }}
                                                    data-tip={`Повторить`}
                                                    data-for={'preorders'}
                                                    style={{ marginLeft: '10px' }}
                                                    height={'15px'}
                                                    width={'20px'}
                                                    icon={`${repeatPreorderIcon}#repeatPreorder`}
                                                    className={styles.icon}
                                                />
                                            </Access>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Pagination
                        setServerSideFilter={setServerSideFilter}
                        limitItemsPage={serverSideFilter?.limit || LIMIT_ITEMS_DEFAULT}
                        hasNextPage={hasNextPage}
                    />
                </Fragment>
            )}

            <Modal show={showCancelModal} onClose={() => setShowCancelModal(false)}>
                <div className={styles.actionModal}>
                    <h2>Отмена предзаказа № {selectedPreorder?.number}</h2>
                    <textarea
                        value={comment}
                        onChange={({ target }) => setComment(target.value)}
                        className={styles.comment}
                        placeholder={'Напишите комментарий для отмены предзаказа'}
                    />
                </div>
                {actions[CANCEL.BASE]?.error && <Notification type={'error'}>Ошибка при отмене заказа</Notification>}
                <div className={styles.actionButtons}>
                    <button
                        onClick={() => {
                            dispatch(
                                CANCEL.base({
                                    id: selectedPreorder.id,
                                    comment
                                })
                            );
                        }}
                        disabled={isEmpty(comment) || actions[CANCEL.BASE]?.loading}
                        type="button"
                    >
                        Продолжить
                    </button>
                </div>
            </Modal>
        </Fragment>
    );
};

export default React.memo(PreorderList);
