import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './messages/ru.json';

i18n.use(initReactI18next).init({
    resources: {
        ru: {
            common: ru
        }
    },
    lng: 'ru',
    fallbackLng: 'ru'
});

export { i18n };
