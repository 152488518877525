import React from 'react';
import styles from './Notification.module.css';
import classNames from 'classnames';

// @ts-ignore
const Notification = ({ children, type = 'info', className = '', ...rest }) => {
    return (
        <div {...rest} className={classNames([className, styles.container, styles[type]])}>
            {children}
        </div>
    );
};

export default React.memo(Notification);
