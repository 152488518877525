import * as React from 'react';
import styles from './StepNotice.module.css';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

const StepNotice = ({ show, notice, showNotice = true, children, showContent = true, error = true, trigger }: any) => {
    const [triggering, setTriggering] = useState(false);

    useEffect(() => {
        show && trigger && trigger?.current?.addEventListener('click', () => setTriggering(true));
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            trigger?.current?.removeEventListener('click', () => setTriggering(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggering]);

    return show ? (
        <div className={styles.container}>
            {showNotice && <div className={classNames([styles.notice, triggering && error ? styles.error : ''])}>{notice}</div>}
            <div className={classNames({ content: showContent })}>{children}</div>
        </div>
    ) : null;
};

export default React.memo(StepNotice);
