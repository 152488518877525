import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import styles from './EmployeesDetailsPage.module.css';
import { CREATE_USER_OFFICE, MODIFY_PERMISSIONS, REMOVE_USER_OFFICE } from 'redux/user/actions';
import { actionsSelector } from 'redux/app/selectors';
import { SET_ACTIONS } from 'redux/app/actions';
import Notification from 'components/notification/Notification';
import Permissions from 'page/settings/employees/components/permissions/Permissions';
import { createMarkup, getPermissions } from 'utils/messageUtils';
import Modal from 'components/modal/Modal';
import { PermissionType } from 'constants/PermissionType';
import Access from 'components/access/Access';
import { AppTypes } from 'types/AppTypes';
import { useTranslation } from 'react-i18next';

const EmployeesDetailsPage = () => {
    const location: any = useLocation();
    const [selectedPermissions, setSelectedPermissions] = useState(location?.state?.user?.permissions);
    const [showModal, setShowModal] = useState(false);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const actions = useSelector(actionsSelector);

    useEffect(() => {
        setShowModal(actions[MODIFY_PERMISSIONS.BASE]?.success || actions[CREATE_USER_OFFICE.BASE]?.success);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[MODIFY_PERMISSIONS.BASE], actions[CREATE_USER_OFFICE.BASE]]);

    useEffect(() => {
        if (actions[REMOVE_USER_OFFICE.BASE]?.success) {
            navigate('/settings/employees');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, location]);

    useEffect(() => {
        if (!location?.state?.user) {
            navigate('/settings/employees');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [REMOVE_USER_OFFICE.BASE]: null
                }
            });
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [MODIFY_PERMISSIONS.BASE]: null
                }
            });
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [CREATE_USER_OFFICE.BASE]: null
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContent = () => {
        const user: any = location?.state?.user;
        return (
            <div className={styles.settingRow}>
                <form method="POST">
                    <span className={styles.userName}>{user?.nickname}</span>
                    <div className={styles.userEmail}>{user?.email}</div>
                    <div className={styles.permissions}>
                        <Permissions permissions={getPermissions()} selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />
                    </div>
                    {actions[REMOVE_USER_OFFICE.BASE]?.error && (
                        <div>
                            <br />
                            <Notification type={'error'}>{t(`EmployeesDetailsPage.error`)}</Notification>
                        </div>
                    )}
                    <div className={styles.actions}>
                        <button
                            type="button"
                            onClick={() => {
                                if (user?.isCurrentOffice === true || user?.isCurrentOffice === undefined) {
                                    dispatch(
                                        MODIFY_PERMISSIONS.base({
                                            userId: user?.id,
                                            permissions: [...selectedPermissions]
                                        })
                                    );
                                } else {
                                    dispatch(
                                        CREATE_USER_OFFICE.base({
                                            userId: user?.id,
                                            permissions: [...selectedPermissions]
                                        })
                                    );
                                }
                            }}
                        >
                            {t(`Common.save`)}
                        </button>
                        <button onClick={() => navigate('/settings/employees')} type="button">
                            {t(`Common.cancel`)}
                        </button>
                        {(user?.isCurrentOffice === true || user?.isCurrentOffice === undefined) && (
                            <div className={styles.removeButton} onClick={() => setShowRemoveUserModal(true)}>
                                {t(`EmployeesDetailsPage.deleteEmployee`)}
                            </div>
                        )}
                    </div>
                </form>
            </div>
        );
    };

    const renderModal = () => {
        const user = location?.state?.user;

        return (
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <h2>{t(`EmployeesDetailsPage.changesSaved`)}</h2>
                <div className={styles.actionModal}>
                    <p dangerouslySetInnerHTML={createMarkup(t(`EmployeesDetailsPage.userReady`, { email: user?.email }))} />
                </div>
                <button onClick={() => navigate('/settings/employees')} type="button">
                    {t(`Common.continue`)}
                </button>
            </Modal>
        );
    };
    const renderRemoveModal = () => {
        const user = location?.state?.user;
        return (
            <Modal show={showRemoveUserModal} onClose={() => setShowRemoveUserModal(false)}>
                <div className={styles.actionModal}>
                    <p
                        style={{ fontSize: '18px' }}
                        dangerouslySetInnerHTML={createMarkup(
                            t(`EmployeesDetailsPage.notice`, { email: user?.email, clientCode: selectedOfficeOrGroup?.clientCode })
                        )}
                    />
                </div>
                <div className={styles.actionButtons}>
                    <button
                        onClick={() => {
                            dispatch(
                                REMOVE_USER_OFFICE.base({
                                    userId: user?.id
                                })
                            );
                        }}
                        type="button"
                    >
                        {t(`Common.yes`)}
                    </button>
                    <button onClick={() => setShowRemoveUserModal(false)} type="button">
                        {t(`Common.no`)}
                    </button>
                </div>
            </Modal>
        );
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`Common.staff`)}</h1>
            <Access
                officeGroup={{
                    selected: Array.isArray(selectedOfficeOrGroup?.offices),
                    message: t(`EmployeesDetailsPage.noticeOffice`)
                }}
                permissions={[PermissionType.MANAGE_USERS]}
                message={t(`Common.noAccess`)}
                errors={[actions[MODIFY_PERMISSIONS.BASE]?.error, actions[CREATE_USER_OFFICE.BASE]?.error, actions[REMOVE_USER_OFFICE.BASE]?.error]}
            >
                <div className={styles.backLink}>
                    ← <Link to={'/settings/employees'}>{t(`Common.back`)}</Link>
                </div>
                {renderContent()}
                {renderModal()}
                {renderRemoveModal()}
            </Access>
        </div>
    );
};

export default React.memo(EmployeesDetailsPage);
