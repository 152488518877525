import { createSelector } from 'reselect';
import { AppTypes } from 'types/AppTypes';
import { isEmpty } from 'utils/messageUtils';

export const officeDataSelector = (state: AppTypes.State) => state.preorders.preorder?.officeData;

export const officeCalculationSelector = (state: AppTypes.State) => state.preorders.preorder?.calculateOffice;

export const selectedPatientSelector = (state: AppTypes.State) => state.preorders.preorder?.patient;

export const preorderDataSelector = (state: AppTypes.State) => state.preorders.preorder;

export const preorderDetailsDataSelector = (state: AppTypes.State) => state.preorders.preorderDetails;

export const preordersHistoryDataSelector = (state: AppTypes.State) => state.preorders.preordersHistory;

export const allProductsSelector = (state: AppTypes.State) => state.preorders.preorder?.allProducts;

export const createPatientSelector = (state: AppTypes.State) => state.preorders.preorder?.createPatient;

const officesByIdSelector = (state: AppTypes.State) => state.preorders.preorder?.offices;

const validationDataByOfficeIdSelector = (state: AppTypes.State) => state.preorders.preorder?.validationData;

const officesFilterSelector = (state: AppTypes.State) => state.preorders.preorder?.filter;

export const chosenOfficeSelector = createSelector(
    [officesByIdSelector, validationDataByOfficeIdSelector, (state) => state.preorders.preorder?.officeId],
    (offices = [], validationData, id = '') => {
        const preorderOffice = offices[id];
        if (!preorderOffice || !validationData) {
            return null;
        }
        return {
            ...preorderOffice,
            validProductsIds: validationData[id]?.validProductsIds
        };
    }
);

export const productsByIdsSelector = createSelector([(state) => state.preorders.preorder?.products], (products) => {
    return (
        products &&
        Object?.values(products).reduce((map: any, product: any) => {
            map[product.id] = product;
            return map;
        }, {})
    );
});

export const productsIdsSelector = createSelector(
    [(state) => state.preorders.preorder?.products],
    (products) => products.filter((product: any) => product.id).map((product: any) => product.id) || []
);

export const productsByArticlesSelector = createSelector([productsByIdsSelector], (productsByIds: any) => {
    return (
        productsByIds &&
        Object?.values(productsByIds).reduce((map: any, product: any) => {
            map[product.article] = product;
            return map;
        }, {})
    );
});

// @ts-ignore
export const cartDataProductsSelector = createSelector([preorderDataSelector, productsByArticlesSelector], (preorderData: any, productByArticle: any) => {
    if (!preorderData?.products || !Array.isArray(preorderData?.products)) {
        return [];
    } else {
        return preorderData?.products.map((product: any) => {
            return { ...product, ...productByArticle[product?.article] };
        });
    }
});

export const cartDataProductsArticlesSelector = createSelector([cartDataProductsSelector], (cartDataProducts) => [
    // @ts-ignore
    ...new Set(cartDataProducts.filter((product) => product).map((product) => product.article) || [])
]);

export const cartDataProductsIdsSelector = createSelector([cartDataProductsSelector], (cartDataProducts) => [
    // @ts-ignore
    ...new Set(cartDataProducts.filter((product: any) => product.id).map((product: any) => product.id) || [])
]);

export const productsSelector = createSelector(
    [cartDataProductsSelector, chosenOfficeSelector, officeDataSelector],
    (cartDataProducts, preorderOffice, officeData) => {
        const valid = [];
        const invalid: any = [];
        if (preorderOffice) {
            if (officeData?.orderProducts && preorderOffice?.office.id === officeData.officeId) {
                const validIds = new Set();
                valid.push(
                    ...officeData.orderProducts.map((product: any) => {
                        validIds.add(product.product.id);
                        return {
                            ...toProduct(product),
                            deleted: false,
                            removable: product.removable && (!product.product.classifierData || !product.product.classifierData.service)
                        };
                    })
                );

                valid.push(...cartDataProducts.filter((product: any) => !validIds.has(product.id)));

                cartDataProducts
                    .filter((product: any) => product.id)
                    .forEach((product: any) => {
                        const convertedProduct = {
                            ...product,
                            removable: !product.classifierData || !product.classifierData.service
                        };
                        if (!preorderOffice.validProductsIds?.includes(product.id)) {
                            invalid.push(convertedProduct);
                        }
                    });
            } else {
                cartDataProducts
                    .filter((product: any) => product.id)
                    .forEach((product: any) => {
                        const convertedProduct = {
                            ...product,
                            removable: !product.classifierData || !product.classifierData.service
                        };
                        if (preorderOffice.validProductsIds?.includes(product.id)) {
                            valid.push(convertedProduct);
                        } else {
                            invalid.push(convertedProduct);
                        }
                    });
            }
        } else {
            valid.push(
                ...cartDataProducts
                    .filter((product: any) => product.id)
                    .map((product: any) => {
                        return { ...product, removable: true };
                    })
            );
        }

        return {
            valid,
            invalid
        };
    }
);

export const preorderDetailsProductsSelector = createSelector(
    [preorderDetailsDataSelector],
    (preorderDetails) =>
        preorderDetails?.products
            ?.filter((product: any) => product)
            .map((product: any) => {
                return { ...product, product };
            }) || []
);

export const cartWarningTypesSelector = createSelector(
    [officeDataSelector],
    (officeData) => new Set(officeData?.warnings?.map((warning: any) => warning.type) || [])
);

const cartValidSelector = createSelector(
    [cartDataProductsSelector, chosenOfficeSelector, officeDataSelector, cartWarningTypesSelector],
    (cartDataProducts, office, officeData) => {
        if (cartDataProducts.filter((product: any) => product).length === 0) {
            return false;
        }
        return !(!office || !office.allProductsAvailable || !officeData.orderProducts);
    }
);

export const preorderSelector = createSelector(
    [preorderDataSelector, productsSelector, cartValidSelector, chosenOfficeSelector, officeDataSelector],
    (data, productsData, valid, preorderOffice, officeData) => {
        const preorderOfficeData = preorderOffice?.office?.id === officeData?.officeId ? officeData : {};
        return {
            ...data,
            ...preorderOfficeData,
            products: productsData.valid,
            invalidProducts: productsData.invalid,
            valid
        };
    }
);

export const preorderDetailsSelector = createSelector([preorderDetailsDataSelector], (data) => {
    if (!data?.products) {
        return {
            products: []
        };
    } else {
        return {
            ...data,
            products: data.products
        };
    }
});

export const preorderBiomaterialSetsSelector = createSelector(
    [officeDataSelector, (state) => state.preorders.preorder.biomaterialModal.productsIds],
    (officeData, chosenProductsIds) => {
        if (!officeData.orderProducts) {
            return [];
        } else {
            return createBiomaterialSets(officeData.orderProducts, chosenProductsIds);
        }
    }
);

export const preorderDetailsBiomaterialSetsSelector = createSelector([(state) => state.preorders.preorderDetails.products], (products) => {
    if (!products) {
        return [];
    } else {
        let orderProducts: any = [];
        products.forEach((product: any) =>
            orderProducts.push({
                product: {
                    ...product
                },
                biomaterial: !!product?.biomaterialId
                    ? {
                          id: product?.biomaterialId,
                          name: product?.biomaterialShortName
                      }
                    : null,
                availableBiomaterials: !!product?.biomaterialId
                    ? [
                          {
                              id: product?.biomaterialId,
                              name: product?.biomaterialShortName
                          }
                      ]
                    : null,
                profileId: product?.profileId,
                removable: false,
                selected: !!product?.biomaterialId
            })
        );
        return createBiomaterialSets(orderProducts);
    }
});

export const isValidCreateFormPatientSelector = createSelector([createPatientSelector], (patient) => {
    return !isEmpty(patient?.firstName) && !isEmpty(patient?.lastName) && !isEmpty(patient?.gender) && !isEmpty(patient?.birthday);
});

export const isValidCreateFormSelector = createSelector(
    [(state) => state.auth.authenticated, selectedPatientSelector, productsSelector, preorderSelector],
    (authenticated, patient, products, preorder) => {
        return !!(
            authenticated &&
            patient?.id &&
            products?.valid?.length > 0 &&
            !(products?.invalid?.length > 0) &&
            !isEmpty(preorder.officeId) &&
            !isEmpty(preorder.daysOfLifePreorder) &&
            (isEmpty(preorder.insuranceType) || (!isEmpty(preorder.insuranceType) && !isEmpty(preorder.insuranceNumber)))
        );
    }
);

const toProduct = (orderProduct: any) => {
    return {
        ...orderProduct.product,
        biomaterial: orderProduct.biomaterial,
        availableBiomaterials: orderProduct.availableBiomaterials,
        removable: orderProduct.removable,
        selected: orderProduct.selected
    };
};

const eqSet = (set1: any, set2: any) => {
    if (set1.size !== set2.size) {
        return false;
    } else {
        for (let element of set1) {
            if (!set2.has(element)) {
                return false;
            }
        }

        return true;
    }
};

const sortStrings = (s1: any, s2: any) => {
    if (s1 < s2) {
        return -1;
    } else if (s1 > s2) {
        return 1;
    } else {
        return 0;
    }
};

const createBiomaterialSets = (orderProducts: any, chosenProductsIds: any = []) => {
    const productById: any = {};
    const biomaterialsById: any = {};
    const biomaterialsByProduct: any = {};
    const selectedBiomaterialByProduct: any = {};
    orderProducts.forEach((orderProduct: any) => {
        if (orderProduct?.availableBiomaterials?.length > 0) {
            const product = orderProduct.product;
            productById[product.id] = product;
            biomaterialsByProduct[product.id] = new Set();
            orderProduct.availableBiomaterials.forEach((biomaterial: any) => {
                biomaterialsById[biomaterial.id] = biomaterial;
                biomaterialsByProduct[product.id].add(biomaterial.id);
            });
            selectedBiomaterialByProduct[product.id] = orderProduct.biomaterial?.id;
        }
    });

    const productsByBiomaterials: any = new Map();
    for (const [productId, biomaterialsIds] of Object.entries(biomaterialsByProduct)) {
        let existentBiomaterialsIds = [...productsByBiomaterials.keys()].find((set) => eqSet(set, biomaterialsIds));
        if (!existentBiomaterialsIds) {
            productsByBiomaterials.set(biomaterialsIds, new Set([productId]));
        } else {
            productsByBiomaterials.get(existentBiomaterialsIds).add(productId);
        }
    }

    return [...productsByBiomaterials]
        .filter(([biomaterialsIds, productsIds]) => {
            return chosenProductsIds.length === 0 || [...productsIds].some((p) => chosenProductsIds.indexOf(p) >= 0);
        })
        .map(([biomaterialsIds, productsIds]) => {
            const productsIdsArr = [...productsIds];
            const biomaterials = [...biomaterialsIds].map((biomaterialId) => {
                return {
                    ...biomaterialsById[biomaterialId],

                    selected: selectedBiomaterialByProduct[productsIdsArr[0]] === biomaterialId
                };
            });

            const products = productsIdsArr.map((productId) => productById[productId]);

            return {
                biomaterials: biomaterials.sort((b1, b2) => sortStrings(b1.name, b2.name)),
                products: products.sort((p1, p2) => sortStrings(p1.article, p2.article))
            };
        });
};

export const officesSelector = createSelector([officesByIdSelector], (offices: any) =>
    Object.values(offices).sort(function (a: any, b: any) {
        return a?.office?.name?.localeCompare(b?.office?.name);
    })
);

export const filteredOfficesSelector = createSelector([officesSelector, officesFilterSelector], (offices, filter) => {
    if (!filter.query) {
        return offices;
    }
    const upperQuery = filter?.query?.toUpperCase();
    return offices.filter(
        (preorderOffice: any) =>
            preorderOffice.office.name.toUpperCase().indexOf(upperQuery) >= 0 || preorderOffice.office.address.toUpperCase().indexOf(upperQuery) >= 0
    );
});
