import React, { useEffect } from 'react';
import styles from './PreorderForm.module.css';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { actionsSelector } from 'redux/app/selectors';
import { CREATE_PATIENT, SET_CREATE_PATIENT_FORM } from 'redux/preorders/actions';
import Notification from 'components/notification/Notification';
import { SET_ACTIONS } from 'redux/app/actions';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';
import { createPatientSelector, isValidCreateFormPatientSelector } from 'redux/preorders/selectors';
import { isContainsNumbers } from 'utils/messageUtils';
import { startOfYesterday } from 'date-fns';
import CustomDatePickerInput from 'components/input/datepicker/DatePickerInput';

const CreatePatientForm = ({
    setPreorder,
    setShowPatientsModal,
    setShowCreatePatientForm
}: {
    setPreorder: any;
    setShowPatientsModal: any;
    setShowCreatePatientForm: any;
}) => {
    const actions = useSelector(actionsSelector);
    const dispatch = useDispatch();
    const submitRef = React.createRef();
    const isValidCreatePatientForm = useSelector(isValidCreateFormPatientSelector);
    const patient = useSelector(createPatientSelector);

    useEffect(() => {
        if (actions[CREATE_PATIENT.BASE]?.success) {
            setShowCreatePatientForm(false);
            setShowPatientsModal(false);
            setPreorder({ createPatient: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[CREATE_PATIENT.BASE]?.success]);

    useEffect(() => {
        return () => {
            setShowCreatePatientForm(false);
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [CREATE_PATIENT.BASE]: null
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.form}>
            <div className={styles.row}>
                <label>
                    Фамилия<span>*</span>
                </label>
                <Input
                    trigger={submitRef}
                    required={true}
                    onChange={({ target }: any) => !isContainsNumbers(target.value) && dispatch(SET_CREATE_PATIENT_FORM.base({ lastName: target.value }))}
                    value={patient?.lastName}
                    type="text"
                    setStyle={false}
                    reuired={true}
                />
            </div>
            <div className={styles.row}>
                <label>
                    Имя<span>*</span>
                </label>
                <Input
                    trigger={submitRef}
                    required={true}
                    onChange={({ target }: any) => !isContainsNumbers(target.value) && dispatch(SET_CREATE_PATIENT_FORM.base({ firstName: target.value }))}
                    value={patient?.firstName}
                    type="text"
                    setStyle={false}
                    reuired={true}
                />
            </div>
            <div className={styles.row}>
                <label>Отчество</label>
                <Input
                    trigger={submitRef}
                    onChange={({ target }: any) => !isContainsNumbers(target.value) && dispatch(SET_CREATE_PATIENT_FORM.base({ middleName: target.value }))}
                    value={patient?.middleName}
                    type="text"
                    setStyle={false}
                    reuired={true}
                />
            </div>
            <div className={styles.row}>
                <label>
                    Пол<span>*</span>
                </label>
                <select
                    onChange={({ target }: any) => dispatch(SET_CREATE_PATIENT_FORM.base({ gender: target.value }))}
                    value={patient?.gender as string}
                    name={'gender'}
                >
                    <option value={''}>не выбран</option>
                    <option value={'MALE'}>мужской</option>
                    <option value={'FEMALE'}>женский</option>
                </select>
            </div>
            <div className={styles.row}>
                <label>
                    Дата рождения<span>*</span>
                </label>
                <DatePicker
                    customInput={<CustomDatePickerInput />}
                    selected={patient?.birthday}
                    locale={ru}
                    required={true}
                    adjustDateOnChange={true}
                    dateFormat="dd.MM.yyyy"
                    onChange={(date: Date) => dispatch(SET_CREATE_PATIENT_FORM.base({ birthday: date }))}
                    maxDate={startOfYesterday()}
                    showYearDropdown={true}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                />
            </div>
            <div className={styles.row}>
                <label>Номер мобильного телефона</label>
                <Input
                    value={patient?.mobilePhone}
                    onChange={({ target }: any) => dispatch(SET_CREATE_PATIENT_FORM.base({ mobilePhone: target.value }))}
                    type={'text'}
                    setStyle={false}
                />
            </div>
            <div className={styles.row}>
                <label>E-mail</label>
                <Input
                    value={patient?.email}
                    type={'text'}
                    onChange={({ target }: any) => dispatch(SET_CREATE_PATIENT_FORM.base({ email: target.value }))}
                    setStyle={false}
                />
            </div>
            {actions[CREATE_PATIENT.BASE]?.error && <Notification type={'error'}>Ошибка при создании пациента</Notification>}
            <div className={styles.row}>
                <Button disabled={!isValidCreatePatientForm} className={styles.button} onClick={() => dispatch(CREATE_PATIENT.base(patient))}>
                    Создать
                </Button>
            </div>
        </div>
    );
};

export default React.memo(CreatePatientForm);
