import React from 'react';
import classNames from 'classnames/bind';
import styles from './Textarea.module.css';
const cx = classNames.bind(styles);

export default class Textarea extends React.PureComponent {
    render() {
        const { error, className, innerRef, ...rest } = this.props;
        const localClassName = cx({
            textarea: true,
            error: this.props.error
        });
        return <textarea className={classNames(localClassName, className)} ref={innerRef} {...rest} />;
    }
}
