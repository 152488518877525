import { all, takeLatest, call, put, takeEvery, select } from 'redux-saga/effects';
import * as requestClient from 'client/RequestClient';
import { RequestsTypes } from 'types/RequestsTypes';
import { AxiosResponse } from 'axios';
import {
    FETCH_MATERIALS_ALL,
    SEND_MATERIAL_ORDER,
    CLEAR_MATERIAL_ORDER,
    FETCH_ORDER_HISTORY,
    FETCH_REQUEST_COURIER,
    CREATE_REQUEST_COURIER,
    CHANGE_REQUEST_COURIER,
    CANCEL_REQUEST_COURIER,
    GET_MATERIAL_PHOTO,
    SET_MATERIAL_ORDER,
    SET_ORDER,
    SET_NOT_AVAILABLE_MATERIALS,
    SET_ADDRESS_ORDER,
    SET_COMMENT_ORDER
} from './actions';
import { LKE_MATERIALS_ORDER_HISTORY } from 'redux/requests/constants';
import localStorageService from 'services/LocalStorageService';
import { AppTypes } from 'types/AppTypes';
import { getAllOrderHistoryStorage, getOrderHistoryStorage } from 'utils/browserUtils';
import { isEmpty } from 'lodash';

function* fetchMaterialsAll(action: RequestsTypes.RequestAPI.ActionFetchMaterialsAll) {
    try {
        const response: AxiosResponse<RequestsTypes.OrderAPI.MaterialDTO[]> = yield call(requestClient.fetchMaterialsAll);
        const { officeId, userId } = yield action.meta;
        const orderHistory = getOrderHistoryStorage(officeId, userId);
        yield put(FETCH_MATERIALS_ALL.success({ materials: response?.data }));
        yield put(SET_ORDER.base({ order: { officeId, userId: userId, materials: orderHistory?.materials || [] } }));
    } catch (err: any) {
        yield put(FETCH_MATERIALS_ALL.error(err?.response));
    }
}

function* getMaterialPhoto(action: RequestsTypes.RequestAPI.ActionGetMaterialPhoto) {
    try {
        const response: AxiosResponse<BlobPart> = yield call(requestClient.getMaterialPhoto, action.meta);
        yield put(
            GET_MATERIAL_PHOTO.success({
                picture: action.meta.picture,
                responseData: response.data,
                REQUEST_ID: action.meta.REQUEST_ID
            })
        );
    } catch (err: any) {
        yield put(GET_MATERIAL_PHOTO.error(err?.response));
    }
}

function* fetchOrderHistory(action: RequestsTypes.OrderAPI.allOfficeHistory) {
    try {
        const response: AxiosResponse<RequestsTypes.OrderAPI.OrderDTO[]> = yield call(requestClient.fetchOrderHistory, action.meta);
        yield put(FETCH_ORDER_HISTORY.success(response));
    } catch (err: any) {
        yield put(FETCH_ORDER_HISTORY.error(err?.response));
    }
}

function* sendMaterialOrder(action: RequestsTypes.OrderAPI.ActionSend) {
    try {
        const response: AxiosResponse<number | boolean> = yield call(requestClient.sendMaterialOrder, action.meta);
        const { selectedOfficeOrGroup } = yield select((state: AppTypes.State) => state.offices);
        const { currentUser } = yield select((state: AppTypes.State) => state.user);
        clearOrderMaterialsHistory(selectedOfficeOrGroup.id, currentUser.id);
        yield put(CLEAR_MATERIAL_ORDER.base());
        yield put(SEND_MATERIAL_ORDER.success(response));
    } catch (err: any) {
        yield put(SEND_MATERIAL_ORDER.error(err?.response));
    }
}

function* clearMaterialOrder() {
    try {
        const { selectedOfficeOrGroup } = yield select((state: AppTypes.State) => state.offices);
        const { currentUser } = yield select((state: AppTypes.State) => state.user);
        clearOrderMaterialsHistory(selectedOfficeOrGroup.id, currentUser.id);
        yield put(CLEAR_MATERIAL_ORDER.success());
    } catch (err: any) {
        yield put(CLEAR_MATERIAL_ORDER.error(err));
    }
}

function* fetchRequestCourier(action: RequestsTypes.OrderAPI.allOfficeHistory) {
    try {
        const response: AxiosResponse<RequestsTypes.RequestAPI.RequestDTO[]> = yield call(requestClient.fetchRequestCourier, action.meta);
        yield put(FETCH_REQUEST_COURIER.success(response));
    } catch (err: any) {
        yield put(FETCH_REQUEST_COURIER.error(err?.response));
    }
}

function* createRequestCourier(action: RequestsTypes.RequestAPI.ActionCreate) {
    try {
        const response: AxiosResponse<RequestsTypes.RequestAPI.RequestDTO> = yield call(requestClient.createRequestCourier, action.meta);
        yield put(CREATE_REQUEST_COURIER.success(response));
        yield put(FETCH_REQUEST_COURIER.base());
    } catch (err: any) {
        yield put(CREATE_REQUEST_COURIER.error(err?.response));
    }
}

function* changeRequestCourier(action: RequestsTypes.RequestAPI.ActionChange) {
    try {
        const response: AxiosResponse<RequestsTypes.RequestAPI.RequestDTO> = yield call(requestClient.changeRequestCourier, action.meta);
        yield put(CHANGE_REQUEST_COURIER.success(response));
        yield put(FETCH_REQUEST_COURIER.base());
    } catch (err: any) {
        yield put(CHANGE_REQUEST_COURIER.error(err?.response));
    }
}

function* cancelRequestCourier(action: RequestsTypes.RequestAPI.ActionCancel) {
    try {
        const { requestId } = yield action.meta;
        const response: AxiosResponse<RequestsTypes.RequestAPI.RequestDTO> = yield call(requestClient.cancelRequestCourier, requestId);
        yield put(CANCEL_REQUEST_COURIER.success(response));
        yield put(FETCH_REQUEST_COURIER.base());
    } catch (err: any) {
        yield put(CANCEL_REQUEST_COURIER.error(err?.response));
    }
}

function* setOrder(action: RequestsTypes.RequestAPI.ActionSetOrder) {
    try {
        const { order } = action?.meta;
        const { selectedOfficeOrGroup } = yield select((state: AppTypes.State) => state.offices);
        const { materials } = yield select((state: AppTypes.State) => state.requests);
        const { currentUser } = yield select((state: AppTypes.State) => state.user);
        const notAvailableMaterials: any = [];
        yield put(CLEAR_MATERIAL_ORDER.base());
        for (let material of order.materials) {
            if (materials.filter((item: any) => item.article === material.article)[0]?.article) {
                yield put(
                    SET_MATERIAL_ORDER.base({
                        officeId: selectedOfficeOrGroup.id,
                        userId: currentUser.id,
                        material,
                        repeat: action.meta?.repeat
                    })
                );
            } else {
                notAvailableMaterials.push(material);
            }
        }
        yield put(SET_COMMENT_ORDER.base({ comment: order.comment }));
        yield put(SET_ADDRESS_ORDER.base({ address: !isEmpty(order.address) ? order.address : selectedOfficeOrGroup.address }));
        if (notAvailableMaterials.length > 0) {
            yield put(SET_NOT_AVAILABLE_MATERIALS.base({ notAvailableMaterials }));
        }
        yield put(SET_ORDER.success());
    } catch (err) {
        yield put(SET_ORDER.error(err));
    }
}

function* setMaterialOrder(action: RequestsTypes.RequestAPI.ActionSetMaterialOrder) {
    try {
        const { officeId, userId, material } = yield action.meta;
        const orderHistory = getAllOrderHistoryStorage();
        const id = `${officeId}/${userId}`;
        if (!orderHistory[`${id}`]) {
            const newOrderHistory: any = {};
            newOrderHistory[`${id}`] = {};
            newOrderHistory[`${id}`].materials = [{ ...material }];
            localStorageService.setItem(LKE_MATERIALS_ORDER_HISTORY, JSON.stringify({ ...orderHistory, ...newOrderHistory }));
        } else {
            const filtered = orderHistory[`${id}`]?.materials?.filter((item: any) => material.article !== item.article);
            if (material.quantity > 0) {
                orderHistory[`${id}`].materials = [...filtered, { ...material }];
            } else {
                orderHistory[`${id}`].materials = [...filtered];
            }
            localStorageService.setItem(LKE_MATERIALS_ORDER_HISTORY, JSON.stringify({ ...orderHistory }));
        }
    } catch (err) {
        yield put(SET_MATERIAL_ORDER.error(err));
    }
}

const clearOrderMaterialsHistory = (officeId: string, userId: string) => {
    const orderHistory = getAllOrderHistoryStorage();
    const id = `${officeId}/${userId}`;
    if (orderHistory[`${id}`]) {
        delete orderHistory[`${id}`];
        localStorageService.setItem(LKE_MATERIALS_ORDER_HISTORY, JSON.stringify({ ...orderHistory }));
    }
};

export default function* requestSagas() {
    yield all([takeLatest(FETCH_MATERIALS_ALL.BASE, fetchMaterialsAll)]);
    yield all([takeEvery(GET_MATERIAL_PHOTO.BASE, getMaterialPhoto)]);
    yield all([takeLatest(FETCH_ORDER_HISTORY.BASE, fetchOrderHistory)]);
    yield all([takeLatest(SEND_MATERIAL_ORDER.BASE, sendMaterialOrder)]);
    yield all([takeLatest(FETCH_REQUEST_COURIER.BASE, fetchRequestCourier)]);
    yield all([takeLatest(CREATE_REQUEST_COURIER.BASE, createRequestCourier)]);
    yield all([takeLatest(CHANGE_REQUEST_COURIER.BASE, changeRequestCourier)]);
    yield all([takeLatest(CANCEL_REQUEST_COURIER.BASE, cancelRequestCourier)]);
    yield all([takeLatest(SET_ORDER.BASE, setOrder)]);
    yield all([takeLatest(SET_MATERIAL_ORDER.BASE, setMaterialOrder)]);
    yield all([takeLatest(CLEAR_MATERIAL_ORDER.BASE, clearMaterialOrder)]);
}
