import React, { Fragment } from 'react';
import styles from './Header.module.css';
import logoInvitro from 'themes/icons/logoInvitro.svg';
import logoVetUnion from 'themes/icons/logoVetUnion.png';
import logoTitle from 'themes/icons/logoTitle.svg';
import { getOrigin } from 'utils/browserUtils';
import UserProfile from 'page/layout/header/profile/UserProfile';
import Phone from 'page/layout/header/phone/Phone';
import Banners from 'page/layout/header/banners/Banners';
import IsInvitro from 'themes/invitro/IsInvitro';
import IsVetunion from 'themes/vetunion/IsVetunion';
import Icon from 'components/icon/Icon';

const Header = (props: any) => {
    const { authenticated } = props;
    const BASE_URL = getOrigin();

    return (
        <Fragment>
            <header className={styles.header}>
                <div className={styles.inner}>
                    <div className={styles.innerLogo}>
                        <IsInvitro>
                            <a href={'https://www.invitro.ru/'} rel="noreferrer" target={'_blank'}>
                                <Icon width={'180px'} height={'30px'} icon={`${logoInvitro}#logoInvitro`} className={styles.logoTitle} />
                            </a>
                        </IsInvitro>
                        <IsVetunion>
                            <a href={'https://www.vetunion.ru/'} rel="noreferrer" target={'_blank'}>
                                <img style={{ position: 'relative', bottom: '3px' }} alt="" className={styles.logo} src={logoVetUnion} />
                            </a>
                        </IsVetunion>
                        <IsInvitro>
                            <a href={BASE_URL}>
                                <Icon width={'170px'} icon={`${logoTitle}#logoTitle`} className={styles.logoTitle} />
                            </a>
                        </IsInvitro>
                    </div>
                    <IsInvitro>
                        <Phone />
                    </IsInvitro>
                </div>
            </header>
            {authenticated && props.headerInfo && (
                <div className={styles.headerInfo}>
                    <UserProfile />
                    <div className={styles.banners}>
                        <Banners />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(Header);
