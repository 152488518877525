import createRequestTypes from 'redux/helpers';
export const FETCH_REQUEST_COURIER = createRequestTypes('REQUESTS/FETCH_REQUEST_COURIER');
export const CREATE_REQUEST_COURIER = createRequestTypes('REQUESTS/CREATE_REQUEST_COURIER');
export const CANCEL_REQUEST_COURIER = createRequestTypes('REQUESTS/CANCEL_REQUEST_COURIER');
export const CHANGE_REQUEST_COURIER = createRequestTypes('REQUESTS/CHANGE_REQUEST_COURIER');
export const FETCH_MATERIALS_ALL = createRequestTypes('REQUESTS/FETCH_MATERIALS_ALL');
export const GET_MATERIAL_PHOTO = createRequestTypes('REQUESTS/GET_MATERIAL_PHOTO');
export const FETCH_ORDER_HISTORY = createRequestTypes('REQUESTS/FETCH_ORDER_HISTORY');
export const SET_ORDER = createRequestTypes('REQUESTS/SET_ORDER');
export const SET_MATERIAL_ORDER = createRequestTypes('REQUESTS/SET_MATERIAL_ORDER');
export const SET_COMMENT_ORDER = createRequestTypes('REQUESTS/SET_COMMENT_ORDER');
export const SET_ADDRESS_ORDER = createRequestTypes('REQUESTS/SET_ADDRESS_ORDER');
export const CLEAR_MATERIAL_ORDER = createRequestTypes('REQUESTS/CLEAR_MATERIAL_ORDER');
export const SEND_MATERIAL_ORDER = createRequestTypes('REQUESTS/SEND_MATERIAL_ORDER');
export const SET_NOT_AVAILABLE_MATERIALS = createRequestTypes('REQUESTS/SET_NOT_AVAILABLE_MATERIALS');
