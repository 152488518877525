import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Input.module.css';
import classNames from 'classnames';
import { isEmpty } from 'utils/messageUtils';

const Input: FC<any> = ({ label, required, value, setStyle = true, trigger, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null)?.current;
    const [error, setError] = useState(false);

    const handleOnChange = useCallback(() => {
        if (required && isEmpty(value)) {
            setError(true);
        } else {
            setError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        trigger && trigger?.current?.addEventListener('click', handleOnChange);
        return () => {
            // eslint-disable-next-line
            trigger?.current?.removeEventListener('click', handleOnChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleOnChange, trigger]);

    useEffect(() => {
        if (required && !isEmpty(value)) {
            setError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Fragment>
            <input ref={inputRef} value={value} className={classNames([setStyle ? styles.input : '', error ? styles.error : ''])} {...rest} />
            {label && (
                <label
                    onClick={() => inputRef?.focus()}
                    className={classNames([value?.length > 0 || (inputRef && inputRef.value.length > 0) ? styles.labelFloat : null])}
                >
                    {label}
                </label>
            )}
        </Fragment>
    );
};

export default Input;
