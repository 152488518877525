/**
 * Перечисление типов статуса закза
 */
export enum RESULT_STATUS {
    //Не готов
    NOT_COMPLETED = 'NOT_COMPLETED',
    //Частично готов
    PARTIAL = 'PARTIAL',
    //Готов
    COMPLETED = 'COMPLETED'
}
