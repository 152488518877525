export function renderReference(test: any) {
    const unitSuffix = test.unit ? ` ${test.unit}` : '';
    if (test.refText) {
        return test.refText;
    } else if (!test.refMin && !test.refMax) {
        return null;
    } else if (!test.refMin) {
        return `< ${test.refMax}${unitSuffix}`;
    } else if (!test.refMax) {
        return `> ${test.refMin}${unitSuffix}`;
    } else {
        return `${test.refMin} — ${test.refMax}${unitSuffix}`;
    }
}

export const comparator = (test1: any, test2: any) => {
    const name1 = test1.shortName.toLowerCase();
    const name2 = test2.shortName.toLowerCase();
    if (name1 < name2) {
        return -1;
    }
    if (name1 > name2) {
        return 1;
    }
    return 0;
};

export const groupComparator = (entry1: any, entry2: any) => {
    const tests1 = entry1[1];
    const tests2 = entry2[1];
    if (!tests1.find((t: any) => t.serviceName) && tests2.find((t: any) => t.serviceName)) {
        return 1;
    }
    if (tests1.find((t: any) => t.serviceName) && !tests2.find((t: any) => t.serviceName)) {
        return -1;
    }
    return 0;
};
