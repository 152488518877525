export const ClassifierType = {
    /**
     * Лабораторный тест.
     */
    TEST: 'TEST',

    /**
     * Профиль.
     */
    PROFILE: 'PROFILE',

    /**
     * Сестринская услуга взятия.
     */
    NURSE_LABORATORY_SERVICE: 'NURSE_LABORATORY_SERVICE',

    /**
     * Врачебная услуга взятия.
     */
    DOCTOR_LABORATORY_SERVICE: 'DOCTOR_LABORATORY_SERVICE',

    /**
     * Медицинская услуга.
     */
    MEDICAL_SERVICE: 'MEDICAL_SERVICE',

    /**
     * Врачебный прием.
     */
    MEDICAL_APPOINTMENT: 'MEDICAL_APPOINTMENT',

    /**
     * Ретейл-продукт.
     */
    RETAIL_PRODUCT: 'RETAIL_PRODUCT',

    /**
     * Сертификат.
     */
    CERTIFICATE: 'CERTIFICATE',

    /**
     * Инъекция.
     */
    INJECTION: 'INJECTION',

    /**
     * Вакцинация.
     */
    VACCINATION: 'VACCINATION',

    /**
     * Транспортная логистическая услуга.
     */
    TRANSPORT_LOGISTIC: 'TRANSPORT_LOGISTIC',

    /**
     * ВНД логистическая услуга.
     */
    VND_LOGISTIC: 'VND_LOGISTIC',

    /**
     * Докторская манипуляция.
     */
    DOCTOR_MANIPULATION: 'DOCTOR_MANIPULATION',

    /**
     * Сестринская манипуляция.
     */
    NURSE_MANIPULATION: 'NURSE_MANIPULATION',

    /**
     * Пробоподготовка.
     */
    SAMPLE_PREPARATION: 'SAMPLE_PREPARATION',

    /**
     * Оформление документации.
     */
    DOCUMENT_REGISTRATION: 'DOCUMENT_REGISTRATION',

    /**
     * Чекап.
     */
    CHECKUP: 'CHECKUP'
};
