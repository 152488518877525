import apiClient from 'client/ApiClient';
import { BASE_URL } from './constants';
import { trimming } from 'utils/messageUtils';
import { PreordersTypes } from 'types/PreordersTypes';
const PREORDERS_URL = `${BASE_URL}/preorder`;
const PATIENT_URL = `${BASE_URL}/patient`;
const PRODUCT_OFFICES_URL = `${BASE_URL}/product/offices`;
const PRODUCT_URL = `${BASE_URL}/product`;
const queryString = require('query-string');

export const fetchAll = (serverSideFilter: PreordersTypes.ServerSideFilter) => {
    return apiClient.get<PreordersTypes.PreordersAPI.PreorderDTO[]>(
        `${PREORDERS_URL}/?${queryString.stringify(trimming(serverSideFilter), { skipNull: true })}`
    );
};

export const fetchPreorderDetails = (data: PreordersTypes.DataFetchPreorderDetails) => {
    return apiClient.get<any>(`${PREORDERS_URL}/${data.id}`);
};

export const searchPatients = (data: any) => {
    return apiClient.get<any>(`${PATIENT_URL}?${queryString.stringify(trimming(data))}`);
};

export const createPatient = (data: PreordersTypes.Patient) => {
    return apiClient.post<PreordersTypes.Patient>(`${PATIENT_URL}`, data);
};

export const fetchAllProducts = () => {
    return apiClient.get<any>(`${PRODUCT_URL}/all`);
};

export const fetchProducts = (data: any) => {
    return apiClient.post<any>(`${PRODUCT_URL}`, data);
};

export const fetchOffices = (data: any) => {
    return apiClient.post<any>(`${PRODUCT_OFFICES_URL}`, data);
};

export const validate = (data: any) => {
    return apiClient.post<any>(`${PRODUCT_URL}/validate`, data);
};

export const calculateOffice = (data: any) => {
    return apiClient.post<any>(`${PRODUCT_URL}/calculate`, data);
};

export const create = (data: any) => {
    return apiClient.post<any>(`${PREORDERS_URL}`, data);
};

export const cancel = (data: any) => {
    return apiClient.delete<any>(`${PREORDERS_URL}?${queryString.stringify(trimming(data))}`);
};

export const getStatus = (data: any) => {
    return apiClient.get<any>(`${PREORDERS_URL}/status/${data.preorderId}`);
};

export const fetchResults = (data: any) => {
    return apiClient.post<any>(`${PREORDERS_URL}/results`, data);
};

export const fetchPreordersHistory = (data: any) => {
    return apiClient.get<any>(`${PREORDERS_URL}/history/${data.patientId}/?${queryString.stringify(trimming(data))}`);
};

export const downloadPreorder = (data: any) => {
    return apiClient.get<BlobPart>(`${PREORDERS_URL}/${data.preorderId}/download`, { responseType: 'blob' });
};
