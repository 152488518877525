import React, { useState } from 'react';
import styles from './Faq.module.css';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Faq = () => {
    const { t } = useTranslation('common');
    const [items, setItems] = useState<Array<any>>([
        {
            title: t('Faq.itemTitle1'),
            description: t('Faq.itemDescription1'),
            active: false
        },
        {
            title: t('Faq.itemTitle2'),
            description: t('Faq.itemDescription2'),
            active: false
        }
    ]);

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>{t('Faq.title')}</h3>
            <div className={styles.list}>
                {items.map((item: any, i) => (
                    <div className={styles.item} key={i}>
                        <h4
                            className={classNames([styles.itemTitle, item.active ? styles.activeItem : null])}
                            onClick={() => {
                                setItems((prevState) => {
                                    let newState = [...prevState];
                                    newState[i]['active'] = !prevState[i]['active'];
                                    return [...newState];
                                });
                            }}
                        >
                            {item.title}
                        </h4>
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: item.description
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default React.memo(Faq);
