import React, { FC, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './MaterialPhoto.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { GET_MATERIAL_PHOTO } from 'redux/requests/actions';
import { actionsSelector } from 'redux/app/selectors';
import { RequestsTypes } from 'types/RequestsTypes';

type TProps = {
    material: RequestsTypes.Material;
};

const MaterialPhoto: FC<TProps> = ({ material }) => {
    const { materialPhotos } = useSelector((state: AppTypes.State) => state.requests);
    const actions = useSelector(actionsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            if (material.picture && !actions[`${GET_MATERIAL_PHOTO.BASE}_${material.picture}`]) {
                dispatch(
                    GET_MATERIAL_PHOTO.base({
                        picture: material.picture,
                        REQUEST_ID: material.picture
                    })
                );
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [material.picture, materialPhotos, actions[`${GET_MATERIAL_PHOTO}_${material.picture}`]]);

    return (
        <img
            alt=""
            src={materialPhotos[material.picture]}
            data-for={'materialList'}
            data-tip={`<img alt="" src="${materialPhotos[material.picture]}" /><p class="${styles.article}">${material.article}</p><p class="${
                styles.description
            }">${material?.name}</p>`}
        />
    );
};

export default React.memo(MaterialPhoto);
