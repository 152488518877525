import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './OfficeGroupsDetailsPage.module.css';
import FormOfficeGroups from '../components/form/FormOfficeGroups';
import { AppTypes } from 'types/AppTypes';
import { FETCH_OFFICE_GROUP } from 'redux/office/actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OfficeGroupsDetailsPage = () => {
    const { officeGroup } = useSelector((state: AppTypes.State) => state.offices);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        if (params.officeGroupId) {
            dispatch(FETCH_OFFICE_GROUP.base({ officeGroupId: params.officeGroupId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.officeGroupId]);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`OfficeGroupsCreatePage.title`)}</h1>
            <FormOfficeGroups edit={true} officeGroup={officeGroup} />
        </div>
    );
};

export default React.memo(OfficeGroupsDetailsPage);
