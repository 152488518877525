import createRequestTypes from 'redux/helpers';
export const CHANGE_PASSWORD_BY_TOKEN = createRequestTypes('USER/CHANGE_PASSWORD_BY_TOKEN');
export const UPDATE_EMAIL = createRequestTypes('USER/UPDATE_EMAIL');
export const CHANGE_PASSWORD = createRequestTypes('USER/CHANGE_PASSWORD');
export const RECOVER_PASSWORD = createRequestTypes('USER/RECOVER_PASSWORD');
export const RECOVER_CHANGE_PASSWORD = createRequestTypes('USER/RECOVER_CHANGE_PASSWORD');
export const CHECK_TOKEN = createRequestTypes('USER/CHECK_TOKEN');
export const FIND_USERS_BY_OFFICE = createRequestTypes('USER/FIND_USERS_BY_OFFICE');
export const SEARCH_USERS = createRequestTypes('USER/SEARCH_USERS');
export const CREATE_USER_OFFICE = createRequestTypes('USER/CREATE_USER_OFFICE');
export const MODIFY_PERMISSIONS = createRequestTypes('USER/MODIFY_PERMISSIONS');
export const CREATE_USER = createRequestTypes('USER/CREATE_USER');
export const REMOVE_USER_OFFICE = createRequestTypes('USER/REMOVE_USER_OFFICE');
export const GET_CURRENT_USER = createRequestTypes('USER/GET_CURRENT_USER');
export const AUTH_USER = createRequestTypes('USER/AUTH_USER');
