import React, { Fragment, FC } from 'react';
import styles from 'components/card/Card.module.css';
import { capitalizeFirstLowerCaseRest, declOfNum, getGenderMessage } from 'utils/messageUtils';
import { age, formatDate, parseISO } from 'utils/timeUtils';
import { DynamicsTypes } from 'types/DynamicsTypes';
import { useTranslation } from 'react-i18next';
import { PreordersTypes } from 'types/PreordersTypes';

type TProps = {
    patient?: DynamicsTypes.TypePatient | PreordersTypes.Patient;
    officeName?: string;
    dynamics?: boolean;
};

const PatientCard: FC<TProps> = ({ patient, officeName, dynamics = false }) => {
    const { t } = useTranslation('common');
    return (
        <Fragment>
            {patient && (
                <div className={styles.container}>
                    <div className={styles.patientInfoItems}>
                        <div className={styles.patientInfoRow}>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>{t(`PatientCard.patient`)}</div>
                                <div className={styles.patientInfoDesc}>
                                    {capitalizeFirstLowerCaseRest(patient?.lastName)} {capitalizeFirstLowerCaseRest(patient?.firstName)}{' '}
                                    {capitalizeFirstLowerCaseRest(patient?.middleName)}
                                </div>
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>{t(`PatientCard.gender`)}</div>
                                <div className={styles.patientInfoDesc}>{t(`PatientCard.${getGenderMessage(patient?.gender)}`)}</div>
                            </div>
                        </div>
                        <div className={styles.patientInfoRow}>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>{t(`PatientCard.age`)}</div>
                                <div className={styles.patientInfoDesc}>
                                    {t(`PatientCard.ageValue`, {
                                        age: `${age(parseISO(patient?.birthday))}
                                                    ${declOfNum(age(parseISO(patient?.birthday)), [
                                                        t(`PatientCard.age1`),
                                                        t(`PatientCard.age2`),
                                                        t(`PatientCard.age3`)
                                                    ])}`
                                    })}
                                </div>
                            </div>
                            {dynamics && (
                                <div className={styles.patientInfoItem}>
                                    <div className={styles.patientInfoTitle}>{t(`PatientCard.birthday`)}</div>
                                    <div className={styles.patientInfoDesc}>{formatDate(parseISO(patient?.birthday), 'ru')}</div>
                                </div>
                            )}
                            {!dynamics && officeName && officeName?.length > 0 && (
                                <div className={styles.patientInfoOfficeName}>
                                    <div className={styles.patientInfoTitle}>{t(`PatientCard.medicalOffice`)}</div>
                                    <div className={styles.patientInfoDesc}>{officeName}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(PatientCard);
