import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import arrowBack from 'themes/icons/arrow.svg';
import styles from './RecoveryPage.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { SET_ACTIONS } from 'redux/app/actions';
import { actionsSelector } from 'redux/app/selectors';
import { RECOVER_CHANGE_PASSWORD, RECOVER_PASSWORD } from 'redux/user/actions';
import Notification from 'components/notification/Notification';
import useRecaptcha from 'hooks/useRecaptcha';
import isEmail from 'validator/lib/isEmail';
import InputPassword from 'components/input/password/InputPassword';
import Input from 'components/input/Input';
import { useTranslation } from 'react-i18next';
import { LOGOUT } from 'redux/auth/actions';
import Icon from 'components/icon/Icon';

const RecoveryPage = () => {
    const { t } = useTranslation('common');
    const [email, setEmail] = useState('');
    const actions = useSelector(actionsSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const recoverCaptcha = useRecaptcha();
    const changePasswordCaptcha = useRecaptcha();
    const [notValidPassword, setNotValidPassword] = useState(false);

    useEffect(() => {
        dispatch(LOGOUT.base({ redirect: false }));
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [RECOVER_PASSWORD.BASE]: null,
                    [RECOVER_CHANGE_PASSWORD.BASE]: null
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (actions[RECOVER_PASSWORD.BASE]?.error) {
            recoverCaptcha?.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[RECOVER_PASSWORD.BASE]]);

    useEffect(() => {
        if (actions[RECOVER_CHANGE_PASSWORD.BASE]?.error) {
            changePasswordCaptcha?.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[RECOVER_CHANGE_PASSWORD.BASE]]);

    // @ts-ignore
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`RecoveryPage.title`)}</h1>
            <div className={styles.content}>
                <Fragment>
                    <div className={styles.top}>
                        <Link
                            className={styles.linkBack}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/');
                            }}
                            to="/login"
                        >
                            <Icon width={'11px'} height={'16px'} icon={`${arrowBack}#arrow`} />
                        </Link>
                        <div className={styles.tabs}>
                            <div className={styles.tab}>
                                <div className={styles.tabTitle}>{t(`RecoveryPage.title`)}</div>
                            </div>
                        </div>
                    </div>
                    {(!actions[RECOVER_PASSWORD.BASE]?.success || actions[RECOVER_PASSWORD.BASE]?.error) && (
                        <div className={styles.form}>
                            {actions[RECOVER_PASSWORD.BASE]?.error?.errors &&
                                actions[RECOVER_PASSWORD.BASE]?.error?.errors?.map((error) => (
                                    <Fragment>
                                        <Notification type={'error'}>
                                            <p className={styles.notice}>{error.message}</p>
                                        </Notification>
                                        <br />
                                    </Fragment>
                                ))}
                            {actions[RECOVER_PASSWORD.BASE]?.error && !actions[RECOVER_PASSWORD.BASE]?.error?.errors && (
                                <Fragment>
                                    <Notification type={'error'}>
                                        <p className={styles.notice}>{actions[RECOVER_PASSWORD.BASE]?.error?.message}</p>
                                    </Notification>
                                    <br />
                                </Fragment>
                            )}
                            <form action="" method="POST">
                                <div className={styles.row}>
                                    <Input
                                        label={t(`Common.email`)}
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(event: any) => setEmail(event.target.value)}
                                    />
                                </div>
                                <div className={styles.recaptcha}>{recoverCaptcha?.render()}</div>
                                <div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            return dispatch(
                                                RECOVER_PASSWORD.base({
                                                    email,
                                                    captcha: recoverCaptcha?.token
                                                })
                                            );
                                        }}
                                        type={'submit'}
                                        disabled={!isEmail(email) || actions[RECOVER_PASSWORD.BASE]?.loading || !recoverCaptcha?.token || recoverCaptcha?.error}
                                    >
                                        {t(`Common.confirm`)}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                    {actions[RECOVER_PASSWORD.BASE]?.success && (
                        <div className={styles.form}>
                            {actions[RECOVER_PASSWORD.BASE]?.success && !actions[RECOVER_CHANGE_PASSWORD.BASE]?.success && (
                                <Fragment>
                                    <Notification type={'success'}>
                                        <p className={styles.notice}>{t(`RecoveryPage.noticeInfo1`)}</p>
                                    </Notification>
                                    <br />
                                </Fragment>
                            )}
                            {actions[RECOVER_CHANGE_PASSWORD.BASE]?.success && (
                                <div className={styles.noticeBlock}>
                                    <Notification type={'success'}>
                                        <p className={styles.notice}>{t(`RecoveryPage.noticeInfo2`)}</p>
                                    </Notification>
                                    <br />
                                    <a href="/login">{t(`Common.linkLogin`)}</a>
                                </div>
                            )}
                            {actions[RECOVER_CHANGE_PASSWORD.BASE]?.error?.errors &&
                                actions[RECOVER_CHANGE_PASSWORD.BASE]?.error?.errors?.map((error) => (
                                    <Fragment>
                                        <Notification type={'error'}>
                                            <p className={styles.notice}>{error.message}</p>
                                        </Notification>
                                        <br />
                                    </Fragment>
                                ))}
                            {actions[RECOVER_CHANGE_PASSWORD.BASE]?.error && !actions[RECOVER_CHANGE_PASSWORD.BASE]?.error?.errors && (
                                <Fragment>
                                    <Notification type={'error'}>
                                        <p className={styles.notice}>{actions[RECOVER_CHANGE_PASSWORD.BASE]?.error?.message}</p>
                                    </Notification>
                                    <br />
                                </Fragment>
                            )}
                            {!actions[RECOVER_CHANGE_PASSWORD.BASE]?.success && (
                                <form action="" method="POST">
                                    <div className={styles.row}>
                                        <InputPassword
                                            label={t(`Common.newPassword`)}
                                            setNotValidPassword={setNotValidPassword}
                                            name="password"
                                            showVisibilityBtn
                                            showStrength
                                            value={password}
                                            error={notValidPassword}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                                            max={64}
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <InputPassword
                                            label={t(`Common.repeatPassword`)}
                                            name="passwordRepeat"
                                            showVisibilityBtn
                                            value={passwordRepeat}
                                            notMatch={password.length > 0 && passwordRepeat.length > 0 && password !== passwordRepeat}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordRepeat(event.target.value)}
                                            max={64}
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <Input
                                            label={t(`Common.confirmationCode`)}
                                            type="text"
                                            value={code}
                                            onChange={(event: any) => setCode(event.target.value)}
                                        />
                                    </div>
                                    <div className={styles.recaptcha}>{changePasswordCaptcha?.render()}</div>
                                    <div>
                                        <button
                                            disabled={
                                                password.length < 1 ||
                                                password !== passwordRepeat ||
                                                code.length < 1 ||
                                                actions[RECOVER_CHANGE_PASSWORD.BASE]?.loading ||
                                                !changePasswordCaptcha.token ||
                                                changePasswordCaptcha.error ||
                                                notValidPassword
                                            }
                                            onClick={() =>
                                                dispatch(
                                                    RECOVER_CHANGE_PASSWORD.base({
                                                        email: email,
                                                        code: code,
                                                        password: password,
                                                        passwordRepeat: passwordRepeat,
                                                        captcha: changePasswordCaptcha.token
                                                    })
                                                )
                                            }
                                            type="button"
                                        >
                                            {t(`Common.send`)}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    )}
                </Fragment>
            </div>
        </div>
    );
};

export default React.memo(RecoveryPage);
