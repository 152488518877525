import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { logger } from './middleware';
import rootSaga from 'redux/rootSaga';
import appReducer from 'redux/app/reducer';
import authReducer from 'redux/auth/reducer';
import userReducer from 'redux/user/reducer';
import resultsReducer from 'redux/results/reducer';
import requestsReducer from 'redux/requests/reducer';
import dynamicsReducer from 'redux/dynamics/reducer';
import newsReducer from 'redux/news/reducer';
import officesReducer from 'redux/office/reducer';
import startupReducer from 'redux/startup/reducer';
import bannersReducer from 'redux/banners/reducer';
import preordersReducer from 'redux/preorders/reducer';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, logger];

const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        // @ts-ignore
        user: userReducer,
        results: resultsReducer,
        // @ts-ignore
        requests: requestsReducer,
        dynamics: dynamicsReducer,
        news: newsReducer,
        // @ts-ignore
        offices: officesReducer,
        startup: startupReducer,
        banners: bannersReducer,
        preorders: preordersReducer
    },
    middleware: [...middlewares]
});

sagaMiddleware.run(rootSaga);

export default store;
