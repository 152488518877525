import { FETCH_SETTINGS, INIT } from './actions';
import { StartupTypes } from 'types/StartupTypes';

const initialState: StartupTypes.State = {
    initialized: false,
    error: false,
    settings: {}
};

const startupReducer = (state: StartupTypes.State = initialState, action: StartupTypes.Action): StartupTypes.State => {
    switch (action.type) {
        case INIT.SUCCEEDED: {
            return {
                ...state,
                initialized: true,
                error: false
            };
        }
        case INIT.ERRORED:
            return {
                ...state,
                initialized: false,
                error: true
            };
        case FETCH_SETTINGS.SUCCEEDED: {
            return {
                ...state,
                settings: action.payload ? action.payload.data : {}
            };
        }
        case FETCH_SETTINGS.ERRORED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }
};

export default startupReducer;
