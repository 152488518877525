import React, { useEffect, useState, FC, Fragment } from 'react';
import styles from './MaterialList.module.css';
import arrowDown from 'themes/icons/arrow.svg';
import classNames from 'classnames';
import { RequestsTypes } from 'types/RequestsTypes';
import { filter, parseInt } from 'lodash';
import ReactTooltip from 'react-tooltip';
import isInt from 'validator/lib/isInt';
import isEmpty from 'validator/lib/isEmpty';
import { useTranslation } from 'react-i18next';
import Notification from 'components/notification/Notification';
import searchStringIcon from 'themes/icons/search.svg';
import Icon from 'components/icon/Icon';
import MaterialPhoto from '../photo/MaterialPhoto';
import { useSelector } from 'react-redux';
import { actionsSelector } from 'redux/app/selectors';
import { SET_ORDER } from 'redux/requests/actions';

type TProps = {
    materials: RequestsTypes.TypeListMaterial[] | [RequestsTypes.TypeListOrder];
    order: RequestsTypes.Order;
    showOrder?: boolean;
    setOrder?: (param: RequestsTypes.Material) => void;
    activeFolderList?: string[] | null | undefined;
    orderId?: string | null | undefined;
};

const MaterialList: FC<TProps> = ({ materials, order, setOrder, showOrder, activeFolderList, orderId }) => {
    const actions = useSelector(actionsSelector);
    const [showFolderList, setShowFolderList] = useState<string[]>(activeFolderList || []);
    const [searchString, setSearchString] = useState('');
    const { t } = useTranslation('common');

    useEffect(() => {
        if (showOrder) {
            materials.forEach((material) => handleShowFolderList(material.folder));
        } else {
            order?.materials?.forEach((material) => {
                handleShowFolderList(material.folder);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOrder, actions[SET_ORDER.BASE]?.success]);

    useEffect(() => {
        if (activeFolderList) {
            setShowFolderList(activeFolderList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFolderList]);

    const resetList = () => {
        if (activeFolderList) {
            setShowFolderList(activeFolderList);
        } else {
            setShowFolderList([]);
        }
    };

    const handleShowFolderList = (folder: string) => {
        if (!showFolderList.includes(folder)) {
            setShowFolderList((prevState) => [...prevState, folder]);
        } else {
            setShowFolderList((prevState) => [...prevState.filter((item: string) => item !== folder)]);
        }
    };

    const handleSetOrder = (material: any, quantity: any) => {
        material.quantity = parseInt(quantity);
        if (setOrder) {
            setOrder(material);
        }
    };

    const handleRoundQuantity = (material: any, quantity: any, event: any) => {
        if (quantity > 0 && material.multiplicity > 1 && quantity % material.multiplicity !== 0) {
            const roundQuantity = ((quantity - (quantity % material.multiplicity)) / material.multiplicity + 1) * material.multiplicity;
            const element = event?.target?.closest('.quantityMaterialWrap')?.querySelector('.roundQuantity');
            element.innerText = `${t(`MaterialList.roundedTo`)} ${roundQuantity}`;
            setTimeout(() => {
                if (setOrder) {
                    element.classList.remove(styles.roundQuantityAction);
                    element.classList.add(styles.roundQuantityAction);
                    setTimeout(() => {
                        element.classList.remove(styles.roundQuantityAction);
                    }, 4000);
                    handleSetOrder(material, roundQuantity);
                }
            }, 500);
        }
    };

    useEffect(() => {
        return ReactTooltip.rebuild() as () => void;
    });

    const renderList = (items: RequestsTypes.TypeListMaterial[] | [RequestsTypes.TypeListOrder]) => {
        return (
            items &&
            items.map((item: RequestsTypes.TypeListMaterial | RequestsTypes.TypeListOrder, i: number) => {
                const showList = showFolderList.includes(item.folder);
                let filtered: RequestsTypes.Material[] = [];
                if (!isEmpty(searchString)) {
                    filtered = filter(item.materials, function (material) {
                        return material?.name?.toUpperCase()?.indexOf(searchString.trim().toUpperCase()) >= 0;
                    });
                }

                if (filtered.length > 0 && !isEmpty(searchString) && !showList) {
                    setShowFolderList((prevState) => [...prevState, item.folder]);
                }

                if ((filtered.length > 0 && !isEmpty(searchString)) || isEmpty(searchString)) {
                    return (
                        <div key={i} className={styles.materialsBlockItem}>
                            <div className={styles.materialBlockHead} onClick={() => handleShowFolderList(item.folder)}>
                                <h1>{item.folder}</h1>
                                <Icon
                                    width={'15px'}
                                    height={'20px'}
                                    icon={`${arrowDown}#arrow`}
                                    className={classNames([showList ? styles.arrowUp : styles.arrowDown])}
                                />
                            </div>
                            {showList &&
                                (!isEmpty(searchString) ? filtered : item.materials).map((material, i) => {
                                    material.quantity = order?.materials.filter((order) => order?.article === material.article)[0]?.quantity || 0;
                                    material.multiplicity = material.multiplicity > 0 ? material.multiplicity : 1;
                                    return (
                                        <div key={i} className={styles.materialListItem}>
                                            <div className={styles.materialItem}>
                                                <div className={styles.materialItemPhoto}>
                                                    <MaterialPhoto material={material} />
                                                </div>
                                                <div className={styles.materialItemTitle}>
                                                    {material.name}
                                                    {material.unit && `, ${material.unit}`}
                                                </div>
                                                <div className={classNames(styles.quantityMaterial, 'quantityMaterialWrap')}>
                                                    <span className="roundQuantity" />
                                                    {setOrder && (
                                                        <span
                                                            className={styles.buttonMinusIcon}
                                                            onClick={() => {
                                                                material.quantity = material.quantity - material.multiplicity;
                                                                return setOrder(material);
                                                            }}
                                                        >
                                                            -
                                                        </span>
                                                    )}
                                                    <input
                                                        onChange={(event) => {
                                                            let value = parseInt(event.target.value);
                                                            if (isInt(event.target.value) || event.target.value === '') {
                                                                handleSetOrder(material, value);
                                                                handleRoundQuantity(material, value, event);
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                        className={styles.quantityMaterialOrder}
                                                        type="text"
                                                        value={material.quantity}
                                                        disabled={!setOrder}
                                                    />
                                                    {setOrder && (
                                                        <span
                                                            className={styles.buttonPlusIcon}
                                                            onClick={() => {
                                                                material.quantity = material.quantity + material.multiplicity;
                                                                return setOrder(material);
                                                            }}
                                                        >
                                                            +
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    );
                } else {
                    return null;
                }
            })
        );
    };

    return (
        <div className={styles.materialsBlock}>
            {materials?.length > 0 ? (
                <Fragment>
                    <div className={styles.searchPanel}>
                        <input
                            value={searchString}
                            type="search"
                            placeholder={t(`MaterialList.findMaterial`)}
                            onChange={(event) => {
                                resetList();
                                setSearchString(event.target.value);
                            }}
                        />
                        <button disabled={searchString.length < 1} onClick={() => setSearchString(searchString)}>
                            <Icon setDefaultStyles={false} icon={`${searchStringIcon}#search`} className={styles.searchStringIcon} />
                        </button>
                    </div>
                    {renderList(materials)}
                </Fragment>
            ) : (
                <Notification type={'error'}>{t(`MaterialList.catalogMaterialsNotFound`)}</Notification>
            )}
            {
                // @ts-ignore
                <ReactTooltip id={'materialList'} resizeHide={true} html={true} type={'light'} effect={'solid'} place={'right'} className={styles.tooltip} />
            }
        </div>
    );
};

export default React.memo(MaterialList);
