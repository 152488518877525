import React, { useEffect } from 'react';
import styles from './SettingsPage.module.css';
import { Route, NavLink, Routes, useNavigate } from 'react-router-dom';
import CabinetPage from './cabinet/CabinetPage';
import EmployeesPage from './employees/EmployeesPage';
import EmployeesDetailsPage from './employees/details/EmployeesDetailsPage';
import EmployeesCreatePage from './employees/create/EmployeesCreatePage';
import { PermissionType } from 'constants/PermissionType';
import Access from 'components/access/Access';
import OfficeGroupsPage from './officeGroups/OfficeGroupsPage';
import OfficeGroupsCreatePage from './officeGroups/create/OfficeGroupsCreatePage';
import OfficeGroupsDetailsPage from './officeGroups/details/OfficeGroupsDetailsPage';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => navigate('/settings/cabinet'), []);

    return (
        <div className={styles.container}>
            <aside className={styles.aside}>
                <h1 className={styles.asideTitle}>{t(`SettingsPage.title`)}</h1>
                <div className={styles.navigation}>
                    <NavLink className={({ isActive }) => (isActive ? styles.activePageLink : undefined)} to={'/settings/cabinet'}>
                        {t(`SettingsPage.cabinetManagement`)}
                    </NavLink>
                    <Access permissions={[PermissionType.MANAGE_USERS]}>
                        <NavLink className={({ isActive }) => (isActive ? styles.activePageLink : undefined)} to={'/settings/employees'}>
                            {t(`Common.staff`)}
                        </NavLink>
                    </Access>
                    <NavLink className={({ isActive }) => (isActive ? styles.activePageLink : undefined)} to={'/settings/officeGroups'}>
                        {t(`Common.officeGroups`)}
                    </NavLink>
                </div>
            </aside>
            <div className={styles.content}>
                <Routes>
                    <Route path={'cabinet'} element={<CabinetPage />} />
                    <Route path={'employees'} element={<EmployeesPage />} />
                    <Route path={'employees/create'} element={<EmployeesCreatePage />} />
                    <Route path={'employees/:userId'} element={<EmployeesDetailsPage />} />
                    <Route path={'officeGroups'} element={<OfficeGroupsPage />} />
                    <Route path={'officeGroups/create'} element={<OfficeGroupsCreatePage />} />
                    <Route path={'officeGroups/:officeGroupId'} element={<OfficeGroupsDetailsPage />} />
                </Routes>
            </div>
        </div>
    );
};

export default React.memo(SettingsPage);
