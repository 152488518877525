import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './AuthPage.module.css';
import { LOGIN } from 'redux/auth/actions';
import classNames from 'classnames';
import { goTo } from 'utils/browserUtils';
import { AppTypes } from 'types/AppTypes';
import { actionsSelector } from 'redux/app/selectors';
import useRecaptcha from 'hooks/useRecaptcha';
import isEmail from 'validator/lib/isEmail';
import InputPassword from 'components/input/password/InputPassword';
import Input from 'components/input/Input';
import { useTranslation } from 'react-i18next';
import IsInvitro from 'themes/invitro/IsInvitro';
import Faq from 'components/faq/Faq';
import IsVetunion from 'themes/vetunion/IsVetunion';
const queryString = require('query-string');

const AuthPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const { authenticated, error } = useSelector((state: AppTypes.State) => state.auth);
    const captcha = useRecaptcha();
    const [formError, setFormError] = useState(false);
    const [fieldsValue, setFieldsValue] = useState({
        login: '',
        password: ''
    });

    useEffect(() => {
        redirectIfAuthenticated();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    useEffect(() => {
        if (actions[LOGIN.BASE]?.error) {
            captcha?.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[LOGIN.BASE]?.error]);

    const redirectIfAuthenticated = () => {
        const query = queryString.parse(location?.search as string);
        const { backUrl } = query;
        // @ts-ignore
        const referrer = backUrl || location?.state?.referrer || { pathname: '/' };
        if (authenticated) {
            if (backUrl) {
                goTo(backUrl);
            } else {
                navigate(referrer, { replace: true });
            }
        }
    };

    return (
        <Fragment>
            {authenticated === false && (
                <div className={styles.container}>
                    <h1 className={styles.title}>
                        <IsInvitro>{t(`AuthPage.title`)}</IsInvitro>
                        <IsVetunion>{t(`AuthPage.titleVetunion`)}</IsVetunion>
                    </h1>
                    <div className={styles.content}>
                        <div className={styles.form}>
                            <form action="" method="post">
                                <div>
                                    <div className={styles.row}>
                                        <Input
                                            label={t(`Common.email`)}
                                            value={fieldsValue.login}
                                            onChange={(event: any) => setFieldsValue({ ...fieldsValue, login: event.target.value })}
                                            type="text"
                                            name="login"
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <InputPassword
                                            label={t(`Common.password`)}
                                            value={fieldsValue.password}
                                            name="password"
                                            showVisibilityBtn
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldsValue({ ...fieldsValue, password: event.target.value })
                                            }
                                        />
                                    </div>
                                    {(error || formError) && <div className={styles.error}>{t(`AuthPage.error`)}</div>}
                                    <div className={classNames([styles.recaptcha, captcha.error ? styles.recaptchaError : null])}>{captcha?.render()}</div>
                                    <div className={styles.row}>
                                        <button
                                            disabled={actions[LOGIN.BASE]?.loading}
                                            type="button"
                                            onClick={() => {
                                                if (!isEmail(fieldsValue.login)) {
                                                    setFormError(true);
                                                    return false;
                                                }
                                                if (!captcha.token) {
                                                    captcha.setError(true);
                                                    return false;
                                                }
                                                setFormError(false);
                                                dispatch(
                                                    LOGIN.base({
                                                        login: fieldsValue.login,
                                                        password: fieldsValue.password,
                                                        captcha: captcha?.token
                                                    })
                                                );
                                            }}
                                        >
                                            {t(`AuthPage.enter`)}
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.recoveryLink}>
                                    <Link to="/recovery">{t(`AuthPage.forgotPassword`)}</Link>
                                </div>
                            </form>
                        </div>
                        <IsInvitro>
                            <div className={styles.photo} />
                        </IsInvitro>
                    </div>
                    <IsVetunion>
                        <Faq />
                    </IsVetunion>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(AuthPage);
