import React, { Fragment, useEffect, useState } from 'react';
import styles from './CreatePreorderPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import successIcon from 'themes/icons/success.svg';
import pdfIcon from 'themes/icons/pdf.svg';
import Icon from 'components/icon/Icon';
import PreorderForm from 'page/preorders/components/form/PreorderForm';
import {
    CALCULATE_OFFICE,
    CLEAR_PREORDER,
    CREATE,
    CREATE_PATIENT,
    DOWNLOAD_PREORDER,
    FETCH_OFFICES,
    REPEAT,
    SELECT_OFFICE,
    SET_PREORDER
} from 'redux/preorders/actions';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { SET_ACTIONS } from 'redux/app/actions';
import { groupBy, keys } from 'lodash';
import { cartDataProductsIdsSelector, preorderSelector } from 'redux/preorders/selectors';
import Button from 'components/button/Button';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { HAS_EXTERNAL_PREORDER_POINTS } from 'redux/office/actions';
import Notification from 'components/notification/Notification';
import BeforeUnload from 'components/helpers/BeforeUnload';

const CreatePreorderPage = () => {
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const { createdPreorder } = useSelector((state: AppTypes.State) => state.preorders);
    const preorder = useSelector((state: AppTypes.State) => preorderSelector(state));
    const productsIds = useSelector((state: AppTypes.State) => cartDataProductsIdsSelector(state));
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [initRepeatPreorder, setInitRepeatPreorder] = useState(false);
    const { t } = useTranslation('common');
    const actions = useSelector(actionsSelector);
    const location: any = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(HAS_EXTERNAL_PREORDER_POINTS.base());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id]);

    useEffect(() => {
        if (location?.state?.repeat && actions[REPEAT.BASE]?.success && !initRepeatPreorder && actions[HAS_EXTERNAL_PREORDER_POINTS.BASE]?.success) {
            setInitRepeatPreorder(() => {
                const articlesProducts = keys(groupBy(preorder?.products || [], 'article'));
                dispatch(FETCH_OFFICES.base({ articles: [...articlesProducts] }));
                return true;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[REPEAT.BASE]?.success, actions[HAS_EXTERNAL_PREORDER_POINTS.BASE]?.success]);

    useEffect(() => {
        if (location?.state?.repeat && actions[FETCH_OFFICES.BASE]?.success && actions[HAS_EXTERNAL_PREORDER_POINTS.BASE]?.success) {
            dispatch(
                SELECT_OFFICE.base({
                    officeId: preorder.dstOffice.id,
                    productsIds: productsIds,
                    repeat: true
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[FETCH_OFFICES.BASE]?.success, actions[HAS_EXTERNAL_PREORDER_POINTS.BASE]?.success]);

    useEffect(() => {
        if (actions[CREATE.BASE]?.success) {
            setShowSuccessModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[CREATE.BASE]?.success]);

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_ACTIONS,
                meta: {
                    [CREATE.BASE]: null,
                    [FETCH_OFFICES.BASE]: null,
                    [HAS_EXTERNAL_PREORDER_POINTS.BASE]: null
                }
            });
            dispatch(CLEAR_PREORDER.base());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPreorder = (value: any) => {
        dispatch(
            SET_PREORDER.base({
                ...value
            })
        );
    };

    const createPreorder = () => {
        dispatch(CREATE.base(preorder));
    };

    return (
        <div className={styles.container}>
            <Fragment>
                <div className={styles.breadcrumbs}>
                    <Breadcrumbs
                        links={[
                            {
                                link: '/preorders',
                                name: 'Предзаказы',
                                active: false
                            },
                            {
                                link: '/preorders',
                                name: 'Создание предзаказа',
                                active: true
                            }
                        ]}
                    />
                </div>
            </Fragment>
            <h1 className={styles.title}>Создание предзаказа</h1>
            {actions[HAS_EXTERNAL_PREORDER_POINTS.BASE]?.error && (
                <Fragment>
                    <Notification type={'error'}>{t(`Common.externalPreorderPoints`)}</Notification>
                    <br />
                </Fragment>
            )}
            {actions[HAS_EXTERNAL_PREORDER_POINTS.BASE]?.success && (
                <IsAvailable options={[{ preOrders: { enable: true } }]}>
                    <Access
                        officeGroup={{
                            selected: Array.isArray(selectedOfficeOrGroup?.offices),
                            message: t(`Common.requestOfficeNotice`)
                        }}
                        permissions={[PermissionType.PREORDERS]}
                        message={t(`Common.preordersNoAccess`)}
                    >
                        <BeforeUnload>
                            <PreorderForm
                                selectedOfficeOrGroup={selectedOfficeOrGroup}
                                createPreorder={createPreorder}
                                create={true}
                                setPreorder={setPreorder}
                                preorder={preorder}
                                dispatch={dispatch}
                            />
                        </BeforeUnload>
                        <Modal show={showSuccessModal}>
                            <div className={styles.modalContainer}>
                                <div className={styles.modalContent}>
                                    <div>
                                        <h2>Ваш предзаказ принят</h2>
                                        <div>№ {createdPreorder?.number}</div>
                                    </div>
                                    <Icon style={{ marginLeft: '30px' }} width="60" height="60" icon={`${successIcon}#success`} setDefaultStyles={false} />
                                </div>
                                <div className={styles.noticeDocs}>
                                    Внимание! Во время посещении медицинского офиса ИНВИТРО пациенту необходимо при себе иметь: паспорт, СНИЛС, страховой полис
                                </div>
                                <Button
                                    className={styles.button}
                                    onClick={() => {
                                        dispatch(DOWNLOAD_PREORDER.base({ preorderId: createdPreorder?.id }));
                                    }}
                                >
                                    <Icon className={styles.pdfIcon} width="40" height="40" icon={`${pdfIcon}#pdf`} setDefaultStyles={false} />
                                    Скачать предзаказ
                                </Button>
                                <Button
                                    className={styles.button}
                                    onClick={() => {
                                        navigate('/preorders');
                                        setShowSuccessModal(false);
                                    }}
                                >
                                    Продолжить
                                </Button>
                            </div>
                        </Modal>
                        <Preloader
                            show={
                                actions[CALCULATE_OFFICE.BASE]?.loading ||
                                actions[SELECT_OFFICE.BASE]?.loading ||
                                actions[FETCH_OFFICES.BASE]?.loading ||
                                actions[CREATE_PATIENT.BASE]?.loading ||
                                actions[CREATE.BASE]?.loading ||
                                actions[DOWNLOAD_PREORDER.BASE]?.loading
                            }
                            overlay={true}
                        />
                    </Access>
                </IsAvailable>
            )}
        </div>
    );
};

export default React.memo(CreatePreorderPage);
