import { FETCH_ALL_BANNERS, FETCH_FILE_BANNER } from 'redux/banners/actions';
import { BannersTypes } from 'types/BannersTypes';

const initialState: BannersTypes.State = {
    allBanners: [],
    filesBanners: {},
    loading: false,
    success: false,
    error: false
};

const bannersReducer = (state: BannersTypes.State = initialState, action: BannersTypes.Action) => {
    switch (action.type) {
        case FETCH_ALL_BANNERS.STARTED:
        case FETCH_ALL_BANNERS.ERRORED:
        case FETCH_ALL_BANNERS.SUCCEEDED: {
            const allBanners = action?.payload?.data;
            return {
                ...state,
                allBanners: allBanners && allBanners.length > 0 ? [...allBanners] : []
            };
        }
        case FETCH_FILE_BANNER.STARTED:
        case FETCH_FILE_BANNER.ERRORED:
        case FETCH_FILE_BANNER.SUCCEEDED: {
            const fileId = action.payload?.file?.fileId;
            const data = action.payload?.file?.data;
            let newStateFilesBanners = { ...state.filesBanners };
            if (fileId && data?.size) {
                const blob = new Blob([data], { type: data.type });
                newStateFilesBanners[fileId] = window.URL.createObjectURL(blob);
            }
            return {
                ...state,
                filesBanners: { ...newStateFilesBanners }
            };
        }
        default:
            return state;
    }
};

export default bannersReducer;
