import { all, takeLatest, call, put, debounce } from 'redux-saga/effects';
import * as newsClient from 'client/NewsClient';
import { DOWNLOAD_FILE, GET_COUNTERS, GET_NEWS_BY_ID, SEARCH } from './actions';
import { AxiosResponse } from 'axios';
import { NewsTypes } from 'types/NewsTypes';
import { loadFile } from 'utils/fileUtils';

function* getNewsById(action: NewsTypes.ActionGetNewsById) {
    try {
        const response: AxiosResponse<NewsTypes.NewsAPI.NewsDTO> = yield call(newsClient.getNewsById, action.meta);
        yield put(GET_COUNTERS.base());
        yield put(GET_NEWS_BY_ID.success(response));
    } catch (err: any) {
        yield put(GET_NEWS_BY_ID.error(err?.response));
    }
}

function* search(action: NewsTypes.ActionSearch) {
    try {
        const response: AxiosResponse<NewsTypes.NewsAPI.NewsDTO[]> = yield call(newsClient.search, action.meta);
        yield put(SEARCH.success(response));
    } catch (err: any) {
        yield put(SEARCH.error(err?.response));
    }
}

function* getCounters() {
    try {
        const response: AxiosResponse<NewsTypes.Count[]> = yield call(newsClient.getCounters);
        yield put(GET_COUNTERS.success(response));
    } catch (err: any) {
        yield put(GET_COUNTERS.error(err?.response));
    }
}

function* downloadFile(action: NewsTypes.ActionDownloadFile) {
    try {
        const response: AxiosResponse<BlobPart> = yield call(newsClient.downloadFile, action.meta);
        yield loadFile(response, response.headers['content-type']);
        yield put(DOWNLOAD_FILE.success());
    } catch (err: any) {
        yield put(DOWNLOAD_FILE.error(err?.response));
    }
}

export default function* newsSagas() {
    yield all([takeLatest(GET_NEWS_BY_ID.BASE, getNewsById)]);
    yield debounce(500, SEARCH.BASE, search);
    yield all([takeLatest(GET_COUNTERS.BASE, getCounters)]);
    yield all([takeLatest(DOWNLOAD_FILE.BASE, downloadFile)]);
}
