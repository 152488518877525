import React, { useEffect, useRef } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import styles from './OfficeMap.module.css';
import officeInactive from './office-inactive.svg';
import officeActive from './office-active.svg';
import officeTruncatedInactive from './office-truncated-inactive.svg';
import officeTruncatedActive from './office-truncated-active.svg';
import { useTranslation } from 'react-i18next';

const OfficeMap = ({ offices, selectedOfficeId, position, chooseOffice, selectOffice }: any) => {
    const { t } = useTranslation('common');
    // @ts-ignore
    window.chooseOffice = (index: string) => {
        chooseOffice(offices[index].office);
    };
    const mapRef = useRef<any>();

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.container.fitToViewport();
            mapRef.current.setCenter(position.center, position.zoom);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offices]);

    return (
        // @ts-ignore
        <YMaps>
            <
                // @ts-ignore
                Map
                width={'100%'}
                height={'100%'}
                modules={['Placemark', 'coordSystem.geo', 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                state={{ ...position }}
                options={{ autoFitToViewport: 'always' }}
                // @ts-ignore
                instanceRef={mapRef}
            >
                {offices
                    .filter((itemOffice: any) => itemOffice.office.latitude && itemOffice.office.longitude)
                    .map((itemOffice: any, index: any) => {
                        const { latitude, longitude } = itemOffice.office;

                        const officeTruncatedInfo = !itemOffice.allProductsAvailable
                            ? `
                            <div class=${styles.officeTruncatedInfo}>
                                <div>${t(`OfficeMap.officeTruncatedInfo`)}
                                </div>
                                <div class="officeTruncatedIcon">
                                    <svg
                                        width="26"
                                        height="24"
                                        viewBox="0 0 26 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.0003 1.5L24.667 22.5H1.33366L13.0003 1.5Z"
                                            stroke="#FF6A13"
                                            stroke-width="1.75"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M13 15.5V10.3229"
                                            stroke="#FF6A13"
                                            stroke-width="1.75"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M13 19V19.1458"
                                            stroke="#FF6A13"
                                            stroke-width="1.75"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                            `
                            : '';

                        const balloonContent = `
                            <div class='${styles.balloonHint}'>
                                    ${officeTruncatedInfo}
                                    <button
                                        onClick={window.chooseOffice(${index})}
                                        type='button'
                                        class=${styles.balloonBtn}
                                    >
                                        ${t(`OfficeMap.selectOffice`)}
                                    </button>
                            </div>`;

                        let iconImageHref;

                        if (itemOffice.allProductsAvailable) {
                            if (selectedOfficeId === itemOffice.office.id) {
                                iconImageHref = officeActive;
                            } else {
                                iconImageHref = officeInactive;
                            }
                        } else {
                            if (selectedOfficeId === itemOffice.office.id) {
                                iconImageHref = officeTruncatedActive;
                            } else {
                                iconImageHref = officeTruncatedInactive;
                            }
                        }

                        return (
                            <
                                // @ts-ignore
                                Placemark
                                key={itemOffice.office.id}
                                properties={{
                                    balloonContent: balloonContent
                                }}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: iconImageHref,
                                    iconImageSize: [32, 32],
                                    iconImageOffset: [-16, -16],
                                    hideIconOnBalloonOpen: false,
                                    autoFitToViewport: 'always',
                                    balloonCloseButton: false,
                                    balloonPanelMaxMapArea: 0,
                                    balloonAutoPanDuration: 0,
                                    balloonOffset: [0, -20]
                                }}
                                geometry={[latitude, longitude]}
                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                onClick={(event: Event) => {
                                    selectedOfficeId === itemOffice.office.id && event.preventDefault();
                                    selectOffice(itemOffice);
                                }}
                                instanceRef={(ref: any) => ref && selectedOfficeId === itemOffice.office.id && ref.balloon.open()}
                            />
                        );
                    })}
            </Map>
        </YMaps>
    );
};

export default React.memo(OfficeMap);