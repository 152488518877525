import React, { FC, Fragment, useEffect } from 'react';
import styles from './DocumentViewer.module.css';
import { Link } from 'react-router-dom';
import { GET_RESULT_DETAILS_FILE } from 'redux/results/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import ReactTooltip from 'react-tooltip';
import { PRINTED_TYPE } from 'constants/printedType';
import resultPrintedIcon from 'themes/icons/printed.svg';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';

type TProps = {
    inz: number;
    nss: string;
    file: {
        id: number;
        printedType: string;
        language: string;
    };
};

const DocumentViewer: FC<TProps> = ({ inz, nss, file }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const { resultDetailsFiles } = useSelector((state: AppTypes.State) => state.results);
    useEffect(() => {
        if (!resultDetailsFiles[inz]?.[file.id]?.['THUMB']?.objectURL && !resultDetailsFiles[inz]?.[file.id]?.['LARGE']?.objectURL) {
            dispatch(GET_RESULT_DETAILS_FILE.base({ inz, nss, fileId: file.id, size: 'THUMB' }));
            dispatch(GET_RESULT_DETAILS_FILE.base({ inz, nss, fileId: file.id, size: 'LARGE' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return ReactTooltip.rebuild() as () => void;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultDetailsFiles[inz]?.[file.id]?.['LARGE']?.objectURL]);

    return (
        <div
            className={styles.thumbnail}
            onMouseEnter={(event) => {
                if (!resultDetailsFiles[inz]?.[file.id]?.['LARGE']?.objectURL) {
                    dispatch(GET_RESULT_DETAILS_FILE.base({ inz, nss, fileId: file.id, size: 'LARGE' }));
                }
            }}
            data-for={'fileResult'}
            data-tip={
                resultDetailsFiles[inz]?.[file.id]?.['LARGE']?.objectURL &&
                `<img alt="" className={styles.preview} src=${resultDetailsFiles[inz]?.[file.id]?.['LARGE']?.objectURL} />`
            }
        >
            <Link to={`/results/pdf?nss=${nss}&inz=${inz}&fileId=${file.id}`} className={styles.thumbnailLink}>
                <Fragment>
                    <img alt="" className={styles.thumbnail} src={resultDetailsFiles[inz]?.[file.id]?.['THUMB']?.objectURL} />
                    {file.printedType === PRINTED_TYPE.PRINTED && (
                        <Icon className={styles.resultPrintedIcon} icon={`${resultPrintedIcon}#printed`} title={t(`DocumentViewer.documentPrinted`)} />
                    )}
                </Fragment>
            </Link>
            {
                // @ts-ignore
                <ReactTooltip
                    border={true}
                    borderColor={'#939393'}
                    id={'fileResult'}
                    resizeHide={true}
                    html={true}
                    type={'light'}
                    effect={'solid'}
                    place={'right'}
                />
            }
        </div>
    );
};

export default React.memo(DocumentViewer);
