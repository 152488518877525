import React, { FC, Fragment, useState } from 'react';
import styles from './FilterPreorders.module.css';
import Icon from 'components/icon/Icon';
import searchIcon from 'themes/icons/search.svg';
import Button from 'components/button/Button';
import { PreordersTypes } from 'types/PreordersTypes';
import { isEmpty } from 'utils/messageUtils';
import FilterPeriodDate from 'components/filter/FilterPeriodDate';
import { LIMIT_ITEMS_DEFAULT } from 'constants/Application';
import queryString from 'query-string';

type TProps = {
    setServerSideFilter: (param: any) => void;
    serverSideFilter: PreordersTypes.ServerSideFilter;
    selectedOfficeOrGroupId?: string;
};

const FilterPreorders: FC<TProps> = ({
    serverSideFilter: { searchString, searchType, all, start, limit, status },
    setServerSideFilter,
    serverSideFilter,
    selectedOfficeOrGroupId
}) => {
    const parsedLocationString = queryString.parse(window.location.search);
    const [limitItemsPage] = useState((parsedLocationString.limit as string) || LIMIT_ITEMS_DEFAULT);

    const renderStatuses = (statuses: { fieldName: string; label: string; value: string }[]) => {
        return statuses?.map((item, i) => (
            <Fragment key={i}>
                <input
                    value={item.value}
                    name={item.fieldName}
                    onChange={({ target }) => {
                        // @ts-ignore
                        if (serverSideFilter[target.name]?.includes(target.value)) {
                            // @ts-ignore
                            setServerSideFilter({ [target.name]: [...serverSideFilter[target.name]?.filter((item) => item !== target.value)] });
                        } else {
                            // @ts-ignore
                            setServerSideFilter({ [target.name]: [...(serverSideFilter[target.name] || []), target.value] });
                        }
                    }}
                    type="checkbox"
                />
                <label>{item.label}</label>
            </Fragment>
        ));
    };

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <input
                    value={searchString as string}
                    onChange={(event) => setServerSideFilter({ searchString: event.target.value, update: false })}
                    type="search"
                    placeholder="ФИО, дата рождения, телефон, почта, номер предзаказа"
                />
                <select
                    defaultValue={searchType as string}
                    onChange={(event) => setServerSideFilter({ searchType: event.target.value, update: false })}
                    className={styles.searchType}
                >
                    <option disabled={true} value="">
                        Тип поиска
                    </option>
                    <option value="BY_FIO">По ФИО пациента</option>
                    <option value="BY_BIRTHDAY">По дате рождения пациента</option>
                    <option value="BY_PHONE">По телефону пациента</option>
                    <option value="BY_EMAIL">По почте пациента</option>
                    <option value="BY_ARMPS_NUMBER">По номеру предзаказа в АРМПС</option>
                </select>
                <Button disabled={isEmpty(searchString)} onClick={() => setServerSideFilter({ ...setServerSideFilter, update: true })}>
                    <Icon setDefaultStyles={false} icon={`${searchIcon}#search`} className={styles.searchIcon} /> Найти
                </Button>
            </div>
            <div className={styles.statuses}>
                <FilterPeriodDate
                    classNamePopup={styles.filterPeriodDatePopup}
                    serverSideFilter={serverSideFilter}
                    setServerSideFilter={setServerSideFilter}
                    limitItemsPage={limitItemsPage}
                    selectedOfficeOrGroupId={selectedOfficeOrGroupId}
                />
                {renderStatuses([
                    { fieldName: 'status', label: 'созданные', value: 'CREATED' },
                    { fieldName: 'status', label: 'оформленные', value: 'PROCESSED' },
                    { fieldName: 'status', label: 'отмененные', value: 'CANCELLED' },
                    { fieldName: 'all', label: 'от всех сотрудников', value: 'true' }
                ])}
            </div>
        </div>
    );
};

export default React.memo(FilterPreorders);
