import { fork } from 'redux-saga/effects';
import authSagas from 'redux/auth/sagas';
import userSagas from 'redux/user/sagas';
import resultsSagas from 'redux/results/sagas';
import requestsSagas from 'redux/requests/sagas';
import dynamicSagas from 'redux/dynamics/sagas';
import newsSagas from 'redux/news/sagas';
import officeSagas from 'redux/office/sagas';
import startupSagas from 'redux/startup/sagas';
import bannersSagas from 'redux/banners/sagas';
import preordersSagas from 'redux/preorders/sagas';

export default function* rootSaga() {
    yield fork(authSagas);
    yield fork(userSagas);
    yield fork(resultsSagas);
    yield fork(requestsSagas);
    yield fork(dynamicSagas);
    yield fork(newsSagas);
    yield fork(officeSagas);
    yield fork(startupSagas);
    yield fork(bannersSagas);
    yield fork(preordersSagas);
}
