import apiClient from 'client/ApiClient';
import { ResultsTypes } from 'types/ResultsTypes';
import { BASE_URL } from './constants';
import { trimming } from 'utils/messageUtils';
const RESULTS_URL = `${BASE_URL}/results`;
const queryString = require('query-string');

export const fetchAll = (serverSideFilter: ResultsTypes.ServerSideFilter = {}) => {
    return apiClient.get<ResultsTypes.ResultsAPI.ResultDTO[]>(`${RESULTS_URL}/?${queryString.stringify(trimming(serverSideFilter))}`);
};

export const getPdfResult = (data: ResultsTypes.DataGetPdf) => {
    return apiClient.post<BlobPart>(`${RESULTS_URL}/pdf`, data.meta, { responseType: 'blob' });
};

export const markDocument = (data: ResultsTypes.DocumentMark) => {
    return apiClient.get<void>(`${RESULTS_URL}/markDocument?inzs=${data.inzs.join(',')}&markPrinted=${data.markPrinted}`);
};

export const fetchResultDetails = (data: ResultsTypes.DataGetResultDetails) => {
    return apiClient.get<ResultsTypes.TypeResultDetails[]>(`${RESULTS_URL}/details?${queryString.stringify(data)}`);
};

export const getResultDetailsFile = (data: ResultsTypes.DataGetResultDetailsFile) => {
    return apiClient.get<BlobPart>(`${RESULTS_URL}/preview?inz=${data.inz}&nss=${data.nss}&fileId=${data.fileId}&size=${data.size}`, {
        responseType: 'blob'
    });
};

export const sendByEmail = (data: ResultsTypes.DataSendByEmail) => {
    return apiClient.post<void>(`${RESULTS_URL}/sendByEmail`, data);
};
