import React from 'react';
import './App.module.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { i18n } from 'locales/i18n';
import store from 'redux/store';
import Routes from 'routing/Routes';
import Layout from 'page/layout/Layout';
import Init from './Init';

const App: React.FC = () => (
    <Provider store={store}>
        <Init>
            <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                    <Layout>
                        <Routes />
                    </Layout>
                </BrowserRouter>
            </I18nextProvider>
        </Init>
    </Provider>
);

export default React.memo(App);
