export const OfficeTag = {
    /**
     * Оплата банковской картой.
     */
    CARD_PAYMENT_AVAILABLE: 'CARD_PAYMENT_AVAILABLE',

    /**
     * Без выходных.
     */
    WORK_WITHOUT_HOLIDAYS: 'WORK_WITHOUT_HOLIDAYS',

    /**
     * Вход с колясками.
     */
    ENTRANCE_WITH_TROLLEYS: 'ENTRANCE_WITH_TROLLEYS',

    /**
     * Без очереди.
     */
    WITHOUT_QUEUING: 'WITHOUT_QUEUING',

    /**
     * Срочные анализы (2 часа).
     */
    URGENT_TESTS: 'URGENT_TESTS',

    /**
     * Ускоренный результат.
     */
    FAST_RESULTS: 'FAST_RESULTS',

    /**
     * Результат за 4 часа.
     */
    RESULTS_IN_FOUR_HOURS: 'RESULTS_IN_FOUR_HOURS',

    /**
     * Платная парковка.
     */
    PAID_PARKING: 'PAID_PARKING',

    /**
     * Бесплатная парковка.
     */
    FREE_PARKING: 'FREE_PARKING',

    /**
     * Бесплатный Wi-Fi
     */
    FREE_WI_FI: 'FREE_WI_FI'
};
