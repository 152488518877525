import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from 'page/auth/confirm/ChangePassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_PASSWORD_BY_TOKEN, CHECK_TOKEN } from 'redux/user/actions';
import { actionsSelector } from 'redux/app/selectors';
import Notification from 'components/notification/Notification';
import useRecaptcha from 'hooks/useRecaptcha';
import InputPassword from 'components/input/password/InputPassword';
import { LOGOUT } from 'redux/auth/actions';
const queryString = require('query-string');

const ChangePasswordPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actions = useSelector(actionsSelector);
    const captcha = useRecaptcha();
    const location: any = useLocation();
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [notValidPassword, setNotValidPassword] = useState(false);
    const parsedLocationString = queryString.parse(location.search);
    const { t } = useTranslation('common');

    useEffect(() => {
        dispatch(LOGOUT.base({ redirect: false }));
        dispatch(CHECK_TOKEN.base({ token: parsedLocationString.token }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.success) {
            setTimeout(() => navigate('/login', { replace: true }), 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[CHANGE_PASSWORD_BY_TOKEN.BASE]]);

    useEffect(() => {
        if (actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.error) {
            captcha.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.error]);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`ChangePasswordPage.title`)}</h1>
            <div className={styles.content}>
                {!actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.success && actions[CHECK_TOKEN.BASE]?.success && (
                    <div className={styles.form}>
                        {actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.error && (
                            <Fragment>
                                {actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.error?.errors?.map((error) => (
                                    <Fragment>
                                        <Notification type={'error'}>
                                            <p className={styles.notice}>{error.message}</p>
                                        </Notification>
                                        <br />
                                    </Fragment>
                                ))}
                            </Fragment>
                        )}
                        <form action="" method="POST">
                            <div className={styles.row}>
                                <InputPassword
                                    label={t(`Common.password`)}
                                    setNotValidPassword={setNotValidPassword}
                                    name="password"
                                    showVisibilityBtn
                                    showStrength
                                    value={password}
                                    error={notValidPassword}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                                    max={64}
                                />
                            </div>
                            <div className={styles.row}>
                                <InputPassword
                                    label={t(`Common.repeatPassword`)}
                                    name="passwordRepeat"
                                    showVisibilityBtn
                                    value={passwordRepeat}
                                    notMatch={password.length > 0 && passwordRepeat.length > 0 && password !== passwordRepeat}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordRepeat(event.target.value)}
                                    max={64}
                                />
                            </div>
                            <div className={styles.recaptcha}>{captcha?.render()}</div>
                            <div>
                                <button
                                    disabled={
                                        password.length < 1 ||
                                        password !== passwordRepeat ||
                                        actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.loading ||
                                        !captcha.token ||
                                        captcha.error ||
                                        notValidPassword
                                    }
                                    onClick={() =>
                                        dispatch(
                                            CHANGE_PASSWORD_BY_TOKEN.base({
                                                token: parsedLocationString.token,
                                                password: password,
                                                passwordRepeat: passwordRepeat,
                                                captcha: captcha.token
                                            })
                                        )
                                    }
                                    type="button"
                                >
                                    {t(`Common.send`)}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                {actions[CHANGE_PASSWORD_BY_TOKEN.BASE]?.success && (
                    <div className={styles.noticeBlock}>
                        <Notification type={'info'}>
                            <p className={styles.notice}>{t(`ChangePasswordPage.noticeInfo`)}</p>
                        </Notification>
                        <br />
                        <a href="/login">{t(`Common.linkLogin`)}</a>
                    </div>
                )}
                {actions[CHECK_TOKEN.BASE]?.error && (
                    <div className={styles.noticeBlock}>
                        <Notification type={'error'}>{t(`ChangePasswordPage.noticeError`)}</Notification>
                        <br />
                        <a href="/login">{t(`Common.linkLogin`)}</a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(ChangePasswordPage);
