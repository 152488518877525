import {
    formatISO,
    parseISO as parseISOFns,
    format,
    differenceInYears as differenceInYearsFns,
    differenceInDays as differenceInDaysFns,
    compareAsc as compareAscFns,
    fromUnixTime as fromUnixTimeFns,
    startOfDay as startOfDayFns,
    startOfMonth as startOfMonthFns,
    startOfYear as startOfYearFns,
    add as addFns,
    parse
} from 'date-fns';
import { registerLocale } from 'react-datepicker';
import { enUS, ru, uk } from 'date-fns/locale';

const defaultLocale = enUS;
const locales: any = { en: enUS, ru, uk };
Object.keys(locales).forEach((code: string) => registerLocale(code, locales[code]));

export function formatISODate(date: any) {
    return formatISO(date, { representation: 'date' });
}

export function formatISODateTime(date: any) {
    return formatISO(date, { format: 'basic' });
}

export function formatDate(date: any, locale: any, formatStr = 'P') {
    if (!date) {
        return null;
    }
    return format(date, formatStr, { locale: getLocaleObj(locale) });
}

export function getDateFormat(locale: any) {
    const localeObj = getLocaleObj(locale);
    return localeObj.formatLong.date({ width: 'short' });
}

export function formatDateTime(date: any, locale?: any, formatStr = 'Pp') {
    return format(date, formatStr, { locale: getLocaleObj(locale) });
}

export function formatTime(time: any, formatStr = 'p') {
    return format(time, formatStr);
}

export function compareAsc(date1: any, date2: any) {
    return compareAscFns(date1, date2);
}

export function parseTime(time: any, formatStr = 'p') {
    return parse(time, formatStr, new Date());
}

export function parseISO(date: any) {
    return parseISOFns(date);
}

export function startOfDay(date: any) {
    return startOfDayFns(date);
}

export function startOfMonth(date: any) {
    return startOfMonthFns(date);
}

export function startOfYear(date: any) {
    return startOfYearFns(date);
}

export function fromUnixTime(time: any) {
    return fromUnixTimeFns(time / 1000);
}

export function differenceInYears(to: any, from: any) {
    return differenceInYearsFns(to, from);
}

export function differenceInDays(to: any, from: any) {
    return differenceInDaysFns(to, from);
}

export function age(birthday: any) {
    return differenceInYears(new Date(), birthday);
}

export function add(date: any, duration: any) {
    return addFns(date, duration);
}

function getLocaleObj(locale: any) {
    return locales[locale] || defaultLocale;
}

export const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

export const localToUTC = (dateTime: Date | null) => {
    if (!dateTime) {
        return null;
    }
    return fromUnixTime(new Date(dateTime).getTime() - getTimezoneOffset(new Date(dateTime)));
};
