import React, { useState, useEffect, useRef, FC } from 'react';
import styles from './Carousel.module.css';
import { BannersTypes } from 'types/BannersTypes';

const delay = 5000;

type TProps = {
    banners: BannersTypes.Banner[] | [];
    filesBanners: any;
};

type T2Props = {
    banners: BannersTypes.Banner[] | [];
    banner: BannersTypes.Banner;
    indexItem?: number;
    indexActiveItem?: number;
    image: string;
};

const Carousel: FC<TProps> = ({ banners, filesBanners }) => {
    const [indexActiveItem, setIndexActiveItem] = useState(0);
    const timeoutRef = useRef<any>(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        if (banners.length > 1) {
            resetTimeout();
            timeoutRef.current = setTimeout(() => setIndexActiveItem((prevIndex) => (prevIndex === banners.length - 1 ? 0 : prevIndex + 1)), delay);
            return () => {
                resetTimeout();
            };
        }
    }, [banners, indexActiveItem]);

    return (
        <div className={styles.slideshow}>
            <div className={styles.slideshowSlider} style={{ transform: `translate3d(${-indexActiveItem * 100}%, 0, 0)` }}>
                {banners.map((banner, index) => (
                    <div className={styles.slide} key={index}>
                        <Item banners={banners} banner={banner} indexItem={index} indexActiveItem={indexActiveItem} image={filesBanners[banner.file.id]} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export const Item: FC<T2Props> = ({ banners, banner, image }) => {
    const img = <img title={banner.title} className={styles.banner} style={{ width: banners.length === 1 ? '750px' : '' }} alt="" src={image} />;
    return (
        <div className={styles.slideshow}>
            {banner?.uri && banner.uri.length > 0 ? (
                <a rel="noreferrer" href={banner.uri} target={'_blank'}>
                    {img}
                </a>
            ) : (
                img
            )}
        </div>
    );
};

export default React.memo(Carousel);
