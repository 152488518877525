import apiClient from 'client/ApiClient';
import { BASE_URL } from './constants';
import { DynamicsTypes } from 'types/DynamicsTypes';
const queryString = require('query-string');

const RESULTS_URL = `${BASE_URL}/dynamics`;

export const fetchByCodes = (data: DynamicsTypes.DataFetchByCodes) => {
    return apiClient.get<DynamicsTypes.DynamicsAPI.DynamicsDTO>(`${RESULTS_URL}/?${queryString.stringify(data)}`);
};

export const fetchByCode = (data: DynamicsTypes.DataFetchByCode) => {
    return apiClient.get<DynamicsTypes.DynamicsAPI.DynamicsDTO>(`${RESULTS_URL}/single/?${queryString.stringify(data)}`);
};

export const download = (data: DynamicsTypes.DataFetchByCodes) => {
    return apiClient.get<BlobPart>(`${RESULTS_URL}/download?${queryString.stringify(data)}`, { responseType: 'blob' });
};

export const createComment = (data: any) => {
    return apiClient.post<DynamicsTypes.DynamicsAPI.CommentDTO>(`${RESULTS_URL}/createResultComment`, data);
};

export const updateComment = (data: any) => {
    return apiClient.post<DynamicsTypes.DynamicsAPI.CommentDTO>(`${RESULTS_URL}/updateResultComment`, data);
};
