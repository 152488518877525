export const RESULTS = {
    actions: {
        download: 'download',
        print: 'print',
        markDocument: 'markDocument'
    },
    filter: {
        all: 'all',
        printed: 'printed',
        unprinted: 'unprinted'
    }
};
