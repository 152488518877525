import React, { useEffect, useState } from 'react';
import Textarea from 'components/textarea/Textarea';
import ButtonLink from 'components/button/link/ButtonLink';
import styles from './UserCommentCell.module.css';
import { useSelector } from 'react-redux';
import { actionsSelector } from 'redux/app/selectors';
import { CREATE_COMMENT } from 'redux/dynamics/actions';
import { useTranslation } from 'react-i18next';
import editIcon from 'themes/icons/edit.svg';
import Icon from 'components/icon/Icon';

const UserCommentCell = ({ cell, onChange }: any) => {
    const [editInProgress, setEditInProgress] = useState(false);
    const [value, setValue] = useState(null);
    const [newComment, setNewComment] = useState(false);
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');

    useEffect(() => {
        if (actions[CREATE_COMMENT.BASE]?.success) {
            setEditInProgress(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[CREATE_COMMENT.BASE]]);

    const onBlur = () => {
        const test = cell.value;
        if (onChange && value !== test.userComment) {
            onChange({
                test: test,
                comment: value,
                newComment
            });
        } else {
            setEditInProgress(false);
        }
        setValue(null);
    };

    const test = cell.value;
    const userComment = test.userComment;
    if (editInProgress) {
        return (
            <div className={styles.userCommentContainer}>
                {actions[CREATE_COMMENT.BASE]?.error ? <span className={styles.error}>{t(`UserCommentCell.error`)}</span> : null}
                <Textarea
                    placeholder={t(`UserCommentCell.comment`)}
                    maxLength={255}
                    defaultValue={userComment}
                    className={styles.textarea}
                    innerRef={(input: any) => input && input.focus()}
                    onChange={(event: any) => setValue(event.target.value)}
                    onBlur={() => onBlur()}
                    onFocus={() => {
                        setNewComment(!(userComment?.length > 0));
                        setValue(userComment);
                    }}
                    disabled={actions[CREATE_COMMENT.BASE]?.loading}
                    error={actions[CREATE_COMMENT.BASE]?.error}
                />
            </div>
        );
    } else {
        if (userComment) {
            return (
                <span className={styles.userComment} onClick={() => setEditInProgress(true)}>
                    {userComment}
                    <Icon setDefaultStyles={false} className={styles.edit} icon={`${editIcon}#edit`} />
                </span>
            );
        } else {
            return (
                <ButtonLink
                    key={test.time}
                    // @ts-ignore
                    text={t(`UserCommentCell.addComment`)}
                    onClick={() => {
                        setEditInProgress(true);
                    }}
                    className={styles.buttonLink}
                />
            );
        }
    }
};

export default React.memo(UserCommentCell);
