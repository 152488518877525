import apiClient from './ApiClient';
import { BASE_URL } from './constants';
import { UserTypes } from 'types/UserTypes';
import { trimming } from 'utils/messageUtils';
const USERS_URL = `${BASE_URL}/users`;
const USERS_OFFICE_URL = `${BASE_URL}/users-offices`;
const queryString = require('query-string');

export const login = ({ login, password, captcha }: UserTypes.DataLogin) => {
    const requestBody = {
        login,
        password,
        captcha
    };
    return apiClient.post(
        `${USERS_URL}/login`,
        queryString.stringify(requestBody, {
            skipNull: true
        }),
        {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' }
        }
    );
};

export const auth = (data: UserTypes.DataAuthUser) => {
    return apiClient.post<void>(`${USERS_URL}/auth`, data);
};

export const logout = () => {
    return apiClient.post(`${USERS_URL}/logout`);
};

export const getCurrentUser = () => {
    return apiClient.get<UserTypes.UsersAPI.UserDTO>(`${USERS_URL}/current`);
};

export const changePasswordByToken = (data: UserTypes.DataChangePasswordByToken) => {
    return apiClient.post<void>(`${USERS_URL}/passbytoken`, data);
};

export const changePassword = (data: UserTypes.DataChangePassword) => {
    return apiClient.put<void>(`${USERS_URL}/password`, data);
};

export const updateEmail = (data: UserTypes.DataUpdateEmail) => {
    return apiClient.put<void>(`${USERS_URL}/email`, data);
};

export const findUsersByOffice = () => {
    return apiClient.get<UserTypes.UsersAPI.UserDTO[]>(`${USERS_OFFICE_URL}`);
};

export const searchUsers = (data: UserTypes.DataSearchUsers) => {
    return apiClient.get<UserTypes.UsersAPI.UserDTO[]>(`${USERS_URL}/?${queryString.stringify(trimming(data))}`);
};

export const recoverPassword = (data: UserTypes.DataRecoverPassword) => {
    return apiClient.post<void>(`${USERS_URL}/sendrecovermsg`, data);
};

export const recoverChangePassword = (data: UserTypes.DataRecoverChangePassword) => {
    return apiClient.post<void>(`${USERS_URL}/recover`, data);
};

export const checkToken = (data: UserTypes.DataCheckToken) => {
    return apiClient.post<void>(`${USERS_URL}/checktoken`, data);
};

export const createUserOffice = (data: UserTypes.DataCreateUserOffice) => {
    return apiClient.post<void>(`${USERS_OFFICE_URL}`, data);
};

export const modifyPermissions = (data: UserTypes.DataModifyPermissions) => {
    return apiClient.put<void>(`${USERS_OFFICE_URL}`, data);
};

export const createUser = (data: UserTypes.DataCreateUser) => {
    return apiClient.post<void>(`${USERS_URL}`, data);
};

export const removeUserOffice = (data: UserTypes.DataRemoveUserOffice) => {
    return apiClient.delete<void>(`${USERS_OFFICE_URL}/?${queryString.stringify(data)}`);
};
