import React, { useEffect, useState } from 'react';
import styles from './PreordersPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import ReactTooltip from 'react-tooltip';
import { PreordersTypes } from 'types/PreordersTypes';
import queryString from 'query-string';
import { FETCH_ALL } from 'redux/preorders/actions';
import Notification from 'components/notification/Notification';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import FilterPreorders from 'page/preorders/components/filter/FilterPreorders';
import { LIMIT_ITEMS_DEFAULT } from 'constants/Application';
import PreorderList from 'page/preorders/create/components/list/PreorderList';
import { isEmpty } from 'utils/messageUtils';

const PreordersPage = () => {
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const { preorders, hasNextPage } = useSelector((state: AppTypes.State) => state.preorders);
    const { t } = useTranslation('common');
    const actions = useSelector(actionsSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const parsedLocationString = queryString.parse(window.location.search);
    const [serverSideFilter, setServerSideFilter] = useState<PreordersTypes.ServerSideFilter>({
        searchString: null,
        searchType: 'BY_ARMPS_NUMBER',
        fromDate: null,
        toDate: null,
        status: null,
        all: null,
        start: parsedLocationString.start || 0,
        limit: LIMIT_ITEMS_DEFAULT,
        update: true
    });

    useEffect(() => ReactTooltip.rebuild() as () => void);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id && (serverSideFilter.update || isEmpty(serverSideFilter.searchString))) {
            const newLocationString = queryString.stringify(serverSideFilter, {
                skipNull: true
            });
            navigate('/preorders?' + newLocationString);
            dispatch(FETCH_ALL.base({ serverSideFilter }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverSideFilter, selectedOfficeOrGroup?.id]);

    const handleSetServerSideFilter = (value: PreordersTypes.ServerSideFilter) => {
        setServerSideFilter((prevState) => ({
            ...prevState,
            ...value,
            update: value.update === undefined ? true : value.update
        }));
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Предзаказы</h1>
            <IsAvailable options={[{ preOrders: { enable: true } }]}>
                <Access
                    officeGroup={{
                        selected: Array.isArray(selectedOfficeOrGroup?.offices),
                        message: t(`Common.requestOfficeNotice`)
                    }}
                    message={t(`Common.preordersNoAccess`)}
                    errors={[actions[FETCH_ALL.BASE]?.error]}
                >
                    <Access permissions={[PermissionType.PREORDERS]}>
                        <Button onClick={() => navigate('/preorders/create')}>Создать предзаказ</Button>
                    </Access>
                    <FilterPreorders
                        serverSideFilter={serverSideFilter}
                        setServerSideFilter={handleSetServerSideFilter}
                        selectedOfficeOrGroupId={selectedOfficeOrGroup?.id}
                    />
                    {!actions[FETCH_ALL.BASE]?.loading && actions[FETCH_ALL.BASE]?.error && (
                        <Notification style={{ margin: '10px 0' }} type={'error'}>
                            <p>{actions[FETCH_ALL.BASE]?.error?.message}</p>
                        </Notification>
                    )}
                    {actions[FETCH_ALL.BASE]?.loading && <Preloader />}
                    {preorders.length > 0 && !actions[FETCH_ALL.BASE]?.loading ? (
                        <PreorderList
                            preorders={preorders}
                            hasNextPage={hasNextPage}
                            serverSideFilter={serverSideFilter}
                            setServerSideFilter={setServerSideFilter}
                        />
                    ) : (
                        !actions[FETCH_ALL.BASE]?.loading && !actions[FETCH_ALL.BASE]?.error && <p>{t(`Common.preordersNotFound`)}</p>
                    )}
                </Access>
                {
                    // @ts-ignore
                    <ReactTooltip
                        id={'preorders'}
                        border={true}
                        borderColor={'#939393'}
                        resizeHide={true}
                        html={true}
                        type={'light'}
                        effect={'solid'}
                        place={'top'}
                    />
                }
            </IsAvailable>
        </div>
    );
};

export default React.memo(PreordersPage);
