import React, { FC, useState, useMemo, ReactNode } from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import styles from './Layout.module.css';
import HeaderMenu from 'page/layout/menu/HeaderMenu';
import { useSelector } from 'react-redux';
export const LayoutContext = React.createContext({
    header: true,
    headerInfo: true,
    headerMenu: true,
    footer: true,
    setVisibility: (value: any) => {}
});

type TProps = {
    children: ReactNode;
};

const Layout: FC<TProps> = ({ children }) => {
    const { authenticated } = useSelector((state: any) => state.auth);
    const [visibility, setVisibility] = useState({
        header: true,
        headerInfo: true,
        headerMenu: true,
        footer: true
    });

    const handleSetVisibility = (value: any) => setVisibility((prevState: any) => ({ ...prevState, ...value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const value = useMemo(() => ({ visibility, setVisibility: handleSetVisibility }), []);

    return (
        // @ts-ignore
        <LayoutContext.Provider value={value}>
            <div className={styles.layout}>
                {visibility.header && <Header headerInfo={visibility.headerInfo} authenticated={authenticated} />}
                {visibility.headerMenu && <HeaderMenu authenticated={authenticated} />}
                <main className={styles.main}>{children}</main>
                {visibility.footer && <Footer />}
            </div>
        </LayoutContext.Provider>
    );
};

export default React.memo(Layout);
