import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'redux/startup/selectors';
declare let grecaptcha: any;

const useRecaptcha = () => {
    const settings = useSelector(settingsSelector);
    const [token, setToken] = useState<string | null>(null);
    const [error, setError] = useState(false);
    const [container, setContainer] = useState<HTMLDivElement | null>();

    useEffect(() => {
        if (container && settings.reCaptchaEnable) {
            grecaptcha?.ready(function () {
                grecaptcha.render(container, {
                    sitekey: settings.reCaptchaSiteKey,
                    callback: (token: string) => {
                        setToken(token);
                        setError(false);
                    },
                    'expired-callback': (data: any) => {
                        grecaptcha.reset(container);
                        setToken(null);
                        setError(data);
                    },
                    'data-error-callback': (data: any) => {
                        grecaptcha.reset(container);
                        setToken(null);
                        setError(data);
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container]);

    return {
        render: () =>
            settings.reCaptchaEnable ? (
                <div
                    ref={(el) => {
                        setContainer(el);
                    }}
                />
            ) : null,
        token: settings.reCaptchaEnable ? token : ' ',
        error: settings.reCaptchaEnable ? error : false,
        setError: (value: boolean) => setError(value),
        reset: settings.reCaptchaEnable
            ? () => {
                  setToken(null);
                  grecaptcha.reset(container);
              }
            : () => true
    };
};

export default useRecaptcha;
