import React, { useEffect, useState } from 'react';
import styles from './PreorderForm.module.css';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { declOfNum, enforceMinMax, getGenderMessage, isEmpty, parseFIO } from 'utils/messageUtils';
import { PreordersTypes } from 'types/PreordersTypes';
import Modal from 'components/modal/Modal';
import Preloader from 'components/preloader/Preloader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { actionsSelector } from 'redux/app/selectors';
import { AppTypes } from 'types/AppTypes';
import { CLEAR_PREORDER, CREATE, SEARCH_PATIENTS, SHOW_OFFICE_SELECTION_MODAL } from 'redux/preorders/actions';
import CreatePatientForm from '../form/CreatePatientForm';
import OfficeSelectionModal from '../../create/components/OfficeSelectionModal';
import ProductsPanel from 'page/preorders/components/formProducts/ProductsPanel';
import PatientCard from 'components/card/PatientCard';
import OfficeCard from 'components/card/OfficeCard';
import StepNotice from 'page/preorders/components/formProducts/notice/StepNotice';
import { isUndefined } from 'lodash';
import Notification from 'components/notification/Notification';
import ProductList from 'page/preorders/components/form/ProductList';
import { isValidCreateFormSelector } from 'redux/preorders/selectors';
import { differenceInDays, parseISO } from 'date-fns';
import { OfficesTypes } from 'types/OfficesTypes';
import { formatDate } from 'utils/timeUtils';

const PreorderForm = ({
    preorder,
    setPreorder,
    createPreorder,
    create,
    details,
    selectedOfficeOrGroup,
    dispatch
}: {
    preorder?: PreordersTypes.Preorder | PreordersTypes.CreatePreorder | null;
    setPreorder?: any;
    createPreorder?: any;
    create?: boolean;
    details?: boolean;
    selectedOfficeOrGroup?: OfficesTypes.OfficeType | null;
    dispatch?: any;
}) => {
    const { patients, showOfficeSelectionModal } = useSelector((state: AppTypes.State) => state.preorders.preorder);
    const isValidCreateForm = useSelector(isValidCreateFormSelector);
    const [showPatientsModal, setShowPatientsModal] = useState(false);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [showCreatePatientForm, setShowCreatePatientForm] = useState(false);
    const [searchString, setSearchString] = useState('');
    const productsModalRef = React.createRef();
    const submitRef = React.createRef();
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');

    useEffect(() => {
        if (!isEmpty(searchString)) {
            dispatch(SEARCH_PATIENTS.base({ searchString, maxResult: 100 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, searchString]);

    const renderDaysOfLifePreorder = (createdDate: any, activeToDate: any, formatted: boolean = false) => {
        const difference = differenceInDays(parseISO(activeToDate as string), parseISO(createdDate as string));
        return `${difference} ${declOfNum(difference, [`день`, `дня`, `дней`])}`;
    };

    const renderPatientList = () => {
        return (
            <div>
                {patients?.length < 1 && !isEmpty(searchString) && !actions[SEARCH_PATIENTS.BASE]?.loading && !showCreatePatientForm ? (
                    <p>Пациенты не найдены</p>
                ) : null}
                {patients.length > 0 && !showCreatePatientForm && (
                    <div className={styles.patientList}>
                        {patients?.map((patient: PreordersTypes.Patient) => {
                            const { birthday, gender, email, mobilePhone } = patient;
                            return (
                                <div key={patient.id} className={styles.patient}>
                                    <div className={styles.patientData}>
                                        <p>{parseFIO(patient)}</p>
                                        {birthday && <p>{formatDate(parseISO(birthday), 'ru') || '-'}</p>}
                                        <p>{t(`PatientCard.${getGenderMessage(gender)}`)}</p>
                                        <p>{email}</p>
                                        <p>{mobilePhone}</p>
                                    </div>
                                    <div>
                                        {preorder?.patient?.id !== patient.id ? (
                                            <button
                                                onClick={() => {
                                                    setPreorder({ patient });
                                                    setShowPatientsModal(false);
                                                }}
                                            >
                                                Выбрать
                                            </button>
                                        ) : (
                                            <button className={styles.selectedButton} onClick={() => setPreorder({ patient: null })}>
                                                Снять выбор
                                            </button>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
                {!showCreatePatientForm && (
                    <button className={styles.button} onClick={() => setShowCreatePatientForm(true)}>
                        Создать нового пациента
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className={styles.form}>
            <StepNotice
                show={create}
                trigger={submitRef}
                target={preorder?.patient}
                showNotice={create && !preorder?.patient}
                notice={'Нажмите здесь, чтобы выбрать или добавить пациента'}
            >
                <button className={styles.button} onClick={() => setShowPatientsModal(!showPatientsModal)}>
                    Выбрать пациента
                </button>
            </StepNotice>
            {preorder?.patient && <PatientCard dynamics={true} patient={preorder?.patient} />}
            <StepNotice
                show={create}
                trigger={submitRef}
                error={preorder?.products && !(preorder?.products?.length > 0)}
                // @ts-ignore
                showNotice={!isUndefined(preorder?.patient) && !preorder?.products?.length > 0}
                notice={'Нажмите здесь, чтобы выбрать продукты предзаказа'}
            >
                <button disabled={!preorder?.patient?.id} className={styles.button} onClick={() => setShowProductsModal(!showProductsModal)}>
                    Добавить продукты
                </button>
            </StepNotice>
            {preorder?.products && preorder?.products?.length > 0 && (
                <div className={styles.row}>
                    <ProductsPanel preorder={preorder} />
                </div>
            )}
            <StepNotice
                show={create}
                trigger={submitRef}
                error={isEmpty(preorder?.officeId)}
                // @ts-ignore
                showNotice={create && !preorder?.officeId && preorder?.products?.length > 0}
                notice={'Нажмите здесь, чтобы выбрать медицинский офис для оказания услуг'}
            >
                <button
                    // @ts-ignore
                    disabled={!preorder?.products?.length > 0 && !preorder?.officeId}
                    className={styles.button}
                    onClick={() => dispatch(SHOW_OFFICE_SELECTION_MODAL.base({ show: !showOfficeSelectionModal }))}
                >
                    Выбрать медицинский офис
                </button>
            </StepNotice>
            {preorder && (preorder?.officeId || preorder?.dstOffice?.id) && (
                <div className={styles.row}>
                    <OfficeCard office={preorder?.dstOffice} />
                </div>
            )}
            <div className={styles.row}>
                <label>Срок жизни предзаказа (1-30 дней)</label>
                <Input
                    trigger={submitRef}
                    required={true}
                    onChange={({ target }: any) => setPreorder({ daysOfLifePreorder: enforceMinMax(target, target.value) })}
                    value={details ? renderDaysOfLifePreorder(preorder?.createdDate, preorder?.activeToDate) : preorder?.daysOfLifePreorder?.toString()}
                    disabled={details}
                    min={create && '1'}
                    max={create && '30'}
                    type={create ? 'number' : 'text'}
                    setStyle={false}
                />
            </div>
            <div className={styles.row}>
                <label>Тип полиса</label>
                <select
                    onChange={({ target }) =>
                        !isEmpty(target.value) ? setPreorder({ insuranceType: target.value }) : setPreorder({ insuranceType: null, insuranceNumber: null })
                    }
                    disabled={details}
                    value={preorder?.insuranceType || 1}
                >
                    <option value={''}>не выбран</option>
                    <option value={'OMS'}>ОМС</option>
                    <option value={'DMS'}>ДМС</option>
                </select>
            </div>
            {preorder?.insuranceType && preorder?.insuranceType.length > 0 && (
                <div className={styles.row}>
                    <label>
                        № полиса пациента<span>*</span>
                    </label>
                    <Input
                        maxLength={128}
                        trigger={submitRef}
                        required={true}
                        disabled={details}
                        onChange={(event: any) => setPreorder({ insuranceNumber: event.target.value })}
                        value={preorder?.insuranceNumber}
                        type="text"
                        setStyle={false}
                    />
                </div>
            )}
            {create && (
                <div className={styles.row}>
                    {actions[CREATE.BASE]?.error && <Notification type={'error'}>Произошла ошибка при создании предзаказа</Notification>}
                    <Button
                        innerRef={submitRef}
                        disabled={actions[CREATE.BASE]?.loading}
                        className={styles.button}
                        onClick={() => isValidCreateForm && createPreorder(preorder)}
                    >
                        Создать
                    </Button>
                </div>
            )}
            <Modal className={styles.modals} show={showPatientsModal} onClose={() => setShowPatientsModal(false)}>
                <div className={styles.modalContainer}>
                    {!showCreatePatientForm ? (
                        <div className={styles.row}>
                            <label>Найти и выбрать пациента: </label>
                            <input
                                onChange={(e) => setSearchString(e.target.value)}
                                value={searchString}
                                className={styles.email}
                                type="search"
                                name="searchString"
                                placeholder={'ФИО, дата рождения, телефон, почта'}
                            />
                        </div>
                    ) : (
                        <CreatePatientForm
                            setShowCreatePatientForm={setShowCreatePatientForm}
                            setShowPatientsModal={setShowPatientsModal}
                            setPreorder={setPreorder}
                        />
                    )}
                    {!actions[SEARCH_PATIENTS.BASE]?.loading ? renderPatientList() : <Preloader />}
                    {actions[SEARCH_PATIENTS.BASE]?.error && <Notification type={'error'}>Произошла ошибка при поиске пациента</Notification>}
                </div>
            </Modal>
            <Modal ref={productsModalRef} className={styles.modals} show={showProductsModal} onClose={() => setShowProductsModal(false)}>
                <div className={styles.modalContainer}>
                    <ProductList productsModalRef={productsModalRef} setShowProductsModal={setShowProductsModal} />
                </div>
            </Modal>
            <OfficeSelectionModal preorder={preorder} setPreorder={setPreorder} />
        </div>
    );
};

export default React.memo(PreorderForm, (prevProps: any, nextProps: any) => {
    if (prevProps?.selectedOfficeOrGroup?.id !== nextProps?.selectedOfficeOrGroup?.id) {
        nextProps?.dispatch(CLEAR_PREORDER.base());
    }
    return false;
});
