import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './ResultsPage.module.css';
import FilterPeriodDate from 'components/filter/FilterPeriodDate';
import ResultList from 'components/list/ResultList';
import classNames from 'classnames';
import { FETCH_ALL, GET_PDF_RESULT } from 'redux/results/actions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/modal/Modal';
import { AppTypes } from 'types/AppTypes';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { actionsSelector } from 'redux/app/selectors';
import { MARK_DOCUMENT } from 'redux/results/actions';
import { RESULTS } from 'redux/results/constants';
import { ResultsTypes } from 'types/ResultsTypes';
import { RESULT_STATUS } from 'constants/ResultStatus';
import ReactTooltip from 'react-tooltip';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import AmountElementsSelector from 'components/amountElementsSelector/AmountElementsSelector';
import { PRINTED_TYPE } from 'constants/printedType';
import { SET_ACTIONS } from 'redux/app/actions';
import { useTranslation } from 'react-i18next';
import { LIMIT_ITEMS_DEFAULT } from 'constants/Application';
import { AUTH_USER } from 'redux/user/actions';
const queryString = require('query-string');

const ResultsPage = () => {
    const [selectedResults, setSelectedResults] = useState<ResultsTypes.TypeResult[] | []>([]);
    const [isSelectedNotPrinted, setIsSelectedNotPrinted] = useState(false);
    const [filteredResults] = useState<ResultsTypes.TypeResult[] | null>(null);
    const [showActionModal, setShowActionModal] = useState(false);
    const [searchString, setSearchString] = useState<string>('');
    const [selected, setSelected] = useState<string | null>();
    const navigate = useNavigate();
    const location: any = useLocation();
    const navigationAction = useNavigationType();
    const parsedLocationString = queryString.parse(location.search);
    const [limitItemsPage, setLimitItemsPage] = useState(navigationAction === 'POP' ? parsedLocationString.limit : LIMIT_ITEMS_DEFAULT);
    const [serverSideFilter, setServerSideFilter] = useState<ResultsTypes.ServerSideFilter>({
        filter: parsedLocationString.filter,
        notPrinted: parsedLocationString.notPrinted,
        outOfReferenceValues: parsedLocationString.outOfReferenceValues,
        fromDate: parsedLocationString.fromDate,
        toDate: parsedLocationString.toDate,
        start: parsedLocationString.start || 0,
        limit: limitItemsPage
    });
    const actions = useSelector(actionsSelector);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const { results, hasNextPage } = useSelector((state: AppTypes.State) => state.results);
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const [actionButtons, setActionButtons] = useState({
        download: {
            active: false
        },
        print: {
            active: false
        },
        markAs: {
            active: false
        }
    });

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            const newLocationString = queryString.stringify(serverSideFilter, {
                skipNull: true
            });
            if (navigationAction === 'POP') {
                navigate('/results?' + newLocationString);
                dispatch(FETCH_ALL.base({ serverSideFilter }));
            }
            if (selected === selectedOfficeOrGroup?.id && navigationAction !== 'POP') {
                navigate(`${newLocationString ? '/results?' + newLocationString : `/results?limit=${limitItemsPage}&start=0`}`);
                dispatch(FETCH_ALL.base({ serverSideFilter }));
            }
            if (selected !== selectedOfficeOrGroup?.id && navigationAction !== 'POP') {
                setSelectedResults([]);
                navigate(`/results?limit=${limitItemsPage}&start=0`);
                setServerSideFilter({ limit: limitItemsPage, start: 0 });
            }
        }
        setSelected(selectedOfficeOrGroup?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverSideFilter, selectedOfficeOrGroup?.id]);

    useEffect(() => {
        setShowActionModal(actions[GET_PDF_RESULT.BASE]?.loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[GET_PDF_RESULT.BASE]?.loading]);

    useEffect(() => {
        setShowActionModal(actions[GET_PDF_RESULT.BASE]?.loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[GET_PDF_RESULT.BASE]?.loading]);

    useEffect(() => {
        if (actions[MARK_DOCUMENT.BASE]?.success) {
            setSelectedResults([]);
            dispatch(FETCH_ALL.base({ serverSideFilter }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[MARK_DOCUMENT.BASE]]);

    useEffect(() => {
        if (actions[GET_PDF_RESULT.BASE]?.success) {
            setSelectedResults([]);
            dispatch(FETCH_ALL.base({ serverSideFilter }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[GET_PDF_RESULT.BASE]]);

    useEffect(() => {
        const check =
            selectedResults.filter((result) => result.printedType === PRINTED_TYPE.UNPRINTED || result.printedType === PRINTED_TYPE.PARTIALLY).length > 0;
        setIsSelectedNotPrinted(check);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedResults]);

    useEffect(() => {
        dispatch({
            type: SET_ACTIONS,
            meta: {
                [FETCH_ALL.BASE]: { loading: true }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSetServerSideFilter = (value: ResultsTypes.ServerSideFilter) => {
        setServerSideFilter((prevState) => ({
            ...prevState,
            ...value
        }));
    };

    const handleAction = (action: string, filter?: string, value?: string) => {
        let filtered_INZ: string[] = [];
        let filtered_INZ_NSS: { firstName?: string | null; middleName?: string | null; lastName?: string | null; inz: string; nss: string }[] = [];
        selectedResults.forEach(function (result: ResultsTypes.TypeResult) {
            if (action === RESULTS.actions.download || action === RESULTS.actions.print) {
                filtered_INZ_NSS.push({
                    firstName: result?.patient?.firstName,
                    middleName: result?.patient?.middleName,
                    lastName: result?.patient?.lastName,
                    inz: result.inz,
                    nss: result.patient.nss
                });
            } else {
                return filtered_INZ.push(result.inz);
            }
        });

        if (action === RESULTS.actions.download || action === RESULTS.actions.print) {
            if (filtered_INZ_NSS.length > 0) {
                dispatch(
                    GET_PDF_RESULT.base({
                        orders: [...filtered_INZ_NSS],
                        fileId: null,
                        download: action === RESULTS.actions.download ? true : action === RESULTS.actions.print ? false : '',
                        markPrinted: action === RESULTS.actions.download ? false : action === RESULTS.actions.print ? true : '',
                        onlyNotPrinted: filter === RESULTS.filter.unprinted
                    })
                );
            }
        }

        if (action === RESULTS.actions.markDocument) {
            if (filtered_INZ.length > 0) {
                dispatch(MARK_DOCUMENT.base({ inzs: [...filtered_INZ], markPrinted: value }));
            }
        }

        setActionButtons({
            download: {
                active: false
            },
            print: {
                active: false
            },
            markAs: {
                active: false
            }
        });
    };

    const handleSelectResult = (event: React.ChangeEvent<HTMLInputElement> | null, result?: ResultsTypes.TypeResult | null) => {
        event?.stopPropagation();
        if (result) {
            if (result.statusType !== RESULT_STATUS.NOT_COMPLETED) {
                setSelectedResults((prevState) => {
                    const isSelected = prevState.filter((item) => item.inz === result.inz);
                    if (isSelected.length > 0) {
                        return [...prevState.filter((item) => item.inz !== result.inz)];
                    } else {
                        return [...prevState, result];
                    }
                });
            }
        } else {
            if (selectedResults.length === results.length || selectedResults.length === filteredResults?.length) {
                setSelectedResults((prevState) => {
                    if (prevState.length > 0) {
                        return [];
                    } else {
                        return [...prevState.filter((result) => result.statusType !== RESULT_STATUS.NOT_COMPLETED)];
                    }
                });
            } else {
                setSelectedResults(
                    filteredResults && filteredResults?.length > 0
                        ? filteredResults.filter((result) => result.statusType !== RESULT_STATUS.NOT_COMPLETED)
                        : results?.length > 0
                        ? results.filter((result) => result.statusType !== RESULT_STATUS.NOT_COMPLETED)
                        : []
                );
            }
        }
    };

    const renderActionModal = () => (
        <Modal show={actions[GET_PDF_RESULT.BASE]?.loading} onClose={() => setShowActionModal(false)}>
            <div className={styles.actionModal}>
                <h2>{t(`ResultsPage.preparationResults`)}</h2>
                <p>
                    {t(`ResultsPage.numberSelectedResults`)} {selectedResults.length}
                </p>
                <p>{t(`ResultsPage.pleaseWait`)}</p>
            </div>
        </Modal>
    );

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t(`ResultsPage.title`)}</h1>
            <IsAvailable options={[{ results: true }]}>
                <Access permissions={[PermissionType.RESULTS]} message={t(`Common.noAccess`)} errors={[actions[FETCH_ALL.BASE]?.error]}>
                    <FilterPeriodDate
                        serverSideFilter={serverSideFilter}
                        setServerSideFilter={handleSetServerSideFilter}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        limitItemsPage={limitItemsPage}
                        selectedOfficeOrGroupId={selectedOfficeOrGroup?.id}
                    />
                    <div className={styles.actions}>
                        <div className={styles.actionControls}>
                            <div className={styles.action}>
                                <div
                                    className={classNames([styles.actionButton, selectedResults.length < 1 ? styles.actionButtonDisabled : null])}
                                    onClick={() => {
                                        setActionButtons((prevState) => ({
                                            ...prevState,
                                            download: {
                                                active: !prevState.download.active
                                            }
                                        }));
                                    }}
                                >
                                    {t(`Common.download`)}
                                </div>
                                <div className={classNames([styles.actionList, actionButtons.download.active ? styles.actionListActive : null])}>
                                    <div className={styles.actionListItem} onClick={() => handleAction(RESULTS.actions.download, RESULTS.filter.all)}>
                                        {t(`ResultsPage.allDocumentsSelected`)}
                                    </div>
                                    <div
                                        className={classNames([styles.actionListItem, !isSelectedNotPrinted ? styles.disabled : ''])}
                                        onClick={() => isSelectedNotPrinted && handleAction(RESULTS.actions.download, RESULTS.filter.unprinted)}
                                    >
                                        {t(`ResultsPage.onlyUnprinted`)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.action}>
                                <div
                                    className={classNames([styles.actionButton, selectedResults.length < 1 ? styles.actionButtonDisabled : null])}
                                    onClick={() =>
                                        setActionButtons((prevState) => ({
                                            ...prevState,
                                            print: {
                                                active: !prevState.print.active
                                            }
                                        }))
                                    }
                                >
                                    {t(`Common.print`)}
                                </div>
                                <div className={classNames([styles.actionList, actionButtons.print.active ? styles.actionListActive : null])}>
                                    <div className={styles.actionListItem} onClick={() => handleAction(RESULTS.actions.print, RESULTS.filter.all)}>
                                        {t(`ResultsPage.allDocumentsSelected`)}
                                    </div>
                                    <div
                                        className={classNames([styles.actionListItem, !isSelectedNotPrinted ? styles.disabled : null])}
                                        onClick={() => isSelectedNotPrinted && handleAction(RESULTS.actions.print, RESULTS.filter.unprinted)}
                                    >
                                        {t(`ResultsPage.onlyUnprinted`)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.action}>
                                <div
                                    className={classNames([styles.actionButton, selectedResults.length < 1 ? styles.actionButtonDisabled : null])}
                                    onClick={() =>
                                        setActionButtons((prevState) => ({
                                            ...prevState,
                                            markAs: {
                                                active: !prevState.markAs.active
                                            }
                                        }))
                                    }
                                >
                                    {t(`ResultsPage.markAs`)}
                                </div>
                                <div className={classNames([styles.actionList, actionButtons.markAs.active ? styles.actionListActive : null])}>
                                    <div
                                        className={styles.actionListItem}
                                        onClick={() => handleAction(RESULTS.actions.markDocument, '', RESULTS.filter.printed)}
                                    >
                                        {t(`Common.printed`)}
                                    </div>
                                    <div
                                        className={styles.actionListItem}
                                        onClick={() => handleAction(RESULTS.actions.markDocument, '', RESULTS.filter.unprinted)}
                                    >
                                        {t(`Common.unprinted`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AmountElementsSelector
                            currentValue={limitItemsPage}
                            handleSetServerSideFilter={handleSetServerSideFilter}
                            setLimitItemsPage={setLimitItemsPage}
                        />
                    </div>
                    <ResultList
                        loading={actions[FETCH_ALL.BASE]?.loading || actions[AUTH_USER.BASE]?.loading}
                        results={filteredResults ? filteredResults : results}
                        selectedResults={selectedResults}
                        onSelectResult={handleSelectResult}
                        setServerSideFilter={handleSetServerSideFilter}
                        limitItemsPage={limitItemsPage}
                        hasNextPage={hasNextPage}
                    />
                    {showActionModal && renderActionModal()}
                    {
                        // @ts-ignore
                        <ReactTooltip
                            border={true}
                            borderColor={'#939393'}
                            id={'results'}
                            resizeHide={true}
                            html={true}
                            type={'light'}
                            effect={'solid'}
                            place={'top'}
                        />
                    }
                </Access>
            </IsAvailable>
        </div>
    );
};

export default React.memo(ResultsPage);
