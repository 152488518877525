import apiClient from 'client/ApiClient';
import * as userClient from 'client/UserClient';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import localStorageService from 'services/LocalStorageService';
import { AUTHENTICATE, CLEAR_AUTHENTICATE, LOGIN, LOGOUT } from './actions';
import { TOKEN_HEADER, TOKEN_KEY } from './constants';
import { AxiosResponse } from 'axios';
import { AuthTypes } from 'types/AuthTypes';
import { fetchOffices } from 'redux/office/sagas';
import { SELECT_OFFICE_OR_GROUP } from 'redux/office/actions';

function* authenticate() {
    const token = localStorageService.getItem(TOKEN_KEY);
    if (token) {
        apiClient.setGlobalHeader(TOKEN_HEADER, token);
        try {
            yield call(fetchOffices);
            yield put(AUTHENTICATE.success());
        } catch (err: any) {
            apiClient.removeGlobalHeader(TOKEN_HEADER);
            localStorageService.removeItem(TOKEN_KEY);
            yield put(AUTHENTICATE.error());
        }
    } else {
        yield put(AUTHENTICATE.error());
    }
}

function* login(action: AuthTypes.ActionLogin) {
    try {
        const response: AxiosResponse = yield call(userClient.login, action.meta);
        const token = response.headers[TOKEN_HEADER];
        apiClient.setGlobalHeader(TOKEN_HEADER, token);
        localStorageService.setItem(TOKEN_KEY, token);
        yield call(fetchOffices);
        yield put(LOGIN.success(response));
    } catch (err: any) {
        yield put(LOGIN.error());
    }
}

function* logout(meta: { redirect?: boolean } = { redirect: true }) {
    try {
        yield call(userClient.logout);
        apiClient.removeGlobalHeader(TOKEN_HEADER);
        localStorageService.removeItem(TOKEN_KEY);
        yield put(CLEAR_AUTHENTICATE.success());
        yield put(SELECT_OFFICE_OR_GROUP.success());
        if (meta.redirect) {
            window.location.replace('/');
        }
    } catch (err: any) {
        yield put(LOGOUT.error());
    }
}

export default function* authSagas() {
    yield all([takeLatest(LOGIN.BASE, login), takeLatest(LOGOUT.BASE, logout), takeLatest(AUTHENTICATE.BASE, authenticate)]);
}
