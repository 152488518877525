import { FETCH_ALL, FETCH_RESULT_DETAILS, GET_PDF_RESULT, MARK_DOCUMENT, GET_RESULT_DETAILS_FILE, SEND_BY_EMAIL } from 'redux/results/actions';
import { ResultsTypes } from 'types/ResultsTypes';

const initialState: ResultsTypes.State = {
    results: [],
    resultDetails: null,
    resultDetailsFiles: {},
    objectURLPdfResult: null,
    responsePdfData: null,
    hasNextPage: null,
    loading: false,
    success: false,
    error: false
};

const userReducer = (state: ResultsTypes.State = initialState, action: ResultsTypes.Action): ResultsTypes.State => {
    switch (action.type) {
        case FETCH_ALL.STARTED:
        case FETCH_ALL.ERRORED:
        case FETCH_ALL.SUCCEEDED: {
            const data = action?.payload?.data?.requisitions;
            const hasNextPage = action?.payload?.data?.hasNextPage || null;
            return {
                ...state,
                results: data && data.length > 0 ? [...data] : [],
                hasNextPage
            };
        }
        case SEND_BY_EMAIL.STARTED:
        case SEND_BY_EMAIL.ERRORED:
        case SEND_BY_EMAIL.SUCCEEDED: {
            return {
                ...state
            };
        }
        case MARK_DOCUMENT.STARTED:
        case MARK_DOCUMENT.ERRORED:
        case MARK_DOCUMENT.SUCCEEDED: {
            return {
                ...state
            };
        }
        case GET_PDF_RESULT.STARTED:
        case GET_PDF_RESULT.ERRORED:
        case GET_PDF_RESULT.SUCCEEDED: {
            let objectURL = null;
            if (action?.payload?.responseData) {
                const responseData = action?.payload?.responseData;
                const blob = new Blob([responseData], { type: 'application/pdf' });
                objectURL = window.URL.createObjectURL(blob);
            }
            return {
                ...state,
                objectURLPdfResult: objectURL,
                responsePdfData: action?.payload?.response
            };
        }
        case FETCH_RESULT_DETAILS.STARTED:
        case FETCH_RESULT_DETAILS.ERRORED:
        case FETCH_RESULT_DETAILS.SUCCEEDED: {
            const resultDetails = action?.payload?.data;
            return {
                ...state,
                resultDetails: resultDetails || null
            };
        }
        case GET_RESULT_DETAILS_FILE.ERRORED:
        case GET_RESULT_DETAILS_FILE.SUCCEEDED: {
            if (action?.payload?.inz) {
                const { inz, fileId = '', size, responseData, type } = action?.payload;
                if (inz && fileId >= 0 && size && responseData) {
                    const blob = new Blob([responseData], { type });
                    const objectURL = window.URL.createObjectURL(blob);
                    return {
                        ...state,
                        resultDetailsFiles: {
                            ...state.resultDetailsFiles,
                            [inz]: {
                                ...(state.resultDetailsFiles[inz] || null),
                                [fileId]: {
                                    ...(state.resultDetailsFiles[inz]?.[fileId] || null),
                                    [size]: {
                                        objectURL
                                    }
                                }
                            }
                        }
                    };
                } else {
                    return {
                        ...state
                    };
                }
            } else {
                return {
                    ...state
                };
            }
        }
        default:
            return state;
    }
};

export default userReducer;
