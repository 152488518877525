import { all, takeLatest, call, put, debounce } from 'redux-saga/effects';
import * as userClient from 'client/UserClient';
import {
    FIND_USERS_BY_OFFICE,
    SEARCH_USERS,
    MODIFY_PERMISSIONS,
    CREATE_USER,
    CREATE_USER_OFFICE,
    REMOVE_USER_OFFICE,
    AUTH_USER,
    CHANGE_PASSWORD_BY_TOKEN,
    CHANGE_PASSWORD,
    UPDATE_EMAIL,
    RECOVER_PASSWORD,
    CHECK_TOKEN,
    GET_CURRENT_USER,
    RECOVER_CHANGE_PASSWORD
} from './actions';
import { AxiosResponse } from 'axios';
import { UserTypes } from 'types/UserTypes';
import { SELECT_OFFICE_OR_GROUP } from 'redux/office/actions';
import { OfficesTypes } from 'types/OfficesTypes';

function* findUsersByOffice() {
    try {
        const response: AxiosResponse<UserTypes.UsersAPI.UserDTO[]> = yield call(userClient.findUsersByOffice);
        yield put(FIND_USERS_BY_OFFICE.success(response));
    } catch (err: any) {
        yield put(FIND_USERS_BY_OFFICE.error(err?.response));
    }
}

function* searchUsers(action: UserTypes.ActionSearchUsers) {
    try {
        const response: AxiosResponse<UserTypes.UsersAPI.UserDTO[]> = yield call(userClient.searchUsers, action.meta);
        yield put(SEARCH_USERS.success(response));
    } catch (err: any) {
        yield put(SEARCH_USERS.error(err?.response));
    }
}

function* createUserOffice(action: UserTypes.ActionCreateUserOffice) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.createUserOffice, action.meta);
        yield put(CREATE_USER_OFFICE.success(response));
    } catch (err: any) {
        yield put(CREATE_USER_OFFICE.error(err?.response));
    }
}

function* modifyPermissions(action: UserTypes.ActionModifyPermissions) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.modifyPermissions, action.meta);
        yield put(MODIFY_PERMISSIONS.success(response));
    } catch (err: any) {
        yield put(MODIFY_PERMISSIONS.error(err?.response));
    }
}

function* createUser(action: UserTypes.ActionCreateUser) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.createUser, action.meta);
        yield put(CREATE_USER.success(response));
        yield call(createUserOffice as any, {
            meta: {
                userId: response.data,
                permissions: action.meta.permissions
            }
        });
    } catch (err: any) {
        yield put(CREATE_USER.error(err?.response));
    }
}

function* removeUserOffice(action: UserTypes.ActionRemoveUserOffice) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.removeUserOffice, action.meta);
        yield put(REMOVE_USER_OFFICE.success(response));
    } catch (err: any) {
        yield put(REMOVE_USER_OFFICE.error(err?.response));
    }
}

function* changePasswordByToken(action: UserTypes.ActionChangePasswordByToken) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.changePasswordByToken, action.meta);
        yield put(CHANGE_PASSWORD_BY_TOKEN.success(response));
    } catch (err: any) {
        yield put(CHANGE_PASSWORD_BY_TOKEN.error(err?.response));
    }
}

function* changePassword(action: UserTypes.ActionChangePassword) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.changePassword, action.meta);
        yield put(CHANGE_PASSWORD.success(response));
    } catch (err: any) {
        yield put(CHANGE_PASSWORD.error(err?.response));
    }
}

function* updateEmail(action: UserTypes.ActionUpdateEmail) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.updateEmail, action.meta);
        yield put(UPDATE_EMAIL.success(response));
    } catch (err: any) {
        yield put(UPDATE_EMAIL.error(err?.response));
    }
}

function* recoverPassword(action: UserTypes.ActionRecoverPassword) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.recoverPassword, action.meta);
        yield put(RECOVER_PASSWORD.success(response));
    } catch (err: any) {
        yield put(RECOVER_PASSWORD.error(err?.response));
    }
}

function* recoverChangePassword(action: UserTypes.ActionRecoverChangePassword) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.recoverChangePassword, action.meta);
        yield put(RECOVER_CHANGE_PASSWORD.success(response));
    } catch (err: any) {
        yield put(RECOVER_CHANGE_PASSWORD.error(err?.response));
    }
}

function* checkToken(action: UserTypes.ActionCheckToken) {
    try {
        const response: AxiosResponse<void> = yield call(userClient.checkToken, action.meta);
        yield put(CHECK_TOKEN.success(response));
    } catch (err: any) {
        yield put(CHECK_TOKEN.error(err?.response));
    }
}

function* getCurrentUser() {
    try {
        const response: AxiosResponse<UserTypes.UsersAPI.UserDTO> = yield call(userClient.getCurrentUser);
        yield put(GET_CURRENT_USER.success(response));
    } catch (err: any) {
        yield put(GET_CURRENT_USER.error(err?.response));
    }
}

function* authUser(action: UserTypes.ActionAuthUser) {
    try {
        const response: AxiosResponse<OfficesTypes.OfficeType> = yield call(userClient.auth, action.meta);
        yield put(AUTH_USER.success());
        yield put(SELECT_OFFICE_OR_GROUP.success({ data: response.data, type: action.meta.type }));
    } catch (err: any) {
        yield put(AUTH_USER.error(err?.response));
    }
}

export default function* userSagas() {
    yield all([takeLatest(FIND_USERS_BY_OFFICE.BASE, findUsersByOffice)]);
    yield debounce(500, SEARCH_USERS.BASE, searchUsers);
    yield all([takeLatest(CREATE_USER_OFFICE.BASE, createUserOffice)]);
    yield all([takeLatest(MODIFY_PERMISSIONS.BASE, modifyPermissions)]);
    yield all([takeLatest(CREATE_USER.BASE, createUser)]);
    yield all([takeLatest(REMOVE_USER_OFFICE.BASE, removeUserOffice)]);
    yield all([takeLatest(CHANGE_PASSWORD_BY_TOKEN.BASE, changePasswordByToken)]);
    yield all([takeLatest(CHANGE_PASSWORD.BASE, changePassword)]);
    yield all([takeLatest(UPDATE_EMAIL.BASE, updateEmail)]);
    yield all([takeLatest(RECOVER_PASSWORD.BASE, recoverPassword)]);
    yield all([takeLatest(RECOVER_CHANGE_PASSWORD.BASE, recoverChangePassword)]);
    yield all([takeLatest(CHECK_TOKEN.BASE, checkToken)]);
    yield all([takeLatest(GET_CURRENT_USER.BASE, getCurrentUser)]);
    yield all([takeLatest(AUTH_USER.BASE, authUser)]);
}
