import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function BeforeUnload({ children }: { children: React.ReactElement }) {
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    // eslint-disable-next-line
    const onUnload = (e: any) => {
        e.preventDefault();
        e.returnValue = '';
    };

    // eslint-disable-next-line
    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm(t(`BeforeUnload.confirm`))) {
            const url = new URL(e.target.href);
            navigate(url.pathname);
        }
    };

    useEffect(() => {
        const anchors = document.getElementsByTagName('a');
        for (let i = 0; i < anchors.length; i++) {
            anchors[i].addEventListener('click', onClick, false);
        }
        return () => {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].removeEventListener('click', onClick);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClick]);

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);
        return () => window.removeEventListener('beforeunload', onUnload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onUnload]);

    return children;
}

export default React.memo(BeforeUnload);
