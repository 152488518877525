import React, { Fragment, useEffect, useState } from 'react';
import styles from 'page/preorders/details/PreorderDetailsPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { PermissionType } from 'constants/PermissionType';
import IsAvailable from 'components/access/IsAvailable';
import Access from 'components/access/Access';
import { useTranslation } from 'react-i18next';
import {
    CLEAR_PREORDER_DETAILS,
    DOWNLOAD_PREORDER,
    FETCH_ALL,
    FETCH_PREORDER_DETAILS,
    FETCH_PREORDERS_HISTORY,
    FETCH_RESULTS,
    REPEAT
} from 'redux/preorders/actions';
import Notification from 'components/notification/Notification';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import PreorderForm from 'page/preorders/components/form/PreorderForm';
import { formatDate } from 'utils/timeUtils';
import { parseISO } from 'date-fns';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Status from 'page/preorders/components/form/Status';
import ReactTooltip from 'react-tooltip';
import { preorderDetailsSelector, preordersHistoryDataSelector } from 'redux/preorders/selectors';
import repeatPreorderIcon from 'themes/icons/repeatPreorder.svg';
import pdfIcon from 'themes/icons/pdf.svg';
import Icon from 'components/icon/Icon';
import { useNavigate } from 'react-router-dom';
import PreorderList from 'page/preorders/create/components/list/PreorderList';
import { PreordersTypes } from 'types/PreordersTypes';
import { LIMIT_ITEMS_DEFAULT } from 'constants/Application';
import ResultList from 'components/list/ResultList';
const queryString = require('query-string');

const PreorderDetailsPage = () => {
    const { selectedOfficeOrGroup } = useSelector((state: AppTypes.State) => state.offices);
    const preorderDetails = useSelector((state: AppTypes.State) => preorderDetailsSelector(state));
    const preordersHistory = useSelector((state: AppTypes.State) => preordersHistoryDataSelector(state));
    const { hasNextPage } = useSelector((state: AppTypes.State) => state.preorders);
    const actions = useSelector(actionsSelector);
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const parsedLocationString = queryString.parse(window.location.search);
    const [serverSideFilter, setServerSideFilter] = useState<PreordersTypes.ServerSideFilter>({
        id: parsedLocationString.id,
        patientId: parsedLocationString.patientId,
        start: parsedLocationString.start || 0,
        limit: LIMIT_ITEMS_DEFAULT
    });

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            const newLocationString = queryString.stringify(serverSideFilter, {
                skipNull: true
            });
            navigate('/preorders/details?' + newLocationString);
            dispatch(FETCH_PREORDERS_HISTORY.base(serverSideFilter));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverSideFilter, selectedOfficeOrGroup?.id]);

    useEffect(() => {
        if (selectedOfficeOrGroup?.id) {
            dispatch(FETCH_PREORDER_DETAILS.base({ id: serverSideFilter.id }));
        }
        return () => {
            dispatch(CLEAR_PREORDER_DETAILS.base());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOfficeOrGroup?.id, serverSideFilter]);

    useEffect(() => {
        if (actions[FETCH_PREORDER_DETAILS.BASE]?.success && preorderDetails?.inzs?.length > 0) {
            dispatch(
                FETCH_RESULTS.base({
                    inzs: preorderDetails?.inzs,
                    clientCode: preorderDetails?.dstOffice?.clientCode
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[FETCH_PREORDER_DETAILS.BASE]?.success]);

    return (
        <div className={styles.container}>
            {preorderDetails && !actions[FETCH_PREORDER_DETAILS.BASE]?.loading && (
                <Fragment>
                    <div className={styles.breadcrumbs}>
                        <Breadcrumbs
                            links={[
                                {
                                    link: '/preorders',
                                    name: 'Предзаказы',
                                    active: false
                                },
                                {
                                    link: '/preorders',
                                    name: preorderDetails.number,
                                    active: true
                                }
                            ]}
                        />
                    </div>
                </Fragment>
            )}
            <IsAvailable options={[{ preOrders: { enable: true } }]}>
                <Access
                    officeGroup={{
                        selected: Array.isArray(selectedOfficeOrGroup?.offices),
                        message: t(`Common.requestOfficeNotice`)
                    }}
                    errors={[actions[FETCH_PREORDER_DETAILS.BASE]?.error]}
                    message={t(`Common.preordersNoAccess`)}
                >
                    {!actions[FETCH_PREORDER_DETAILS.BASE]?.loading && actions[FETCH_ALL.BASE]?.error && (
                        <Notification style={{ margin: '10px 0' }} type={'error'}>
                            <p>{actions[FETCH_PREORDER_DETAILS.BASE]?.error?.message}</p>
                        </Notification>
                    )}
                    {actions[FETCH_PREORDER_DETAILS.BASE]?.loading && <Preloader />}
                    {!actions[FETCH_PREORDER_DETAILS.BASE]?.loading && preorderDetails.id && (
                        <Fragment>
                            <div className={styles.head}>
                                <h1 className={styles.title}>
                                    Предзаказ №{preorderDetails.number} от {formatDate(parseISO(preorderDetails.createdDate), 'ru', 'dd.MM.yyyy')}
                                </h1>
                                <div className={styles.actions}>
                                    <div
                                        className={styles.repeat}
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                            dispatch(DOWNLOAD_PREORDER.base({ preorderId: preorderDetails.id }));
                                        }}
                                    >
                                        <Icon data-tip={`Скачать предзаказ`} data-for={'preorders'} height={'17px'} width={'20px'} icon={`${pdfIcon}#pdf`} />
                                        Скачать предзаказ
                                    </div>
                                    <div
                                        className={styles.repeat}
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                            dispatch(REPEAT.base(preorderDetails));
                                            navigate('/preorders/create', { state: { repeat: true } });
                                        }}
                                    >
                                        <Access permissions={[PermissionType.PREORDERS]}>
                                            <Icon
                                                data-tip={`Повторить предзаказ`}
                                                data-for={'preorders'}
                                                height={'15px'}
                                                width={'20px'}
                                                icon={`${repeatPreorderIcon}#repeatPreorder`}
                                            />
                                            Повторить предзаказ
                                        </Access>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.status}>
                                <Status preorder={preorderDetails} inlineAddress={true} />
                            </div>
                            <PreorderForm details={true} preorder={preorderDetails} dispatch={dispatch} />
                            {preorderDetails?.results?.length > 0 && (
                                <div className={styles.tableResults}>
                                    <h2 className={styles.entryTitle}>Результаты исследований</h2>
                                    <ResultList preorders={true} loading={actions[FETCH_RESULTS.BASE]?.loading} results={preorderDetails?.results} />
                                </div>
                            )}
                            {preordersHistory?.length > 0 && (
                                <div className={styles.preordersHistory}>
                                    <h2 className={styles.entryTitle}>История предзаказов</h2>
                                    <PreorderList
                                        loading={actions[FETCH_PREORDERS_HISTORY.BASE]?.loading}
                                        serverSideFilter={serverSideFilter}
                                        setServerSideFilter={setServerSideFilter}
                                        preorders={preordersHistory}
                                        hasNextPage={hasNextPage}
                                    />
                                </div>
                            )}
                        </Fragment>
                    )}
                    <Preloader show={actions[DOWNLOAD_PREORDER.BASE]?.loading} overlay={true} />
                    {
                        // @ts-ignore
                        <ReactTooltip
                            id={'preorders'}
                            border={true}
                            borderColor={'#939393'}
                            resizeHide={true}
                            html={true}
                            type={'light'}
                            effect={'solid'}
                            place={'top'}
                        />
                    }
                </Access>
            </IsAvailable>
        </div>
    );
};

export default React.memo(PreorderDetailsPage);
