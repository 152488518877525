import React, { Fragment } from 'react';
import styles from './Address.module.css';
import { PreordersTypes } from 'types/PreordersTypes';

const Address = ({ preorder, inlineAddress = false }: any) => {
    const renderAddress = (preorder: PreordersTypes.Preorder) => {
        return (
            preorder?.dstOffice?.address && (
                <Fragment>
                    {inlineAddress ? (
                        <span onClick={(event) => event.stopPropagation()} className={styles.inlineAddressLink}>
                            <span>Адрес визита:</span> {`${preorder?.dstOffice?.clientCode} | ${preorder?.dstOffice?.address}`}
                        </span>
                    ) : (
                        <span
                            onClick={(event) => event.stopPropagation()}
                            data-for={'preorders'}
                            data-tip={`${preorder?.dstOffice?.clientCode} | ${preorder?.dstOffice?.address}`}
                            className={styles.addressLink}
                        >
                            Адрес визита
                        </span>
                    )}
                </Fragment>
            )
        );
    };

    return <Fragment>{renderAddress(preorder)}</Fragment>;
};

export default React.memo(Address);
