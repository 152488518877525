import { SET_ACTIONS } from 'redux/app/actions';
import { LOGOUT } from 'redux/auth/actions';
import { STATUS_CODES } from 'constants/StatusCodes';
export const logger = (store: any) => {
    return function (next: any) {
        return function (action: any) {
            const type = action.type;
            next(action);
            const state = store.getState();
            const REQUEST_ID = action.meta?.REQUEST_ID ? `_${action.meta?.REQUEST_ID}` : action.payload?.REQUEST_ID ? `_${action.payload?.REQUEST_ID}` : '';
            if (type.endsWith('_STARTED') || type === action.typeName) {
                return store.dispatch({
                    type: SET_ACTIONS,
                    meta: {
                        [`${action.typeName}${REQUEST_ID}`]: {
                            request: action.meta,
                            loading: true,
                            success: false,
                            error: undefined
                        }
                    }
                });
            } else if (type.endsWith('_SUCCEEDED')) {
                return store.dispatch({
                    type: SET_ACTIONS,
                    meta: {
                        [`${action.typeName}${REQUEST_ID}`]: {
                            loading: false,
                            success: true,
                            error: undefined,
                            payload: action?.payload || null
                        }
                    }
                });
            } else if (type.endsWith('_ERRORED')) {
                if (action?.payload?.status === STATUS_CODES.UNAUTHORIZED && state.auth.authenticated) {
                    return store.dispatch({
                        type: LOGOUT.BASE
                    });
                } else {
                    return store.dispatch({
                        type: SET_ACTIONS,
                        meta: {
                            [`${action.typeName}${REQUEST_ID}`]: {
                                loading: false,
                                success: false,
                                error: action.payload?.data || {}
                            }
                        }
                    });
                }
            } else {
                return state;
            }
        };
    };
};
