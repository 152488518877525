import React, { Fragment, FC } from 'react';
import styles from 'components/card/Card.module.css';
import { capitalizeFirstLowerCaseRest } from 'utils/messageUtils';
import { DynamicsTypes } from 'types/DynamicsTypes';
import { useTranslation } from 'react-i18next';

type TProps = {
    patient?: DynamicsTypes.TypePatient;
    officeName?: string;
};

const PatientCard: FC<TProps> = ({ patient, officeName }) => {
    const { t } = useTranslation('common');
    return (
        <Fragment>
            {patient?.nss && (
                <div className={styles.container}>
                    <div className={styles.patientInfoItems}>
                        <div className={styles.patientInfoRow}>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>{t(`VetunionPatientCard.owner`)}</div>
                                <div className={styles.patientInfoDesc}>{capitalizeFirstLowerCaseRest(patient?.lastName || '-')}</div>
                            </div>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>{t(`VetunionPatientCard.specie`)}</div>
                                <div className={styles.patientInfoDesc}>{patient?.specie || '-'}</div>
                            </div>
                        </div>
                        <div className={styles.patientInfoRow}>
                            <div className={styles.patientInfoItem}>
                                <div className={styles.patientInfoTitle}>{t(`VetunionPatientCard.nickname`)}</div>
                                <div className={styles.patientInfoDesc}>{capitalizeFirstLowerCaseRest(patient?.firstName || '-')}</div>
                            </div>
                            {officeName && officeName?.length > 0 && (
                                <div className={styles.patientInfoOfficeName}>
                                    <div className={styles.patientInfoTitle}>{t(`PatientCard.medicalOffice`)}</div>
                                    <div className={styles.patientInfoDesc}>{officeName || '-'}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(PatientCard);
