import { FETCH_BY_CODES, FETCH_BY_CODE, CREATE_COMMENT, UPDATE_COMMENT } from './actions';
import { DynamicsTypes } from 'types/DynamicsTypes';
import { ResultsTypes } from 'types/ResultsTypes';

const initialState: DynamicsTypes.State = {
    dynamics: {},
    details: {},
    loading: false,
    success: false,
    error: false
};

const userReducer = (state = initialState, action: DynamicsTypes.Action) => {
    switch (action.type) {
        case FETCH_BY_CODES.STARTED:
        case FETCH_BY_CODES.ERRORED:
        case FETCH_BY_CODES.SUCCEEDED: {
            const data = action?.payload?.data;
            return {
                ...state,
                dynamics: data ? { ...data } : {}
            };
        }
        case FETCH_BY_CODE.STARTED:
        case FETCH_BY_CODE.ERRORED:
        case FETCH_BY_CODE.SUCCEEDED: {
            const details = action?.payload?.data;
            return {
                ...state,
                details: details ? { ...details } : {}
            };
        }
        case CREATE_COMMENT.STARTED:
        case CREATE_COMMENT.ERRORED:
        case CREATE_COMMENT.SUCCEEDED: {
            const createdComment = action?.payload?.data;
            let newTestResults: ResultsTypes.TypeTestResult[] | [] = [];
            if (createdComment && state.details.testResults) {
                newTestResults = [...state.details.testResults];
                const foundedResult = newTestResults.findIndex((result: any) => result.inz === createdComment?.inz);
                if (foundedResult >= 0) {
                    newTestResults[foundedResult].userComment = (createdComment && createdComment.comment) || null;
                }
            }
            return {
                ...state,
                details: {
                    ...state.details,
                    testResults: newTestResults.length > 0 ? [...newTestResults] : state.details.testResults ? [...state.details.testResults] : []
                }
            };
        }
        case UPDATE_COMMENT.STARTED:
        case UPDATE_COMMENT.ERRORED:
        case UPDATE_COMMENT.SUCCEEDED: {
            const createdComment = action?.payload?.data;
            let newTestResults: ResultsTypes.TypeTestResult[] | [] = [];
            if (createdComment && state.details.testResults) {
                newTestResults = [...state.details.testResults];
                const foundedResult = newTestResults.findIndex((result: any) => result.inz === createdComment?.inz);
                if (foundedResult >= 0) {
                    newTestResults[foundedResult].userComment = (createdComment && createdComment.comment) || null;
                }
            }
            return {
                ...state,
                details: {
                    ...state.details,
                    testResults: newTestResults.length > 0 ? [...newTestResults] : state.details.testResults ? [...state.details.testResults] : []
                }
            };
        }
        default:
            return state;
    }
};

export default userReducer;
