/**
 * Перечисление типов запросов для заявки на вызов курьера курьеру
 */

export enum REQUEST_TYPE {
    // Вызов курьера
    CALL = 'CALL',
    // Отмена вызова курьера
    CANCEL = 'CANCEL'
}
