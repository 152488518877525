import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { AppTypes } from 'types/AppTypes';
import { useTranslation } from 'react-i18next';
import { isEmpty, isMatch, keys } from 'lodash';

const IsAvailable = ({
    children,
    options,
    notAvailableMessage = true
}: {
    children: React.ReactElement | React.ReactElement[];
    options: { news?: boolean; results?: boolean; preOrders?: { enable: boolean }; orders?: { enable?: boolean; couriers?: boolean; materials?: boolean } }[];
    notAvailableMessage?: string | boolean;
}) => {
    const { siteSetting } = useSelector((state: AppTypes.State) => state.startup.settings);
    const { t } = useTranslation('common');
    let checkPoints: boolean[] = [];
    options.forEach((option: any) => {
        const optionKeys = keys(option);
        optionKeys.map((keys) => {
            if (!isEmpty(option[keys])) {
                if (isMatch(siteSetting[keys], option[keys])) {
                    checkPoints.push(true);
                }
            } else {
                if (isMatch(siteSetting, option)) {
                    checkPoints.push(true);
                }
            }
        });
    });

    return checkPoints.includes(true) ? <Fragment>{children}</Fragment> : notAvailableMessage ? <div className={'noAccess'}>{t(`Common.noAccess`)}</div> : null;
};

export default React.memo(IsAvailable);
