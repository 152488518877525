import {
    ADD_PRODUCT,
    CALCULATE_OFFICE,
    CHOOSE_OFFICE,
    CLEAR_PREORDER,
    CLEAR_PREORDER_DETAILS,
    CREATE,
    CREATE_PATIENT,
    DELETE_PRODUCT,
    FETCH_ALL,
    FETCH_ALL_PRODUCTS,
    FETCH_OFFICES,
    FETCH_PREORDER_DETAILS,
    FETCH_PREORDERS_HISTORY,
    FETCH_PRODUCTS,
    FETCH_RESULTS,
    GET_STATUS,
    REMOVE_INVALID_PRODUCTS,
    SEARCH_PATIENTS,
    SELECT_OFFICE,
    SET_BIOMATERIALS,
    SET_CREATE_PATIENT_FORM,
    SET_FILTER,
    SET_PREORDER,
    SHOW_BIOMATERIAL_SELECTION_MODAL,
    SHOW_OFFICE_SELECTION_MODAL
} from 'redux/preorders/actions';
import { PreordersTypes } from 'types/PreordersTypes';
import { keyBy } from 'lodash';

const defaultPosition = [55.751574, 37.573856];
const defaultCreateValues: any = {
    data: {},
    products: [],
    daysOfLifePreorder: 10,
    biomaterialModal: {
        show: false,
        productsIds: []
    },
    officeData: {},
    calculateOffice: null,
    invalidProducts: [],
    patients: [],
    createPatient: null,
    categories: [],
    allProducts: [],
    officeId: null,
    offices: [],
    position: {
        center: defaultPosition,
        zoom: 10
    },
    filter: {},
    chosenId: null,
    validationData: null,
    showOfficeSelectionModal: false,
    orderProductsKeys: null
};

// @ts-ignore
const initialState: PreordersTypes.State = {
    preorders: [],
    hasNextPage: null,
    preorderDetails: {},
    preorder: { ...defaultCreateValues }
};

const preordersReducer = (state: PreordersTypes.State = initialState, action: any): PreordersTypes.State => {
    const preorder = state.preorder;
    switch (action.type) {
        case FETCH_ALL.STARTED:
        case FETCH_ALL.ERRORED:
        case FETCH_ALL.SUCCEEDED: {
            const data = action?.payload?.data?.preorders;
            const hasNextPage = action?.payload?.data?.hasNextPage || null;
            return {
                ...state,
                preorders: data && data.length > 0 ? [...data] : [],
                hasNextPage
            };
        }
        case FETCH_PREORDER_DETAILS.STARTED:
        case FETCH_PREORDER_DETAILS.ERRORED:
        case FETCH_PREORDER_DETAILS.SUCCEEDED: {
            const data = action?.payload?.data;
            return {
                ...state,
                preorderDetails: data ? { ...data } : null
            };
        }
        case SEARCH_PATIENTS.STARTED:
        case SEARCH_PATIENTS.ERRORED:
        case SEARCH_PATIENTS.SUCCEEDED: {
            const data = action?.payload?.data;
            return {
                ...state,
                preorder: {
                    ...state.preorder,
                    patients: data && data.length > 0 ? [...data] : []
                }
            };
        }
        case FETCH_ALL_PRODUCTS.STARTED:
        case FETCH_ALL_PRODUCTS.ERRORED:
        case FETCH_ALL_PRODUCTS.SUCCEEDED: {
            const data = action?.payload?.data;
            return {
                ...state,
                preorder: {
                    ...state.preorder,
                    allProducts: [...data]
                }
            };
        }

        case FETCH_PRODUCTS.STARTED:
        case FETCH_PRODUCTS.ERRORED:
        case FETCH_PRODUCTS.SUCCEEDED: {
            const products = action.payload.data;
            return {
                ...state,
                preorder: {
                    ...preorder,
                    products
                }
            };
        }

        case SET_CREATE_PATIENT_FORM.BASE: {
            return {
                ...state,
                preorder: {
                    ...preorder,
                    createPatient: {
                        ...preorder.createPatient,
                        ...(action.meta || {})
                    }
                }
            };
        }

        case CREATE_PATIENT.STARTED:
        case CREATE_PATIENT.ERRORED:
        case CREATE_PATIENT.SUCCEEDED: {
            const patient = action.payload.data;
            return {
                ...state,
                preorder: {
                    ...preorder,
                    patient
                }
            };
        }

        case ADD_PRODUCT.SUCCEEDED: {
            const { product } = action.meta;
            let products: any = (preorder && preorder?.products) || [];
            if (Array.isArray(product)) {
                product && product.forEach((item: any) => products.push(item));
            } else {
                product && products.push(product);
            }
            return {
                ...state,
                preorder: {
                    ...preorder,
                    products
                }
            };
        }

        case DELETE_PRODUCT.SUCCEEDED: {
            const data = action.meta;
            let products = (preorder && preorder?.products) || [];
            if (Array.isArray(data)) {
                const productsById = keyBy(products, 'id');
                products = products.filter((product: any) => productsById[product.id]);
            } else {
                products = products.filter((product: any) => product.article !== data.article);
            }

            return {
                ...state,
                preorder: {
                    ...preorder,
                    products
                }
            };
        }

        case FETCH_OFFICES.STARTED:
        case FETCH_OFFICES.ERRORED:
        case FETCH_OFFICES.SUCCEEDED: {
            let offices = action.payload.data || [];
            let data = action.payload.data || [];
            let position = preorder?.position;
            offices = offices.reduce((map: any, preorderOffice: any) => {
                map[preorderOffice.office.id] = preorderOffice;
                return map;
            }, {});
            position = {
                ...position,
                center: calculateCenter(data)
            };
            return {
                ...state,
                preorder: {
                    ...preorder,
                    offices,
                    position
                }
            };
        }

        case SELECT_OFFICE.STARTED:
        case SELECT_OFFICE.ERRORED:
        case SELECT_OFFICE.SUCCEEDED: {
            const { officeId, productsIds } = action.meta;
            let position = preorder?.position;
            let offices = preorder?.offices;
            let validationData = preorder?.validationData;
            const selectedOffice = preorder?.offices[officeId];
            position = {
                ...preorder?.position,
                center: [selectedOffice.office.latitude, selectedOffice.office.longitude]
            };
            const response = action.payload.data;
            offices = {
                ...offices,
                [officeId]: {
                    ...selectedOffice,
                    allProductsAvailable: productsIds.length === response.validProductsIds.length
                }
            };
            validationData = {
                ...validationData,
                [officeId]: {
                    validProductsIds: response.validProductsIds
                }
            };

            return {
                ...state,
                preorder: {
                    ...preorder,
                    offices,
                    validationData,
                    position,
                    officeId: officeId
                }
            };
        }

        case CHOOSE_OFFICE.SUCCEEDED: {
            const office = action.meta;

            return {
                ...state,
                preorder: {
                    ...preorder,
                    officeId: office.id,
                    dstOffice: { ...office }
                }
            };
        }

        case CALCULATE_OFFICE.SUCCEEDED: {
            let officeData = {
                ...action.payload.data,
                officeId: action.meta.officeId
            };
            return {
                ...state,
                preorder: {
                    ...preorder,
                    officeData
                }
            };
        }

        case SET_PREORDER.BASE: {
            return {
                ...state,
                preorder: {
                    ...preorder,
                    ...(action.meta || {})
                }
            };
        }
        case SET_FILTER.BASE: {
            return {
                ...state,
                preorder: {
                    ...preorder,
                    filter: action.meta
                }
            };
        }

        case SHOW_OFFICE_SELECTION_MODAL.BASE: {
            const { show } = action.meta;
            let filter = state.preorder?.filter;
            if (!show) {
                filter = {};
            }
            return {
                ...state,
                preorder: {
                    ...preorder,
                    filter,
                    showOfficeSelectionModal: show
                }
            };
        }

        case SHOW_BIOMATERIAL_SELECTION_MODAL.BASE: {
            const { show, productsIds } = action.meta;
            return {
                ...state,
                preorder: {
                    ...preorder,
                    biomaterialModal: {
                        ...preorder.biomaterialModal,
                        show,
                        productsIds: productsIds || []
                    }
                }
            };
        }

        case REMOVE_INVALID_PRODUCTS.SUCCEEDED: {
            const { articles } = action.meta;
            const products = ((preorder && preorder?.products) || []).filter((product: any) => !articles.includes(product.article));
            return {
                ...state,
                preorder: {
                    ...preorder,
                    products
                }
            };
        }

        case GET_STATUS.SUCCEEDED: {
            const { REQUEST_ID, response } = action.payload;

            const newPreorderList = [...state.preorders];
            const foundedPreorder = newPreorderList.findIndex((preorder: any) => preorder.id === REQUEST_ID);
            if (foundedPreorder >= 0) {
                newPreorderList[foundedPreorder].armpsStatus = response.data || null;
            }

            return {
                ...state,
                preorders: newPreorderList
            };
        }

        case CREATE.SUCCEEDED: {
            const createdPreorder = action.payload.data;
            return {
                ...state,
                createdPreorder
            };
        }

        case CLEAR_PREORDER.BASE: {
            return {
                ...state,
                preorder: { ...defaultCreateValues, products: [] },
                createdPreorder: null
            };
        }

        case CLEAR_PREORDER_DETAILS.BASE: {
            return {
                ...state,
                preorderDetails: {},
                preordersHistory: []
            };
        }

        case FETCH_RESULTS.STARTED:
        case FETCH_RESULTS.ERRORED:
        case FETCH_RESULTS.SUCCEEDED: {
            const results = action?.payload?.data;
            return {
                ...state,
                preorderDetails: {
                    ...state.preorderDetails,
                    results
                }
            };
        }

        case FETCH_PREORDERS_HISTORY.STARTED:
        case FETCH_PREORDERS_HISTORY.ERRORED:
        case FETCH_PREORDERS_HISTORY.SUCCEEDED: {
            const data = action?.payload?.data?.preorders;
            const hasNextPage = action?.payload?.data?.hasNextPage || null;
            return {
                ...state,
                preordersHistory: data && data.length > 0 ? [...data] : [],
                hasNextPage
            };
        }

        case SET_BIOMATERIALS.STARTED:
        case SET_BIOMATERIALS.ERRORED:
        case SET_BIOMATERIALS.SUCCEEDED: {
            const orderProductsKeys = action?.payload || [];
            return {
                ...state,
                preorder: {
                    ...preorder,
                    ...orderProductsKeys
                }
            };
        }

        default:
            return state;
    }
};

function calculateCenter(itemOffices: any) {
    if (!(itemOffices?.length > 0)) {
        return defaultPosition;
    }

    const firstOffice = itemOffices[0].office;
    const data = {
        minLatitude: firstOffice.latitude,
        maxLatitude: firstOffice.latitude,
        minLongitude: firstOffice.longitude,
        maxLongitude: firstOffice.longitude
    };

    itemOffices.forEach((itemOffice: any) => {
        const office = itemOffice.office;
        if (data.minLatitude > office.latitude) {
            data.minLatitude = office.latitude;
        }
        if (data.maxLatitude < office.latitude) {
            data.maxLatitude = office.latitude;
        }
        if (data.minLongitude > office.longitude) {
            data.minLongitude = office.longitude;
        }
        if (data.maxLongitude < office.longitude) {
            data.maxLongitude = office.longitude;
        }
    });

    return [data.minLatitude + (data.maxLatitude - data.minLatitude) / 2, data.minLongitude + (data.maxLongitude - data.minLongitude) / 2];
}

export default preordersReducer;
