import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as dynamicClient from 'client/DynamicClient';
import { FETCH_BY_CODES, FETCH_BY_CODE, DOWNLOAD, CREATE_COMMENT, UPDATE_COMMENT } from './actions';
import { AxiosResponse } from 'axios';
import { loadFile } from 'utils/fileUtils';
import { DynamicsTypes } from 'types/DynamicsTypes';

function* fetchByCodes(action: DynamicsTypes.DynamicsAPI.ActionFetchByCodes) {
    try {
        const response: AxiosResponse<DynamicsTypes.DynamicsAPI.DynamicsDTO> = yield call(dynamicClient.fetchByCodes, action.meta);
        yield put(FETCH_BY_CODES.success(response));
    } catch (err: any) {
        yield put(FETCH_BY_CODES.error(err?.response));
    }
}

function* fetchByCode(action: DynamicsTypes.DynamicsAPI.ActionFetchByCode) {
    try {
        const response: AxiosResponse<DynamicsTypes.DynamicsAPI.DynamicsDTO> = yield call(dynamicClient.fetchByCode, action.meta);
        yield put(FETCH_BY_CODE.success(response));
    } catch (err: any) {
        yield put(FETCH_BY_CODE.error(err?.response));
    }
}

function* download(action: DynamicsTypes.DynamicsAPI.ActionFetchByCodes) {
    try {
        const response: AxiosResponse<BlobPart> = yield call(dynamicClient.download, action.meta);
        yield loadFile(response, response.headers['content-type']);
        yield put(DOWNLOAD.success());
    } catch (err: any) {
        yield put(DOWNLOAD.error(err?.response));
    }
}

function* createComment(action: any) {
    try {
        const response: AxiosResponse<DynamicsTypes.DynamicsAPI.CommentDTO> = yield call(dynamicClient.createComment, action.meta);
        yield put(CREATE_COMMENT.success(response));
    } catch (err: any) {
        yield put(CREATE_COMMENT.error(err?.response));
    }
}

function* updateComment(action: any) {
    try {
        const response: AxiosResponse<DynamicsTypes.DynamicsAPI.CommentDTO> = yield call(dynamicClient.updateComment, action.meta);
        yield put(UPDATE_COMMENT.success(response));
    } catch (err: any) {
        yield put(UPDATE_COMMENT.error(err?.response));
    }
}

export default function* resultsSagas() {
    yield all([takeLatest(FETCH_BY_CODES.BASE, fetchByCodes)]);
    yield all([takeLatest(FETCH_BY_CODE.BASE, fetchByCode)]);
    yield all([takeLatest(DOWNLOAD.BASE, download)]);
    yield all([takeLatest(CREATE_COMMENT.BASE, createComment)]);
    yield all([takeLatest(UPDATE_COMMENT.BASE, updateComment)]);
}
