import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import menuResultsIcon from 'themes/icons/results.svg';
import menuRequestsIcon from 'themes/icons/requests.svg';
import menuLaboratoryIcon from 'themes/icons/laboratory.svg';
import menuPreordersIcon from 'themes/icons/preorders.svg';
import styles from './HeaderMenu.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { newsCountersSelector } from 'redux/news/selectors';
import { GET_COUNTERS } from 'redux/news/actions';
import { AUTH_USER } from 'redux/user/actions';
import { actionsSelector } from 'redux/app/selectors';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';
import { sumBy } from 'lodash';
import IsAvailable from 'components/access/IsAvailable';
import { LIMIT_ITEMS_DEFAULT } from 'constants/Application';

const HeaderMenu = (props: any) => {
    const { authenticated } = props;
    const { t } = useTranslation('common');
    const newsCounters = useSelector(newsCountersSelector);
    const actions = useSelector(actionsSelector);
    const dispatch = useDispatch();
    const location: any = useLocation();

    useEffect(() => {
        if (actions[AUTH_USER.BASE]?.success && !(newsCounters.length > 0)) {
            dispatch(GET_COUNTERS.base());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions[AUTH_USER.BASE]]);

    if (!authenticated) {
        return null;
    }

    return (
        <nav className={styles.headerMenu}>
            <ul className={styles.menu}>
                <IsAvailable options={[{ results: true }]} notAvailableMessage={false}>
                    <li className={styles.menuItem}>
                        <NavLink className={({ isActive }) => (isActive ? styles.activeMenu : undefined)} to={`/results?limit=${LIMIT_ITEMS_DEFAULT}&start=0`}>
                            <Icon icon={`${menuResultsIcon}#results`} className={styles.headerMenuIcon} />
                            {t(`Common.results`)}
                        </NavLink>
                    </li>
                </IsAvailable>
                <IsAvailable options={[{ orders: { enable: true } }]} notAvailableMessage={false}>
                    <li className={styles.menuItem}>
                        <NavLink className={({ isActive }) => (isActive ? styles.activeMenu : undefined)} to={'/requests'}>
                            <Icon icon={`${menuRequestsIcon}#requests`} className={styles.headerMenuIcon} />
                            {t(`Common.requests`)}
                        </NavLink>
                    </li>
                </IsAvailable>
                <IsAvailable options={[{ news: true }]} notAvailableMessage={false}>
                    <li className={styles.menuItem}>
                        <NavLink className={() => (location.pathname?.search('/news') >= 0 ? styles.activeMenu : undefined)} to={'/news?category=INVITRO_NEWS'}>
                            <Icon icon={`${menuLaboratoryIcon}#laboratory`} className={styles.headerMenuLaboratory} />
                            {t(`Common.laboratory`)}
                            {sumBy(newsCounters, 'count') > 0 && <span className={styles.messageCounter}>{sumBy(newsCounters, 'count')}</span>}
                        </NavLink>
                    </li>
                </IsAvailable>
                <IsAvailable options={[{ preOrders: { enable: true } }]} notAvailableMessage={false}>
                    <li className={styles.menuItem}>
                        <NavLink className={() => (location.pathname?.search('/preorders') >= 0 ? styles.activeMenu : undefined)} to={'/preorders'}>
                            <Icon icon={`${menuPreordersIcon}#preorders`} className={styles.headerMenuPreorders} />
                            Предзаказы
                        </NavLink>
                    </li>
                </IsAvailable>
            </ul>
        </nav>
    );
};

export default React.memo(HeaderMenu);
