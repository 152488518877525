import React, { FC, useState } from 'react';
import cx from 'classnames/bind';
import Input from 'components/input/Input';
import styles from './InputPassword.module.css';
import zxcvbn from 'zxcvbn';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon/Icon';
import visibleIcon from 'themes/icons/visible.svg';
import invisibleIcon from 'themes/icons/invisible.svg';

const classNames = cx.bind(styles);
const STRENGTH: any = {
    0: 'Worst',
    1: 'Bad',
    2: 'Weak',
    3: 'Good',
    4: 'Strong'
};

const InputPasswordComponent: FC<any> = ({ showStrength, showVisibilityBtn, strengthTranslation, setNotValidPassword, notMatch, ...rest }) => {
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState<string>('password');
    const [strength, setStrength] = useState<any>(-1);
    const { t } = useTranslation('common');

    const changeVisibility = () => {
        setVisible(!visible);
        setType(visible ? 'password' : 'text');
    };

    const onPasswordChange = (event: any) => {
        if (showStrength) {
            const text = event.currentTarget.value;
            setStrength(text ? zxcvbn(text).score : -1);
            setNotValidPassword && (zxcvbn(text).score <= 1 ? setNotValidPassword(true) : setNotValidPassword(false));
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.input}>
                <Input type={type} onKeyUp={(event: any) => onPasswordChange(event)} {...rest} />
                {visible && (
                    <Icon
                        onClick={() => changeVisibility()}
                        icon={`${visibleIcon}#visible`}
                        className={classNames({
                            visibilityBtn: true,
                            invisible: !showVisibilityBtn
                        })}
                    />
                )}
                {!visible && (
                    <Icon
                        onClick={() => changeVisibility()}
                        icon={`${invisibleIcon}#invisible`}
                        className={classNames({
                            visibilityBtn: true,
                            invisible: !showVisibilityBtn
                        })}
                    />
                )}
                <span
                    className={classNames(
                        {
                            invisible: !showStrength || strength < 0
                        },
                        styles.strengthLine
                    )}
                    style={{ transform: `scale(${strength / 4}, 1)` }}
                />
            </div>
            <p
                className={classNames(
                    {
                        invisible: !showStrength || strength < 0
                    },
                    styles.strengthText,
                    `passwordStrength${strength}`
                )}
            >
                {strengthTranslation ? strengthTranslation[strength] : STRENGTH[strength]}
            </p>
            {notMatch && <p className={classNames(styles.strengthText, styles.passwordStrength0)}>{t(`InputPassword.passwordsNotMatch`)}</p>}
        </div>
    );
};

const InputPassword = (props: any) => {
    const { t } = useTranslation('common');
    return (
        <InputPasswordComponent
            {...props}
            strengthTranslation={{
                0: t(`InputPassword.passwordVerySimple`),
                1: t(`InputPassword.passwordSimple`),
                2: t(`InputPassword.passwordAverageReliability`),
                3: t(`InputPassword.passwordStrong`),
                4: t(`InputPassword.passwordVeryStrong`)
            }}
        />
    );
};

export default React.memo(InputPassword);
