import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFoundPage.module.css';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const { t } = useTranslation('common');
    return (
        <div className={styles.container}>
            <h1>{t(`Common.pageNotFound`)}</h1>
            <Link to={'/'}>{t(`Common.backBeginning`)}</Link>
        </div>
    );
};

export default React.memo(NotFoundPage);
