import React, { Fragment } from 'react';
import styles from './Status.module.css';
import classNames from 'classnames';
import { GET_STATUS } from 'redux/preorders/actions';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from 'components/preloader/Preloader';
import { actionsSelector } from 'redux/app/selectors';
import Icon from 'components/icon/Icon';
import { PreordersTypes } from 'types/PreordersTypes';
import { PREORDER_STATUS } from 'constants/PreordersStatus';
import hintPreorderIcon from 'themes/icons/hintPreorder.svg';
import Address from 'page/preorders/components/form/Address';
import { formatDate } from 'utils/timeUtils';
import { parseISO } from 'date-fns';

const ProductList = ({ preorder, inlineAddress = false }: any) => {
    const dispatch = useDispatch();
    const actions = useSelector(actionsSelector);

    const getStatus = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        dispatch(
            GET_STATUS.base({
                preorderId: preorder.id,
                REQUEST_ID: preorder.id
            })
        );
    };

    const renderActiveToDate = (preorder: PreordersTypes.Preorder) =>
        preorder?.armpsStatus === PREORDER_STATUS.CREATED &&
        preorder?.activeToDate && <div className={styles.date}>Активен до {formatDate(parseISO(preorder.activeToDate), 'ru', 'dd MMMM yyyy')}</div>;

    const renderStatus = (preorder: PreordersTypes.Preorder) => {
        switch (preorder.armpsStatus) {
            case PREORDER_STATUS.CREATED:
                return (
                    <div className={styles.statusBlock}>
                        {!actions[`${GET_STATUS.BASE}_${preorder.id}`]?.loading ? (
                            <Fragment>
                                <span
                                    onClick={(event) => getStatus(event)}
                                    data-tip={`Нажмите, чтобы обновить статус`}
                                    data-for={'preorders'}
                                    className={classNames([styles.checkStatus, styles.statusOrange])}
                                >
                                    Создан
                                </span>
                            </Fragment>
                        ) : (
                            <Preloader height={'20px'} width={'20px'} showText={false} />
                        )}
                        <Icon
                            onClick={(event: any) => getStatus(event)}
                            data-tip={`Нажмите, чтобы обновить статус`}
                            data-for={'preorders'}
                            height={'22px'}
                            width={'22px'}
                            setDefaultStyles={false}
                            icon={`${hintPreorderIcon}#hintPreorder`}
                            className={classNames([styles.checkStatusHint, styles.icon])}
                        />
                        <Address preorder={preorder} inlineAddress={inlineAddress} />
                    </div>
                );
            case PREORDER_STATUS.PROCESSED:
                return (
                    <Fragment>
                        <span className={styles.statusGreen}>Оформлен</span>
                        <Address preorder={preorder} inlineAddress={inlineAddress} />
                    </Fragment>
                );
            case PREORDER_STATUS.CANCELLED:
                return (
                    <Fragment>
                        <span
                            onClick={(event) => event.stopPropagation()}
                            data-for={'preorders'}
                            data-tip={`Комментарий: ${preorder?.comment}`}
                            className={styles.statusRed}
                        >
                            Отменен
                        </span>
                        <Address preorder={preorder} inlineAddress={inlineAddress} />
                    </Fragment>
                );
        }
    };

    return (
        <Fragment>
            {renderStatus(preorder)}
            {renderActiveToDate(preorder)}
        </Fragment>
    );
};

export default React.memo(ProductList);
