import React, { Fragment } from 'react';
import classNames from 'classnames';
import styles from './Button.module.css';
import Preloader from 'components/preloader/Preloader';

const Button = React.forwardRef(
    ({
        ref,
        children,
        text,
        onClick,
        className = {},
        disabled,
        innerRef,
        loading,
        ...rest
    }: {
        ref?: any;
        children?: any;
        text?: any;
        onClick?: any;
        className?: any;
        disabled?: any;
        innerRef?: any;
        loading?: any;
    }) => {
        return (
            <button disabled={loading || disabled || false} className={classNames(styles.button, className)} onClick={onClick} ref={innerRef} {...rest}>
                {children ? (
                    <Fragment>
                        {loading && <Preloader height={'15px'} width={'15px'} showText={false} />}
                        {children}
                    </Fragment>
                ) : (
                    <Fragment>{text}</Fragment>
                )}
            </button>
        );
    }
);

export default React.memo(Button);
